{% import converter from 'shared/lib/converter' %}

<div class="{{ price.value | lower_modificator(min_price) }}">
  <a href="/flights/{{ depart_date | short_params(return_date) }}" class="{{ (!price.value && (!is_current_date)) ? 'price--search' : '' }} days" role="calendar_link">
    {% if depart_date_formatted %}
      <span class="prices__date"> {% unsafe depart_date_formatted %}   </span>
    {% endif %}
    {% if return_date_formatted %}
      <span class="prices__date_separator">–</span>
      <span class="prices__date"> {% unsafe return_date_formatted %}   </span>
    {% endif %}
    {% if price.value || (is_current_date) %}
      {% if is_current_date && current_min_price == 0 %}
        <span class="prices__price currency_font">
          ¯\_(ツ)_/¯
        </span>
      {% else %}
        <span class="prices__price currency_font currency_font--{{currency}}">{{ converter.format((is_current_date && current_min_price) || price.value) }}</span>
      {% endif %}
    {% endif %}
  </a>
</div>
