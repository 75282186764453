{% import PriceTile from '../shared/price_tile.monk' %}
{% import Header from '../shared/header.monk' %}
{% import Footer from '../shared/footer.monk' %}
{% import Spinner from 'shared/components/spinner/spinner.monk' %}

<div class="calendar--one_way-wrapper">
  <section class="calendar calendar--one_way">
  <div class="calendar-loader">
    <Spinner />
  </div>
  <Header existDirectFlights="{{ existDirectFlights }}" direct_only="{{ direct_only }}"/>

  <section class="return_dates return_dates--month">
    <header class="return_dates__title">
      <div class="month_name">
        {{month_name}}
        <div class="calendar_roll_button calendar_roll_button--month_left" role="calendar_move" data-d-diff="-1" data-r-diff="0"></div>
        <div class="calendar_roll_button calendar_roll_button--month_right" role="calendar_move" data-d-diff="1" data-r-diff="0"></div>
      </div>
    </header>
    <ul class="return_dates__list">
      {% for week_day of week_days %}
        <li class="{{ week_day.is_weekend ? "return_dates__list_weekend" : "" }}">{% unsafe week_day.name %} </li>
      {% endfor %}
    </ul>
  </section>

  <ul class="prices prices--month">
    {% for i of start_weekday_offset %}
      <li class="prices-offset_days"></li>
    {% endfor %}
    {% for index, date of depart_dates.raw %}
      {% if(date.number < today_number) %}
        <li class="forbidden_dates" role="cell" data-cell="{{ date.date }}">—</li>
      {% else %}
        <li class="{{ (current_depart_date === date.date) ? 'price--current' : '' }}" role="cell" data-cell="{{ date.date }}">
          <PriceTile
            price="{{ prices | price_for_date(date, false, direct_only) }}"
            currency="{{ currency }}"
            depart_date="{{ date.date }}"
            return_date="{{ false }}"
            depart_date_formatted="{{ depart_dates.formatted[index] }}"
            return_date_formatted="{{ false }}"
            min_price="{{ minPrice }}"
            is_current_date="{{ current_depart_date === date.date }}"
            current_min_price="{{ direct_only ? currentDirectMinPrice : currentMinPrice }}"
          />
        </li>
      {% endif %}
    {% endfor %}
    {% for i of end_weekday_offset %}
      <li class="prices-offset_days"></li>
    {% endfor %}
  </ul>

  <Footer />
</section>
</div>
