_cloneDeep = require('lodash/cloneDeep')
_map = require('lodash/map')
_union = require('lodash/union')
_delay = require('lodash/delay')
yasen_client = require('./yasen_client.coffee')
storage = require('shared/lib/local_storage.coffee')

module.exports = (request_params, callbacks) ->
  if storage.getItem('debug')
    if not not storage.getItem('yasen_response')
      _delay((-> callbacks.on_update(JSON.parse(storage.getItem('yasen_response')))), 100)
      _delay((-> callbacks.on_finish(JSON.parse(storage.getItem('yasen_response')))), 200)
      stop: ->
    else
      recording_callbacks = {
        result: []
        on_update: (data) ->
          @result = _union(@result, data)
          callbacks.on_update(data)
        on_finish: ->
          # res = _map(_cloneDeep(@result), (resp) ->
          #   resp.proposals = resp.proposals[0...Math.floor(resp.proposals.length / 2)] if resp.proposals && resp.proposals.length > 0
          # )
          storage.setItem('yasen_response', JSON.stringify(@result))
          callbacks.on_finish(@result)
      }
      yasen_client(request_params, recording_callbacks)
  else
    yasen_client(request_params, callbacks)
