###*
# Copyright Marc J. Schmidt. See the LICENSE file at the top-level
# directory of this distribution and at
# https://github.com/marcj/css-element-queries/blob/master/LICENSE.
# XXXVII: changed context of callback function to the element instead of window
###

###
# Welcome to the new js2coffee 2.0, now
# rewritten to use the esprima parser.
# try it out!
###

listen = (el, event, handler) ->
  if el.addEventListener
    el.addEventListener event, handler
  else
    el.attachEvent 'on' + event, ->
      handler.call el

do ->

  ###*
  # Class for dimension change detection.
  #
  # @param {Element|Element[]|Elements|jQuery} element
  # @param {Function} callback
  #
  # @constructor
  ###

  @ResizeSensor = (element, callback) ->

    ###*
    #
    # @constructor
    ###

    EventQueue = ->
      @q = []

      @add = (ev) ->
        @q.push ev
        return

      i = undefined
      j = undefined

      @send = (node) ->
        i = 0
        j = @q.length
        while i < j
          @q[i] node
          i++
        return

      return

    ###*
    # @param {HTMLElement} element
    # @param {String}      prop
    # @returns {String|Number}
    ###

    getComputedStyle = (element, prop) ->
      if element.currentStyle
        element.currentStyle[prop]
      else if window.getComputedStyle
        window.getComputedStyle(element, null).getPropertyValue prop
      else
        element.style[prop]

    ###*
    #
    # @param {HTMLElement} element
    # @param {Function}    resized
    ###

    attachResizeEvent = (element, resized) ->
      if !element.resizedAttached
        element.resizedAttached = new EventQueue
        element.resizedAttached.add resized
      else if element.resizedAttached
        element.resizedAttached.add resized
        return
      element.resizeSensor = document.createElement('div')
      element.resizeSensor.className = 'resize-sensor'
      style = 'position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: scroll; z-index: -1; visibility: hidden;'
      styleChild = 'position: absolute; left: 0; top: 0;'
      element.resizeSensor.style.cssText = style
      element.resizeSensor.innerHTML = '<div class="resize-sensor-expand" style="' + style + '">' + '<div style="' + styleChild + '"></div>' + '</div>' + '<div class="resize-sensor-shrink" style="' + style + '">' + '<div style="' + styleChild + ' width: 200%; height: 200%"></div>' + '</div>'
      element.appendChild element.resizeSensor
      if !{
          fixed: 1
          absolute: 1
        }[getComputedStyle(element, 'position')]
        element.style.position = 'relative'
      expand = element.resizeSensor.childNodes[0]
      expandChild = expand.childNodes[0]
      shrink = element.resizeSensor.childNodes[1]
      shrinkChild = shrink.childNodes[0]
      lastWidth = undefined
      lastHeight = undefined

      reset = ->
        expandChild.style.width = expand.offsetWidth + 10 + 'px'
        expandChild.style.height = expand.offsetHeight + 10 + 'px'
        expand.scrollLeft = expand.scrollWidth
        expand.scrollTop = expand.scrollHeight
        shrink.scrollLeft = shrink.scrollWidth
        shrink.scrollTop = shrink.scrollHeight
        lastWidth = element.offsetWidth
        lastHeight = element.offsetHeight
        return

      reset()

      changed = ->
        if element.resizedAttached
          element.resizedAttached.send element
        return

      addEvent = (el, name, cb) ->
        if el.attachEvent
          el.attachEvent 'on' + name, cb
        else
          el.addEventListener name, cb
        return

      addEvent expand, 'scroll', ->
        if element.offsetWidth > lastWidth or element.offsetHeight > lastHeight
          changed()
        reset()
        return
      addEvent shrink, 'scroll', ->
        if element.offsetWidth < lastWidth or element.offsetHeight < lastHeight
          changed()
        reset()
        return
      return

    if '[object Array]' == Object::toString.call(element) or 'undefined' != typeof jQuery and element instanceof jQuery or 'undefined' != typeof Elements and element instanceof Elements
      i = 0
      j = element.length
      while i < j
        attachResizeEvent element[i], callback
        i++
    else
      attachResizeEvent element, callback

    @detach = ->
      ResizeSensor.detach element
      return

    return

  @ResizeSensor.detach = (element) ->
    if element.resizeSensor
      element.removeChild element.resizeSensor
      delete element.resizeSensor
      delete element.resizedAttached
    return

  module.exports = ResizeSensor
  return

# ---
# generated by js2coffee 2.2.0
