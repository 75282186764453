import _forEach from 'lodash/forEach'
import _memoize from 'lodash/memoize'

export default _memoize((ticket) => {
  var signs = [ticket.validating_carrier]

  _forEach(ticket.segment, (segment, idx) => {
    signs.push(`${segment.flight[0]['local_departure_timestamp']}`)
    signs.push(`${segment.flight[segment.flight.length - 1]['local_arrival_timestamp']}`)
    signs.push(('000000' + ticket.segment_durations[idx]).substr(-6))

    var airports = []
    for (let index in segment.flight) {
      let flight = segment.flight[index]
      airports.push(`(${[flight['departure'], flight['arrival']]})`)
    }
    Array.prototype.push.apply(signs, airports)
  })

  return [signs.join(''), ticket.sign].join('_')
}, (ticket) => { return ticket.sign })
