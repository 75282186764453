import _groupBy from 'lodash/groupBy'
import _defaultsDeep from 'lodash/defaultsDeep'
import CalendarBase from '../base.js'
import Template from './calendar_line.monk'
import dispatcher from 'shared/lib/dispatcher'
import filters from '../filters.js'
import moment from 'moment'

const COUNT_TILE_MAP = {
  's': 4,
  'xs': 3
}

export default class CalendarLine extends CalendarBase {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'calendar_line',
      render: {
        template: Template,
        options: { filters }
      },
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.cell_dates--hover': {
            'outline-color': window.TPWLCONFIG.color_scheme.bg
          },
          '.cell_dates--hover': {
            'outline-color': window.TPWLCONFIG.color_scheme.bg
          },
          '.calendar_line-info_toggle': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.calendar_line-info_toggle:before': {
            'background': `url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%207%205%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ECombined%20Shape%3C%2Ftitle%3E%3Cpath%20d%3D%22M3.4%202.28L5.68%200%206.8%201.12%203.42%204.5l-.02-.02-.02.02L0%201.12%201.12%200%203.4%202.28z%22%20fill%3D%22${encodeURIComponent(window.TPWLCONFIG.color_scheme.link)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E") 50% 50% no-repeat`
          }
        }
      }
    }, CalendarBase.defaultOptions())
  }

  static areParamsValid (params) {
    return super.areParamsValid(params) && params.segments.length == 1
  }

  setDates () {
    // Generate dates for table based on requestParams
    this.state.depart_dates = this.generateDates(this.requestParams.depart_start, {}, this.countTiles)
  }

  fixParams (params) {
    // If it possible then current search date place in center of table
    // otherwise shifted to the upper left corner
    let departStart = moment(params.depart_start).startOf('date').add(this.options.dOffset, 'days')
    let today = moment(new Date()).startOf('date')
    let departDiff = (departStart - today) / 1000 / 60 / 60 / 24

    params['need_request'] = true
    let currentDayShift = this.countTiles > 4 ? 3 : 1
    params['depart_start'] = moment.ISODate(departStart.subtract(departDiff > 3 ? currentDayShift : departDiff, 'days'))
    return params
  }

  castData (data) {
    return _groupBy(data, 'depart_date')
  }

  _countTilesDetector () {
    // let searchResultSize = document.querySelector('[role="tpwl-content"]').className.match('TPWL_widget--(.{1,2}) ')[1]
    return 7
    // return COUNT_TILE_MAP[searchResultSize] || 7
  }

  onSearchStart (params) {
    this.countTiles = this._countTilesDetector()
    this.fetchDataAndRender()
  }

  setRequestParams () {
    this.requestParams = this.fixParams({
      origin_iata: this.searchParams.segments[0].origin_city_iata || this.searchParams.segments[0].origin,
      destination_iata: this.searchParams.segments[0].destination_city_iata || this.searchParams.segments[0].destination,
      depart_start: this.state.current_depart_date,
      depart_range: this.countTiles - 1
    })
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)
    view.on('click', '[role="calendar_show"]', (event, node) => {
      dispatcher.send('calendar_toggle')
      view.querySelector('[role="calendar_line--wrapper"]').classList.add('calendar_line--hide')
      CalendarBase.reach_goal('CALENDAR_TOGGLE')
    })
  }
}
