_uniq = require('lodash/uniq')
_map = require('lodash/map')
_map = require('lodash/map')
_find = require('lodash/find')
_sortBy = require('lodash/sortBy')
_groupBy = require('lodash/groupBy')
_minBy = require('lodash/minBy')
_filter = require('lodash/filter')
_memoize = require('lodash/memoize')
_includes = require('lodash/includes')
_clone = require('lodash/clone')
_last = require('lodash/last')
_reverse = require('lodash/reverse')
moment = require('moment')
filters = require('shared/lib/filters.coffee')
utils = require('shared/lib/utils.coffee')
marker = require('shared/lib/marker.coffee')
cookies = require('shared/lib/cookies.coffee')
config = require('shared/lib/tp_config.js')
dictionary = require('shared/lib/dictionary.js').default
ticketSignatureGenerator = require('flights/components/highlighted_ticket/ticket_signature_generator.js').default
baggage_presenter = require('./baggage_presenter.js').default

MAX_PROPOSALS = 4
CREDIT_MIN_PRICE = 40000

ticket_helper =
  create_deeplink: (proposal, gate, search_id) ->
    _marker = marker.get() || null
    deeplink = config.url + "/searches/#{search_id.search_id}/clicks/#{proposal.url}.html"
    deeplink = deeplink + "?gate_label=#{gate.label}&gate_id=#{proposal.gate_id}"
    deeplink += "&fallback=#{encodeURIComponent(window.location.href)}"
    deeplink = deeplink + "&gate_currency=#{proposal.currency}"
    deeplink = deeplink + "&marker=#{encodeURIComponent(_marker)}" if _marker
    deeplink = deeplink + "&banner_delay=" + gate.banner_delay if gate.banner_delay
    deeplink
  get_wide_logo_path: (carrier_code) ->
    if carrier_code then "https://img.wway.io/pics/root/#{ carrier_code }@avif?exar=1&g=we&rs=fit:240:70:0" else ""
  get_square_logo_path: (carrier_code) ->
    if carrier_code then "https://img.wway.io/pics/al_square/#{ carrier_code }@avif?exar=1&rs=fit:80:80:0" else ""

is_kviku_credit_available = (_ticket, _params) ->
  false && LOCALE == 'ru' &&
    (_ticket.segments[0].flights[0].depart_country == 'Россия' || _ticket.segments[0].flights[0].depart_iata == 'SIP') &&
    (_last(_ticket.segments[0].flights).arrival_country == 'Россия' || _last(_ticket.segments[0].flights).arrival_iata == 'SIP') &&
    _ticket.proposals[0].unified_price < 30000 &&
    _params.params.passengers.adults < 4 && _params.params.passengers.children == 0 &&
    _params.params.passengers.infants == 0 &&
    moment(_params.params.segments[0].date).diff(moment(), 'days') < 31

get_kviku_params = (_params, _marker, _ticket) ->
  ID_MARKET = '777'
  PASSWORD = '1fe036ad0201fd8621b563aa98ac6eea'

  _kviku_params = [
    { key: 'id_market', value: ID_MARKET }
    { key: 'password', value: PASSWORD }
    { key: 'count_adult', value: _params.params.passengers.adults }
    { key: 'marker', value: _marker }
    { key: 'utm_source', value: 'travelpayouts' }
  ]

  for proposal, index in _ticket.proposals
    _kviku_params.push(
      { key: "links[#{index}][link]", value: "#{window.location.protocol}#{document.location.hostname}#{proposal.deeplink}" }
      { key: "links[#{index}][price]", value: proposal.unified_price }
      { key: "links[#{index}][provider]", value: proposal.name }
      { key: "links[#{index}][gate_name]", value: proposal.name }
    )

  for segment_index, segment of _ticket.segments
    for flight_index, flight of segment.flights
      key_prefix = "segments[#{segment_index}][flight][#{flight_index}]"
      _kviku_params.push(
        { key: "#{key_prefix}[departure_country]", value: flight.depart_country }
        { key: "#{key_prefix}[departure_airport_code]", value: flight.depart_iata }
        { key: "#{key_prefix}[departure_airport_name]", value: "#{flight.depart_city}, #{flight.depart_airport}" }
        { key: "#{key_prefix}[departure_datetime]", value: moment(flight.depart_timestamp, 'X').utc().format('YYYY-MM-DD HH:mm') }
        { key: "#{key_prefix}[arrival_country]", value: flight.arrival_country }
        { key: "#{key_prefix}[arrival_airport_code]", value: flight.arrival_iata }
        { key: "#{key_prefix}[arrival_airport_name]", value: "#{flight.arrival_city}, #{flight.arrival_airport}" }
        { key: "#{key_prefix}[arrival_datetime]", value: moment(flight.arrival_timestamp, 'X').utc().format('YYYY-MM-DD HH:mm') }
        { key: "#{key_prefix}[carrier_code]", value: flight.carrier_code }
        { key: "#{key_prefix}[carrier_name]", value: flight.carrier_name }
        { key: "#{key_prefix}[flight_duration]", value: flight.raw_duration }
      )

  _kviku_params

module.exports = (data, params) ->
  [ticket, proposals] = data.ticket
  ticket_index = data.index
  preloaded = !!ticket.preloaded
  _marker = marker.get() || null
  hostname = document.location.hostname || null
  proposals = _clone(proposals)
  proposal.original_index = index for index, proposal of proposals
  check_in_time = '12:00'
  hotels_near_airports_radius = '5'
  baggage_config = dictionary.airline_rules?[ticket.validating_carrier || ticket.carriers[0]]

  presented_ticket = {
    index: ticket_index
    type: 'ticket'
    labels: ticket.labels || false
    logo_proposal_index: ''
    is_charter: !!ticket.is_charter
    segments: []
    currency: params.currency
    proposals: []
    main_proposal: {}
    on: __('ticket.on')
    main_button_text: if params.search_expired then __('ticket.update') else __('ticket.book_now')
    main_button_additional_text: if params.search_expired then '' else __('ticket.for')
    buy_button_text: if params.search_expired then __('ticket.update') else __('ticket.book_now')
    expired_class: if params.search_expired then ' ticket--expired ' else ''
    carrier_code: ticket.validating_carrier
    wide_logo_path: ticket_helper.get_wide_logo_path(ticket.validating_carrier)
    square_logo_path: ticket_helper.get_square_logo_path(ticket.validating_carrier)
    opened_class: if ticket.expanded then 'opened' else ''
    baggage_price: (ticket.baggage && ticket.baggage.priceDifference) || false
    preloaded: preloaded
    ticket_google_tag: false
    highlight_class: if ticket.highlight then 'highlighted' else ''
    ticket_rating: ticket.ticket_rating
    show_hide_text: if ticket.expanded then __('ticket.hide_details') else __('ticket.show_details')
    copy_link_text: __('ticket.share.link')
    sign: ticket.sign
    baggage: ticket.baggage
    multi: utils.is_open_jaw(ticket.segment)
    proposals_count_classname: ""
  }

  for index, segment of ticket.segment
    segment_flights = segment.flight
    stops_count = segment_flights.length - 1
    last_flight = segment_flights[stops_count]

    operating_airlines = _map(segment_flights, 'operating_carrier')
    operating_airlines = _uniq(operating_airlines)
    operating_airlines = _map(operating_airlines, (code) => dictionary.airlineByCode(code).name)
    operating_airlines = operating_airlines.join(', ')

    is_am_pm = (time) => time.format('LT').indexOf('M') >= 0
    depart_ts = segment_flights[0].local_departure_timestamp
    depart_date = moment.utc(depart_ts, 'X')

    arrival_ts = last_flight.local_arrival_timestamp
    arrival_date = moment.utc(arrival_ts, 'X')
    direction_header = if index is '0' then __('search.tickets.tickets.take_off') else __('nano_ui_localization.return_flight')

    departure_name = "#{dictionary.airportByIata(segment_flights[0].departure).name}, #{dictionary.airportByIata(segment_flights[0].departure).city}"
    arrival_name = "#{dictionary.airportByIata(last_flight.arrival).name}, #{dictionary.airportByIata(last_flight.arrival).city}"
    direction_text = if utils.is_open_jaw(ticket.segment)
        __('ticket.segment').toUpperCase() + ' ' + String(ticket.segment.indexOf(segment) + 1)
      else if index == '0'
        __('ticket.depart')
      else
        __('ticket.return')
    diff_day = if arrival_ts && depart_ts
      parseInt(Math.floor(arrival_ts/86400) - Math.floor(depart_ts/86400), 10)
    else
      0

    next_day_fly = if diff_day != 0 then true else false

    night_flight = (depart_date, arrive_date, next_day_fly) ->
      return true if depart_date.format('HH') > 23 || arrival_date.format('HH') < 7
      return true if next_day_fly && ( (depart_date.format('HH') < 4 || depart_date.format('HH') > 21) || (arrival_date.format('HH') > 5) )
      false

    get_airports_info = () ->
      # not including origin/destination airports
      airports_info = []

      if segment_flights.length > 1
        for flight_index, flight of segment_flights
          previous_flight = segment_flights[flight_index - 1]
          if previous_flight
            next_airport = dictionary.airportByIata(flight.departure)
            previous_airport = dictionary.airportByIata(previous_flight.arrival)
            airport_info =
              connection_time: filters.format_transfer_duration(flight.delay)
              iata: flight.departure
              name: "#{next_airport.name}, #{next_airport.city}"
            unless preloaded
              departure_hour = +flight.departure_time.split(":")[0]
              arrival_hour = +previous_flight.arrival_time.split(":")[0] unless preloaded
              if 0 < departure_hour < 7 || 23 < departure_hour < 24
                airport_info.night_layover = true
                airport_info.layover_modifier = '--night'

              if (12 < departure_hour < 23 || 7 < arrival_hour < 18) && flight.local_departure_timestamp - previous_flight.local_arrival_timestamp > 5 * 60 * 60
                airport_info.day_layover = true
                airport_info.layover_modifier = '--day' unless airport_info.layover_modifier

            if previous_flight.arrival != flight.departure
              airport_info.change_airports = [
                {iata: previous_flight.arrival, name: "#{previous_airport.name}, #{previous_airport.city}"},
                {iata: flight.departure, name: "#{next_airport.name}, #{next_airport.city}"}
              ]
            else
              airport_info.change_airports = false
            airports_info.push(airport_info)
      airports_info

    if segment_flights.length > 1
      for flight_index, flight of segment_flights
        previous_flight = segment_flights[flight_index - 1]

        if previous_flight and !presented_ticket.change_airports
          if previous_flight.arrival != flight.departure
            presented_ticket.change_airports = [previous_flight.arrival, flight.departure]
            break
          else
            presented_ticket.change_airports = false
    result = {
      flights: []
      mini:
        depart_name: dictionary.airportByIata(segment_flights[0].departure).city
        depart_iata: segment_flights[0].departure
        depart_time: if is_am_pm(depart_date) then depart_date.format('h:mm') else depart_date.format('LT')
        depart_meridiem: if is_am_pm(depart_date) then depart_date.format('A') else ''
        depart_day: depart_date.format('D')
        depart_month_short: depart_date.format('MMM')
        depart_day_weekday_month: moment.dayWeekdayMonthName(depart_date)
        depart_day_month_year: depart_date.format('LL')
        raw_depart_date: depart_date

        arrival_name: dictionary.airportByIata(last_flight.arrival).city
        arrival_iata: last_flight.arrival
        arrival_time: if is_am_pm(arrival_date) then arrival_date.format('h:mm') else arrival_date.format('LT')
        arrival_meridiem: if is_am_pm(arrival_date) then arrival_date.format('A') else ''
        arrival_day: arrival_date.format('D')
        arrival_month_short: arrival_date.format('MMM')
        raw_arrival_date: arrival_date

        airports: get_airports_info()

        stops_count: stops_count
        duration: filters.format_transfer_duration(ticket.segment_durations[index])
        carrier_code: segment_flights[0].operating_carrier
        wide_logo_path: ticket_helper.get_wide_logo_path(segment_flights[0].operating_carrier)
        square_logo_path: ticket_helper.get_square_logo_path(segment_flights[0].operating_carrier)
        operating_airlines: operating_airlines

        next_day_fly: next_day_fly
        diff_day: if diff_day > 0 then "+#{diff_day}" else "-#{diff_day}"

      direction_text: direction_text
      direction_header: direction_header
      direction: if index is '0' then 'depart' else 'return'
      segment_hash: segment.hash
      segment_hash_selected: _includes(params.kraynov_filter, segment.hash)
      show_kraynov_filter: ticket.segment.length > 1
    }


    for flight, segment_index in segment_flights
      previous_flight = segment_flights[segment_index - 1]
      depart_date = moment.utc(flight.local_departure_timestamp, 'X')
      arrival_date = moment.utc(flight.local_arrival_timestamp, 'X')

      next_day_messsage = if flight.arrival == last_flight.arrival and next_day_fly then true else false

      legroom = switch params.trip_class
        when 'Y' then dictionary.airlineByCode(presented_ticket.carrier_code).economyLegroom
        when 'F' then dictionary.airlineByCode(presented_ticket.carrier_code).firstLegroom
        else false
      legroom = false if legroom is 0

      pres_flight = {
        depart_time: depart_date.format('LT')
        depart_day_weekday_month: moment.dayWeekdayMonthName(depart_date)
        depart_timestamp: flight.local_departure_timestamp

        arrival_time: arrival_date.format('LT')
        arrival_day_weekday_month: moment.dayWeekdayMonthName(arrival_date)
        arrival_timestamp: flight.local_arrival_timestamp # fix?

        depart_iata: flight.departure
        depart_city: dictionary.airportByIata(flight.departure).city
        depart_airport: dictionary.airportByIata(flight.departure).name
        depart_country: dictionary.airportByIata(flight.departure).country

        arrival_iata: flight.arrival
        arrival_city: dictionary.airportByIata(flight.arrival).city
        arrival_airport: dictionary.airportByIata(flight.arrival).name
        arrival_country: dictionary.airportByIata(flight.arrival).country

        duration: filters.format_transfer_duration(flight.duration)
        raw_duration: flight.duration
        carrier_name: dictionary.airlineByCode(flight.operating_carrier).name
        carrier_number: "#{flight.operating_carrier}-#{flight.number}"
        aircraft: flight.aircraft?.replace(' ', '\xA0') or ''
        carrier_code: flight.operating_carrier
        wide_logo_path: ticket_helper.get_wide_logo_path(flight.operating_carrier)
        square_logo_path: ticket_helper.get_square_logo_path(flight.operating_carrier)
        stop: false
        wifi: dictionary.airlineByCode(presented_ticket.carrier_code).wifi
        # TODO: fix after yasen patch
        #laptopPower: dictionary.airlineByCode(presented_ticket.carrier_code).laptopPower
        night_flight: night_flight(depart_date, arrival_date, next_day_fly)
        # laptopPower: false
        legroom: legroom
        baggage: dictionary.airlineByCode(presented_ticket.carrier_code).baggage
        arrival_date_warning: arrival_date
        next_day_messsage: next_day_messsage
        diff_day: if diff_day > 0 then "+#{diff_day}" else "-#{diff_day}"
      }

      if segment_flights.length > 1 and previous_flight
        if previous_flight.arrival != flight.departure
          ticket.change_airports = [previous_flight.arrival, flight.departure]
        else
          ticket.change_airports = false

      if flight.delay > 0
        pres_flight.stop = {
          icon: if ticket.change_airports then 'flight-change_airport-icon' else 'flight-wait-icon'
          title: dictionary.airportDeclByIata(flight.departure, 'pr') or dictionary.airportByIata(flight.departure).city
          duration: filters.format_transfer_duration(flight.delay)
          arrow_width: if flight.delay > 480 then 'wide' else ''
          hotel_info: if flight.delay > 480 then {
            iata: flight.departure
          } else false
        }

        if ticket.change_airports
          pres_flight.stop.same_airport_layover = false
          pres_flight.stop.change_airports = {
            arrival_airport: ticket.change_airports[0],
            depart_airport: ticket.change_airports[1]
          }
        else
          pres_flight.stop.change_airports = false
          pres_flight.stop.same_airport_layover = {
            title: dictionary.airportDeclByIata(flight.departure, 'pr') or dictionary.airportByIata(flight.departure).city
            iata: flight.departure
          }

      result.flights.push(pres_flight)

    presented_ticket.segments.push(result)
  carriers_key = (carriers) ->
    carriers.sort().join(',')

  _should_raise_productivity = (carriers, gate) ->
    'raise_productivity_airlines' of gate and
      carriers_key(carriers) in gate.raise_productivity_airlines

  adapt_proposal = (proposal, proposal_index) ->
    price_in_currency = Math.round(proposal.unified_price / (dictionary.currencies[params.currency] || 1))
    price_in_origin_currency = Math.round(proposal.unified_price / (dictionary.currencies[proposal.currency] || 1))
    gate = dictionary.gateById(proposal.gate_id)

    res =
      name: gate.label || proposal.label
      price: filters.split_price(price_in_currency)
      currency: params.currency
      has_baggage: proposal.hasBaggage || false
      original_gate_price: filters.split_price(price_in_origin_currency)
      original_index: proposal_index
      original_gate_currency: proposal.currency
      url: proposal.url
      original_price: price_in_currency
      unified_price: proposal.unified_price
      bags: proposal.bags.map (bag) -> baggage_presenter(bag[0])
      worstBags: baggage_presenter(proposal.worstBags)
      worstBagsForSegments: proposal.worstBagsForSegments.map (bag) -> baggage_presenter(bag)
      gate_id: proposal.gate_id
      url: "#{document.location.protocol}//#{hostname}#{document.location.pathname}?ticket=#{ticketSignatureGenerator(ticket)}_#{proposal.unified_price}_#{encodeURIComponent(gate.label)}&marker=#{_marker}&locale=#{LOCALE}"
      deeplink: ticket_helper.create_deeplink(proposal, gate, dictionary.search_id)
      productivity: if _should_raise_productivity(ticket.carriers, gate) then 1 else proposal.multiplier / proposal.proposal_multiplier
      proposal_multiplier: proposal.proposal_multiplier || 1
      multiplier: proposal.multiplier || 1
      is_airline: gate.is_airline
    res

  get_selector_link = (gate, ota) ->
    protocol = window.location.protocol
    deeplink = "#{protocol}#{hostname}#{gate.deeplink}"
    selector_params = [
      "locale=#{LOCALE}"
      "currency=#{params.currency}"
      "gate_url=#{encodeURIComponent(deeplink)}"
      "gate_price=#{encodeURIComponent(gate.original_price)}"
      "gate_iata=#{presented_ticket.carrier_code}"
    ]
    if ota
      ota_deeplink = "#{protocol}#{hostname}#{ota.deeplink}"
      selector_params = selector_params.concat [
        "ota_url=#{encodeURIComponent(ota_deeplink)}"
        "ota_price=#{encodeURIComponent(ota.original_price)}"
      ]
    jr_ota_host = window.JR_OTA_HOST || "book.jetradar.co.th"
    "//#{jr_ota_host}/gates?#{selector_params.join('&')}"

  smart_sort_proposals = (proposals) ->
    airline_proposals = _filter(proposals, 'is_airline')
    if airline_proposals.length > 0
      if airline_proposals.length == 1
        airline_proposals[0].is_foreground = true
      else
        _minBy(airline_proposals, (pr) -> parseInt(pr.unified_price, 10)).is_foreground = true

    sorted_proposals = _reverse(_sortBy(proposals, (pr) -> pr.productivity * pr.multiplier))
    sorted_proposals = _sortBy(sorted_proposals, (pr) ->
      return if pr.is_foreground then 0 else +(parseInt(pr.unified_price, 10))
    )

    #Aircompany is second when none of this is true:
    # 1. A/c price is the best
    # 2. Price is same with gate but there's no a/c iata in gates raise_productivity_airlines
    # 3. Price is the same but multiplier * productivity is the best
    if sorted_proposals[0] && sorted_proposals[1]
      if sorted_proposals[0].unified_price > sorted_proposals[1].unified_price
        sorted_proposals[0] = sorted_proposals.splice(1, 1, sorted_proposals[0])[0]
      else if sorted_proposals[0].unified_price == sorted_proposals[1].unified_price
        if sorted_proposals[0].productivity * sorted_proposals[0].multiplier < sorted_proposals[1].productivity * sorted_proposals[1].multiplier
          sorted_proposals[0] = sorted_proposals.splice(1, 1, sorted_proposals[0])[0]
    return sorted_proposals

  groupProposalsByGate = (proposals) ->
    Object.keys(proposals).forEach (type) ->
      grouped_proposals = _groupBy(proposals[type], 'gate_id')
      proposals[type] = smart_sort_proposals(Object.keys(grouped_proposals).map((gate_id) ->
        _minBy(grouped_proposals[gate_id], 'unified_price')
      ))
    return proposals

  sortedProposalsByBaggage = (ticket, proposals) ->
    return proposals.reduce (acc, proposal) ->
     if proposal.has_baggage then acc.hasBaggage.push(proposal) else acc.otherBaggage.push(proposal)
     acc
    , {otherBaggage: [], hasBaggage: []}

  mergeOtherProposalsByPrice = (ticket, proposals) ->
    if ticket.baggage_price < 0
      sortedProposal = proposals.hasBaggage.filter((proposal) ->
        proposal.original_price > proposals.otherBaggage[0].original_price)
      return [].concat(proposals.otherBaggage).concat(sortedProposal)
    return [].concat(proposals.otherBaggage).concat(proposals.hasBaggage)

  new_proposals = []
  for index, proposal of proposals
    new_proposals.push(proposal)

  #All proposals show everytime and everywhere
  max_proposals_length = new_proposals.length

  presented_ticket.proposals = _map(new_proposals.slice(0, max_proposals_length), adapt_proposal)
  presented_ticket.proposals_count_classname = "proposals_list--single"  if presented_ticket.proposals.length == 1
  presented_ticket.logo_proposal_index = 0

  # raw_ota_proposal = _find new_proposals, (pr) ->
  #   pr.gate_id == "999" || pr.gate_id == "9999"

  # if window.ENABLE_JR_SELECTOR # condition for jetradar param
  #   # OTA mode
  #   presented_ticket.jr_selector_class = 'jr_selector'
  #   if raw_ota_proposal
  #     ota_proposal = adapt_proposal(raw_ota_proposal)
  #     if ota_proposal.original_price <= +main_proposal.original_price
  #       # Send to OTA booking directly.
  #       presented_ticket.buy_link = ota_proposal.deeplink
  #     else
  #       # Send to selector
  #       presented_ticket.buy_link = get_selector_link(main_proposal, ota_proposal)
  #   else
  #     # No OTA proposal: send to proposal.
  #     presented_ticket.buy_link = main_proposal.deeplink
  # else
  #   # Normal META mode
  #   presented_ticket.buy_link = main_proposal.deeplink

  is_one_way = presented_ticket.segments.length == 1
  if is_one_way
    presented_ticket.ticket_type_class = 'oneway'
  else if utils.is_open_jaw(presented_ticket.segments)
    presented_ticket.ticket_type_class = 'multicity'
  else
    presented_ticket.ticket_type_class = ''

  if params.search_expired
    presented_ticket.expired_price_class = 'price-expired'

  presented_ticket.kviku_params = is_kviku_credit_available(presented_ticket, params) && get_kviku_params(params, _marker, presented_ticket)
  presented_ticket.proposals = sortedProposalsByBaggage(ticket, presented_ticket.proposals)
  if presented_ticket.proposals.otherBaggage.length
    presented_ticket.proposals.otherBaggage = mergeOtherProposalsByPrice(presented_ticket, presented_ticket.proposals)
  presented_ticket.proposals = groupProposalsByGate(presented_ticket.proposals)
  presented_ticket
