_defer = require('lodash/defer')
_forEach = require('lodash/forEach')
_assignIn = require('lodash/assignIn')
utils                      = require('shared/lib/utils.coffee')
DOMComponent               = require('shared/components/base.js').default
Template                   = require('./search_results.monk')
Monkberry                  = require('monkberry')
Ticket                     = require('flights/components/ticket/ticket.coffee')
ThirdTicket                = require('flights/components/third_ticket/third_ticket.coffee')
bench                      = require('shared/lib/bench.coffee')
animations                 = require('shared/lib/animations.coffee')
metrics                    = require('shared/lib/metrics.coffee')
cookie_params              = require('flights/lib/cookie_params.coffee')
visibility                 = require('shared/lib/visibility.coffee')
Device                     = require('shared/lib/device.js').default
Swiper                     = require('shared/lib/swiper.js').default
PopupScroller              = require('shared/lib/popup_scroller.js').default

FIRST_TICKET_COUNT = 10
CHUNK_TICKET_COUNT = 10

class SearchResults extends DOMComponent
  @instance: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @broadcast: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @initializeComponent: () -> #HACK static inheritance in CoffeeScript

  @defaultOptions: -> {
    name: 'search_results',
    render: { template: Template },
    first_render_happend: false
    cssx:{
      scope: '.TPWL-widget .layout-manager',
      styles: {
        # ".search_results-wrapper-filters": {
        #   "background": "#{window.TPWLCONFIG.color_scheme.body_bg}"
        # },
        ".filters-header": {
          "background": "#{window.TPWLCONFIG.color_scheme.bg}"
        },
        ".sorting-header": {
          "background": "#{window.TPWLCONFIG.color_scheme.bg}"
        },
        ".preroll-trigger": {
          "color": "#{window.TPWLCONFIG.color_scheme.link}"
        }
      }
    },
    state: {
      search_expired: false
      currency: { value: 'rub' }
      tickets: []
      request_id: null
      params: {}
      googletagIsAvailable: window.googletag != undefined
      yaAdfoxIsAvailable: window.Ya != undefined and window.Ya.adfoxCode != undefined
    }
  }


  constructor: (containerNode, options = {}) ->
    super(containerNode, _assignIn(options))
    this.renderedTickets = []
    this.resultsContainer = this.view.querySelector('[role="tickets_container"]')
    this.transitionEndEvent = animations.get_transition_event_name(containerNode)
    this.node = this.view.createDocument()
    this.refresh()

    if (Device.isTouch() && !Device.isDesktopSize())
      this._initSwiper()

  _initSwiper: () ->
    swipeTriggerNode = this.view.querySelector('[role="swipe-trigger"]')
    swipeTargetNode = this.view.querySelector('[role="filters_wrapper"]')
    this.swiper = new Swiper(swipeTriggerNode, swipeTargetNode, 'right', 280, 'filters--shown', 'filters_popup_opened', 'filters_popup_closed')

  _initDOMEvents: (view) ->
    view.on 'click', '[role="copy-link-input"]', (event, eventTarget) ->
      eventTarget.setSelectionRange(0, eventTarget.value.length)
    view.on 'click', '[role="fade"]', () =>
      @_dispatcher.send('filters_popup_closed')


  _initEvents: (dispatcher) ->
    dispatcher.on 'start_search', (event, {request_id, params}) =>
      this.state.params = params
      this.state.search_id = request_id
      this.renderedTickets = []
      utils.clean_node(this.resultsContainer)
      Ticket.buildCache(FIRST_TICKET_COUNT * 2)
      cookie_params.set_params(params)

    dispatcher.on 'ticket_open', (event, ticket) =>
      this.state.open_ticket = {}
      this.state.open_ticket.sign = this.state.tickets[ticket.state.index][0].sign
      this.state.open_ticket.ticket = ticket

    dispatcher.on 'fuzzy_tickets_updated', (event, {tickets}) =>
      _forEach tickets, (ticketPair, index) =>
        if this.state.open_ticket && ticketPair[0].sign == this.state.open_ticket.sign
          this.state.open_ticket.ticket.update({'index': index, 'ticket': ticketPair})

    dispatcher.on 'places_restored', (event, params) =>
      cookie_params.set_params(params) if this.state.request_id

    dispatcher.on 'tickets_updated', (event, {request_id: request_id, tickets: tickets, reason: reason}) =>
      this.state.tickets = tickets
      this.state.request_id = request_id
      if reason == 'sort_order' || (reason == 'filters_updated' && this.renderedTickets.length != 0)
        this.blink(this.render)
      else
        this.render()

    dispatcher.on 'all-tickets-shown', (event) => this.show(this.resultsContainer)
    dispatcher.on 'best-tickets-shown', (event) => this.hide(this.resultsContainer)

    dispatcher.on 'search_finished', =>
      dispatcher.send('ecommerce_tickets_shown', {
        tickets: this.state.tickets[0..CHUNK_TICKET_COUNT],
        search_params: this.state.params
      } , 'search_results')

    dispatcher.on 'pagination-show-more', (event, paginator) =>
      this.showMoreTickets(paginator)

  show: ->
    super(arguments...)
    this.resultsContainer.classList.remove('fade')

  appendTickets: bench 'appendTickets', (tickets) ->
    lastIndex = this.renderedTickets.length
    fragment = document.createDocumentFragment()

    for index, ticket of tickets
      options = {state: {index: lastIndex + +index, ticket: ticket}}

      if this.state.googletagIsAvailable and !this.first_render_happend and index == '2'
        this.renderedTickets.push(new ThirdTicket(fragment))
        this.first_render_happend = true

      this.renderedTickets.push(new Ticket(fragment, options))

    window.requestAnimationFrame(=> this.resultsContainer.appendChild(fragment))
    _defer(Ticket.buildCache, CHUNK_TICKET_COUNT)

  rerenderTickets: ->
    this.rerenderContinueDict = {}
    rerender_id = (0|Math.random()*9e6).toString(36)
    this.rerenderContinueDict[rerender_id] = true
    this.removeFadeClass = true
    this.rerenderChunk(rerender_id, 0, this.renderedTickets.length)

  rerenderChunk: bench('rerenderChunk', (rerender_id, start, total) ->
    i = start
    stop = start + 10
    stop = total if stop > total

    while i < stop
      this.renderedTickets[i].update({index: i, ticket: this.state.tickets[i]}) if this.state.tickets[i]
      i++

    if this.removeFadeClass
      window.requestAnimationFrame(=>this.resultsContainer.classList.remove('fade'))
      this.removeFadeClass = false

    if i < total and this.rerenderContinueDict[rerender_id]
      window.requestAnimationFrame(=> this.rerenderChunk(rerender_id, i, total))
  )

  showMoreTickets: (paginator) ->
    startIndex = this.renderedTickets.length
    endIndex = startIndex + paginator.ChunkItemCount
    tickets = this.state.tickets[startIndex..endIndex]

    this._dispatcher.send('all_tickets_rendered') if endIndex >= this.state.tickets.length

    this.appendTickets(tickets) if tickets.length

    if !tickets.length || tickets.length <= paginator.ChunkItemCount
      paginator.hide()
      this._dispatcher.send('all_tickets_rendered')

  blink: (callback) ->
    if utils.transition_events_supported() && false
      my_cb = =>
        callback.apply(this)
        this.view.off(this.transitionEndEvent, my_cb)
      this.view.on(this.transitionEndEvent, ".fade", my_cb)
      window.requestAnimationFrame(=> this.resultsContainer.classList.add('fade'))
    else
      callback.apply(this)

  render: ->
    wasRenderedElements = this.renderedTickets.length
    if this.state.tickets.length < this.renderedTickets.length or !this.renderedTickets.length
      this.renderedTickets = []
      utils.clean_node(this.resultsContainer)
      this.appendTickets(this.state.tickets[0...FIRST_TICKET_COUNT])
      window.requestAnimationFrame(=> this.resultsContainer.classList.remove('fade'))
    else
      this.rerenderTickets()

    if this.renderedTickets.length < FIRST_TICKET_COUNT
      this.appendTickets(this.state.tickets[this.renderedTickets.length...FIRST_TICKET_COUNT])

    this._dispatcher.send('all_tickets_rendered') if this.state.tickets.length == this.renderedTickets.length - 1

    console.log('less tickets was rendered') if wasRenderedElements > this.renderedTickets.length

module.exports = SearchResults
