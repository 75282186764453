import Filters from 'shared/components/filters/filters.js'
import Price from './price/price.js'
import FlightsDuration from './flights_duration/flights_duration.js'
import StopsDuration from './stops_duration/stops_duration.js'
import Sorting from './sorting/sorting.js'
import Gates from './gates/gates.js'
import Airlines from './airlines/airlines.js'
import BestTickets from './best_tickets/best_tickets.js'
import ArrivalDeparture from './departure_arrival_time/departure_arrival_time.js'
import Airports from './airports/airports.js'
import UserSettings from 'shared/components/user_settings/user_settings.js'
import Stops from './stops/stops.js'
import Baggage from './baggage/baggage.js'
import StopsAirports from './stops_airports/stops_airports.js'
import converter from 'shared/lib/converter'
import utils from 'shared/lib/utils.coffee'
import _cloneDeep from 'lodash/cloneDeep'
import _extend from 'lodash/extend'
import _includes from 'lodash/includes'
import _without from 'lodash/without'
import _clone from 'lodash/clone'
import _defaultsDeep from 'lodash/defaultsDeep'
import Template from './filters.monk'

const defaultOptionsRange = {
  two_way: true,
  barchart: false,
  step: 1,
  multiplier: 1,
  range: true,
  dynamic: true
}
const defaultOptionsCheckBox = {
  preview_count: 0,
  has_check_all: true,
  has_only_button: true
}

export default class FlightFilters extends Filters {
  static defaultOptions () {
    return _defaultsDeep({
      render: { template: Template },
      state: { collapsed: false }
    }, super.defaultOptions())
  }
  constructor (node, options = {}) {
    super(node, options)
    this.rate = 1
    this.currency = UserSettings.getCurrency() || 'rub'
    this.precheck = {}
    this.gates = {}
    this.kraynovFilter = []
    this.rangeFilters = ['price', 'flights_duration', 'stops_duration']
    this.filters = {
      'best_ticket':       [BestTickets,      {selectedId: 'all_tickets'}],
      'sorting':           [Sorting,          {}],
      'stops_count':       [Stops,            _extend(_clone(defaultOptionsCheckBox), { currency: this.currency, rate: this.rate })],
      'baggage':           [Baggage,          _extend(_clone(defaultOptionsCheckBox), { currency: this.currency, rate: this.rate })],
      'stops_duration':    [StopsDuration,    defaultOptionsRange],
      'price':             [Price,            _extend(_clone(defaultOptionsRange), { currency: this.currency, multiplier: 100 })],
      'arrival_departure': [ArrivalDeparture, {filters: this, defaultOptions: defaultOptionsRange}],
      'flights_duration':  [FlightsDuration,  defaultOptionsRange],
      'stops_airports':    [StopsAirports,    defaultOptionsCheckBox],
      'airlines':          [Airlines,         defaultOptionsCheckBox],
      'airports':          [Airports,         {filters: this, defaultOptions: defaultOptionsCheckBox}],
      'gates':             [Gates,            defaultOptionsCheckBox]
    }
    this.urlFragmentEnabled = false
    this._init()
  }

  _initEvents (dispatcher) {
    super._initEvents(dispatcher)
    dispatcher.on('currency_updated', (event, currency) => {
      this.rate = converter.rates[currency] ? converter.rates[currency] : this.rate
      this.update({
        price: {
          step: (1 * this.rate),
          multiplier: 100 / this.rate,
          currency
        },
        stops_count: {
          rate: 1 / this.rate,
          currency
        }
      })
    })

    dispatcher.on('currencies_updated', (event, {request_id, currencies}) => {
      this.rate = currencies[UserSettings.getCurrency()]
      this.update({
        price: {
          step: (1 * this.rate),
          multiplier: 100 / this.rate
        },
        stops_count: {
          rate: 1 / this.rate
        }
      })
    })

    dispatcher.on('start_search', (event, { request_id, params }) => {
      this.request_id = request_id
      this.filters['best_ticket'].state['request_id'] = this.request_id
      this.filters['sorting'].state['request_id'] = this.request_id
      this.update({arrival_departure: {segments: params.segments} })
    })
    dispatcher.on('search_finished', (event, { data }) => {
      if (data && data.length) {
        dispatcher.send('filters_state_updated', this.getState());
      }
    })
    dispatcher.on('all-tickets-shown', (event, params) => {
      this.update({ best_ticket: {selectedId: 'all_tickets'}})
    })
    dispatcher.on('best-tickets-shown', (event, params) => {
      this.update({ best_ticket: {selectedId: 'best_tickets'}})
    })
    dispatcher.on('direct_only_change', (event, direct_only) => {
      this.updateState({ stops_count: direct_only ? 0 : -1 })
    })
    dispatcher.on('airports_updated', (event, {request_id, airports}) => {
      this.update({stops_airports: airports})
    })
    dispatcher.on('gates_updated', (event, {request_id, gates}) => {
      this.gates = gates
    })

    dispatcher.on('kraynov-filter-clicked', (event, hash) => {
      if (_includes(this.kraynovFilter, hash)) {
        this.kraynovFilter = _without(this.kraynovFilter, hash)
      } else {
        this.kraynovFilter = [...this.kraynovFilter, hash]
      }
      dispatcher.send('kraynov-filter-new-state', this.kraynovFilter)
      dispatcher.send('filters_state_updated', this.getState())
    })

    dispatcher.on('airlines_updated', (event, {request_id, airlines}) => {
      this.update({airlines: airlines})
    })
    dispatcher.on('gates_meta_updated', (event, {request_id, meta}) => {
      this.update({gates: {meta, gates: this.gates}})
    })
    dispatcher.on('places_restored', (event, params) => {
      this.precheck = utils.filters_state_by_params(params)
    })
    dispatcher.on('boundaries_updated', (event, data) => {
      const {boundaries} = _cloneDeep(data)
      this.update({airports: boundaries})
      this.filters.stops_airports.state.airportsArrivalDeparture =
        [...boundaries.arrival_airports, ...boundaries.departure_airports]
      this.update(boundaries)
      Object.keys(this.precheck).forEach(name => {
        this.updateState({[`${name}`]: this.precheck[name]})
        dispatcher.send('airport_precheck', true)
      })
    })
  }

  getState () {
    let state = super.getState()
    let filtersState = {}

    this.eachFilter(name => {
      _extend(filtersState, state[name])
    })
    _extend(filtersState, { kraynov_filter: this.kraynovFilter })
    return { request_id: this.request_id, filters_state: filtersState }
  }
}

window.f = Filters
