module.exports =
  setItem: (key, value)->
    try
      localStorage?.setItem(key, value)
    catch e
      console.log e
      # Rollbar?.debug("User in porn mode " + e.message)

  getItem: (key) ->
    try
      localStorage?.getItem(key)
    catch e
      console.log e
      # Rollbar?.debug("User in porn mode " + e.message)
      return null

  removeItem: (key) ->
    try
      localStorage?.removeItem(key)
    catch e
      console.log e
      # Rollbar?.debug("User in porn mode " + e.message)
