<div class="ticket ticket--fake" data-nosnippet><div class="ticket-scroll-container">
  <div class="ticket-action">
    <div class="ticket-action__container">
      <div class="ticket-action-button">
        <a class="ticket-action-button-deeplink">{% unsafe __('ticket.book_now') %}</a>
      </div>
    </div>
  </div>
  <div class="ticket-details">
    <a class="ticket-action-airline" target="_blank"></a>
    <div class="ticket-baggage">
      <div class="ticket-baggage-icons ticket-baggage-icons--relative {{ baggage.relative_baggage.class }}">
        <i class="ticket-baggage-icon" data-weight="{{ baggage.relative_baggage.weight }}"></i>
      </div>
      <div class="ticket-baggage-icons ticket-baggage-icons--handbags {{ baggage.handbags.class }}">
        <i class="ticket-baggage-icon" data-weight="{{ baggage.handbags.weight }}"></i>
        <i class="ticket-baggage-icon" data-weight="{{ baggage.handbags.weight }}"></i>
        <i class="ticket-baggage-icon" data-weight="{{ baggage.handbags.weight }}"></i>
        <i class="ticket-baggage-icon" data-weight="{{ baggage.handbags.weight }}"></i>
      </div>
      <div class="ticket-baggage-icons ticket-baggage-icons--baggage {{ baggage.baggage.class }}">
        <i class="ticket-baggage-icon" data-weight="{{ baggage.baggage.weight }}"></i>
        <i class="ticket-baggage-icon" data-weight="{{ baggage.baggage.weight }}"></i>
        <i class="ticket-baggage-icon" data-weight="{{ baggage.baggage.weight }}"></i>
        <i class="ticket-baggage-icon" data-weight="{{ baggage.baggage.weight }}"></i>
      </div>
      <div class="ticket-baggage-tooltip tooltip --top --on-hover">{{ baggage.baggage.description }} {{ baggage.handbags.description }}</div>
    </div>
    {% for segments %}
    <div class="flight flight--{{ direction }}">
      <div class="flight-brief">
        <div class="flight-brief-departure">
          <time class="flight-brief-time">
            00:00
          </time>
          <div class="flight-brief-date">
            <span class="flight-brief-date__day">00</span> mon
          </div>
          <div class="flight-brief__city">
            Moscow
          </div>
        </div>
        <div class="flight-brief-layovers ">
          <div class="flight-brief-layovers__flight_time noindex">
            Time is money
          </div>
          <div class="flight-brief-layovers__list">
            <div class="flight-brief-layovers__list-wrapper">
              <div class="flight-brief-layover"><div class="flight-brief-layover__iata"></div></div>
              <div class="flight-brief-layover"><div class="flight-brief-layover__iata"></div></div>
            </div>
          </div>
          <div class="flight-brief-layovers__direct_flight noindex">
            Time is money
          </div>
        </div>
        <div class="flight-brief-arrival">
          <div class="flight-brief-date">
            <span class="flight-brief-date__day">00</span>
            mon
          </div>
          <time class="flight-brief-time">
            00:00
          </time>
          <div class="flight-brief__city">
            Phuket
          </div>
        </div>
      </div>
    </div>
    {% endfor %}
    <div class="ticket-details-toggler"></div>
  </div>
</div></div>
