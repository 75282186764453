import _forEach from 'lodash/forEach'
import _sortBy from 'lodash/sortBy'
import _isString from 'lodash/isString'
import { offsetTop } from 'shared/lib/offset_top'

export default {
  elements: [],
  globOffset: window.STICKY_SETTER_GLOB_OFFSET || 0,
  cache: {},
  stickPartHeight: 0,

  addElement (triggerNode, modifier, stickyNode, stickyHeight = false, invisible = false, fixedHeight = false) {
    if (_isString(triggerNode)) { triggerNode = document.querySelector(triggerNode) || triggerNode };
    if (_isString(stickyNode)) { stickyNode = document.querySelector(stickyNode) || triggerNode };
    if (!stickyNode) { stickyNode = triggerNode };
    if (!modifier) { modifier = 'sticky' };
    let el = {
      triggerNode,
      stickyNode,
      modifier,
      fixedHeight,
      stickyHeight,
      invisible,
      sticky: false,
      extenderNode: null,
      locked: false,
      forceLocked: false,
      cache: {}
    }
    this.elements.push(el)
    if (this.needToStick(el)) this.sticking(el)
    if (this.elements.length === 1) this.init()
    return el
  },

  init () {
    this.prevPageYOffset = 0
    window.addEventListener('scroll', (event) => {
      this.checkStateChanges(this.prevPageYOffset < window.pageYOffset)
      this.prevPageYOffset = window.pageYOffset
    })
  },

  checkStateChanges (scrollDown) {
    let sortedElements = _sortBy(this.elements, (el) => { return el.cache.top })
    _forEach(sortedElements, (el) => {
      let needToStick = this.needToStick(el)
      if (el.sticky && !needToStick && !scrollDown) {
        this.unstick(el)
      } else if (needToStick && !el.sticky && scrollDown) {
        this.sticking(el)
      }
    })
  },

  sticking (el) {
    if (el.sticky || el.locked) return false
    el.sticky = true

    window.requestAnimationFrame(() => {
      if (!el.invisible) { this.createExtender(el) }
      el.stickyNode.classList.add(el.modifier)
    })

    window.requestAnimationFrame(() => { this.stickyChanged(el) })
  },

  unstick (el) {
    if (!el.sticky) return false
    el.sticky = false
    if (!el.stickyHeight) el.locked = true

    window.requestAnimationFrame(() => {
      if (!el.invisible) { this.removeExtender(el) }
      el.stickyNode.classList.remove(el.modifier)
      el.stickyNode.style.top = 'inherit'
    })
    window.requestAnimationFrame(() => { this.stickyChanged() })
  },

  createExtender (el) {
    el.extenderNode = document.createElement('div')
    el.extenderNode.style.height = `${el.stickyNode.offsetHeight}px`
    el.extenderNode.className = `${el.modifier}_extender`
    el.stickyNode.parentNode.insertBefore(el.extenderNode, el.stickyNode.nextSibling)
  },

  removeExtender (el) {
    el.stickyNode.parentNode.removeChild(el.extenderNode)
    el.extenderNode = false
  },

  stickyChanged (el) {
    this.recalculateStickPart()
    this.recalculatePositions()
    this.checkStateChanges()
  },

  recalculatePositions () {
    let offset = this.globOffset
    _forEach(_sortBy(this.elements, (el) => { return el.cache.top }), (el) => {
      if (el.sticky) {
        el.stickyNode.style.top = `${offset}px`
        if(!el.fixedHeight) el.stickyHeight = el.stickyNode.offsetHeight
        offset += el.stickyHeight
      };
    })
  },

  recalculateStickPart () {
    this.stickPartHeight = 0
    _forEach(_sortBy(this.elements, (el) => { return el.cache.top }), (el) => {
      if (el.sticky) { this.stickPartHeight += el.stickyNode.offsetHeight }
    })
  },

  needToStick (element) {
    var el = element.extenderNode || element.triggerNode
    var top = offsetTop(el)
    if (element.invisible) {
      top = offsetTop(element.stickyNode.parentNode) - element.stickyHeight
    }
    var height = el.offsetHeight
    var stickyHeight = element.stickyHeight || height

    if (!height) return false

    element.cache.top = top
    return window.pageYOffset + this.stickPartHeight - (element.sticky ? stickyHeight : 0) >= top + height - stickyHeight
  },

  lock (el) {
    el.locked = true
    el.forceLocked = true
  },

  unlock (el) {
    if (el) {
      el.locked = false
      el.forceLocked = false
    } else {
      _forEach(this.elements, (element) => { if (!element.forceLocked) element.locked = false })
    }
  }
}
