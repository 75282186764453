import interpolate from 'shared/lib/interpolate.coffee'

const baggageType = {handbags: {decl:  __('ticket.baggage.handbags')}, baggage: {decl: __('ticket.baggage.baggage')}}
const baggageShortType = {handbags: {decl:  __('ticket.baggage.handbags_ro')}, baggage: {decl: __('ticket.baggage.baggage_ro')}}
const fullPlaces = {
  '1': {
    'default': __('ticket.baggage.one_place_expanded'),
    'without_weight': __(`ticket.baggage.one_place_expanded_without_weight`),
    'with_measurement': __('ticket.baggage.one_place_expanded_with_measurement')
  },
  '2': {
    'default': __('ticket.baggage.several_places_expanded'),
    'without_weight': __(`ticket.baggage.several_places_expanded_without_weight`),
    'with_measurement': __('ticket.baggage.one_place_expanded_with_measurement')
  },
  '3': {
    'default': __('ticket.baggage.several_places_expanded'),
    'without_weight': __(`ticket.baggage.several_places_expanded_without_weight`),
    'with_measurement': __('ticket.baggage.one_place_expanded_with_measurement')
  },
  '4': {
    'default': __('ticket.baggage.several_places_expanded'),
    'without_weight': __(`ticket.baggage.several_places_expanded_without_weight`),
    'with_measurement': __('ticket.baggage.one_place_expanded_with_measurement')
  }
}
const shortPlaces = {
  '1': {
    'default': __('ticket.baggage.one_place_closed'),
    'without_weight': __(`ticket.baggage.one_place_closed_without_weight`),
    'with_measurement': __('ticket.baggage.one_place_closed_with_measurement')
  },
  '2': {'default': __('ticket.baggage.several_places_closed'),
    'without_weight': __(`ticket.baggage.several_places_closed_without_weight`),
    'with_measurement': __('ticket.baggage.one_place_closed_with_measurement')
  },
  '3': {'default': __('ticket.baggage.several_places_closed'),
        'without_weight': __(`ticket.baggage.several_places_closed_without_weight`),
        'with_measurement': __('ticket.baggage.one_place_closed_with_measurement')
  },
  '4': {'default': __('ticket.baggage.several_places_closed'),
        'without_weight': __(`ticket.baggage.several_places_closed_without_weight`),
        'with_measurement': __('ticket.baggage.one_place_closed_with_measurement')
       }
}

export default (bag) => {
  const getDescription = (bag, type, place, decl) => {
    const { amount, weight, dimensions } = bag;
    if (!amount) return false;
    const placeTranslations = place[amount] || place[4];

    if (dimensions) {
      return interpolate(placeTranslations['with_measurement'], {
        decl,
        count: amount,
        dim: dimensions,
        units: __('ticket.baggage.measurement'),
      });
    }

    if (!isNaN(weight)) {
      return interpolate(placeTranslations['default'], {
        decl,
        count: amount,
        weight,
        units: __('ticket.baggage.units'),
      });
    }
    
    return interpolate(placeTranslations['without_weight'], {
      decl,
      count: amount,
    });
  };

  const getClasses = (bag, type) => {

    const {amount, weight, dimensions, code} = bag
    return `${amount ? '' : `bag-icon--without-${type}`}
    ${isNaN(weight) ? 'bag-icon--without-weight' : ''}
    ${dimensions && isNaN(weight) ? 'bag-icon--with-dimensions' : ''}
    ${code === 'UNKNOWN' ? `bag-icon--unknown-${type}` : ''}`
  }

  Object.keys(baggageType).forEach(type => {
    bag[type].description = getDescription(bag[type], type, fullPlaces, baggageType[type].decl)
    bag[type].classes = getClasses(bag[type], type)
  } )
  Object.keys(baggageShortType).forEach(type => {
    bag[type].short_description = getDescription(bag[type], type, shortPlaces, baggageShortType[type].decl)
  } )
  return bag
}
