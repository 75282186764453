import _map from 'lodash/map'
import _minBy from 'lodash/minBy'
import _remove from 'lodash/remove'
import _groupBy from 'lodash/groupBy'
import _defaultsDeep from 'lodash/defaultsDeep'
import CalendarBase from '../base.js'
import Template from './calendar_line.monk'
import dispatcher from 'shared/lib/dispatcher'
import filters from '../filters.js'
import moment from 'moment'

const COUNT_TILE_MAP = {
  's': 4,
  'xs': 3
}

export default class CalendarLine extends CalendarBase {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'calendar_line',
      render: {
        template: Template,
        options: { filters }
      },
      rOffset: 0,
      state: {
        prices: [{impossible: true}, {impossible: true}, {impossible: true}, {impossible: true}, {impossible: true}, {impossible: true}, {impossible: true}]
      }
    }, CalendarBase.defaultOptions())
  }

  static areParamsValid (params) {
    return super.areParamsValid(params) && params.segments.length == 2
  }

  setDates () {
    // Generate dates for table based on requestParams
    let departDate = moment(
      new Date(this.state.current_depart_date)
    ).startOf('date').subtract(3, 'd')
    let returnDate = moment(
      new Date(this.state.current_return_date)
    ).startOf('date').subtract(3, 'd')
    this.state.depart_dates = this.generateDates(moment.ISODate(departDate), {}, 7, false)
    this.state.return_dates = this.generateDates(moment.ISODate(returnDate), {}, 7, false)

    this.state.today_number = moment(new Date()).startOf('date').valueOf()
  }

  fixParams (params) {
    // If it possible then current search date place in center of table
    // otherwise shifted to the upper left corner
    let departStart = moment(params.depart_start).startOf('date').add(this.options.dOffset - 3, 'days')
    let returnStart = moment(params.return_start).startOf('date').add(this.options.rOffset - 3, 'days')
    let today = moment(new Date()).startOf('date')
    let departDiff = (departStart - today) / 1000 / 60 / 60 / 24
    let returnDiff = (returnStart - today) / 1000 / 60 / 60 / 24

    params['need_request'] = true
    params['depart_start'] = moment.ISODate(departStart)
    params['return_start'] = moment.ISODate(returnStart)
    return params
  }

  castData (data) {
    let grouped = _groupBy(data, 'depart_date')
    let current = [].concat(grouped[this.state.current_depart_date] || [])
    let currentVal = _remove(current, (item) => {
      return item.return_date == this.state.current_return_date
    })[0]
    let minCurrent = _minBy(current, 'value')

    let result = _map(this.state.depart_dates.raw, (date, index) => {
      let dayGrouped = grouped[date.date] || false
      let dayData = {depart_date: date.date, return_date: this.state.return_dates.raw[index].date, value: false}
      if (dayGrouped) {
        if (index == 3) {
          if (currentVal) dayData = currentVal
        } else {
          dayData = _minBy(dayGrouped, 'value')
        }
      }

      if (index == 2 && minCurrent && (!dayGrouped || dayData.value > minCurrent.value)) {
        dayData = minCurrent
      }
      dayData.impossible = this.state.depart_dates.dict[date.date] ? this.state.depart_dates.dict[date.date].number < this.state.today_number : true
      return dayData
    })

    return result
  }

  _countTilesDetector () {
    // let searchResultSize = document.querySelector('[role="tpwl-content"]').className.match('TPWL_widget--(.{1,2}) ')[1]
    return 7
    // return COUNT_TILE_MAP[searchResultSize] || 7
  }

  onSearchStart (params) {
    this.countTiles = this._countTilesDetector()
    this.fetchDataAndRender()
  }

  setRequestParams () {
    this.requestParams = this.fixParams({
      origin_iata: this.searchParams.segments[0].origin_city_iata || this.searchParams.segments[0].origin,
      destination_iata: this.searchParams.segments[0].destination_city_iata || this.searchParams.segments[0].destination,
      depart_start: this.state.current_depart_date,
      return_start: this.state.current_return_date,
      depart_range: 6,
      return_range: 6
    })
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)
    view.on('click', '[role="calendar_show"]', (event, node) => {
      dispatcher.send('calendar_toggle')
      view.querySelector('[role="calendar_line--wrapper"]').classList.add('calendar_line--hide')
      CalendarBase.reach_goal('CALENDAR_TOGGLE')
    })
  }
}
