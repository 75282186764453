_map = require('lodash/map')
moment = require('moment')
dispatcher            = require('shared/lib/dispatcher')
search_re = /([a-z\u0430-\u044F]{3})(\d{4})([a-z\u0430-\u044F]{3})(\d{4}|-)?(?:([a-z\u0430-\u044F]{3})(\d{4}|-)?)?(?:([a-z\u0430-\u044F]{3})(\d{4}|-)?)?(?:([a-z\u0430-\u044F]{3})(\d{4}|-)?)?(?:([a-z\u0430-\u044F]{3})(\d{4}|-)?)?(?:([a-z\u0430-\u044F]{3})(\d{4})?)?(f)?(b)?(\d)(\d)?(\d)?/i

prepare_date = (date_array) ->
  result = _map(date_array, (i) -> "#{i}")
  for index in [1, 2]
    if result[index].length == 1
      result[index] = '0' + result[index]

  result.join('-')

module.exports = (pathname) ->
  if pathname.match("undefined")
    dispatcher.send "udefined_on_url"
    return {}

  match = pathname.match(search_re)
  return {} if !match

  parsed_params = {}
  min_date = moment().hour(0).minute(0).second(0).subtract(1, 'days')
  current_year = min_date.year()

  segments = []
  first_origin = null

  for index in [1, 3, 5, 7, 9, 11, 13]
    iata = match[index]
    date_match = match[index + 1]
    first_segment = index == 1
    last_segment = (index is 13) or !match[index + 4]

    break if !iata
    continue if !date_match or date_match is '-'

    date = moment("#{date_match}#{current_year}", 'DDMMYYYY')
    date.add(1, 'years') if date.dayOfYear() < min_date.dayOfYear()
    formatted_date = [date.year(), date.month() + 1, date.date()]


    if first_segment
      first_origin = iata
      segments.push({
        origin: iata
        date: prepare_date(formatted_date)
        destination: match[index + 2]
      })
    else if match[index + 2]
      segments.push({
        origin: iata
        date: prepare_date(formatted_date)
        destination: match[index + 2]
      })
    else if last_segment and date
      segments.push({
        origin: iata
        destination: first_origin
        date: prepare_date(formatted_date)
      })

  parsed_params.segments = segments
  parsed_params.trip_class = if match[16] then 'C' else 'Y'
  parsed_params.passengers =
    adults: +match[17]
    children: +match[18] or 0
    infants: +match[19] or 0

  parsed_params.with_request = true
  parsed_params
