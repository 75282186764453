module.exports = {
  parse(location) {
    let params = {}
    if(location && location.charAt(0) == '?'){
      let pairs = location.substr(1).replace(/\+/g, ' ').split('&')
      pairs.forEach((pair) => {
        try {
          let keyValue = pair.split('=')
          let key = decodeURIComponent(keyValue.shift())
          let value = decodeURIComponent(keyValue.join('='))
          params[key] = value
        } catch(error){
          console.error(error, pair)
        }
      });
    }

    return params
  }
}
