import _groupBy from 'lodash/groupBy'
import _defaultsDeep from 'lodash/defaultsDeep'
import CalendarWithInterface from '../with_interface.js'
import Template from './calendar_one_way.monk'
import filters from '../filters.js'
import moment from 'moment'

export default class CalendarOneWay extends CalendarWithInterface {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'calendar_one_way',
      render: {
        template: Template,
        options: { filters }
      },
      state: {
        month_name: '',
        start_weekday_offset: [],
        end_weekday_offset: []
      }
    }, CalendarWithInterface.defaultOptions())
  }

  static areParamsValid (params) {
    return super.areParamsValid(params) && params.segments.length == 1
  }

  setDates () {
    // Generate dates for table based on requestParams
    this.state.depart_dates = this.generateDates(this.requestParams.depart_start, {}, this.requestParams['depart_range'])

    this.state.week_days = []
    for (let i = 0; i < 7; i++) {
      let week_day = moment().startOf('week').add(i, 'day')
      this.state.week_days.push({
        name: week_day.format('ddd'),
        is_weekend: (week_day.isoWeekday() == 6 || week_day.isoWeekday() == 7)
      })
    }

    this.state.today_number = moment(new Date()).startOf('date').valueOf()
  }

  formatDate (date) {
    return date.date()
  }

  fixParams (params) {
    // If it possible then current search date place in center of table
    // otherwise shifted to the upper left corner
    let departStart = moment(params.depart_start).add(this.options.dOffset, 'months').startOf('month')
    let departEnd = moment(params.depart_start).add(this.options.dOffset, 'months').endOf('month')
    let departRange = (departEnd - departStart) / 1000 / 60 / 60 / 24 + 1
    let currentMonth = moment(new Date()).startOf('month')

    let delta = departStart.clone().startOf('week').isoWeekday() == 7 ? 1 : 0
    this.state.start_weekday_offset = new Array((departStart.isoWeekday() - 1 + delta) % 7)
    this.state.end_weekday_offset = new Array((7 - departEnd.isoWeekday() - delta + 7) % 7)
    this.state.month_name = departStart.format('MMMM') + ' ' + departStart.year() // hack for arabian locale

    params['need_request'] = currentMonth <= departStart
    params['depart_start'] = moment.ISODate(departStart)
    params['depart_range'] = parseInt(departRange, 10)
    return params
  }

  castData (data) {
    return _groupBy(data, 'depart_date')
  }

  _countTilesDetector () {
    let node = document.querySelector('[role="tpwl-content"]')
    if (!node) return 7
    let match = node.className.match('TPWL_widget--(.{1,2}) ')
    if (!match) return 7
    return COUNT_TILE_MAP[match[1]] || 7
  }

  onSearchStart (params) {
    if (this.waitingSearch) {
      CalendarWithInterface.reach_goal(`CALENDAR_${this.options.name.toUpperCase()}_FORCE_SHOW`)
      this.fetchDataAndRender()
      document.querySelector('[role="calendar_line--wrapper"]').classList.add('calendar_line--hide')
    }
  }

  setRequestParams () {
    this.requestParams = this.fixParams({
      origin_iata: this.searchParams.segments[0].origin_city_iata || this.searchParams.segments[0].origin,
      destination_iata: this.searchParams.segments[0].destination_city_iata || this.searchParams.segments[0].destination,
      depart_start: this.state.current_depart_date
    })
  }
}
