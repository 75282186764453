let dispatcher = {
  subscribers: {},
  lastExecutions: {},
  mandatoryEvents: {},

  makeMandatory (eventName) { this.mandatoryEvents[eventName] = true },

  on (eventName, callback, options) {
    if (this.subscribers[eventName] === undefined) this.subscribers[eventName] = []

    this.subscribers[eventName].push(callback)
    if (this.lastExecutions[eventName] !== undefined) {
      if (DEBUG) console.debug('MANDATORY EXECUTE', eventName, this.lastExecutions[eventName])
      callback.apply(undefined, this.lastExecutions[eventName])
      if (window.GO) throw new Error('asd')
    }
    return this
  },

  _trigger (eventName, data, source) {
    this.lastExecutions[eventName] = [eventName, data, source]
    var eventSubscribers = this.subscribers[eventName]
    if (eventSubscribers === undefined) return false

    for (var i = 0; i < eventSubscribers.length; i++) eventSubscribers[i](eventName, data, source)
  }
}

if (DEBUG) {
  dispatcher.send = function (eventName, data, source) {
    console.debug('%cEvent %s from %s:', 'color: grey', eventName, source, data)
    return this._trigger.apply(this, arguments)
  }
} else {
  dispatcher.send = function (eventName, data, source) { this._trigger.apply(this, arguments) }
}

module.exports = dispatcher
