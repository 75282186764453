import Params from 'flights/lib/params.js'
import UserSettings from 'shared/components/user_settings/user_settings.js'
import DOMComponent from 'shared/components/base'
import colorUtils from 'shared/lib/color_utils.js'
import marker from 'shared/lib/marker.coffee'
import dispatcher from 'shared/lib/dispatcher'

export default class Ducklett extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'ducklett',
      cssx: {
        scope: '.ducklett-widget .ducklett-special_offer',
        styles: {
          '.ducklett-special_offer-body__button': {
            'background-color': `${window.TPWLCONFIG.color_scheme.btn_bg} !important`,
            'color': `${window.TPWLCONFIG.color_scheme.btn_text} !important`
          },
          '.ducklett-special_offer-body__button:hover': {
            'background-color': `${colorUtils.shadeBlend(0.12, window.TPWLCONFIG.color_scheme.btn_bg, '#FFFFFF')} !important`
          }
        }
      }
    }
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    var script = document.createElement('script')
    script.setAttribute('charset', 'UTF-8')
    script.setAttribute('async', true)
    let destinationIata = containerNode.getAttribute('data-destination') || (Params.affiliate.default_destination && Params.affiliate.default_destination.iata) || 'MOW'
    let locale = UserSettings.getLocale()
    if (locale == 'ru') {
      locale = ''
    } else {
      locale = '_' + locale
    }

    dispatcher.on('marker_restored', () => {
      script.src = `//www.travelpayouts.com/ducklett/scripts${locale}.js?powered_by=false&widget_type=brickwork&currency=${UserSettings.getCurrency()}&host=${Params.affiliate.host}&marker=${marker.get()}&limit=6&locale=${UserSettings.getLocale()}`
      containerNode.appendChild(script)
    });
  }
}
