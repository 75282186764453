{% import Selector from './user_settings_selector.monk' %}
{% import Device from 'shared/lib/device.js' %}

<div class="TPWL-widget user-settings">
  <style>
    .user-settings {
      display: none;
    }
  </style>
  <div class="user-settings-informer" role="{{Device.isMobileSize() ? 'user-settings-informer-mobile' : 'user-settings-informer'}}">
    <span class="user-settings-informer__locale user-settings-flag user-settings-flag--{{ flag | lowercase }}"></span>
    <span class="user-settings-informer__currency currency_font currency_font--{{currency | lowercase }}"></span>
  </div>
  <Selector flag="{{ flag }}" locale="{{ locale | lowercase }}" locales="{{ locales }}" currencies="{{ currencies }}" currency="{{ currency | lowercase }}"/>
  <div class="user-settings-selector" role="user-settings-modal">
    <div class="user-settings-selector-content">
      <div class="user-settings-selector-currencies">
        <label class="user-settings-selector-currencies__label" title="{{ __('user_settings.currency') }}">{{ __('user_settings.currency') }}</label>
        <ul class="user-settings-selector-currencies-list" role="scrolling_list">
          {% for current_currency of currencies %}
            {% for code, name of current_currency %}
              <li class="user-settings-selector-currencies-list__item {{ code == currency ? 'user-settings-selector-currencies-list__item--active' : '' }}"
                role="currency-switcher" data-currency="{{ code | uppercase }}">
                  {% if reload_currencies %}
                    <a href="{{ ('currency=' + code) | asParam}}" class="user-settings-selector-locales-list__item-link">
                      <span class="user-settings-selector-currencies-list__item-block">{{ code | uppercase }}</span>
                      <span class="user-settings-selector-currencies-list__item-name">
                        {{ name[0].toUpperCase() + name.substring(1) }}
                      </span>
                    </a>
                  {% else %}
                    <span class="user-settings-selector-currencies-list__item-block">{{ code | uppercase }}</span>
                    <span class="user-settings-selector-currencies-list__item-name">
                      {{ name[0].toUpperCase() + name.substring(1) }}
                    </span>
                  {% endif %}
              </li>
            {% endfor %}
          {% endfor %}
        </ul>
      </div>
      <div class="user-settings-selector-locales">
        <label class="user-settings-selector-locales__label" title="{{ __('user_settings.language') }}">{{ __('user_settings.language') }}</label>
        <ul class="user-settings-selector-locales-list" role="scrolling_list">
          {% for elem of locales %}
            {% for item of elem %}
              {% if item.code == locale %}
                <li class="user-settings-selector-locales-list__item user-settings-selector-locales-list__item--active" role="locale-switcher">
                  <a href="{{ ('locale=' + item.code) | asParam}}"
                  class="user-settings-selector-locales-list__item-link user-settings-selector-locales-list__item-link--{{ item.code }}">
                    <span class="user-settings-flag user-settings-flag--{{item.flag | lowercase}}"></span>{{ item.language }}
                  </a>
                </li>
              {% else %}
                <li class="user-settings-selector-locales-list__item" role="locale-switcher">
                  <a href="{{ ('locale=' + item.code) | asParam}}"
                  class="user-settings-selector-locales-list__item-link user-settings-selector-locales-list__item-link--{{ item.code }}">
                    <span class="user-settings-flag user-settings-flag--{{item.flag | lowercase}}"></span>{{ item.language }}
                  </a>
                </li>
              {% endif %}
            {% endfor %}
          {% endfor %}
        </ul>
      </div>
    </div>
  </div>
</div>
