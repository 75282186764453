<style>
  .search_history {
    top: {{top}} !important;
    left: {{left}} !important;
  }
</style>
<div class="search_history">
  <div class="search_history-header">
    <span class="search_history-header__name">{{ __('search_history.header') }}</span>
    <span class="search_history-header__reset" role="clear">{{ __('search_history.clear') }}</span>
  </div>
  <ul class="search_history-wrapper">
    {% for key, flights of history %}
      <li class="search_history-wrapper-item" data-key="{{ flights.key }}" role="search_item">
        <b class="search_history-wrapper-item-iata">
         <span>{{ flights.originIata }}&thinsp;</span>
         {% if !flights.isOneWay %}
           <span class="search_history-wrapper-item-iata__arrow">&harr;</span>
         {% else %}
          <span class="search_history-wrapper-item-iata__arrow search_history-wrapper-item-iata__arrow--ltr">&rarr;</span>
          <span class="search_history-wrapper-item-iata__arrow search_history-wrapper-item-iata__arrow--rtr">&larr;</span>
         {% endif %}
         <span >&thinsp;{{ flights.destinationIata }}</span>
        </b>
        <div class="search_history-wrapper-item-dates">
          <span>{{ flights.departDate }}</span>
          {% if !flights.isOneWay %}
          -
          <span>{{flights.returnDate }}</span>
          {% endif %}
        </div>
        {% if flights.formattedPrice %}
          <span class="search_history-wrapper-item-currency currency_font currency_font--{{currency}}">{{flights.formattedPrice}}</span>
        {% endif %}
      </li>
    {% endfor %}
  </ul>
</div>
