import _map from 'lodash/map'
import _size from 'lodash/size'
import moment from 'moment'
import converter from 'shared/lib/converter'
import searchHistoryStore from './store'
import { generate_id } from 'shared/lib/utils.coffee'
import { offsetTop } from 'shared/lib/offset_top'
import paramsManager from 'flights/lib/params.js'
import metrics from 'shared/lib/metrics.coffee'
import DOMComponent from 'shared/components/base'
import delegate from 'dom-delegate'
import Template from './search_history.monk'

function historyPresenter (currency) {
  let store = searchHistoryStore.getAll()
  if (_size(store) == 0) { return [] }
  let presentedHistory = _map(store, (flights) => {
    if (Object.keys(converter.rates).length) {
      flights.formattedPrice = Math.round(converter.convert(flights.unifiedPrice, 'rub', currency))
    } else {
      flights.formattedPrice = flights.unifiedPrice
    }
    return flights
  })

  presentedHistory.reverse()
  return presentedHistory
}

export default class SearchHistory extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'search_history',
      render: { template: Template },
      state: {
        currency: TPWLCONFIG.currency
      }
    }
  }

  constructor (node, options = {}) {
    super(node, options)
    this.store = searchHistoryStore.getAll()
    this.state.history = historyPresenter(this.state.currency)
  }

  show (nearestElement) {
    this.state.history = historyPresenter(this.state.currency)
    this.refresh()
    if (!this.state.history || this.state.history.length == 0) { return }

    this.state.top = (offsetTop(nearestElement, true) + nearestElement.offsetHeight + 1) + 'px'
    this.state.left = (nearestElement.getBoundingClientRect().left - 1) + 'px'

    this.refresh()
    super.show()
  }

  _initDOMEvents (view) {
    view.on('click', '[role="clear"]', (event, node) => {
    //  let historyId = node.parentNode.querySelectorAll('.js-history-flight')[0].dataset.key;
    //  let entry = store[historyId];
      searchHistoryStore.delete()
      this.hide()
    })

    view.on('click', '[role="search_item"]', (event, node) => {
      let historyId = node.dataset.key
      let entry = searchHistoryStore.get(historyId)

      if (entry && entry.params) {
        let url = paramsManager.getShortParams(entry.params.params)
        window.open('/flights/' + url, '_blank')
      }
    })

    document.querySelector('.TPWL-widget').addEventListener('click', (event, event_target) => {
      if (event.target.getAttribute('role') != 'flights-origin' && event.target.getAttribute('role') != 'flights-destination') {
        this.hide()
      }
    })

    window.addEventListener('scroll', (event, event_target) => {
      this.hide()
    })
  }

  _initEvents (dispatcher) {
    dispatcher.on('mewtwo_init', () => {
      let flightsOriginNode = document.querySelector('[role="flights-origin"]')
      if(!flightsOriginNode) return true;
      let flightsDestinationNode = document.querySelector('[role="flights-destination"]')
      flightsOriginNode.addEventListener('keyup', () => {
        this.hide()
      })
      flightsOriginNode.addEventListener('click', (event) => {
        this.show(event.target)
      })
      flightsDestinationNode.addEventListener('keyup', () => {
        this.hide()
      })
      flightsDestinationNode.addEventListener('click', (event) => {
        this.show(event.target)
      })
    })

    dispatcher.on('start_search', (eventName, params) => {
      this.hide()
      searchHistoryStore.add(params)
      this.state.history = historyPresenter(this.state.currency)
    })

    dispatcher.on('cheapest_ticket_updated', (name, params) => {
      searchHistoryStore.updatePrice(params)
      this.state.history = historyPresenter(this.state.currency)
      this.refresh()
    })
    dispatcher.on('currency_updated', (event, currency_code) => {
      this.state.currency = currency_code
      this.refresh()
    })
    dispatcher.on('places_restored', (name, params) => {
      searchHistoryStore.updatePlaces(params)
      this.refresh()
    })
  }
}
