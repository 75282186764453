import marker from 'shared/lib/marker.coffee'
import _defaultsDeep from 'lodash/defaultsDeep'
import PoweredByWidget from 'powered_by';

export default class PoweredBy {
  init (containerNode, options = {}) {
    const defaultOptions = {
      marker: marker.get(),
      locale: LOCALE,
      show: window.TPWLCONFIG.powered_by,
      utm_medium: 'whitelabel',
      utm_campaign: 'whitelabel',
      id: "wl_search",
      node: containerNode,
      send_metrics: false,
    }
    new PoweredByWidget(_defaultsDeep(options, defaultOptions));
  }
}
