<div class="TPWL-template-wrapper">
  <div class="TPWL-template-header js-header" is="header" role="main_header"></div>
  {% if page === 'serp' || page === 'map' %}
    <div class="TPWL-template-search-results" is="search_results"></div>
  {% else %}
    {% if page === 'hotel_page' %}
      <div class="TPWL-template-hotel" is="hotel_page"></div>
    {% endif %}
  {% endif %}
</div>
