import ResizeWatcher from 'shared/lib/resize_watcher.coffee';

let modifier = 'mobile_overflow--hidden'

let PopupScroller = {
  scrollTop: 0,
  modifier: modifier,

  toggle () {
    if (!ResizeWatcher.isMobile || window != window.top ) return false
    if (this.enabled === undefined) this.enabled = document.body.classList.contains(modifier)
    this.enabled ? this.disable() : this.enable()
  },

  enable () {
    this.enabled = true
    this.scrollTop = window.pageYOffset
    this.positionMemo = document.body.style.position

    document.body.style.position = 'relative'
    document.body.style.top = '-#{this.scrollTop}px'
    document.body.classList.add(this.modifier)
  },

  disable () {
    this.enabled = false

    document.body.style.position = this.positionMemo
    document.body.style.top = 0
    document.body.classList.remove(this.modifier)

    window.requestAnimationFrame(() => { window.scrollTo(0, this.scrollTop) })
  },

  closePopup: function () {
    if (this.enabled) this.toggle()
  }
}

export default PopupScroller
