<div class="range range--{{ range_modifier }}">
  <div class="from{{ left === false || (infinity && right === upper) ? ' from--hidden' : '' }}">
    {% if left !== false && (!infinity || right != upper) %}
      {% unsafe getFromText() %}
      <strong>
        {% unsafe left | decorateMeasurement %}
      </strong>
    {% endif %}
  </div>

  <div class="to{{ right === false || (infinity && right === upper) ? ' to--hidden' : '' }}">
    {% if right !== false && (!infinity || right !== upper) %}
      {% unsafe getToText(left) %}
      <strong>
        {% unsafe right | decorateMeasurement %}
      </strong>
    {% endif %}
  </div>

  <div class="any{{ ((right !== false || left !== false) && !infinity) ? ' any--hidden' : ''}}">
    {% if (right === false && left === false) || (infinity && right === upper) %}
      <strong>{% unsafe getAnyText() %}</strong>
    {% endif %}
  </div>
</div>
