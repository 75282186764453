_extend = require('lodash/extend')
dispatcher = require('shared/lib/dispatcher')
config = require('shared/lib/tp_config.js')
RequestProcessor = require("./request_processor.coffee")
marker = require('shared/lib/marker.coffee')
cookies = require('shared/lib/cookies.coffee')
converter = require('shared/lib/converter')
UserSettings = require('shared/components/user_settings/user_settings.js').default
Locale = require('shared/lib/locale').default

send = (event, data) ->
  dispatcher.send(event, data, 'dispatcher_interaction')

di = {
  _requests: {}
  _sent_city_distances: {}
  _send_first_tickets: {}
  sort_order: 'price'

  start: (id, params, sort_order) ->
    @_requests[id] = request = new RequestProcessor(id, @callbacks(), sort_order)
    request.start(params)

  stop: (id) ->
    @_requests[id].stop()

  set_filters_state: (id, filters_state, with_composing) ->
    @_requests[id].set_filters(filters_state, with_composing) if @_requests[id]

  reset_filters_state: (id) ->
    @_requests[id].reset_filters()

  set_sort_order: (id, sort_order) ->
    @_requests[id].set_sort_order(sort_order)
    @sort_order = sort_order

  callbacks: ->
    currencies_updated: (request_id, currencies) =>
      converter.rates = currencies # We need to setup rates before telling other modules what currencies was updated.
      send('currencies_updated', {request_id: request_id, currencies: currencies}, 'disp_interaction')
    city_distance_updated: (request_id, city_distance) =>
      unless @_sent_city_distances[request_id]
        send('city_distance_updated', {request_id: request_id, city_distance: city_distance}, 'disp_interaction')
        @_sent_city_distances[request_id] = true
    segments_updated: (request_id, segments) =>
      send('segments_updated', {request_id: request_id, segments: segments}, 'disp_interaction')
    airlines_updated: (request_id, airlines) =>
      send('airlines_updated', {request_id: request_id, airlines: airlines}, 'disp_interaction')
    airline_rules_updated: (request_id, airline_rules) ->
      send('airline_rules_updated', {request_id: request_id, airline_rules: airline_rules}, 'disp_interaction')
    airports_updated: (request_id, airports) =>
      send('airports_updated', {request_id: request_id, airports: airports}, 'disp_interaction')
    gates_updated: (request_id, gates) =>
      send('gates_updated', {request_id: request_id, gates: gates}, 'disp_interaction')
    boundaries_updated: (request_id, boundaries) =>
      send('boundaries_updated', {request_id: request_id, boundaries: boundaries}, 'disp_interaction')
    tickets_updated: (request_id, tickets, reason = 'append') =>
      if !@_send_first_tickets[request_id]
        send('first_tickets_arrived', {request_id: request_id}, 'disp_interaction')
        @_send_first_tickets[request_id] = true
      send('tickets_updated', {request_id: request_id, tickets: tickets, reason: reason}, 'disp_interaction')
    cheapest_ticket_updated: (request_id, ticket) =>
      send('cheapest_ticket_updated', {request_id: request_id, ticket: ticket}, 'disp_interaction')
    fuzzy_tickets_updated: (request_id, tickets) =>
      send('fuzzy_tickets_updated', {request_id: request_id, tickets: tickets}, 'disp_interaction') if tickets.length && tickets[0].length
    search_id_updated: (request_id, search_id) =>
      send('search_id_updated', {request_id: request_id, search_id: search_id}, 'disp_interaction')
    server_name_updated: (request_id, server_name) =>
      send('server_name_updated', {request_id: request_id, server_name: server_name}, 'disp_interaction')
    average_prices_updated: (request_id, average_prices) =>
      send('average_prices_updated', {request_id: request_id, average_prices: average_prices}, 'disp_interaction')
    finish: (request_id, data) =>
      send('search_finished', {request_id: request_id, data}, 'disp_interaction')
    error: (request_id, data) =>
      send('search_failed', {request_id: request_id, data}, 'disp_interaction')
      # Rollbar?.configure({payload: {request_id: request_id, search_failed: data}})
    gates_meta_updated: (request_id, meta) =>
      send('gates_meta_updated', {request_id: request_id, meta: meta}, 'disp_interaction')
}

dispatcher.on('start_search', (event, {request_id: request_id, params: params}) =>
  search_params = _extend({}, params, {
    locale: config.host.yasen_locale_param
    lang: Locale.getLocaleFallback(LOCALE)
    host: window.location.hostname
    marker: marker.get()
    currency: UserSettings.getCurrency()
  })
  # Rollbar?.configure({payload: {yasen_request: {request_id: request_id, search_params: search_params}}})
  di.start(request_id, search_params, di.sort_order)
)

dispatcher.on('stop_search', (event, {request_id: request_id}) ->
  di.stop(request_id)
)

dispatcher.on('filters_state_updated', (event, {request_id: request_id, filters_state: filters_state, with_composing: with_composing}) ->
  with_composing = true if with_composing == undefined
  di.set_filters_state(request_id, filters_state, with_composing)
)

dispatcher.on('reset_filters_state', (event, {request_id: request_id}) ->
  di.reset_filters_state(request_id)
)

dispatcher.on('sort_order_updated', (event, {request_id: request_id, sort_order: sort_order}) ->
  di.set_sort_order(request_id, sort_order)
)
