import DOMComponent from 'shared/components/base'
import Template from './fake_ticket.monk'

export default class FakeTicket extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'fake_ticket',
      render: { template: Template },
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.ticket-action-button-deeplink': { 'background-color': window.TPWLCONFIG.color_scheme.btn_bg },
          '.ticket-action-button': { 'color': window.TPWLCONFIG.color_scheme.btn_text }
        }
      }
    }
  }

  constructor (node, options = {}) {
    super(node, options)
    if (options.onlyInit) return false
    this.view.update({segments: [{direction: 'depart'}, {direction: 'return'}]})
  }

  _initEvents (dispatcher) {
    dispatcher.on('start_search', () => { this.show() })
    dispatcher.on('first_tickets_arrived', () => { this.hide() })
    dispatcher.on('systemMessageError', () => { this.hide() })
  }
}

delete (new FakeTicket(document.createElement('div'), {onlyInit: true})) // HACK for styles initialize
