import _defaultsDeep from 'lodash/defaultsDeep'
import BaseFilter from 'shared/components/filters/base/base.js'
import Template from 'shared/components/filters/radio/radio.monk'
import dispatcher from 'shared/lib/dispatcher'

const SORTINGS = {
  'all_tickets': __('sorting.all_tickets'),
  'best_tickets': __('sorting.best_tickets')
}

export default class BestTickets extends BaseFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'best_tickets',
      render: {
        template: Template
      },
      state: {
        title: '',
        selectedId: 'all_tickets',
        options: SORTINGS
      }
    }, super.defaultOptions())
  }

  constructor(node, options = {}) {
    super(node, options)
    this.state.filter_id = encodeURIComponent(this.options.name).replace(/%/g, '')
  }

  getState () {
    return {request_id: this.state.request_id, sort_order: this.state.selectedId}
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)

    view.on('change', '[role="filter-option"]', (event, eventTarget) => {
      if (this.state.selectedId !== eventTarget.name) {
        this.state.selectedId = eventTarget.name
        super.update()
        dispatcher.send(`${this.state.selectedId.replace(/_/g, '-')}-shown`, {force: true}, 'tickets-sorting-tabs')
      }
    })
  }
}
