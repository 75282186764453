_assign = require('lodash/assign')
_map = require('lodash/map')
_compact = require('lodash/compact')
#example:
#date_formatter = require('shared/lib/date_formatter.coffee')
#date_formatter.form_date(+new Date())

moment = require('moment')

_cache = {}

default_formatter = (ts)->
  moment.utc(ts).format()

defaults =
  form_date: default_formatter

  ticket_date: default_formatter
  non_localized_date: default_formatter

  ticket_time: (ts) ->
    dt = new Date(ts * 1000)
    hours = dt.getUTCHours()
    minutes = dt.getUTCMinutes()
    "#{if hours < 10 then "0#{hours}" else hours}:#{if minutes < 10 then "0#{minutes}" else minutes}"

  flight_time_filter: (date_string) ->
    moment(date_string, 'YYYY-MM-DD').format('D MMMM')

  format_duration: (duration) ->
    units = __('leg_durations')
    parts = [Math.floor(duration / (60*24)), Math.floor((duration / 60) % 24), duration % 60]
    result = ''
    for index, val of parts
      result += ' ' + "#{val}".slice(-2) + units[index] if val > 0
    result

  format_transfer_duration: (duration) ->
    units = __('durations')
    "<span class='formatted_time'>" + "#{Math.floor(duration / 60)}".slice(-2) + "#{units[0]}</span> " + "&nbsp;" + "<span class='formatted_time'>" + "00#{duration % 60}".slice(-2) + "#{units[1]}</span>"

  flight_time_format_duration_with_units: (duration, units, show_days) ->
    units ?= __('durations')
    show_days ?= true
    hours = ''
    days = ''
    if duration >= 60 * 24 && show_days
      days_count = Math.floor(duration / (60 * 24))
      days = days_count + units[2]
      hours = Math.floor((duration - 60 * 24 * days_count) / 60) + units[0]
    else if duration >= 60
      hours = "#{Math.floor(duration / 60)}".slice(-2) + units[0]
    minutes = "#{duration % 60}".slice(-2) + units[1]
    _compact([days, hours, minutes]).join('&thinsp;')

  format_time_from_timestamp: (ts) ->
    moment.utc(ts * 1000).format("#{__('moment.time')} D MMM")

  format_time_from_h_m_string: (s) ->
    moment(s, 'hh:mm').format(__('moment.time'))

  time_respecting_locale: (minutesFromDayStart) ->
    moment()
        .startOf('day')
        .add(minutesFromDayStart, 'minutes' )
        .format(__('moment.time'))

  time_and_date: (ts) ->
    moment.utc(ts * 1000).format(__('moment.time_date'))

  format_time_from_h_m_string_short: (s) ->
    moment(s, 'hh:mm').format(__('moment.time_short'))

  slider_format_from_h_m_string: (s) ->
    duration = s.split(':')
    parseInt(duration[0], 10) * 60 + parseInt(duration[1], 10)

  to_human_format: (minutesFromDayStart) ->
    "0#{Math.floor(minutesFromDayStart/ 60)}".slice(-2) + ":" + "0#{minutesFromDayStart % 60}".slice(-2)

defaults.flight_time_format_duration_with_short_units = (duration) ->
  units = _map(__('durations'), (val) -> val.substr(0, 1))
  defaults.flight_time_format_duration_with_units(duration, units, false)

module.exports = _assign(defaults, (objectValue, fn, key)->
  _cache[key] = {}
  (ts)->
    if !_cache[key][ts]
      _cache[key][ts] = fn(ts)
    _cache[key][ts]
)
