import Device from 'shared/lib/device.js'
import dispatcher from 'shared/lib/dispatcher'

class Swiper {
  constructor (triggerNode, targetNode, location, width, shownClass, openState, closeState) {
    this.triggerNode = triggerNode
    this.targetNode = targetNode
    this.location = location
    this.width = width
    this.opened = false
    this.sensivity = 100
    this.openState = openState
    this.closeState = closeState
    this.shownClass = shownClass
    this._initTouchEvents()
  }

  _initTouchEvents () {
    this.triggerNode.addEventListener('touchstart', (event) => {
      event.preventDefault()
      this.transitionMemo = this.targetNode.style.transition
      this.targetNode.style.transition = 'none'
      this.startAt = new Date()
    })

    this.triggerNode.addEventListener('click', (event) => {
      if (this.isShown()) this.close()
    })

    this.triggerNode.addEventListener('touchmove', (event) => {
      event.preventDefault()
      this.diff = -event.view.innerWidth + event.touches[0].clientX
      if (this.diff < -this.width) this.diff = -this.width
      else if (this.diff > 0) this.diff = 0
      this.targetNode.style.transform = `translate3d(${this.diff}px,0,0)`
    })

    this.triggerNode.addEventListener('touchend', (event) => {
      event.preventDefault()
      this.targetNode.style.transition = this.transitionMemo
      let shown = this.isShown()
      let border = shown ? -this.width + this.sensivity : -this.sensivity

      if (this.diff < border) {
        this.open()
      } else {
        this.close()
      }

      if (shown && (new Date() - this.startAt) < 300) this.close()
    })
  }

  open () {
    document.body.classList.add('overflow--hidden')
    this.targetNode.classList.add(this.shownClass)
    this.targetNode.style.transform = ''
    this.openState && dispatcher.send(this.openState)
  }

  close () {
    document.body.classList.remove('overflow--hidden')
    this.targetNode.classList.remove(this.shownClass)
    this.targetNode.style.transform = ''
    this.closeState && dispatcher.send(this.closeState)
  }

  toggle () {
    this.isShown() ? this.close() : this.open()
  }

  isShown () {
    return this.targetNode.classList.contains(this.shownClass)
  }
}

export default Swiper
