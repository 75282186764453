<div class="debugger-modal-window js-debugger-modal-window {{ visible ? '' : 'hidden' }}">
    <div class="js-debugger-close-button">✖</div>
    <h2 class="debugger-marker">Marker: {{ marker }}; Market: {{market}}; SearchId: {{search_id}}</h2>
    <div class="debugger-tabs js-tabs">
        <div class="debugger-tab {{ tab == 'succeeded' ? 'active' : '' }}" data-tab="succeeded">Succeeded gates table</div>
        <div class="debugger-tab {{ failed_checked ? '' : 'debugger-tab--hidden' }} {{ tab == 'failed' ? 'active' : '' }}" data-tab="failed">Failed gates table</div>
        <div class="debugger-tab {{ tab == 'server_names' ? 'active' : '' }}" data-tab="server_names">Server names</div>
        <div class="debugger-tab {{ tab == 'settings' ? 'active' : '' }}" data-tab="settings">Settings</div>
    </div>


    <div class="debugger-content {{ tab == 'succeeded' ? '' : 'hidden' }}">
        <table class="debugger-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Tickets count</th>
                <th>Good tickets</th>
                <th>Bad tickets</th>
                <th>Response time</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
            {% for gates %}
                <tr>
                    <td>{{ id }}</td>
                    <td>{{ name }}</td>
                    <td>{{ count }}</td>
                    <td>{{ good_count }}</td>
                    <td>{{ bad_count }}</td>
                    <td>{{ duration }}</td>
                    <td>{{ error }}</td>
                </tr>
            {% endfor %}
            </tbody>
        </table>
    </div>

    <div class="debugger-content {{ tab == 'failed' ? '' : 'hidden' }}">
        <div> Search: <input class="debugger-search js-debugger_search" type="text"/>
        </div>

        <table class="debugger-table">
            {% for debug_infos %}
                <tr>
                    <td>
                      {% for blockers %}
                        <p><b>{{ unit_name }}:</b>&nbsp;{{ message }}</p>
                      {% endfor %}
                    </td>
                </tr>
            {% endfor %}
        </table>
    </div>


    <div class="debugger-content {{ tab == 'server_names' ? '' : 'hidden' }}">
      <table class="debugger-table">
        <tr>
          <th>Request ID</th>
          <th>Server name</th>
        </tr>
        <tr>
          <td>{{ request_id }}</td>
          <td>{{ server_name }}</td>
        </tr>
      </table>
    </div>

    <div class="debugger-content debugger-content--settings {{ tab == 'settings' ? '' : 'debugger-content--hidden' }}">
        <label>
            <input type="checkbox" class="js-use-local-storage" checked="{{ cache_checked }}"/>
            Save search result to <em>localStorage</em>
        </label>
        <label>
            <input type="checkbox" class="js-debugger-extract-debug"  checked="{{ failes_checked }}"/>
            Debug yasen failed gates
        </label>
        <label>
            <input type="checkbox" class="js-debugger-proposals-debug"  checked="{{ proposals_debug }}"/>
            Show proposals debug messages
        </label>
        <label>
            <input type="checkbox" class="js-debugger-results-debug"  checked="{{ results_debug }}"/>
            Send dumps to debug stream
        </label>
    </div>

</div>
