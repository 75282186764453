import _defaultsDeep from 'lodash/defaultsDeep'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import dictionary from 'shared/lib/dictionary.js'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import _mapValues from 'lodash/mapValues'
import _mapKeys from 'lodash/mapKeys'
import _forEach from 'lodash/forEach'
import _sortBy from 'lodash/sortBy'
import _reduce from 'lodash/reduce'

import _cloneDeep from 'lodash/cloneDeep'

let iataFromNewCountry

export default class StopsAirports extends CheckboxFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'stops_airports',
      state: {
        title: __('filters.stops_airports'),
        labels: (iata) => {
          let airport = dictionary.airportByIata(iata)
          return (airport.name + ' ' + iata)
        },
        groupLabels: (iata) => {
          if (iataFromNewCountry.indexOf(iata) < 0) { return }
          return dictionary.airportByIata(iata).country
        }
      }
    }, super.defaultOptions())
  }
  constructor (node, options = {}) {
    super(node, options)
    this.needDafaultSatate = true
  }
  _keysSortFunction (key) {
    return parseInt(key)
  }
  getState () {
    const state = super.getState()
    const result = [..._keys(state), ...this.state.airportsArrivalDeparture]  // поддержка обратной совместимости данных
    return { stops_airports: result }
  }
  update (data, keys) {
    if ((Object.keys(data).length === 0)) return
    const newData = _cloneDeep(data)
    let newDataWithoutArrivalDepartureAirports =
      this.state.airportsArrivalDeparture ? this._removeArrivalDepartureAirports(newData) : newData
    if (!keys) {
      const groupAirports = this.groupAirportsByCountry(newDataWithoutArrivalDepartureAirports)
      const sortAirportsByCountry = this._sortAirportsByCountry(groupAirports)
      const airportsArr = this.iterateEachAirportByCountry(sortAirportsByCountry, this.createAirportsArr)
      iataFromNewCountry = this.iterateEachAirportByCountry(sortAirportsByCountry, this.findFirstAirportFromNewCountry)
      const airportsObjByIata = _mapKeys(airportsArr, (value, key) => value.iata)
      keys = _keys(airportsObjByIata)
      newDataWithoutArrivalDepartureAirports = _mapValues(airportsObjByIata, (value, key) => false)
    }
    super.update(newDataWithoutArrivalDepartureAirports, keys)
  }
  _removeArrivalDepartureAirports (data) {
    return _reduce(data, (acc, value, key) => {
      if (this.state.airportsArrivalDeparture.indexOf(key) < 0) { acc[key] = value }
      return acc
    }, {})
  }
  groupAirportsByCountry (unsortedAirports) {
    const unsortedAirportsWithIata = _mapValues(unsortedAirports, (value, key) => {
      value.iata = key
      return value
    })
    return _groupBy(unsortedAirportsWithIata, 'country')
  }

  _sortAirportsByCountry (groupAirports) {
    const sortAirports = _map(groupAirports, (value, key) => {
      return {
        country: key,
        airports: _sortBy(value, (a) => a.name)
      }
    })
    const sortCountry = _sortBy(sortAirports, (a) => a.country)
    return sortCountry
  }
  iterateEachAirportByCountry (groupAirports, callback) {
    let result = []
    _map(groupAirports, (value, key) => {
      _forEach(value.airports, (item, id) => {
        callback(item, result, id)
      })
    })
    return result
  }
  createAirportsArr (item, result) {
    result.push(item)
  }
  findFirstAirportFromNewCountry (item, result, id) {
    if (id === 0) { result.push(item.iata) }
  }
}
