import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _minBy from 'lodash/minBy'
import _forEach from 'lodash/forEach'
import _values from 'lodash/values'
import _min from 'lodash/min'
import _cloneDeep from 'lodash/cloneDeep'
import DOMComponent from 'shared/components/base'
import matrixPriceFetcher from './matrix_price_fetcher.js'
import metrics from 'shared/lib/metrics.coffee'
import moment from 'moment'

var responseCache = {}

export default class CalendarBase extends DOMComponent {
  static defaultOptions () {
    return {
      onlyInit: true,
      shown: false,
      state: {
        prices: [],
        empty_price: {},
        currentMinPrice: 0,
        currentDirectMinPrice: 0,
        minPrice: 0,
        today_number: 0,
        adults: 1,
        direct_only: false,
        existDirectFlights: false,
        current_depart_date: '',
        current_return_date: '',
        currencies: false
      },
      waitingSearch: false,
      validParams: false,
      dOffset: 0,
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.price--current': {
            'outline-color': window.TPWLCONFIG.color_scheme.btn_bg
          },
          '.calendar_footer__close': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.calendar_footer__close:before': {
            'background': `url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%207%205%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ECombined%20Shape%3C%2Ftitle%3E%3Cpath%20d%3D%22M3.4%202.28L5.68%200%206.8%201.12%203.42%204.5l-.02-.02-.02.02L0%201.12%201.12%200%203.4%202.28z%22%20fill%3D%22${encodeURIComponent(window.TPWLCONFIG.color_scheme.link)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E") 50% 50% no-repeat`
          },
          '.cell_dates--hover': {
            'outline-color': window.TPWLCONFIG.color_scheme.bg
          },
          '.calendar-loader .spinner div': {
            'background': window.TPWLCONFIG.color_scheme.bg
          },
          '.calendar_roll_button': {
            'background': `url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226%22%20height%3D%2210%22%20viewBox%3D%220%200%206%2010%22%3E%0A%20%20%3Cpath%20fill%3D%22${encodeURIComponent(window.TPWLCONFIG.color_scheme.link)}%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.03115789%2C10%20L6%2C9.03556472%20C6%2C9.03556472%201.87263158%2C5.00031252%201.87957895%2C4.98531158%20L5.95515789%2C0.936308519%20L5.01410526%2C0%20L0%2C4.99156197%20L5.03115789%2C10%20Z%22%2F%3E%0A%3C%2Fsvg%3E') 50% 50% no-repeat`
          },
          '.calendar_roll_button:hover': {
            'background': `${window.TPWLCONFIG.color_scheme.link} url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226%22%20height%3D%2210%22%20viewBox%3D%220%200%206%2010%22%3E%0A%20%20%3Cpath%20fill%3D%22%23ffffff%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.03115789%2C10%20L6%2C9.03556472%20C6%2C9.03556472%201.87263158%2C5.00031252%201.87957895%2C4.98531158%20L5.95515789%2C0.936308519%20L5.01410526%2C0%20L0%2C4.99156197%20L5.03115789%2C10%20Z%22%2F%3E%0A%3C%2Fsvg%3E') 50% 50% no-repeat`,
            'border-color': window.TPWLCONFIG.color_scheme.link
          }
        }
      }
    }
  }

  static areParamsValid (params) {
    return params.trip_class == 'Y' && !params.passengers.children && !params.passengers.infants
  }

  constructor (node, options = {}) {
    super(node, options)
    this.attempts = 0
    this.stopCountPrices = {}
    this.minPrices = new matrixPriceFetcher()
    this._initEvents(this._dispatcher)
  }

  generateDates (date, result = {}, count = 7, with_weekday = true) {
    date = moment(date).startOf('date')
    result['raw'] || (result['raw'] = [])
    result['formatted'] || (result['formatted'] = [])
    result['dict'] || (result['dict'] = {})

    for (let i = 0; i < count; i++) {
      let raw = moment.ISODate(date)
      let formatted = this.formatDate(date, with_weekday)
      result.raw.push({ date: raw, number: date.valueOf()})
      result.formatted.push(formatted)
      result['dict'][raw] = { date: raw, number: date.valueOf(), formatted: formatted}

      date.add(1, 'd')
    }

    return result
  }

  formatDate (date, withWeekday = true) {
    if (withWeekday) {
      let dateFormated = moment.dayWeekdayMonthName(date)
      let modifyWeekday = `<span${(date.day() === 6 || date.day() === 0) ? ' class="cell_dates-day--holiday"' : ''}>${date.format('ddd')}</span>`
      let weekday = moment(date).format('ddd')
      return dateFormated.replace(weekday, modifyWeekday)
    } else {
      return moment(date).format('ll').replace(',', '').split(' ').slice(0, 2).join(' ')
    }
  }

  open () {
    if (this.view) {
      CalendarBase.reach_goal(`CALENDAR_${this.options.name.toUpperCase()}_SHOW`)
      this.show()
    } else {
      this.fetchDataAndRender()
    }
  }

  show () {
    this.shown = true
    super.show()
  }

  _initDOMEvents (view) {
    view.on('click', '[role="calendar_link"]', (event, node) => {
      CalendarBase.reach_goal(`CALENDAR_${this.options.name.toUpperCase()}_LINK_CLICK`)
    })
  }

  _initEvents (dispatcher) {
    dispatcher.on('start_search', (event, {request_id, params}) => {
      this.validParams = this.constructor.areParamsValid(params)

      if (this.validParams) {
        this.searchParams = params
        this.constructor.searchParams = _cloneDeep(params)
        this.state.current_depart_date = this.searchParams.segments[0].date
        if (this.searchParams.segments[1]) {
          this.state.current_return_date = this.searchParams.segments[1].date
        }
        this.state.adults = parseInt(params.passengers.adults, 10)

        this.onSearchStart(this.constructor.searchParams)
      }
    })

    dispatcher.on('currencies_updated', (event, {request_id, currencies}) => {
      this.state.currencies = currencies
      this.refresh()
    })

    dispatcher.on('currency_updated', (event, currency) => {
      this.state.currency = currency
      this.refresh()
    })

    dispatcher.on('boundaries_updated', (event, {request_id, boundaries}) => {
      if (boundaries && boundaries.stops_count) {
        this.stopCountPrices = boundaries.stops_count
        this.state.currentMinPrice = parseInt(_min(_values(this.stopCountPrices)) / this.state.adults, 10)
        this.state.currentDirectMinPrice = (this.stopCountPrices[0] &&
          parseInt(this.stopCountPrices[0] / this.state.adults, 10)) || 0

        this.refresh()
      }
    })

    dispatcher.on('filters_state_updated', (model, caller, with_composing) => {
      if (with_composing === 'filters' || with_composing === 'stops_count_filter') {
        let beforeState = this.state.direct_only
        if (caller.filters_state.stops_count === null) {
          this.state.direct_only = false
        } else if (caller.filters_state.stops_count) {
          let values = _values(caller.filters_state.stops_count)
          this.state.direct_only = values.length === 1 && values[0] === 0
        }

        if (beforeState != this.state.direct_only) {
          this.setMinPrice()
          this.refresh()
        }
      }
    })

    dispatcher.on('reset_filters', (event) => {
      this.state.direct_only = false
      this.refresh()
    })
  }

  fetchDataAndRender () {
    if (this.searchParams && this.validParams) {
      this.setRequestParams()
      this.setDates()

      if (!this.view) {
        this._render(this.options.render)
        this._initDOMEvents(this.view)
      }

      this.containerNode.classList.add(`${this.options.name}--loading`)
      this.refresh()
      this.show()
      let cacheKey = []
      _forEach(this.requestParams, (value, key) => {
        cacheKey.push(`${key}=${value}`)
      })
      cacheKey = cacheKey.join('&')

      if (responseCache[cacheKey]) {
        this.fetchCallback(cacheKey)(responseCache[cacheKey])
      } else {
        this.minPrices.fetch(
          this.requestParams,
          (data) => { this.fetchCallback(cacheKey)(data) },
          (errors) => { this.onError(errors) }
        )
      }
    } else {
      this.waitingSearch = true
    }
  }

  fetchCallback (cacheKey) {
    return (data) => {
      this.rawData = data
      responseCache[cacheKey] = data
      if (data.length) {
        this.setMinPrice()
        this.directFlightDetector(data)
      }

      this.state.prices = this.castData(data)

      this.containerNode.classList.remove(`${this.options.name}--loading`)
      if (this.state.currencies) this.refresh()
    }
  }

  onError (errors) {
    if (errors && errors['errors'] &&
      (
        errors['errors']['depart_date'] ||
        errors['errors']['return_date'] ||
        errors['errors']['no_need_request']
      )
    ) {
      this.containerNode.classList.remove(`${this.options.name}--loading`)
    } else {
      if (this.attempts++ < 3) {
        setTimeout(() => { this.fetchDataAndRender() }, 1000)
      } else {
        this.containerNode.classList.remove(`${this.options.name}--loading`)
      }
    }
  }

  filterData (data) {
    return data
  }

  setMinPrice () {
    if (this.rawData) {
      let price = false

      if (!this.state.direct_only) {
        price = _minBy(this.rawData, 'value')
      } else {
        price = _minBy(_filter(this.rawData, function (el) {
          return el.number_of_changes === 0
        }), 'value')
      }

      this.state.minPrice = (price && price.value) || 0
    }
  }

  directFlightDetector (data) {
    this.state.existDirectFlights = !!_find(data, ['number_of_changes', 0])
  }

  castData (data) {
    return data
  }

  static reach_goal (goal) {
    metrics.reach_goal(goal)
  }
}
