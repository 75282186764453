import _has from 'lodash/has'
import _groupBy from 'lodash/groupBy'
import converter from 'shared/lib/converter'
import * as utils from 'shared/lib/utils.coffee'
import * as storage from 'shared/lib/local_storage.coffee'
import moment from 'moment'

const MAXIMUM_STORED_COUNT = 5

const getSearchHistory = () => {
  let searchHistory

  try {
    searchHistory = JSON.parse(storage.getItem('searchHistoryWl'))
  } catch (e) {
    console.error(e)
  }

  return searchHistory || {}
}

class SearchHistoryStore {
  constructor () {
    this.store = getSearchHistory()
    this.searches = this.store.searches
  }

  add (params) {
    let segments = params.params.segments
    let key = this.createKey(segments)
    let isOneWay = segments.length === 1
    let storeGrouppedByRoutes = _groupBy(this.store, 'route')

    if (utils.is_open_jaw(segments)) {
      return
    }

    let oldestRouteEntry
    let oldestRoutesGroup

    let entry = {
      params,
      isOneWay,
      key,
      requestID: params.request_id,
      departDate: moment(segments[0].date).format('DD.MM'),
      returnDate: isOneWay ? __('search_history.one_way') : moment(segments[segments.length - 1].date).format('DD.MM'),
      originIata: segments[0].origin,
      destinationIata: segments[0].destination,
      route: segments[0].origin + segments[0].destination
    }

    if (segments[0].origin_name) {
      this.setPlacesNames(entry, segments[0])
    }

    // Delete latest entry under group if there are more than MAXIMUM_STORED_COUNT of them
    if (storeGrouppedByRoutes[entry.route] && storeGrouppedByRoutes[entry.route].length === MAXIMUM_STORED_COUNT && !_has(this.store, entry.key)) {
      oldestRouteEntry = storeGrouppedByRoutes[entry.route][0]
      delete this.store[oldestRouteEntry.key]
    }

    // Delete latest entries group if there are more than MAXIMUM_STORED_COUNT of them
    if (Object.keys(storeGrouppedByRoutes).length === MAXIMUM_STORED_COUNT && !_has(storeGrouppedByRoutes, entry.route)) {
      oldestRoutesGroup = storeGrouppedByRoutes[Object.keys(storeGrouppedByRoutes)[0]]
      oldestRoutesGroup.forEach((entry) => {
        delete this.store[entry.key]
      })
    }

    this.store[key] = this.currentItem = entry

    while (Object.keys(this.store).length > MAXIMUM_STORED_COUNT) {
      delete this.store[Object.keys(this.store)[0]]
    }
    this.save()
  }

  setPlacesNames (entry, segment) {
    entry.originName = segment.origin_name
    entry.destinationName = segment.destination_name
    this.save()
  }

  save () {
    storage.setItem('searchHistoryWl', JSON.stringify(this.store))
  }

  get (key) {
    return this.store[key]
  }
  delete (entry) {
    storage.removeItem('searchHistoryWl')
    this.store = {}
    storage.setItem('searchHistoryWl', JSON.stringify(this.store))
  }
  updatePlaces (params) {
    let segments = params.segments
    let key = this.createKey(segments)
    let entry = this.get(key)

    if (entry && !entry.originName) {
      this.setPlacesNames(entry, segments[0])
    }

    this.save()
  }

  updatePrice (params) {
    let entry = this.currentItem
    let price = params.ticket[1][0].unified_price

    if (!entry) {
      return
    }

    entry.unifiedPrice = price
    // entry.formattedPrice = `${__('search_history.from')} ${converter.format(price)}`;

    this.save()
  }

  getAll () {
    return this.store
  }

  createKey (segments) {
    let key = ''

    segments.forEach((segment) => key += segment.origin + segment.destination + segment.date)

    return key
  }
}

export default new SearchHistoryStore()
