import Params from 'flights/lib/params.js'
import UserSettings from 'shared/components/user_settings/user_settings.js'
import DOMComponent from 'shared/components/base'
import colorUtils from 'shared/lib/color_utils.js'
import marker from 'shared/lib/marker.coffee'
import dispatcher from 'shared/lib/dispatcher'

export default class Weedle extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'weedle',
      cssx: {
        scope: '.weedle-widget',
        styles: {
          '.weedle-offers_list .weedle-offers_list-item > a': {
            'color': `${window.TPWLCONFIG.color_scheme.link} !important`
          },
          '.weedle-offers_list .weedle-offers_list-item > a:hover': {
            'color': `${colorUtils.shadeBlend(0.2, window.TPWLCONFIG.color_scheme.link, '#FFFFFF')} !important`
          },
          ".weedle-loader[role='loader'] > div": {
            'background-color': `${window.TPWLCONFIG.color_scheme.link} !important`
          }
        }
      }
    }
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    var script = document.createElement('script')
    script.setAttribute('charset', 'UTF-8')
    script.setAttribute('async', true)
    let destinationIata = containerNode.getAttribute('data-destination') || (Params.affiliate.default_destination && Params.affiliate.default_destination.iata) || 'MOW'
    dispatcher.on('marker_restored', () => {
      script.src = `https://www.travelpayouts.com/weedle/widget.js?multiple=true&width=300px&marker=${marker.get()}&host=${Params.affiliate.host}&locale=${LOCALE}&currency=${UserSettings.getCurrency()}&destination=${destinationIata}&destination_name=`
      containerNode.appendChild(script)
    });
  }
}
