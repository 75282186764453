import _defaultsDeep from 'lodash/defaultsDeep'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import filters from 'shared/lib/filters.coffee'
import Device from 'shared/lib/device.js'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import metrics from 'shared/lib/metrics.coffee'
import UserSettings from 'shared/components/user_settings/user_settings.js'
import converter from 'shared/lib/converter'

let defaultLabels = {
  '0': __('filters.baggage_no'),
  '1': __('filters.baggage_yes'),
}

export default class Baggage extends CheckboxFilter {
  static defaultOptions () {
    const color = Device.isTouch() ? '#FFFFFF' : window.TPWLCONFIG.color_scheme.body_bg
    return _defaultsDeep({
      name: 'baggage',
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.TPWL_widget--xl .filter--baggage_filter .only-button': {
            'background-color': color
          }
        }
      },
      state: {
        has_check_all: true,
        collapsed: false,
        title: __('filters.baggage'),
        labels: (id) => {
          return defaultLabels[+id ? '1' : '0']
        }
      },
      render: {
        options: {
          filters: {
            decorateMeasurement (value) {
              this.currency = UserSettings.getCurrency()
              return `<span class="currency_font currency_font--${this.currency}">${filters.split_price(converter.convert(value))}</span>`
            }
          }
        }
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    this.needDafaultSatate = true
  }

  update (data, keys) {
    if ('currency' in data || 'rate' in data) {
      if ('currency' in data) this.state.currency = data.currency
      if ('rate' in data) this.state.rate = data.rate

      this.data = this._convertPrices(this.defaultPrices)
    } else {
      this.defaultPrices = data
      this.data = this._convertPrices(data)
    }

    super.update(this.data, keys)
  }

  updateState (id, forced = false) {
    const state = {}
    if (id in this.state.keys) {
      _map(Object.keys(this.state.keys), i => parseInt(i, 10) === id ? state[i] = true : state[i] = false)
    } else {
      _map(Object.keys(this.state.keys), i => state[i] = true)
    }
    super.updateState(state, forced)
    this.onChange()
  }

  _convertPrices (data) {
    let convertedPrices = {}
    _forEach(data, (val, key) => {
      convertedPrices[key] = (val === false || val === true) ? val : parseFloat(val * this.state.rate, 10)
    })

    return convertedPrices
  }

  _keysSortFunction (key) {
    return parseInt(key)
  }

  onChange (sendCallback = true, forceUncheck = false) {
    super.onChange(sendCallback, forceUncheck)
    metrics.reach_goal("FILTERS_BAGGAGE_CHANGED")
  }

  getState () {
    let state = super.getState()
    return { baggage: _map(Object.keys(state), (i) => parseInt(i, 10)) }
  }
}
