import _isEqual from 'lodash/isEqual'
import _extend from 'lodash/extend'
import _defaults from 'lodash/defaults'
import DOMComponent from 'shared/components/base'

export default class BaseFilter extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'base_filter',
      state: {title: 'base', subtitle: false},
      defaultFilterState: {},
      filterState: {},
      collapsed: false,
      show: true
    }
  }

  constructor (node, options = {}) {
    super(node, options)
    this.needDafaultSatate = false
    this.isDefaultState = true
    this.state.name = this.options.name
    this.refresh()
  }

  getState () {
    return this.options.filterState
  }

  getShortState () {
    throw new Error(`Implement this method in ${this.options.name}`)
  }

  update (data) {
    this.state = _defaults(data, this.state)
    this.refresh()
  }

  updateState (data, forced = false) {
    this.forced = forced
    this.options.filterState = _defaults(data, this.options.filterState)
    this.onChange(false)
    this.refresh()
  }

  resetState () {
    this.forced = false
    if (!this.options.options.dynamic) {
      this.options.filterState = _extend({}, this.options.defaultFilterState)
    }
    this.onChange.apply(this, arguments) // FIXME pass arguments for CheckboxFilter :(
  }

  onChange (sendCallback = true) {
    this.resetNode || (this.resetNode = this.view.querySelector('[role="reset-filter"]'))
    this.isDefaultState = this.isThisDefaultState()
    if (this.resetNode) this.resetNode.classList.toggle('reset-filter--hidden', this.isDefaultState)
    if (sendCallback && this.options['onChange']) { this.options['onChange'].call(this, this.options.name) }
  }

  isThisDefaultState () {
    return _isEqual(this.options.defaultFilterState, this.options.filterState)
  }

  parseShortState (state) {
    return state
  }

  _initDOMEvents (view) {
    view.on('click', '[role="filter-toggler"]', (event, target) => {
      this.state.collapsed = !this.state.collapsed
      this.refresh()
    })

    view.on('click', '[role="reset-filter"]', (event, target) => {
      this.resetState()
    })
  }
}
