_some = require('lodash/some')
_filter = require('lodash/filter')
_debounce = require('lodash/debounce')
_map = require('lodash/map')
_reduce = require('lodash/reduce')
_sortBy = require('lodash/sortBy')
_merge = require('lodash/merge')
dispatcher = require('shared/lib/dispatcher')
metrics = require('shared/lib/metrics.coffee')
debug_client = require('./debug_client.coffee')
storage = require('shared/lib/local_storage.coffee')
cookies = require('shared/lib/cookies.coffee')
marker = require('shared/lib/marker.coffee')
Monkberry = require('monkberry')
Template = require('./debug.monk')

DEBUG_PREFIX = ''
DUMP_METRICS_TIMEOUT = 15000 #ms
SECONDS_IN_MONTH = 60 * 60 * 24 * 30

console.log '%cType %cdebug%c for debug window.', 'color:grey', 'color:#00CE00', 'color:grey'

module.exports = (root) ->
  state =
    visible: false
    tab: 'succeeded'
    search_id: null
    server_names: null
    market: ''
    request_id: null
    debug_infos: []
    marker: marker.get()
    gates: []
    results_debug: if storage.getItem('results_debug') || cookies.get('results_debug')  then 'checked' else ''
    proposals_debug: if storage.getItem('proposals_debug') || cookies.get('proposals_debug')  then 'checked' else ''
    cache_checked: if storage.getItem('debug') then 'checked' else ''
    failed_checked: false
    dispatcher_log_checked: if storage.getItem('disable_dispatcher_log') then 'checked' else ''

  gates_info = []
  log_info = {}

  view = Monkberry.render Template, root
  view.update(state)

  toggle = ->
    cookies.set 'yasen_debug', true, 60 * 60 * 24 * 30 # 30 days
    state.visible = !state.visible
    view.update state

  key_pressed = []
  document.addEventListener 'keydown', (event) ->
    key_pressed.push(event.keyCode)
    key_pressed.shift() if key_pressed.length > 5
    toggle() if key_pressed.join('') is '6869668571' # debug

  view.on('click', '.js-debugger-close-button', toggle)

  dispatcher.on 'marker_restored', (event, current_marker) ->
    state.marker = current_marker
    view.update state

  dispatcher.on 'server_name_updated', (event, params) ->
    state.request_id = params.request_id
    state.server_name = params.server_name
    view.update state

  dispatcher.on 'gates_updated', (request_id, params) ->
    _merge(gates_info, params.gates)

  dispatcher.on 'gates_meta_updated', (event, params) ->
    result = params.meta?.map (meta) ->
      'error': if meta.error then meta.error.tos else ''
      'count': meta.count
      'bad_count': if meta.bad_count and Object.keys(meta.bad_count).length then JSON.stringify(meta.bad_count) else ''
      'good_count': meta.good_count
      'duration': (Math.round(meta.duration * 100) / 100)
      'id': meta.id
      'name': gates_info[meta.id]?.label || meta.id
    result_sort_by_name = _sortBy(result, (obj) -> obj['name'].toLowerCase())
    view.update gates: state.gates = result_sort_by_name

  dispatcher.on 'benchmark_info', (event, {func, time}) ->
    log_info["#{DEBUG_PREFIX}function_#{func}"] or= []
    log_info["#{DEBUG_PREFIX}function_#{func}"].push(time)

  setInterval ->
    for k, v of log_info
      metrics.send_metric(
        goal: k
        count: v.length
        data: _reduce(v, (a, m, i, p) ->
          a + m / p.length
        , 0)
      )
    log_info = {}
  , DUMP_METRICS_TIMEOUT


  if window.performance?.timing
    timing = window.performance.timing
    groups =
      'Connection': [timing.connectEnd - timing.connectStart]
      'Response': [timing.responseEnd - timing.responseStart]
      'Domain Lookup': [timing.domainLookupEnd - timing.domainLookupStart]
      'Load Event': [timing.loadEventEnd - timing.loadEventStart]
      'Unload Event': [timing.unloadEventEnd - timing.unloadEventStart]
      'DOMContentLoaded Event': [timing.domContentLoadedEventEnd - timing.domContentLoadedEventStart]

    log_info["#{DEBUG_PREFIX}#{k}"] = v for k, v of groups

  #### TABS ####
  view.on 'click', '.js-tabs', (event) ->
    view.update tab: state.tab = event.target.dataset.tab


  #### SETTINGS ####
  view.on 'click', '.js-use-local-storage', (event) ->
    if event.target.checked
      storage.setItem('debug', true)
    else
      storage.removeItem('debug')

  view.on 'click', '.js-debugger-proposals-debug', (event) ->
    name = 'proposals_debug'
    if event.target.checked
      cookies.set(name, true, SECONDS_IN_MONTH)
      storage.setItem(name, true)
      state[name] = true
    else
      cookies.remove(name)
      storage.removeItem(name)
      state[name] = false
    view.update proposals_debug: state[name]

  view.on 'click', '.js-debugger-results-debug', (event) ->
    if event.target.checked
      storage.setItem('results_debug', true)
      state['results_debug'] = true
      cookies.set 'results_debug', true, 60 * 60 * 24 * 30 # 30 days
    else
      state['results_debug'] = false
      storage.removeItem('results_debug')
      cookies.remove('results_debug')
    view.update results_debug: state['results_debug']

  #####  FAILED GATES #####

  dispatcher.on 'search_finished', (event, {request_id, data}) ->
    state.market = data[0] && data[0].market
    view.update market: state.market

  dispatcher.on 'start_search', (event, params) ->
    state.debug_infos = []
    view.update debug_infos: present_debug_infos(state.debug_infos)

  dispatcher.on 'search_id_updated', (event, {request_id, search_id}) ->
    state.search_id = search_id.search_id
    view.update search_id: state.search_id

  present_debug_infos = (debug_infos) ->
    result = []
    for debug_info in _sortBy(debug_infos, (di) -> di.blockers[0].unit_name)
      result.push blockers: _map(debug_info.blockers, (blocker) -> {unit_name: blocker.unit_name, message: blocker.message})
    return result

  view.on 'click', '.js-debugger-extract-debug', ->
    state.failed_checked = !state.failed_checked
    view.update failed_checked: state.failed_checked
    if state.search_id
      debug_client(state.search_id, (debug_infos) ->
        state.debug_infos = state.debug_infos.concat(debug_infos)
        view.update debug_infos: present_debug_infos(state.debug_infos)
      )
    else
      alert "Don't have search id"


  view.on "input", ".js-debugger_search", (event, event_target) ->
    _debounce((value) ->
      new_debug_infos = _filter(state.debug_infos, (di) ->
        _some(di.blockers, (blocker) -> blocker.unit_name.indexOf(value) != -1)
      )
      view.update debug_infos: present_debug_infos(new_debug_infos)
    , 50)(event_target.value)
