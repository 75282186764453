function offset (el, dimension, stopIfRelative) {
  let result = el[dimension]

  while (el.offsetParent) {
    if (stopIfRelative && el.style.position === 'relative') break
    el = el.offsetParent
    result += el[dimension]
  }

  return result
}

export function offsetTop (el, stopIfRelative) {
  return offset(el, 'offsetTop', stopIfRelative)
}

export function offsetLeft (el, stopIfRelative) {
  return offset(el, 'offsetLeft', stopIfRelative)
}
