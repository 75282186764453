module.exports = [
  {
    'autocomplete_url': 'www.jetradar.at/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'AT',
    'country': 'Austria',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-28',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '4000286987',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.at',
    'hreflang': 'de-AT',
    'is_ota': false,
    'locale': 'de',
    'mobile_host': 'm.jetradar.at',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'at',
    'market': 'at'
  },
  {
    'autocomplete_url': 'www.jetradar.com.au/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'AU',
    'country': 'Australia',
    'currency': 'AUD',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-8',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '1186326582',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.com.au',
    'hreflang': 'en-AU',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.com.au',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en_AU',
    'market': 'au'
  },
  {
    'autocomplete_url': 'www.jetradar.be/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'BE',
    'country': 'Belgium',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-29',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '5477020189',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.be',
    'hreflang': 'en-BE',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.be',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'be',
    'market': 'be'
  },
  {
    'autocomplete_url': 'www.jetradar.com.br/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'BR',
    'country': 'Brazil',
    'currency': 'BRL',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-20',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '2663059780',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.com.br',
    'hreflang': 'pt-BR',
    'is_ota': false,
    'locale': 'pt-BR',
    'mobile_host': 'm.jetradar.com.br',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en_BR',
    'market': 'br'
  },
  {
    'autocomplete_url': 'ca.jetradar.com/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'CA',
    'country': 'Canada',
    'currency': 'CAD',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-9',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '4139792989',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'ca.jetradar.com',
    'hreflang': 'en-CA',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.ca.jetradar.com',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en_CA',
    'market': 'ca'
  },
  {
    'autocomplete_url': 'www.jetradar.ch/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'CH',
    'country': 'Switzerland',
    'currency': 'CHF',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-30',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '6953753382',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.ch',
    'hreflang': 'de-CH',
    'is_ota': false,
    'locale': 'de',
    'mobile_host': 'm.jetradar.ch',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'ch',
    'market': 'ch'
  },
  {
    'autocomplete_url': 'www.jetradar.de/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'DE',
    'country': 'Germany',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-4',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '5616526184',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.de',
    'hreflang': 'de-DE',
    'is_ota': false,
    'locale': 'de',
    'mobile_host': 'm.jetradar.de',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'de',
    'market': 'de'
  },
  {
    'autocomplete_url': 'www.jetradar.es/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'ES',
    'country': 'Spain',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-16',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '7093259380',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.es',
    'hreflang': 'es-ES',
    'is_ota': false,
    'locale': 'es',
    'mobile_host': 'm.jetradar.es',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'es',
    'market': 'es'
  },
  {
    'autocomplete_url': 'www.jetradar.fr/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'FR',
    'country': 'France',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-14',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '8569992582',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.fr',
    'hreflang': 'fr-FR',
    'is_ota': false,
    'locale': 'fr',
    'mobile_host': 'm.jetradar.fr',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'fr',
    'market': 'fr'
  },
  {
    'autocomplete_url': 'www.jetradar.co.uk/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'GB',
    'country': 'United Kingdom',
    'currency': 'GBP',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-6',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '3860591387',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.co.uk',
    'hreflang': 'en-GB',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.co.uk',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en_GB',
    'market': 'gb'
  },
  {
    'autocomplete_url': 'www.jetradar.gr/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'GR',
    'country': 'Greece',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-35',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '5337419387',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.gr',
    'hreflang': 'en-GR',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.gr',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'gr',
    'market': 'gr'
  },
  {
    'autocomplete_url': 'www.jetradar.hk/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'HK',
    'country': 'Hong Kong',
    'currency': 'HKD',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-36',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '6814152588',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.hk',
    'hreflang': 'en-HK',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.hk',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'hk',
    'market': 'hk'
  },
  {
    'autocomplete_url': 'www.jetradar.co.id/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'ID',
    'country': 'Indonesia',
    'currency': 'IDR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-31',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '8430486584',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.co.id',
    'hreflang': 'id-ID',
    'is_ota': false,
    'locale': 'id',
    'mobile_host': 'm.jetradar.co.id',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'id',
    'market': 'id'
  },
  {
    'autocomplete_url': 'ie.jetradar.com/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'IE',
    'country': 'Ireland',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-11',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '1046725788',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'ie.jetradar.com',
    'hreflang': 'en-IE',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.ie.jetradar.com',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en_IE',
    'market': 'ie'
  },
  {
    'autocomplete_url': 'www.jetradar.co.il/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'IL',
    'country': 'Israel',
    'currency': 'ILS',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-32',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '9907219786',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.co.il',
    'hreflang': 'en-IL',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.co.il',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'il',
    'market': 'il'
  },
  {
    'autocomplete_url': 'www.jetradar.in/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'IN',
    'country': 'India',
    'currency': 'INR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-13',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '2523458985',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.in',
    'hreflang': 'en-IN',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.in',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en_IN',
    'market': 'in'
  },
  {
    'autocomplete_url': 'www.jetradar.it/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'IT',
    'country': 'Italy',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-15',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '4000192186',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.it',
    'hreflang': 'it-IT',
    'is_ota': false,
    'locale': 'it',
    'mobile_host': 'm.jetradar.it',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'it',
    'market': 'it'
  },
  {
    'autocomplete_url': 'www.jetradar.ko/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'KR',
    'country': 'South Korea',
    'currency': 'KRW',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-37',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '8290885789',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.kr',
    'hreflang': 'ko-KR',
    'is_ota': false,
    'locale': 'ko',
    'mobile_host': 'm.jetradar.kr',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'ko',
    'market': 'ko'
  },
  {
    'autocomplete_url': 'www.jetradar.mx/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'MX',
    'country': 'Mexico',
    'currency': 'MXN',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-38',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '9767618984',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.mx',
    'hreflang': 'es-MX',
    'is_ota': false,
    'locale': 'es',
    'mobile_host': 'm.jetradar.mx',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'mx',
    'market': 'mx'
  },
  {
    'autocomplete_url': 'www.jetradar.my/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'MY',
    'country': 'Malaysia',
    'currency': 'MYR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-39',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '2244352181',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.my',
    'hreflang': 'en-MY',
    'is_ota': true,
    'locale': 'en',
    'mobile_host': 'm.jetradar.my',
    'ota_host': 'book.jetradar.my',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'ms',
    'market': 'my'
  },
  {
    'autocomplete_url': 'www.jetradar.co.nl/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'NL',
    'country': 'Netherlands',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-33',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '2383952988',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.co.nl',
    'hreflang': 'en-NL',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.co.nl',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'nl',
    'market': 'nl'
  },
  {
    'autocomplete_url': 'www.jetradar.co.nz/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'NZ',
    'country': 'New Zealand',
    'currency': 'NZD',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-10',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '5476925389',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.co.nz',
    'hreflang': 'en-NZ',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.co.nz',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en_NZ',
    'market': 'nz'
  },
  {
    'autocomplete_url': 'www.jetradar.ph/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'PH',
    'country': 'Philippines',
    'currency': 'PHP',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-40',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '3721085380',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.ph',
    'hreflang': 'tl-PH',
    'is_ota': false,
    'locale': 'tl',
    'mobile_host': 'm.jetradar.ph',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'tl',
    'market': 'ph'
  },
  {
    'autocomplete_url': 'www.jetradar.pl/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'PL',
    'country': 'Poland',
    'currency': 'PLN',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-18',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '6953658582',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.pl',
    'hreflang': 'pl-PL',
    'is_ota': false,
    'locale': 'pl',
    'mobile_host': 'm.jetradar.pl',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'pl',
    'market': 'pl'
  },
  {
    'autocomplete_url': 'www.jetradar.pt/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'PT',
    'country': 'Portugal',
    'currency': 'EUR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-19',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '8430391788',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.pt',
    'hreflang': 'pt-PT',
    'is_ota': false,
    'locale': 'pt',
    'mobile_host': 'm.jetradar.pt',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'pt',
    'market': 'pt'
  },
  {
    'autocomplete_url': 'places.aviasales.ru/match?',
    'banner_type': '',
    'buses_link': false,
    'chat_enabled': false,
    'code': 'RU',
    'country': 'Russia',
    'currency': 'RUB',
    'google_analytics': 'UA-1481416-4',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.aviasales.ru',
    'hreflang': 'ru-RU',
    'is_ota': false,
    'locale': 'ru',
    'mobile_host': 'm.aviasales.ru',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'https:',
    'special_offers': false,
    'subscription_host': 'woody.aviasales.ru',
    'yasen_locale_param': 'ru',
    'market': 'ru'
  },
  {
    'autocomplete_url': 'www.jetradar.sg/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'SG',
    'country': 'Singapore',
    'currency': 'SGD',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-12',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '9907124989',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.sg',
    'hreflang': 'en-SG',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.sg',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en_SG',
    'market': 'sg'
  },
  {
    'autocomplete_url': 'www.jetradar.co.th/autocomplete/places?',
    'banner_type': '',
    'buses_link': 'https://tickets.co.th/?locale=th&partner=Hr8bc5iN7VCawd8cgvyGGasj',
    'chat_app_id': 'cenf3jui',
    'chat_enabled': true,
    'code': 'TH',
    'country': 'Thailand',
    'currency': 'THB',
    'facebook_pixel': true,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-17',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '2383858188',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.co.th',
    'hreflang': 'th-TH',
    'is_ota': true,
    'locale': 'th',
    'mobile_host': 'm.jetradar.co.th',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'https:',
    'special_offers': true,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'th',
    'market': 'th'
  },
  {
    'autocomplete_url': 'www.jetradar.tw/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'TW',
    'country': 'Taiwan',
    'currency': 'TWD',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-41',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '5197818582',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.tw',
    'hreflang': 'zh-TW',
    'is_ota': false,
    'locale': 'zh-TW',
    'mobile_host': 'm.jetradar.tw',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'tw',
    'market': 'tw'
  },
  {
    'autocomplete_url': 'www.jetradar.com/autocomplete/places?',
    'banner_type': '',
    'buses_link': 'https://tickets.co.th/?locale=en&partner=Hr8bc5iN7VCawd8cgvyGGasj',
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'US',
    'country': 'United States',
    'currency': 'USD',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-1',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '8710886982',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.com',
    'hreflang': 'en-US',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.com',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'https:',
    'special_offers': true,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'en',
    'market': 'us'
  },
  {
    'autocomplete_url': 'www.jetradar.vn/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'VN',
    'country': 'Vietnam',
    'currency': 'VND',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-42',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '6674551783',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.vn',
    'hreflang': 'vi-VN',
    'is_ota': false,
    'locale': 'vi',
    'mobile_host': 'm.jetradar.vn',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'vi',
    'market': 'vn'
  },
  {
    'autocomplete_url': 'www.jetradar.co.za/autocomplete/places?',
    'banner_type': '',
    'buses_link': false,
    'chat_app_id': 'cenf3jui',
    'chat_enabled': false,
    'code': 'ZA',
    'country': 'South Africa',
    'currency': 'ZAR',
    'facebook_pixel': false,
    'facebook_pixel_id': '888565404541558',
    'google_analytics': 'UA-27732219-34',
    'google_analytics_common': 'UA-27732219-23',
    'google_analytics_global': 'UA-27732219-43',
    'google_csa_channel': '3860686188',
    'google_tag_manager': 'GTM-WTJT28',
    'host': 'www.jetradar.co.za',
    'hreflang': 'en-ZA',
    'is_ota': false,
    'locale': 'en',
    'mobile_host': 'm.jetradar.co.za',
    'ota_host': 'book.jetradar.co.th',
    'protocol': 'http:',
    'special_offers': false,
    'subscription_host': 'woody.jetradar.com',
    'yasen_locale_param': 'za',
    'market': 'za'
  }
]
