moment = require('moment')

module.exports = -> 
  return {
    segments: [
      {
        origin: ''
        origin_name: ''
        destination: ''
        destination_name: ''
        date: null
      }
    ]
    passengers:
      adults: 1
      children: 0
      infants: 0
    trip_class: "Y"
  }
