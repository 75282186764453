_intersection = require('lodash/intersection')
ResizeSensor = require('./resize_sensor.coffee')

module.exports =
  init: (wrapper) ->
    @modifiers =
      "TPWL_widget--xl": [1023, 9000]
      "TPWL_widget--l": [768, 1023]
      "TPWL_widget--m": [660, 768]
      "TPWL_widget--s": [440, 660]
      "TPWL_widget--xs": [0, 440]
      "TPWL_sticky_informer--over660": [660, 9000]
      "TPWL_sticky_informer--less660": [0, 660]
      "TPWL_sticky_informer--over500": [500, 9000]
      "TPWL_sticky_informer--less500": [0, 500]
    @prevModifiers = null
    @isMobile = false
    @wrapper = wrapper
    @parent = wrapper.parentNode
    @initResizeSensor()
    @touchResize()

  initResizeSensor:  ->
    return @checkVisibility() if @parent.offsetWidth <= 0
    new ResizeSensor @parent, => @touchResize()

  checkVisibility: ->
    width = @parent.offsetWidth
    if width > 0
      @initResizeSensor()
      @touchResize()
    else
      setTimeout((=> @checkVisibility()), 500)

  touchResize: ->
    @width = @parent.offsetWidth
    @setModifier(@width)

  setModifier: (width) ->
    if @modifiers["TPWL_widget--xs"][0] < width and width <= @modifiers["TPWL_widget--xs"][1]
      document.body.classList.add("TPWL_mobile")
      @isMobile = true
    else
      document.body.classList.remove("TPWL_mobile")
      @isMobile = false

    classList = @parent.classList
    modifiers = []
    for name, range of @modifiers
      modifiers.push(name) if range[0] < width and width <= range[1]

    unless _intersection(@prevModifiers, modifiers).length == modifiers.length
      @prevModifiers = []
      classList.remove(name) for name, range of @modifiers
      for modifier in modifiers
        classList.add(modifier)
        @prevModifiers.push modifier

    @checkVisibility() if @parent.offsetWidth <= 0
