xhr      = require('xhr')
config = require('shared/lib/tp_config.js')

DEBUG_URL = config.url + '/adaptors/debug_info/'

module.exports = (search_uuid, cb) ->
  on_receive = (err, res) ->
    if res?.abort or res?.statusCode == 0 or res?.statusCode != 200
      alert('Request for debug info - failed')
      return

    cb(res.body)

  url = "#{DEBUG_URL}?uuid=#{search_uuid}&#{Math.random()}"
  _xhr = xhr({
      url: url
      json: {}
      method: 'get'
    }, on_receive)
