import _forEach from 'lodash/forEach'
import _groupBy from 'lodash/groupBy'
import _each from 'lodash/each'
import _defaultsDeep from 'lodash/defaultsDeep'
import moment from 'moment'
import Template from './calendar.monk'
import filters from '../filters.js'
import CalendarWithInterface from '../with_interface.js'

export default class Calendar extends CalendarWithInterface {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'calendar_round_trip',
      render: {
        template: Template,
        options: { filters }
      },
      rOffset: 0
    }, CalendarWithInterface.defaultOptions())
  }

  static areParamsValid (params) {
    return super.areParamsValid(params) && params.segments.length > 1
  }

  setDates () {
    // Generate dates for table based on requestParams
    this.state.depart_dates = this.generateDates(this.requestParams.depart_start)
    this.state.return_dates = this.generateDates(this.requestParams.return_start)
    this.state.today_number = moment(new Date()).startOf('date').format('x')
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)

    this.initHoverOnCell(view)
  }

  fixParams (params) {
    // If it possible then current search date place in center of table
    // otherwise shifted to the upper left corner
    let departStart = moment(params.depart_start).startOf('date').add(this.options.dOffset - 3, 'days')
    let returnStart = moment(params.return_start).startOf('date').add(this.options.rOffset - 3, 'days')
    let today = moment(new Date()).startOf('date')
    let departDiff = (departStart - today) / 1000 / 60 / 60 / 24
    let returnDiff = (returnStart - today) / 1000 / 60 / 60 / 24

    params['need_request'] = departDiff >= -3 && returnDiff >= -2 && returnDiff >= departDiff
    params['depart_start'] = moment.ISODate(departStart)
    params['return_start'] = moment.ISODate(returnStart)

    return params
  }

  toggleHoverClass (event, method) {
    let departDate, returnDate;
    [departDate, returnDate] = event.target.dataset.cell.split('/')

    if (!this.departDateNodes[departDate]) {
      this.departDateNodes[departDate] = this.view.querySelector(`[role='cell_dates_depart--${departDate}']`)
    }

    if (!this.returnDateNodes[returnDate]) {
      this.returnDateNodes[returnDate] = this.view.querySelector(`[role='cell_dates_return--${returnDate}']`)
    }

    this.departDateNodes[departDate].classList[method]('cell_dates--hover')
    this.returnDateNodes[returnDate].classList[method]('cell_dates--hover')
  }

  initHoverOnCell (view) {
    this.departDateNodes = {}
    this.returnDateNodes = {}

    _each(view.createDocument().querySelectorAll('[role="cell"]'), (element) => {
      element.addEventListener('mouseenter', (event) => { this.toggleHoverClass(event, 'add') })
      element.addEventListener('mouseleave', (event) => { this.toggleHoverClass(event, 'remove') })
    })
  }

  castData (data) {
    let result = _groupBy(data, 'depart_date')
    _forEach(result, (prices, depart_date) => {
      result[depart_date] = _groupBy(prices, 'return_date')
    })
    return result
  }

  onSearchStart (params) {
    this.state.current_return_date = this.searchParams.segments[1].date

    if (this.waitingSearch) {
      CalendarWithInterface.reach_goal(`CALENDAR_${this.options.name.toUpperCase()}_FORCE_SHOW`)
      this.fetchDataAndRender()
      document.querySelector('[role="calendar_line--wrapper"]').classList.add('calendar_line--hide')
    }
  }

  setRequestParams () {
    this.requestParams = this.fixParams({
      origin_iata: this.searchParams.segments[0].origin_city_iata || this.searchParams.segments[0].origin,
      destination_iata: this.searchParams.segments[0].destination_city_iata || this.searchParams.segments[0].destination,
      depart_start: this.state.current_depart_date,
      return_start: this.state.current_return_date,
      depart_range: 6,
      return_range: 6
    })
  }
}
