import _defaultsDeep from 'lodash/defaultsDeep'
import _keys from 'lodash/keys'
import _extend from 'lodash/extend'
import _clone from 'lodash/clone'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import BaseFilter from 'shared/components/filters/base/base.js'
import Template from 'shared/components/filters/base/base.monk'
import dictionary from 'shared/lib/dictionary.js'

class Airport extends CheckboxFilter {
  static defaultOptions () {
    return _defaultsDeep({
      state: {
        title: false,
        collapsed: false,
        labels: (iata) => {
          let airport = dictionary.airportByIata(iata)
          return (airport.name + ' ' + iata)
        }
      }
    }, super.defaultOptions())
  }
  static resetState () {
    let instance = this.instance()
    return instance ? instance.resetState() : {}
  }

  constructor (node, options = {}) {
    super(node, options)
    this.needDafaultSatate = true
  }

  update (iatas) {
    this.state.subtitle = `${dictionary.airportByIata(iatas[0]).city}`
    const keys = iatas
    const state = this._prepareState(iatas)
    super.update(state, keys)
  }
  getState () {
    let state = super.getState()
    let result = _keys(state)
    return { [this.options.name]: result }
  }
  updateState (data, forced = false) {
    const state = _extend(_clone(this.data), { [data[0]]: true })
    super.updateState(state, forced)
    this.onChange()
  }
  _prepareState (data) {
    return data.reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {})
  }
}

const filtersName = ['departure_airports', 'arrival_airports']

export default class Airports extends BaseFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'airports',
      render: { template: Template },
      state: {
        title: __('filters.airports.title'),
        collapsed: true,
        show: true
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    this.filters = options.options.filters
    this.defaultOptions = options.options.defaultOptions
    this.wrapper = this.view.querySelector('[role="filter-body"]')
  }

  update (data) {
    this.state.show = filtersName.some(name => data[name].length > 1)
    this.view.update(this.state)
    this._clearWrapper(this.wrapper)
    this._createNewFilters(this.wrapper, data, filtersName)
    this.filters.filtersKeys = Object.keys(this.filters.filters)
  }

  _createNewFilters (wrapper, data, names) {
    names.forEach(name => {
      if (data[name].length > 1) {
        this.filters.filters[name] = new Airport(wrapper, {
          name,
          options: this.defaultOptions,
          onChange: this.filters.onChangeCallback()
        })
      }
    })
  }

  _clearWrapper (wrapper) {
    wrapper.innerHTML = ''
  }
}
