import _clone from 'lodash/clone'
import _extend from 'lodash/extend'
import _compact from 'lodash/compact'
import _forEach from 'lodash/forEach'
import moment from 'moment'
import DOMComponent from 'shared/components/base'
import Template from './highlighted_ticket.monk'
import Ticket from 'flights/components/ticket/ticket.coffee'
import Params from 'flights/lib/params.js'
import converter from 'shared/lib/converter'
import dictionary from 'shared/lib/dictionary.js'
import UserSettings from 'shared/components/user_settings/user_settings.js'

var statusMessages = {
  in_progress: __('highlighted_ticket.please_wait'),
  price_better: __('highlighted_ticket.price_better'),
  price_worse: __('highlighted_ticket.price_worse'),
  sold_out: __('highlighted_ticket.sold_out'),
  all_right: __('highlighted_ticket.all_right')
}

export default class HighlightedTicket extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'highlighted_ticket',
      render: { template: Template },
      state: {
        status_message: statusMessages.in_progress,
        status: ''
      },
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.highlighted_ticket': {
            'border-color': window.TPWLCONFIG.color_scheme.bg,
            'background-color': window.TPWLCONFIG.color_scheme.bg
          },
          '.highlighted_ticket__header': {
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.highlighted_ticket.highlighted_ticket__header.currency_font': {
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.ticket.ticket--preloaded .ticket-action-button-deeplink:hover': {
            'background-color': window.TPWLCONFIG.color_scheme.btn_bg
          }
        }
      }
    }
  }

  constructor (node, options = {}) {
    super(node, options)
    this.currency = UserSettings.getCurrency()

    this.refresh()

    let params = Params.parsed.highlighted_ticket
    let parsed = this.parseSignature(params)
    this.paramsError = !params || !parsed
    if (this.paramsError) return false
    this.defaultParams = this.getDefault(parsed)
    this.ticketsContainerNode = this.view.querySelector('[role="highlighted_ticket_conteiner"]')
  }

  receiveTickets(tickets) {
    if (this.paramsError) return false
    const highlighted = tickets.filter(ticketPair => {
      return ticketPair[0].sign === this.defaultParams.ticket[0].sign
    })
    _forEach(highlighted, (ticketPair) => {
      this.findTicket = ticketPair

      if (this.ticket) {
        this.ticket.update({ 'index': 'highlighted', 'ticket': ticketPair })
      }
      else {
        this.ticket = new Ticket(this.ticketsContainerNode,
          {
            state: {
              index: this.defaultParams.index,
              ticket: this.findTicket
            }
          })
      }
      if (this.ticket) this.show()
      this.priceDiff = parseInt(this.defaultParams.ticket[1][0].unified_price - ticketPair[1][0].unified_price, 10)
      this.refresh()
    })
  }

  _initEvents (dispatcher) {
    this.findTicket = null
    dispatcher.on('fuzzy_tickets_updated', (event, {tickets}) => {
      this.receiveTickets(tickets)
    })
    dispatcher.on('tickets_updated', (event, {tickets}) => {
      this.receiveTickets(tickets)
    })

    dispatcher.on('search_finished', (event, params) => {
      if (this.paramsError) return false
      if (this.findTicket == null) {
        this.state.status = 'highlighted_ticket--price_worse'
        this.state.status_message = statusMessages.sold_out
        this.refresh()
      }
    })

    dispatcher.on('places_restored', (name, params) => {
      if (this.paramsError) return false
    })

    dispatcher.on('systemMessageError', () => { this.hide() })

    dispatcher.on('currency_updated', (event, currency) => {
      this.currency = currency
      if (this.findTicket) this.refresh()
    })
  }

  refresh () {
    if (this.findTicket) {
      let message = ''
      let status = ''

      if (this.priceDiff == 0) {
        message = statusMessages.all_right
      } else {
        if (this.priceDiff > 0) {
          status = 'highlighted_ticket--price_better'
          message = statusMessages.price_better
        } else {
          status = 'highlighted_ticket--price_worse'
          message = statusMessages.price_worse
        }

        let price = Math.round(Math.abs(this.priceDiff) / (dictionary.currencies[this.currency] || 1))
        message = message.replace('%{price}', price).replace('%{currency}', this.currency)
      }

      this.state.status_message = message
      this.state.status = status
    }

    super.refresh()
  }

  parseSignature (signature) {
    if (!signature) { return false };

    let segments = []
    let ref = signature.split('_')
    let ticket = ref[0]
    let sign = ref[1]
    let price = ref[2]
    let label = ref[3]
    let parts = ticket.substr(2).match(/[0-9]+[\(\),a-z]+/gi)

    if (!parts) { return false };

    for (let i = 0; i < parts.length; i++) {
      let part = parts[i]
      let airports = part.substr(27, 16).replace(/\)$/, '').split(')(')
      if (airports == '') { return false };

      segments.push({
        departure_timestamp: part.substr(0, 10) | 0,
        arrival_timestamp: part.substr(10, 10) | 0,
        duration: part.substr(20, 6) | 0,
        airports: _compact([airports[0] && airports[0].split(','), airports[1] && airports[1].split(',')])
      })
    }

    return {
      airline: ticket.substr(0, 2),
      price: price || 0,
      segments: segments,
      sign: sign,
      label: label
    }
  }

  getDefault (t) {
    var airport, arrivalMoment, departureMoment, durations, flight, flights, i, idx, j, last, len, len1, next_airport, ref, ref1, s, segments
    segments = []
    durations = []
    ref = t.segments
    for (i = 0, len = ref.length; i < len; i++) {
      s = ref[i]
      flights = []
      departureMoment = moment(s.departure_timestamp + '000', 'x')
      arrivalMoment = moment(s.arrival_timestamp + '000', 'x')
      last = s.airports.length - 1
      ref1 = s.airports

      for (idx = j = 0, len1 = ref1.length; j < len1; idx = ++j) {
        airport = ref1[idx]

        flight = {
          departure: airport[0],
          arrival: airport[1]
        }

        if (idx === 0) {
          _extend(flight, {
            departure_date: departureMoment.format('YYYY-MM-DD'),
            departure_time: departureMoment.format('HH:ss'),
            departure_timestamp: s.departure_timestamp,
            local_departure_timestamp: s.departure_timestamp
          })
        }
        if (idx === last) {
          _extend(flight, {
            arrival_date: arrivalMoment.format('YYYY-MM-DD'),
            arrival_time: arrivalMoment.format('HH:ss'),
            arrival_timestamp: s.arrival_timestamp,
            local_arrival_timestamp: s.arrival_timestamp
          })
        }
        flights.push(_clone(flight))
      }
      segments.push({
        flight: _clone(flights)
      })
      durations.push(s.duration)
    }

    return {
      index: 'highlighted',
      ticket: [{
        carrier_code: t.airline,
        is_direct: t.segments.length === 1 && t.segments[0].airports.length === 2,
        segment: segments,
        segment_durations: durations,
        validating_carrier: t.airline,
        sign: t.sign,
        main_proposal: false,
        preloaded: true
      }, [{unified_price: t.price, price: 0, label: t.label}]]
    }
  }
}
