<div class="search_progressbar {{ animation_class }}">
  <div class="countdown-info flex">
    <div class="countdown-info__wrap">
      <div class="countdown-background"></div>
      <div class="countdown-title">
        <span class="countdown-title__text">{{ title }}</span> &nbsp;
        {{ __('search_progressbar.completed_after') }} &nbsp;
        <b>{{ seconds_to_go }}</b>
        {{ countdown_message }}
      </div>
    </div>
  </div>
  <div class="progress-bar-wrap">
    <div class="progress-bar" role="progress-bar"></div>
  </div>
</div>
