import metrics from 'shared/lib/metrics.coffee'
import Monkberry from 'monkberry'
import Template from 'shared/template.monk'
// import 'shared/lib/rollbar.js'
import 'shared/lib/data_set.js'
import 'shared/lib/class_list.js'
import 'shared/lib/url_fragment.js'
import 'shared/lib/smartbanner.js'
import 'requestanimationframe'
import 'console-polyfill'
import 'perfnow'
import 'monkberry-events'
import 'shared/lib/moment/customization'
import ResizeWatcher from 'shared/lib/resize_watcher.coffee'
import dispatcher from 'shared/lib/dispatcher'
import ModalHistory from 'shared/lib/modal_history'

if (document.location.pathname !== '/') {
  metrics.reach_goal('PRE_INIT_3')
} else {
  metrics.reach_goal('FRONTPAGE_LOAD')
}

export default class ComponentsInitializer {
  constructor (containerNode, options = {}) {
    this.DOMInitialized = false
    window.history.scrollRestoration = 'manual' // Hack for correct scrolling after this to first result for task TP-1836

    this._dispatcher = dispatcher
    window.TP_DISPATCHER = this._dispatcher // HACK FOR PLUGINS

    this.mainTemplateNode = document.querySelector('[role="tpwl-content"]')
    this._mobileInit()

    this.mainTemplate = Monkberry.render(Template, this.mainTemplateNode)
    ResizeWatcher.init(this.mainTemplate.createDocument())
    this._initRouter()

    window._location = window.location.href + ''
    this.componentsMap()
    this.sharedRequirements()
    this.specificRequirements()

    // Ставится инлайн-скриптом внутри onload элемента стилей.
    // https://github.com/KosyanMedia/whitelabel/pull/184/files#diff-42459146be7efe33a186e5c9bd3ea5c3365abb3c34ce40ecad2268dabea410ddR15
    if(window.TP_WL_CSS_LOADED){
      this.hideAppLoader();
    }else{
      this._dispatcher.on('style-ready', this.hideAppLoader.bind(this))
    }

    // fix while we don't have Google Analytics
    if (!window.ga) { window.ga = function () {} };
  }

  componentsMap () {
    return {
      'debug': require('shared/components/debugger/debug.coffee'),
      'header': require('shared/components/header/header.js').default,
      'search_progressbar': require('shared/components/search_progressbar/search_progressbar.coffee').default,
      'user_settings': require('shared/components/user_settings/user_settings.js').default,
      'banner': require('shared/components/banner/banner.js').default,
      'show_filters_button': require('shared/components/show_filters_button/show_filters_button.js').default
    }
  }

  _initRouter () {
    dispatcher.on('page_changed', (event, {old: oldPage, new: newPage}) => {
      document.body.classList.remove(`TPWL-widget--${oldPage}`)
      document.body.classList.add(`TPWL-widget--${newPage}`)
      if (!oldPage) this.mainTemplate.update({page: newPage})
      if (!this.DOMInitialized) {
        this.DOMInitialized = true
        this._initDOMEvents()
      }
    })
  }

  sharedRequirements () {
    require('shared/lib/iframe_messenger.coffee')
  }

  specificRequirements () {}

  _mobileInit () {
    let isTouch = require('shared/lib/device.js').default.isTouch()

    if (isTouch) this.mainTemplateNode.classList.add('TPWL--touch')

    let cssx = require('cssx')
    let sheet = cssx('tpwl-css-template')
    sheet.add({ 'body': { 'background-color': window.TPWLCONFIG.color_scheme.body_bg } })
  }

  _initComponents (wrapper, loadedComponents) {
    let componentsMap = this.componentsMap()
    let components = wrapper.querySelectorAll('[is]')
    for (var i = 0; i < components.length; i++) {
      let component = components[i]
      let componentName = component.getAttribute('is')
      let componentConstructor = componentsMap[componentName]
      if (typeof componentConstructor === 'function') {
        loadedComponents[componentName] || (loadedComponents[componentName] = [])
        let children = {}
        loadedComponents[componentName].push(children)

        let needInit = component.childNodes.length === 0
        new componentsMap[componentName](component)
        if (needInit && component.childNodes.length > 0) { this._initComponents(component, children) }
      } else {
        this._dispatcher.send(
          'log_warning',
          'There is no constructor for component ' + componentName,
          'main'
        )
      }
    }
    return loadedComponents
  }

  _initDOMEvents () {
    if (document.location.pathname !== '/') {
      metrics.reach_goal('PRE_INIT_BEFORE_COMPONENT_LOADING')
    }
    metrics.init()
    this._dispatcher.send('components_loaded', this._initComponents(document, {}), 'main')
    if (document.location.pathname !== '/') {
      metrics.reach_goal('PRE_INIT_COMPONENTS_LOADED')
    }
  }

  hideAppLoader () {
    let loader = document.querySelector('[role="application_loader"]')
    if (loader) {
      setTimeout(() => {
        loader.style.display = 'none'
        let content = document.querySelector('.TPWL-wl_content');
        if(content) content.classList.remove('TPWL-wl_content-hidden')
      }, 400)
    }
  }
}
