import Pagination from 'shared/components/footer_pagination/pagination.js'

export default class extends Pagination {
  constructor (containerNode, options = {}) {
    options = { state: { button_text: __('search_results.show_more_flights') } }
    super(containerNode, options)
  }

  _initEvents (dispatcher) {
    super._initEvents(dispatcher)

    dispatcher.on('tickets_updated', (event, {tickets}) => {
      this.itemsUpdated(tickets.length)
    })

    dispatcher.on('all-tickets-shown', (event) => {
      this.state.buttonHidden = false
      this.checkVisibility()
    })

    dispatcher.on('best-tickets-shown', (event) => {
      this.state.buttonHidden = true
      this.checkVisibility()
    })
  }
}
