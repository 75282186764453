{% import FilterTitle from '../base/title.monk' %}
{% import Subtitle from '../base/subtitle.monk' %}
{% import Helper from './helper.monk' %}
{% import Barchart from 'shared/components/barchart/barchart.monk' %}

<div class="filter" >
  {% if title %}
    <FilterTitle title="{{title}}" collapsed="{{collapsed}}"/>
  {% endif %}
  <div class="filter-control-container {{ collapsed ? 'filter-control-container--collapsed' : '' }}">
    <div class="control {{ collapsed ? 'hidden' : '' }}" role="filter-body">
      {% if subtitle %}
        <Subtitle subtitle="{{subtitle}}"/>
      {% endif %}
      <div class="slider-block">
        {% if barchart %}
          <div class="barchart-container"> 
            <Barchart bars="{{ percents_by_counts }}" left="{{ left }}" right="{{ right - 1 }}" />
          </div>
        {% else %}
          {% if withGroups %}
            <Helper left="{{ groups[left] }}" right="{{ groups[right] }}" upper="{{ upper }}" infinity="{{ infinity }}" range_modifier="top" getToText="{{ getToText }}" getFromText="{{ getFromText }}"  getAnyText="{{ getAnyText }}"/>
          {% else %}
            <Helper left="{{ left * multiplier }}" right="{{ right * multiplier }}" upper="{{ upper * multiplier }}" infinity="{{ infinity }}" range_modifier="top" getToText="{{ getToText }}" getFromText="{{ getFromText }}"  getAnyText="{{ getAnyText }}"/>
          {% endif %}
        {% endif %}
        <div class="range_slider custom-range" role="range_slider">
          <div class="js-filter-bar custom-range-load"></div>
        </div>
        {% if barchart %}
          {% if withGroups %}
            <Helper left="{{ groups[left] }}" right="{{ groups[right] }}" upper="{{ upper }}" infinity="{{ infinity }}" range_modifier="bottom" getToText="{{ getToText }}"  getFromText="{{ getFromText }}"  getAnyText="{{ getAnyText }}"/>
          {% else %}
            <Helper left="{{ left * multiplier }}" right="{{ right * multiplier }}" upper="{{ upper * multiplier }}" infinity="{{ infinity }}" range_modifier="bottom" getToText="{{ getToText }}" getFromText="{{ getFromText }}" getAnyText="{{ getAnyText }}"/>
          {% endif %}
        {% endif %}
      </div>
    </div>
  </div>
</div>
