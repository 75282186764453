import DOMComponent from 'shared/components/base';
import Template from './banner.monk';
import cookies from 'shared/lib/cookies.coffee';

const cookieName = 'cookie_policy_accepted';
const countryList = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'HR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];

export default class Banner extends DOMComponent {
  static defaultOptions() {
    return {
      name: 'banner',
      render: { template: Template },
    };
  }

  constructor(containerNode, options = {}) {
    super(containerNode, options);
    this.state.show = false;
    this.show();
  }

  _initDOMEvents(view) {
    view.on('click', '[role="close"]', (event) => {
      this.state.show = false;
      this.refresh();
    });
  }

  show() {
    const geoIp =
      window.GEOIP &&
      window.GEOIP.country_code &&
      window.GEOIP.country_code.toUpperCase();
    if (countryList.indexOf(geoIp) === -1) return;
    this.state.show = !cookies.get(cookieName);
    if (this.state.show) {
      cookies.set(cookieName, true, 'unlimited');
    }
    this.refresh();
  }
}
