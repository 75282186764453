import _forEach from 'lodash/forEach'
import _defaultsDeep from 'lodash/defaultsDeep'
/*
To insert component in your template you need add tag like this: `<div is="component"></div>`

COMPONENT EXAMPLE
Structure:
--components/
    component/
      component.js
      component.monk
    component1/
      component1.js
      component1.monk

component.js
```
import DOMComponent from 'shared/components/base';
import Template from './component.monk';

export default class Component extends DOMComponent {
  static defaultOptions() { return _defaultsDeep({
    name: 'component', // Unique name of component
    onlyInit: false, // Do not render template
    manual_init: false, // Set true if you need call Component.initializeComponent() before create instance of this
    render: {
      template: Template, // or Hash of Template objects if multiple == true
      delayed: false, // Set true if you need delay template rendering and run instance._render() manually
      replace: false, // Set true if you need replace component container node by dendered component
      multiple: false, // Set true if you have different Templates for different conditions
      options: { // You can pass custom options for monkberry template
        filters: {}
      }
    },
    cssx: { // You can create custom styles for component
      scope: ".component",
      styles: {"": { "background": "#FFFFFF" }}
    },
    state: { // You can set default state of component here
      key: 'value'
    }
  }, super.defaultOptions())}

  constructor(containerNode, options = {}) {
    super(...arguments);
  }
}
```
*/

import Monkberry from 'monkberry'
import dispatcher from 'shared/lib/dispatcher'
import cssx from 'cssx'
import 'monkberry-events'

export default class DOMComponent {
  static initializeComponent (dispatcher) {}
  static defaultOptions () { return {} }

  static instance (index = 0) {
    this.instances || (this.instances = {})
    if (this.options === undefined) return undefined
    this.instances[this.options.name] || (this.instances[this.options.name] = [])
    return this.instances[this.options.name][index]
  }

  static broadcast (method, ...args) {
    if (this.options === undefined) return undefined
    _forEach(this.instances[this.options.name], (instance) => {
      instance[method].apply(instance, args)
    })
  }

  constructor (containerNode, options = {}) {
    let defaultOptions = this.initGlobalsAndGetOptions(this.constructor, options)

    this._dispatcher = dispatcher
    this.containerNode = containerNode
    this.options = _defaultsDeep(options, defaultOptions)
    if (this.options.shared_state === true) {
      if (this.constructor.state === undefined) this.constructor.state = {}
      this.state = this.constructor.state
    } else {
      this.state = options.state || {}
    }

    delete options['state']

    if (this.options.render && !this.options.render.delayed && !options.onlyInit) this._render(this.options.render)
    if (this.options.cssx) this.initStyles(this.options.cssx)

    if (!options.onlyInit) {
      this._initDOMEvents(this.view)
      this._initEvents(dispatcher)
    }
  }

  initGlobalsAndGetOptions (constructor, options) {
    let defaultOptions = constructor.defaultOptions()

    if (!constructor.componentInitialized || !constructor.componentInitialized[defaultOptions.name]) {
      constructor.componentInitialized || (constructor.componentInitialized = {})
      constructor.componentInitialized[defaultOptions.name] = true

      constructor.options = defaultOptions
      if (!constructor.options.name) throw 'add `name` property to defaultOptions() for ' + this.name
      if (!constructor.options.manual_init) constructor.initializeComponent(dispatcher)
    }

    constructor.instance()
    if (!options.onlyInit) constructor.instances[this.constructor.options.name].push(this)

    return constructor.options
  }

  _initDOMEvents (view) {}
  _initEvents (dispatcher) {}

  initStyles (params) {
    let sheet = cssx(`tpwl-css-${this.options.name.toLowerCase()}`)
    if (sheet.rules().length > 0) return true
    sheet.scope(params.scope || '.TPWL-widget')
    sheet.add(params.styles)
  }

  _render (params) {
    let template = params.multiple ? params.template[this.getTemplateName()] : params.template

    if (params.replace) {
      this.view = Monkberry.render(template, document.body, params.options || {})
      this.view.insertBefore(this.containerNode)
      this.containerNode.parentNode.removeChild(this.containerNode)
    } else {
      this.view = Monkberry.render(template, this.containerNode, params.options || {})
    }
  }

  refresh () { if (this.view !== undefined) this.view.update(this.state) }
  show (node = this.containerNode) { node.classList.remove('hidden') }
  hide (node = this.containerNode) { node.classList.add('hidden') }

  getTemplateName () { throw 'add `getTemplateName` logic for multiple templates.' }
}
