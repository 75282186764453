import _defaultsDeep from 'lodash/defaultsDeep'
import filters from 'shared/lib/filters.coffee'
import dictionary from 'shared/lib/dictionary.js'
import utils from 'shared/lib/utils.coffee'
import RangeFilter from 'shared/components/filters/range/range.js'
import BaseFilter from 'shared/components/filters/base/base.js'
import Template from './departure_arrival_time.monk'

class DepartureFilter extends RangeFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'departure_time_0',
      render: {
        options: {
          filters: {
            decorateMeasurement (value) {
              return filters.time_respecting_locale(value)
            }
          }
        }
      },
      state: {
        getFromText () { return `&nbsp;` },
        getToText () { return `&nbsp;–&nbsp;` },
        title: false,
        subtitle: __('filters.departure')
      }
    }, super.defaultOptions())
  }

  update (data) {
    let cityName = dictionary.airportDeclByIata(this.iata, 'ro') || ''
    this.state.subtitle = `${__('filters.departure')} ${cityName} ${this.iata}`
    const formatFromData = {
      min: filters.slider_format_from_h_m_string(data.min),
      max: filters.slider_format_from_h_m_string(data.max)
    }
    super.update(formatFromData)
  }

  getState () {
    const state = super.getState()
    return {
      [state.name]: {
        '0': filters.to_human_format(state.min),
        '1': filters.to_human_format(state.max)
      }
    }
  }
}

class ArrivalFilter extends RangeFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'arrival_datetime_0',
      render: {
        options: {
          filters: {
            decorateMeasurement (value) {
              return filters.time_and_date(value)
            }
          }
        }
      },
      state: {
        getFromText () { return `&nbsp;` },
        getToText () { return `&nbsp;–&nbsp;` },
        title: false,
        subtitle: __('filters.arrive')
      }
    }, super.defaultOptions())
  }

  update (data) {
    let cityName = dictionary.airportDeclByIata(this.iata, 'vi') || ''
    this.state.subtitle = `${__('filters.arrive')} ${cityName} ${this.iata}`
    const formatFromData = {
      min: data.min,
      max: data.max
    }
    super.update(formatFromData)
  }
  getState () {
    const state = super.getState()
    return {
      [state.name]: {
        '0': state.left,
        '1': state.right
      }
    }
  }
}

export default class ArrivalDeparture extends BaseFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'departure_arrival',
      render: { template: Template },
      state: {
        title: __('filters.time_filters_title'),
        collapsed: false,
        activeTabIndex: 0,
        isOpenjaw: false,
        segments: []
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    this.filters = options.options.filters
    this.defaultOptions = options.options.defaultOptions
    this.wrapper = this.view.querySelector('[role="filter-departure-arrival"] [role="filter-body"]')
  }

  update (data) {
    this.state.segments = data.segments
    this.state.isOpenjaw = utils.is_open_jaw(this.state.segments)
    this.view.update(this.state)
    this._clearWrapper(this.wrapper)
    this._createNewFilters(this.wrapper, this.state.segments)
  }

  _createNewFilters (wrapper, segments) {
    segments.forEach((iata, i) => {
      const innerElement = this.state.isOpenjaw ? wrapper.querySelector(`[data-tab="${i}"]`) : wrapper
      const departureFilterName = `departure_time_${i}`
      const arrivalFilterName = `arrival_datetime_${i}`
      this.filters.filters[departureFilterName] = new DepartureFilter(innerElement, {
        name: departureFilterName,
        options: this.defaultOptions,
        onChange: this.filters.onChangeCallback()
      })
      this.filters.filters[arrivalFilterName] = new ArrivalFilter(innerElement, {
        name: arrivalFilterName,
        options: this.defaultOptions,
        onChange: this.filters.onChangeCallback()
      })
      this.filters.rangeFilters = [...this.filters.rangeFilters, departureFilterName, arrivalFilterName]
      this.filters.filtersKeys = Object.keys(this.filters.filters)
      this.filters.filters[departureFilterName]['iata'] = iata.origin.toUpperCase()
      this.filters.filters[arrivalFilterName]['iata'] = iata.destination.toUpperCase()
    })
  }

  _clearWrapper (wrapper) {
    if (this.state.isOpenjaw) {
      [...wrapper.querySelectorAll(`[data-tab]`)].forEach(node => (node.innerHTML = ''))
    } else {
      wrapper.innerHTML = ''
    }
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)
    this.view.on('click', '[role="tab-label"]', (event, target) => {
      this.state.activeTabIndex = target.getAttribute('data-tab')
      this.view.update(this.state)
    })
  }
}
