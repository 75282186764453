<div class="policy-bar {{ show ?  'policy-bar--show' : '' }} ">
  {% if show %}
    <p class="policy-bar__text">
      {{ __('gdpr.cookies_policy') }}
      <br>
      <span>{% unsafe __('gdpr.cookies_policy_link') %}</span>
    </p>
    <button type="button" role="close" class="policy-bar__close"></button>
  {% endif %}
</div>
