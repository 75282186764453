import xhr from 'xhr'

const MATRIX_PRICE_FETCHER_API_URL = '/price_matrix?'

export default class MatrixPriceFetcher {
  _prepareQuery (paramsObj) {
    let params = []
    for (let key in paramsObj) {
      params.push(key + '=' + encodeURIComponent(paramsObj[key]))
    }
    return params.join('&')
  }

  fetch (params, callback, errorCallback) {
    let url = MATRIX_PRICE_FETCHER_API_URL + this._prepareQuery(params)
    if (!params['need_request']) {
      return errorCallback({errors: {no_need_request: true}})
    }
    this._xhr = xhr({
      url: url,
      method: 'get'
    }, (error, response, body) => {
      if (response.statusCode === 0) { return false }

      if (!error && response.statusCode == 200) {
        let result = JSON.parse(body)
        callback(result.prices)
      } else if (response.statusCode == 400) {
        errorCallback ? errorCallback(JSON.parse(body)) : callback([])
      } else if (error) {
        console.error('Matrix prices connection error', error)
        errorCallback ? errorCallback({}) : callback([])
      } else {
        console.error('Matrix prices server error', error)
        errorCallback ? errorCallback({}) : callback([])
      }
    })
  }
}
