import _defaultsDeep from 'lodash/defaultsDeep'
import UserSettings from 'shared/components/user_settings/user_settings.js'
import filters from 'shared/lib/filters.coffee'
import RangeFilter from 'shared/components/filters/range/range.js'
import converter from 'shared/lib/converter.js'

export default class Price extends RangeFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'price_filter',
      render: {
        options: {
          filters: {
            decorateMeasurement (value) {
              this.currency = UserSettings.getCurrency()
              return `<span class="currency_font currency_font--${this.currency}">${filters.split_price(value)}</span>`
            }
          }
        }
      },
      state: { title: __('filters.price') }
    }, super.defaultOptions())
  }

  getState () {
    let state = super.getState()
    return {
      min_unified_price: Math.floor(converter.revert(state.min)),
      max_unified_price: Math.ceil(converter.revert(state.max))
    }
  }
  updateState (data, forced = false) {
    super.updateState(data, forced)
    this._updateMinMax(this.state.left, this.state.right)
  }
}
