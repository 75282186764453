<div class="baggage-tooltip {{ debug ? 'baggage-tooltip--debug' : '' }}">
  <div class="baggage-tooltip__item">
    {% if bags.baggage.amount == 0 %} 
    <span class="baggage-info">{{ bags.baggage.code == 'UNKNOWN' ? __('ticket.baggage.unknown_baggage') : __('ticket.baggage.without_baggage') }}</span>
    {% else %}
    <span class="baggage-info">{{ bags.baggage.short_description }}</span>
    {% endif %}
  </div>
  {% if debug %}
    <div class="baggage-tooltip__item baggage-tooltip__item--border">
      <span class="baggage-info"> {{ __('ticket.baggage.baggage')}}: {{bags.baggage.code }};{{bags.baggage.debug }}</span>
    </div>
  {% endif %}
  <div class="baggage-tooltip__item">
    {% if bags.handbags.amount == 0 %} 
    <span class="baggage-info">{{ bags.handbags.code == 'UNKNOWN' ? __('ticket.baggage.unknown_handbags') : __('ticket.baggage.without_handbags') }}<sup>*</sup></span>
    {% else %}
    <span class="baggage-info">{{ bags.handbags.short_description }}<sup>*</sup></span>
    {% endif %}
  </div>
  {% if debug %}
    <div class="baggage-tooltip__item baggage-tooltip__item--border">
      <span class="baggage-info">{{ __('ticket.baggage.handbags')}}: {{bags.handbags.code }};{{bags.handbags.debug }}</span>
    </div>
    <div class="baggage-tooltip__item baggage-tooltip__item--border">
      <span class="baggage-info">Tariff: {{tariff}}</span>
    </div>
  {% endif %}
</div>
