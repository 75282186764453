_extend = require('lodash/extend')
default_params        = require('./strategies/default_params.coffee')
aviasales_ru_params   = require('./strategies/aviasales_ru_params.coffee')
short_params_parser   = require('./strategies/short_params.coffee')
jr_params_parser      = require('./strategies/jr_params.coffee')

module.exports =
  get: ->
    this.params ||= {}
    transformedSearch = if window.location.search then jr_params_parser.transformLegacySearch(window.location.search) else ""
    transformedPath = jr_params_parser.transformLegacyPath(window.location.pathname)

    this.params = _extend(
      this.params,
      default_params(),
      aviasales_ru_params(transformedSearch),
      short_params_parser(decodeURIComponent(transformedPath))
    )

    this.params
