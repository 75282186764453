{% import BaggageTooltip from './baggage_tooltip.monk' %}

<div class="bags-info">
  <div class="bags-info__icons bags-info__icons--baggage">
    <i class="bag-icon bag-icon--baggage {{bags.baggage.classes}}"
    data-weight="{{ (bags.baggage.weight | is_NaN) ? 'x1'  : bags.baggage.weight }}"></i>
    {% if bags.baggage.amount > 1 %} 
      <i class="bag-icon bag-icon--baggage
      {{bags.baggage.amount ? '' : 'bag-icon--without-baggage'}}"
      data-weight="{{ (bags.baggage.weight | is_NaN) ? 'x1'  : bags.baggage.weight }}"></i>
    {% endif %}
    {% if bags.baggage.amount > 2 %} 
      <i class="bag-icon bag-icon--baggage
      {{bags.baggage.amount ? '' : 'bag-icon--without-baggage'}}"
      data-weight="{{ (bags.baggage.weight | is_NaN) ? 'x1'  : bags.baggage.weight }}"></i>
    {% endif %}
    {% if bags.baggage.amount == 0 %} 
      <span class="baggage-info">{{ bags.baggage.code == 'UNKNOWN' ? __('ticket.baggage.unknown_baggage') : __('ticket.baggage.without_baggage') }}</span>
    {% else %}
      <span class="baggage-info">{{ bags.baggage.description }}</span>
    {% endif %}
  </div>
  <div class="bags-info__icons bags-info__icons--handbags">
    <i class="bag-icon bag-icon--handbags {{bags.handbags.classes}}"
    data-weight="{{ (bags.handbags.weight | is_NaN) ? 'x1'  : bags.handbags.weight }}"></i>
    {% if bags.handbags.amount == 0 %} 
      <span class="baggage-info">{{ bags.handbags.code == 'UNKNOWN' ? __('ticket.baggage.unknown_handbags') : __('ticket.baggage.without_handbags') }}<sup>*</sup></span>
    {% else %}
      <span class="baggage-info">{{ bags.handbags.description }}<sup>*</sup></span>
    {% endif %}
  </div>
  <BaggageTooltip  bags={{bags}} tariff={{tariff}} debug={{debug}} />
</div>
