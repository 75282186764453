_partial = require('lodash/partial')
_isNull = require('lodash/isNull')
_isString = require('lodash/isString')
_keys = require('lodash/keys')
_isObject = require('lodash/isObject')
_extend = require('lodash/extend')
xhr      = require('xhr')
dispatcher = require('shared/lib/dispatcher')
metrics = require('shared/lib/metrics.coffee')
config = require('shared/lib/tp_config.js')
cookies = require('shared/lib/cookies.coffee')

TRIES_LIMIT = 3
SEARCH_RESULTS_URL = config.url + "/new_searches_results_united"
SEARCH_REQUEST_URL = config.url + "/new_adaptors/chains/"

WL_SEARCH_RESULTS_URL = config.url + "/searches_results_united"
WL_SEARCH_REQUEST_URL = config.url + "/adaptors/chains/"

module.exports = (request_params, callbacks = {}) ->
    #private
    callbacks = _extend({
      "on_update": ->
      "on_finish": ->
      "on_error" : ->
      "on_abort" : ->
    }, callbacks)

    search_uuid = null
    _xhr        = null
    tries_count = 0

    abort = -> _xhr?.abort()

    stop_marker_received = (data) ->
      stop_marker = (_isObject(data) and _keys(data).length == 1)
      _isString(data) or _isNull(data) or stop_marker

    failed_with_500 = (res) ->
      not res or res?.statusCode.toString()[0] == '5'

    is_as_chain = (locale) ->
      locale && ['ru', 'uk', 'hy', 'tg', 'be', 'ce', 'kk', 'uz', 'uk', 'az'].indexOf(locale.toLowerCase()) != -1

    get_search_url = (search_uuid = null, locale=null) ->
      if search_uuid
        url = "#{WL_SEARCH_RESULTS_URL}?uuid=#{search_uuid}&#{Math.random()}"
      else
        chain = if is_as_chain(locale) then 'rt_search_native_format' else 'jetradar_rt_search_native_format'

        url = WL_SEARCH_REQUEST_URL + chain

      url

    on_receive = (req_params, err, res) =>
      return callbacks.on_abort() if res?.abort
      return if res?.statusCode == 0

      if res?.statusCode is 200
        tries_count = 0
        if res?.body?.meta?.uuid
          search_uuid = res.body.meta.uuid
        data = []
        for item, index in [].concat(res.body)
          if stop_marker_received(item)
            callbacks.on_update(data)
            callbacks.on_finish(data)
            return
          data.push(item)
        callbacks.on_update(data)

        send_request(get_search_url(search_uuid, req_params.lang))
      else
        if tries_count < TRIES_LIMIT and failed_with_500(res)
          dispatcher.send('log_warning', "Trying to reconnect #{tries_count} times", 'yasen_client')
          send_request(res.url, res.method, req_params)
        else
          callbacks.on_error({status: res?.statusCode})

        metrics.reach_goal('SEARCH_RESPONSE_ERROR', {status: res?.statusCode})


    send_request = (url, method = "get", request_params = {}) ->
      tries_count++
      # FIXME: Fix if something will go wrong
      request_params.currency ||= window.TPWLCONFIG.currency
      request_params.locale = request_params.lang if request_params.lang
      request_params.debug = cookies.get 'yasen_debug'
      request_params.results_debug = cookies.get 'results_debug'
      _xhr = xhr({
          url: url
          json: request_params
          method: method
        }, _partial(on_receive, request_params))

    send_request(get_search_url(null, request_params.lang), "post", request_params)

    #public
    stop: -> abort()
