<div class="sticky-informer-info">
  <div class="sticky-informer-info__left-block">
    <div class="sticky-informer-info__route">
      <div class="sticky-informer-info-route__origin">
        {{ params.segments[0] && params.segments[0].origin.toUpperCase() }}
      </div>
      <div class="sticky-informer-info-route__arrow">
        {% unsafe (params.segments[1] ? "&xharr;" :
          "<span class='sticky-informer-info-route__arrow-ltr'>&xrarr;</span>\
           <span class='sticky-informer-info-route__arrow-rtr'>&xlarr;</span>")
        %}
      </div>
      <div class="sticky-informer-info-route__destination">
        {{ params.segments[0] && params.segments[0].destination.toUpperCase() }}
      </div>
    </div>
    <div class="sticky-informer-info-dates-narrow">
      <span class="sticky-informer-info-dates__date">{% unsafe (params.segments[0] && params.segments[0].date) | sticky_date_narrow %}</span>
      <span class="sticky-informer-info-dates__arrow">{% unsafe (params.segments[1] ? "&mdash;" : "") %}</span>
      <span class="sticky-informer-info-dates__date">{% unsafe  (params.segments[1] && params.segments[1].date) | sticky_date_narrow %}</span>
    </div>
  </div>
  <span class="sticky-informer-info__separator">|</span>
  <div class="sticky-informer-info-dates-wide">
    <span class="sticky-informer-info-dates__date">{% unsafe (params.segments[0] && params.segments[0].date) | sticky_date_wide %}</span>
    <span class="sticky-informer-info-dates__arrow">{% unsafe (params.segments[1] ? "&xharr;" : "") %}</span>
    <span class="sticky-informer-info-dates__date">{% unsafe  (params.segments[1] && params.segments[1].date) | sticky_date_wide %}</span>
  </div>
  <span class="sticky-informer-info__separator">|</span>
  <div class="sticky-informer-info__passengers">
    <b>{{ (params.passengers.adults + params.passengers.infants + params.passengers.children) | passengers }}</b>
    <br />
    {{ params.trip_class == 'Y' ? __('form.trip_class.Y') : __('form.trip_class.C') }}
  </div>
  <div class="sticky-informer-info__button" onclick="window.scrollTo(0, 0)">
    {{ __('helpers.submit.search.update') }}
  </div>
</div>
