import _forEach from 'lodash/forEach'
import DOMComponent from 'shared/components/base'
import sorter from 'flights/components/request/sorter.coffee'
import Template from './best_tickets.monk'
import bench from 'shared/lib/bench.coffee'
import utils from 'shared/lib/utils.coffee'
import Ticket from 'flights/components/ticket/ticket.coffee'

var compare = (a, b) => {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

var translations = {
  cheap: __('best_tickets.price'),
  fast: __('best_tickets.fastest'),
  rating: __('best_tickets.rating'),
  best: __('best_tickets.best')
}

export default class BestTickets extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'best_tickets',
      render: { template: Template },
      state: {
        tickets_received: false,
        shown: false,
        forced: false
      }
    }
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    this.node = this.view.querySelector('[role="best-tickets-container"]')
    this.headerNode = this.view.querySelector('[role="best-tickets-header"]')
  }

  show (node) {
    super.show(node)
    if (!node) this.state.shown = true
  }

  hide (node) {
    super.hide(node)
    if (!node) this.state.shown = false
  }

  _initEvents (dispatcher) {
    dispatcher.on('all-tickets-shown', (event, params) => {
      if (params.force) { this.state.forced = false }
      this.hide()
    })
    dispatcher.on('best-tickets-shown', (event, params) => {
      if (params && params.force) { this.state.forced = true }
      this.show()
    })

    dispatcher.on('first_tickets_arrived', () => { this.state.tickets_received = true })
    dispatcher.on('start_search', () => { this.state.tickets_received = false })

    dispatcher.on('tickets_updated', (event, {request_id, tickets, reason}) => {
      if (reason == 'filters_updated') {
        if (this.state.tickets_received && tickets.length == 0) {
          this.show(this.headerNode)

          if (!this.state.shown) dispatcher.send('best-tickets-shown', {}, 'best_tickets')
        } else {
          if (tickets.length > 0) {
            this.hide(this.headerNode)
          }

          if (this.state.shown) dispatcher.send('all-tickets-shown', {}, 'best_tickets')
        }
      }
    })

    dispatcher.on('fuzzy_tickets_updated', bench('best_prices', (event, {request_id, tickets}) => {
      let before_using_sort = tickets
      utils.clean_node(this.node)

      let bestTickets = {
        cheap: tickets[0],
        fast: tickets[0],
        rating: tickets[0]
      }

      _forEach(tickets, (ticketPair) => {
        ticketPair[0].labels = false
        if (sorter.sort_funcs.price(ticketPair, bestTickets.cheap) < 0) bestTickets.cheap = ticketPair
        if (sorter.sort_funcs.duration(ticketPair, bestTickets.fast) < 0) bestTickets.fast = ticketPair
      })

      this._setRating(bestTickets.rating, bestTickets)

      _forEach(tickets, (ticketPair) => {
        this._setRating(ticketPair, bestTickets)
        if (ticketPair && compare(ticketPair[0].rating, bestTickets.rating[0].rating) < 0) bestTickets.rating = ticketPair
      })

      let ticketsToRender = {}

      _forEach(bestTickets, (value, key) => {
        let ticket = value[0]
        ticket.labels || (ticket.labels = {})
        if (!ticket.labels[key]) ticket.labels[key] = translations[key]
        ticketsToRender[ticket.sign] = value
      })

      _forEach(ticketsToRender, (value, sign) => {
        let ticket = value[0]
        if (Object.keys(ticket.labels).length > 1) {
          ticket.labels = {
            best: translations.best
          }
        }
      })

      let i = 0
      _forEach(ticketsToRender, (value, key) => {
        new Ticket(this.node, {state: {index: i++, ticket: value}})
      })

      dispatcher.send('cheapest_ticket_updated', { ticket: bestTickets.cheap})
    }))
  }

  _setRating (ticketPair, bestTickets) {
    ticketPair[0].rating = (
      ticketPair[0].total_duration / bestTickets.fast[0].total_duration
    ) * (
      (ticketPair[1][0] || {unified_price: 9999999}).unified_price /
      (bestTickets.cheap[1][0] || {unified_price: 9999999}).unified_price
    )
    if (ticketPair[0].min_stop_duration < 60) ticketPair[0].rating += 2
  }
}
