import ExpiredSearch from 'shared/components/expired_search/expired_search.js'
import utils from 'shared/lib/utils.coffee'

const SEARCH_EXPIRED_TIMEOUT = 60 * 1000 * 15 // 15min

export default class FlightsExpiredSearch extends ExpiredSearch {
  _initEvents (dispatcher) {
    super._initEvents(dispatcher)

    dispatcher.on('start_search', (event, {request_id, params}) => {
      this.hide()
      this.state.params = params
      this.skipExpiration()
    })

    dispatcher.on('search_finished', () => { this.changeTTL(SEARCH_EXPIRED_TIMEOUT) })
  }

  _initDOMEvents (view) {
    view.on('click', '[role="informer-restart-search-trigger"]', () => {
      this._dispatcher.send('start_search', {request_id: utils.generate_id(), params: this.state.params}, 'search_results')
    })
  }
}
