styles_modifier = require('shared/lib/styles_modifier.coffee')
utils           = require('shared/lib/utils.coffee')

module.exports =
  hide: (el, with_opacity = false) ->
    if with_opacity && utils.transition_events_supported()
      styles_modifier(el).add('hide-with-opacity')
    else
      styles_modifier(el).add('hidden')

    @
  show: (el) ->
    styles_modifier(el).remove('hidden')
    styles_modifier(el).remove('hide-with-opacity')
    @

  toggle: (el, toggle) ->
    if toggle then @show(el) else @hide(el, true)

  disable: (el) ->
    styles_modifier(el).remove('enabled')
    styles_modifier(el).add('disabled')
    @

  enable: (el) ->
    styles_modifier(el).remove('disabled')
    styles_modifier(el).add('enabled')
    @
