{% import FilterTitle from 'shared/components/filters/base/title.monk' %}
<div class="filter" role="filter-departure-arrival" >
  {% if isOpenjaw %}
    <div class="segment_selection">
      <h3 class="segment_selection__title">{{ __('filters.select_segment') }}</h3>
      {% for index, iata of segments %}
        <span class="segment_selection__item {{ activeTabIndex == index ? 'segment_selection__item--selected' : '' }} " role="tab-label" data-tab="{{ index }}">
          {{ iata.origin }}
          <br>
          {{ iata.destination }}
        </span>
      {% endfor %}
    </div>
  {% endif %}
  <FilterTitle title="{{ title }}" collapsed="{{ collapsed }}"/>
  <div class="filter-control-container {{ collapsed ? 'filter-control-container--collapsed' : '' }}" >
    <div class="control {{ collapsed ? 'hidden' : '' }}" role="filter-body">
    {% if isOpenjaw %}
      {% for index, iata of segments %}
        <div class="filter_tab_wrapper {{ activeTabIndex == index ? 'filter_tab_wrapper--active' : '' }}" data-tab="{{ index }}">
        </div>
      {% endfor %}
    {% endif %}
    </div>
  </div>
</div>
