import moment from 'moment'
import UserSettings from 'shared/components/user_settings/user_settings.js'

moment.ISODate = function (date) {
  date = date.toObject()
  return `${date.years}-${('0' + (date.months + 1)).slice(-2)}-${('0' + date.date).slice(-2)}`
}
moment.dayLongWeekdayMonthName = function (date) {
  // 22 Aug, Wednesday
  const shortWeekday = moment(date).format('ddd')
  const longWeekday = moment(date).format('dddd')
  return moment(date).format('LLLL').replace(shortWeekday, longWeekday)
}

moment.dayLongWeekdayMonthNameStyling = function (date) {
  // <b>22 Aug</b> <br/> Wednesday
  const formatDate = moment.dayLongWeekdayMonthName(date)
  const formatDateArr = formatDate.split(',')
  const firstPositionWeekday = formatDateArr[0] === moment(date).format('dddd')
  firstPositionWeekday ? formatDateArr[1] = `<b>${formatDateArr[1]}</b>` : formatDateArr[0] = `<b>${formatDateArr[0]}</b>`
  return formatDateArr.join(' <br/>')
}
moment.dayWeekdayMonthName = function (date) {
  // 22 Aug, Wed
  return moment(date).format('LLLL')
}
moment.dayLongMonthName = function (date) {
  // 22 August
  return moment(date).format('LL').replace(',', '').split(' ').slice(0, 2).join(' ')
}
moment.dayLongMonthNameYear = function (date) {
  // 2 January 2019
  return moment(date).format('LL').replace(',', '').replace('.', '')
}
moment.dayLongMonthNameNextYear = function (date) {
  // 2 January 2019 or 2 January if current year
  const thisYear = moment().format('Y')
  const dataYear = moment(date).format('Y')
  const nextYear = thisYear !== dataYear
  return nextYear ? moment.dayLongMonthNameYear(date) : moment.dayLongMonthName(date)
}

// Magic detected /config/webpack/moment_localisation.js
MOMENT_LOCALE_CUSTOMIZATION_WILL_BE_HERE(moment)
