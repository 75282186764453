DOMComponent    = require('shared/components/base.js').default
Template        = require('./sticky_informer.monk')
filters         = require('shared/lib/filters.coffee')
moment          = require('moment')
metrics         = require('shared/lib/metrics.coffee')
utils           = require('shared/lib/utils.coffee')

templateFilters =
  passengers: (count) -> filters.pluralize count, __('form.passengers')
  sticky_date_narrow: (date) -> if date then moment.dayWeekdayMonthName(date) else ''
  sticky_date_wide: (date) -> if date then moment.dayLongWeekdayMonthNameStyling(date) else ''
  sticky_date_mobile: (date) -> if date then moment.dayLongMonthName(date) else ''
  city_name_shortener: (cities_name) -> if cities_name.length > 28 then cities_name.slice(0,28)+"..." else cities_name

class StickyInformer extends DOMComponent
  @instance: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @broadcast: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @initializeComponent: () ->  #HACK static inheritance in CoffeeScript
  @defaultOptions: () -> {
    name: 'sticky_informer',
    render: {
      template: Template
      options: {filters: templateFilters}
    },
    state:{
      multi: false
      params: {}
    },
    cssx:{
      scope: '.TPWL-widget .sticky-informer',
      styles: {
        "": {
          "background": window.TPWLCONFIG.color_scheme.bg,
          "color": window.TPWLCONFIG.color_scheme.text_contrast
        },
        ".sticky-informer-info__button": {
          "background-color": window.TPWLCONFIG.color_scheme.btn_bg,
          "color": window.TPWLCONFIG.color_scheme.btn_text
        },
        ".sticky-informer-info--multi .sticky-informer-info__passengers": {
          "background": window.TPWLCONFIG.color_scheme.bg
        },
        ".sticky-informer-info--multi .sticky-informer-info__passengers:before": {
          "background": "linear-gradient(to left, #{window.TPWLCONFIG.color_scheme.bg}, rgba(211,81,48,0))"
        },
        ".sticky-informer-info--multi .sticky-informer-info__separator": {
          "color": window.TPWLCONFIG.color_scheme.btn_text
        }
      }
    }
  }

  constructor: (containerNode, options = {}) ->
    super(containerNode, options)

  _initDOMEvents: (view) ->
    view.update({segments:[], passengers:{ adults:1, children:0, infants:0 }})
    view.on 'click', '.sticky-informer-search-info', -> metrics.reach_goal("CLICK_STICK")

  _initEvents: (dispatcher) ->
    dispatcher.on 'places_restored', (event, params) =>
      @state.multi = utils.is_open_jaw(params.segments)
      @state.params = params
      @refresh()

module.exports = { default: StickyInformer }
