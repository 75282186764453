# Take params and process them to canonical
module.exports =
  transformLegacySearch: (location) ->
    transform =
      'search[origin_name]': 'origin_name'
      'search[origin_iata]': 'origin_iata'
      'search[destination_name]': 'destination_name'
      'search[destination_iata]': 'destination_iata'
      'search[depart_date]': 'depart_date'
      'search[return_date]': 'return_date'
      'search[one_way]': 'one_way'
      'search[trip_class]': 'trip_class'
      'search[adults]': 'adults'
      'search[children]': 'children'
      'search[infants]': 'infants'

    for source, target of transform
      location = location.replace(source, target)

    location

  transformLegacyPath: (path) ->
    removeAC = /(a|c)([a-z]{3})(\d{4}|-|c\d{1,3}$|y\d{1,3}$)/gi
    newPath = path.replace(removeAC, '$2$3')

    removeY = /(\d|[a-z]{3})(y)(\d{1,3})$/i
    newPath = newPath.replace(removeY, '$1$3')

    replaceCWithB = /(\d{4}|[a-z]{3})(c)(\d{1,3})$/i
    newPath = newPath.replace(replaceCWithB, '$1B$3')

    removeDash = /-/g
    newPath = newPath.replace(removeDash, '')

    newPath
