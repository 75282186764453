import _findIndex from 'lodash/findIndex'
import _extend from 'lodash/extend'
import _map from 'lodash/map'
import _defaultsDeep from 'lodash/defaultsDeep'
import BaseFilter from 'shared/components/filters/base/base.js'
import Slider from 'shared/components/slider/slider'
import Template from './range.monk'

export default class RangeFilter extends BaseFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'range_filter',
      render: {
        template: Template,
        options: {
          filters: {
            decorateMeasurement (value) { return value },
            round: (f) => Math.round(f * 10) / 10,
            floor: (f) => Math.floor(f * 10) / 10
          }
        }
      },
      state: {
        collapsed: false,
        getFromText () { return `${__('from')} &nbsp;` },
        getToText (left) { return `&nbsp;${left !== false ? __('to').toLowerCase() : __('to')}&nbsp;` },
        activeBarsRange: {},
        percents_by_counts: [],
        getAnyText () { return __('filters.any') }
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    this.slider = false
    this.sliderWrapperNode = this.view.querySelector('[role="range_slider"]')
    this.state.name = this.options.name
    this.options.filterState.min = this.state.right
    this.options.filterState.max = this.state.left
    this.state.withGroups = this.options.options.steps !== undefined
    this.state.barchart = this.state.withGroups && !!this.options.options.barchart
    this.needDafaultSatate = this.state.withGroups || this.options.options.dynamic
    this.state.infinity = !!this.options.options.infinity

    this.hiddenArrowRole = false
    if ('two_way' in this.options.options && this.options.options.two_way === false) {
      this.hiddenArrowRole = 'right'
      if ('inverted' in this.options.options && this.options.options.inverted === true) this.hiddenArrowRole = 'left'
    }

    this.state.withGroups ? this.initGroupSlider(options) : this.initStepSlider(options)
  }

  initGroupSlider (options) {
    this.state.groups = this.options.options.steps.slice()
    this.options.defaultFilterState.groups = this.options.filterState.groups = this.options.options.steps
    this.state.step = 1
    this.state.lower = 0
    if (this.state.left === undefined) this.state.left = 0
    this.state.upper = this.state.groups.length - 1
    if (this.state.right === undefined) this.state.right = this.state.upper
    if (this.state.barchart) {
      this.state.percents_by_counts = _map(this.options.filterState.groups, () => 0).slice(1)
    }
    if (this.state.groups[this.state.upper] === Infinity) {
      // We need send to backend int value
      this.options.filterState.groups[this.state.upper] = 2147483647
      // We need hide right border in helper
      if (!this.options.options.show_borders) this.state.groups[this.state.upper] = false
    }

    if (this.state.groups[this.state.lower] === 0 && !this.options.options.show_borders) {
      // We need hide right border in helper
      this.state.groups[this.state.lower] = false
    }
    this.refresh()
  }

  initStepSlider (options) {
    this.state.lower = options.options.min
    if (this.state.left === undefined) this.state.left = this.state.lower
    this.state.upper = options.options.max
    if (this.state.right === undefined) this.state.right = this.state.upper
    this.state.step = options.options.step
    this.state.multiplier = options.options.multiplier || 1
    this.state.groups = []
  }

  initSlider () {
    this.slider = Slider(this.sliderWrapperNode, this.state, (left, right, inProgress) => {
      if (!inProgress) {
        this._updateMinMax(left, right)

        this.state.left = left
        this.state.right = right
        this.onChange()
      }
      this.state.left = left
      this.state.right = right
      this.refresh()
    }, undefined, _extend({ hidden_arrow: this.hiddenArrowRole }, this.options.options))
  }

  _updateMinMax (left, right) {
    this.options.filterState.min = this.state.withGroups ? this.options.filterState.groups[left] : this.state.left * this.state.multiplier
    this.options.filterState.max = this.state.withGroups ? this.options.filterState.groups[right] : this.state.right * this.state.multiplier
  }

  getActiveGroupsRange (left, right) {
    let activeRange = {
      left: 0,
      right: this.state.groups.length - 1
    }

    for (let i = 1; i <= activeRange.right; i++) {
      if (left > this.options.filterState.groups[i]) { activeRange.left = i }
      if (right < this.options.filterState.groups[i]) { activeRange.right = i }
    }

    return [activeRange.left, activeRange.right]
  }

  update (data) {
    if (data === null) data = {min: this.state.lower, max: this.state.upper}
    this.state.data = data

    if (this.state.withGroups && 'percents_by_counts' in data) {
      if (data.percents_by_counts.length === this.options.filterState.groups.length) {
        data.percents_by_counts = data.percents_by_counts.slice(1)
      }
      this.state.percents_by_counts = data.percents_by_counts
    } else {
      this.updateState(data)
    }

    if (!this.slider) { this.initSlider() }
    this.slider(this.state)
    super.update(data)
  }

  isThisDefaultState () {
    let state = this.options.filterState
    return state.left === state.lower && state.right === state.upper
  }

  resetState () {
    this.state.left = this.state.lower
    this.state.right = this.state.upper
    this.state.min = this.state.lower * this.state.multiplier
    this.state.max = this.state.upper * this.state.multiplier
    this.repaintSlider()
    super.resetState()
    this.refresh()
  }

  repaintSlider () {
    if (this.slider) { this.slider(this.state) }
  }

  parseShortState (state) {
    let result = {}
    if (state[0]) result['min'] = this._nearestGroup(parseFloat(state[0], 10), 1)
    if (state[1]) result['max'] = this._nearestGroup(parseFloat(state[1], 10))
    return result
  }

  _nearestGroup (value, offset = 0) {
    if (this.state.withGroups) {
      let groups = this.state.groups
      return groups[_findIndex(groups, (i) => value <= i) - offset]
    } else {
      return value
    }
  }

  updateState (data, forced = false) {
    if (this.state.groups && this.state.groups.length) {
      if (data['min']) this.state.left = _findIndex(this.state.groups, (i) => data['min'] === i)
      if (data['max']) this.state.right = _findIndex(this.state.groups, (i) => data['max'] === i)
    } else {
      if (data['min']) this.state.left = data['min'] / (this.options.options.step * this.options.options.multiplier)
      if (data['max']) this.state.right = data['max'] / (this.options.options.step * this.options.options.multiplier)
    }
    this.options.options.dynamic && this._updateLowerUpper(this.state.left, this.state.right)
    if (data['multiplier']) this.state.multiplier = data['multiplier']
    this._updateMinMax(this.state.left, this.state.right)
    super.updateState(data, forced)
  }

  _updateLowerUpper (lower, upper) {
    this.state.lower = lower
    this.state.upper = upper
  }

  getShortState () {
    return [this.options.filterState.min, this.options.filterState.max]
  }
}
