_assign = require('lodash/assign')
plurals        = require('make-plural')
date_formatter = require('shared/lib/date_formatter.coffee')
converter      = require('shared/lib/converter')
DateDifferenceInDays = require('shared/lib/date_difference.js')
Locale = require('shared/lib/locale.js').default

TRIP_CLASSES =
  'Y': __('form.trip_class.Y'),
  'C': __('form.trip_class.C'),

filters =
  count: (obj) ->
    obj.length

  split_price: (price) -> converter.formatUnifiedPrice(price)

  price: (price) -> converter.format(price)

  abs: (number) -> Math.abs(number)

  pluralize: (count, messages) ->
    return messages.zero if count == 0 && messages.zero
    messages[plurals[Locale.getPluralLocaleFallback(LOCALE)](count)].replace('%{count}', count)

  trip_class: (key) -> if TRIP_CLASSES.hasOwnProperty(key) then TRIP_CLASSES[key] else key

  withDecimalDigit: (float) -> if parseInt(float) == float then "#{float}.0" else float

  localisedStayRange: (checkIn, checkOut) ->
    daysDifference = DateDifferenceInDays(new Date(checkIn), new Date(checkOut))
    "#{daysDifference} #{this.pluralize(daysDifference, __('card.nights'))}"

  positionStyle: (obj, pos) ->
    if obj && obj[pos] then "#{pos}: #{obj[pos]}px;" else ''

module.exports = _assign(filters, date_formatter)
