import searchParams from 'flights/components/params_parser/search_params.coffee'
import cookies from 'shared/lib/cookies.coffee'
import utils from 'shared/lib/utils.coffee'
import dispatcher from 'shared/lib/dispatcher.js'
import moment from 'moment'
import constants from "../../shared/constants";

var Params = {
  init () {
    this.parsed = searchParams.get()
    if (this.parsed.segments[0].date !== null) {
      this.temporary = this.parsed
      setTimeout(() => {
        this.shortUrl = this.getShortParams(this.temporary)
        if (this.temporary.ticket) { this.shortUrl += '?ticket=' + this.temporary.ticket } // FIXME on production

        let pathname = window.location.pathname
        if(!constants.IS_LOCALHOST){
          if ((pathname === '/' || pathname.indexOf('/searches/') === 0) && this.temporary.with_request && !this.ISLOCALHOST) {
            window.location = '/flights/' + this.shortUrl
          } else {
            window.history.replaceState(window.history.state, null, '/flights/' + this.shortUrl)
          }
        }
      }, 1)
      this.parsed = this.castparsed(this.parsed)
    } else {
      this.parsed = false
    }

    this.affiliate = window.TPWLCONFIG
    this.cookie = cookies.get('flight_search_params')
    this.cookie = (this.cookie && JSON.parse(this.cookie).params_attributes) || {'origin': {}, 'destination': {}}
  },
  castparsed (params) {
    return {
      depart_date: params.segments[0].date,
      return_date: (params.segments[1] && params.segments[1].date) || '',
      origin: {
        name: params.segments[0].origin_name_name,
        iata: params.segments[0].origin
      },
      destination: {
        name: params.segments[0].destination_name_name,
        iata: params.segments[0].destination
      },
      adults: params.passengers.adults,
      children: params.passengers.children,
      infants: params.passengers.infants,
      trip_class: params.trip_class,
      preview: params.preview,
      host: params.host,
      highlighted_ticket: params.ticket || false

    }
  },
  getShortParams (params, departDate, returnDate) {
    if (typeof departDate === 'string') departDate = moment.ISODate(moment(departDate))
    var multi = utils.is_open_jaw(params.segments)
    var url = params.segments[0].origin
    var date

    if (multi) {
      for (let i = 0; i < params.segments.length; i++) {
        if (params.segments[i].date) {
          if (i > 0 && params.segments[i - 1].destination !== params.segments[i].origin) {
            url += '-' + params.segments[i].origin
          }
          url += params.segments[i].date.split('-')[2] // day
          url += params.segments[i].date.split('-')[1] // month
          if (i !== params.segments.length - 1 || params.segments[i].destination !== params.segments[0].origin) {
            url += params.segments[i].destination
          }
        }
      }
    } else {
      if (params.segments[0].date) {
        date = departDate || params.segments[0].date
        url += date.split('-')[2] // day
        url += date.split('-')[1] // month
        url += params.segments[0].destination
      }
      if (params.segments.length > 1 && params.segments[1].date) {
        date = returnDate || params.segments[1].date
        url += date.split('-')[2] // day
        url += date.split('-')[1] // month
      }
    }

    // trip class - if economy then do nothing, if business then add 'b'
    if (params.trip_class === 'B' || params.trip_class === 'C' || params.trip_class === 'b') {
      url += 'b'
    }
    url += params.passengers.adults
    if (params.passengers.children > 0 || params.passengers.infants > 0) {
      url += params.passengers.children
      url += params.passengers.infants
    }
    return url
  }
}

dispatcher.on('start_search', (event, search) => {
  Params.lastSearch = search
})

Params.init()

export default Params
