import DOMComponent from 'shared/components/base'
import Template from './expired_search.monk'
import stickySetter from 'shared/lib/sticky_setter.js'

export default class ExpiredSearch extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'expired_search',
      render: { template: Template }
    }
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    stickySetter.addElement(containerNode, 'expired-search-plate--sticky', null, 51)
  }

  changeTTL (msec) {
    this.skipExpiration()
    this._searchExpiredTimeoutId = setTimeout(() => {
      this._dispatcher.send('search_expired', null, 'expired_search')
    }, msec)
  }

  skipExpiration () {
    this._searchExpiredTimeoutId && clearTimeout(this._searchExpiredTimeoutId)
  }

  _initEvents (dispatcher) {
    dispatcher.on('systemMessageError', () => {
      this.errors = true
      this.hide()
    })

    dispatcher.on('search_expired', (event, params) => {
      if (!this.errors) { this.show() }
      stickySetter.stickyChanged()
      document.body.classList.add('TPWL-widget--search_expired')
    })
  }
}
