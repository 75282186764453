import _extend from 'lodash/extend'
import Device from 'shared/lib/device.js'
import Monkberry from 'monkberry'
import Template from './slider.monk'
import colorUtils from 'shared/lib/color_utils.js'
import cssx from 'cssx'

var isTouch = Device.isTouch()
const isRtl = Device.isRtl()

var sheet = cssx('tpwl-css-slider')
sheet.scope('.TPWL-widget .slider_field')

sheet.add({
  '.slider_bar': {
    'background-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.bg, '#000000')
  },
  '.slider_arrow': {
    'border-color': colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.bg, '#000000')
  },
  '.slider_arrow:hover': {
    'border-color': window.TPWLCONFIG.color_scheme.bg
  },
  '.slider_arrow.active': {
    'border-color': window.TPWLCONFIG.color_scheme.bg
  }
})

module.exports = (element, params, callback, dragCallbacks, options = {}) => {
  var data = {lower: params.lower, upper: params.upper, step: params.step, left: params.left, right: params.right}
  // console.log('adsasd', _extend({}, data))
  // console.log('INITIAL', data)
  var view = Monkberry.render(Template, element)
  view.update({base: 'slider'})

  var field = element.querySelector('[role="field"]')
  var left = field.querySelector('[role="left"]')
  var right = field.querySelector('[role="right"]')
  var bar = field.querySelector('[role="bar"]')

  var fieldRect, fieldWidth, dragLeft, diff, dragInProgress, xPrev, valMemo, arrowWidth
  var arrows = {left, right}
  var hiddenArrowRole = 'hidden_arrow' in options && options.hidden_arrow

  if (hiddenArrowRole) {
    arrows[hiddenArrowRole].parentNode.removeChild(arrows[hiddenArrowRole])
    element.classList.add(`range_slider--${hiddenArrowRole}_sided`)
    arrows[hiddenArrowRole] = false
  }

  var attempts = 0

  var externalInterface = function (d) {
    // console.log('EXTERNAL', Object.assign({}, d))
    if (d && d.upper > 0 && d.upper !== d.lower) {
      var argNames = ['lower', 'upper', 'step', 'left', 'right']
      for (var i = 0; i < argNames.length; i++) {
        var name = argNames[i]
        if (d[name] !== undefined) { data[name] = d[name] }
      }
    }

    let success = calculateVars()
    if (!success) {
      if (attempts++ < 25) setTimeout(function () { externalInterface(data) }, 200)
    } else {
      attempts = 0
      setLeft(data.left, false)
      setRight(data.right, false)
    }
  }

  var calculateVars = function () {
    fieldRect = field.getBoundingClientRect()
    if (fieldWidth && (fieldRect.width === 0 || fieldRect.height === 0)) return false
    if (arrows.left) arrowWidth = left.getBoundingClientRect().width
    if (arrows.right) arrowWidth = right.getBoundingClientRect().width
    fieldWidth = fieldRect.width
    if (arrowWidth === fieldWidth) return false
    diff = arrowWidth / 2

    if (data.leftDest === undefined && diff) data.leftDest = -diff
    if (data.rightDest === undefined && diff && fieldWidth) data.rightDest = fieldWidth - diff

    // console.log('CALCULATE VARS', diff, fieldWidth, arrowWidth, Object.assign({}, arrows), Object.assign({}, data), fieldRect)
    return true
  }

  var norm2val = function (n) {
    // console.log('norm2val', align(data.lower + (data.upper - data.lower) * n))
    return align(data.lower + (data.upper - data.lower) * n)
  }
  var val2norm = function (val) {
    // console.log('val2norm', (val - data.lower) / (data.upper - data.lower), val, data.lower, data.upper)
    return (val - data.lower) / (data.upper - data.lower)
  }
  var norm2px = function (n) {
    // console.log('norm2px', fieldWidth, n, fieldWidth * n)
    return fieldWidth * n
  }

  var setBar = function () {
    bar.style[`${isRtl ? 'right' : 'left'}`] = `${data.leftDest}px`
    bar.style.width = `${data.rightDest - data.leftDest + arrowWidth}px`
  }

  var align = function (val) {
    if (data.step && val !== data.lower && val !== data.upper) {
      var rem = val % data.step
      if (rem >= data.step / 2) {
        return Math.round(val + data.step - rem)
      } else {
        return Math.round(val - rem)
      }
    }
    return val
  }

  var setLeft = hiddenArrowRole === 'left' ? function () {} : function (val, callCallback) {
    if (val > data.right) val = data.right
    if (val === data.upper && !options.include_upper) return false
    setArrow('left', val, callCallback, false)
  }

  var setRight = hiddenArrowRole === 'right' ? function () {} : function (val, callCallback) {
    if (val < data.left) val = data.left
    if ((val === data.lower && !options.include_lower)) return false
    setArrow('right', val, callCallback, true)
  }

  var setArrow = function (side, val, callCallback, isRight) {
    // console.log(`SET ARROW ${side}`, val)
    data[side] = val
    data[`${side}Dest`] = norm2px(val2norm(val)) - diff
    arrows[side].style[`${isRtl ? 'right' : 'left'}`] = `${data[`${side}Dest`]}px`
    setBar()

    if (callCallback) callback(data.left, data.right, dragInProgress)
  }

  field.addEventListener('click', function (e) {
    dragLeft = null
    calculateVars()
    var norm = (isRtl ? (fieldRect.right - e.pageX) : (e.pageX - fieldRect.left)) / fieldWidth
    if (norm >= 0 && norm <= 1) {
      var val = norm2val(norm)
      if (hiddenArrowRole === false) {
        if (Math.abs(val - data.left) < Math.abs(val - data.right)) {
          setLeft(val, true)
        } else {
          setRight(val, true)
        }
      } else {
        if (!arrows.left) {
          setRight(val, true)
        } else {
          setLeft(val, true)
        }
      }
    }
  })

  var dragEvent = function (e) {
    let x = e.pageX || (e.touches && e.touches[0].pageX)
    if (e.stopPropagation) e.stopPropagation()
    if (e.preventDefault) e.preventDefault()
    e.cancelBubble = true
    e.returnValue = false

    if (xPrev && xPrev === x) return false

    let norm = (isRtl ? (fieldRect.right - x) : (x - fieldRect.left)) / fieldWidth
    if (norm < 0) norm = 0
    else if (norm > 1) norm = 1

    let val = norm2val(norm)
    if (valMemo === val) return false
    else valMemo = val

    if (dragLeft && val <= data.right) setLeft(val, true)
    else if (!dragLeft && val >= data.left) setRight(val, true)

    return false
  }

  var dragStop = function (e) {
    if (isTouch) {
      window.removeEventListener('touchmove', dragEvent)
      window.removeEventListener('touchend', dragStop)
    } else {
      window.removeEventListener('mousemove', dragEvent)
      window.removeEventListener('mouseup', dragStop)
    }

    dragInProgress = false

    if (callback) callback(data.left, data.right, dragInProgress)
    if (dragCallbacks) dragCallbacks.on_drag_finished(field)
  }

  var dragStart = function (which) {
    if (dragCallbacks && 'on_dragStarted' in dragCallbacks) dragCallbacks.on_dragStarted(field)
    calculateVars()
    dragInProgress = which
    dragLeft = which === 'left'

    if (which === 'left') {
      left.style.zIndex = 4
      right.style.zIndex = 3
    } else {
      left.style.zIndex = 3
      right.style.zIndex = 4
    }

    if (isTouch) {
      window.addEventListener('touchmove', dragEvent)
      window.addEventListener('touchend', dragStop)
    } else {
      window.addEventListener('mousemove', dragEvent)
      window.addEventListener('mouseup', dragStop)
    }
  }

  if (isTouch) {
    left.addEventListener('touchstart', function (e) { dragStart('left') })
    right.addEventListener('touchstart', function (e) { dragStart('right') })
  } else {
    left.addEventListener('mousedown', function (e) { dragStart('left') })
    right.addEventListener('mousedown', function (e) { dragStart('right') })
  }

  externalInterface(data)
  return externalInterface
}
