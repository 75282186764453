{% import Proposals from './ticket_proposals.monk' %}
{% import Baggage from './ticket_baggage.monk' %}

<div class="ticket {{expired_class}} {{ ticket_open ? 'ticket--open': ''}} {{ ticket_expanded ? 'ticket--expanded' : ''}} {{ labels ? 'ticket--with-labels' : ''}} {{ preloaded ? 'ticket--preloaded' : ''}} {{ ticket_type_class == 'multicity' ? 'ticket--multi' : '' }} {{ baggage_price && proposals.hasBaggage.length && proposals.otherBaggage.length ? '' : 'ticket--one_label_baggage' }}" role="ticket-container">
  {% if labels %}
    <div class="ticket-labels-container">
      {% for key, value of labels %}
        <span class="ticket-label ticket-label--{{ key }}"> {{ value }} </span>
      {% endfor %}
    </div>
  {% endif %}

  <div class="ticket-header">
    <div class="ticket-header__mobile_back_button" role="ticket-mobile-back"> </div>
    {% if multi %}
      <div class="ticket-header-cities">
        {{ (segments[0].mini.depart_name + " ... " + segments[segments.length-1].mini.arrival_name) | city_name_shortener }}
      </div>
      <div class="ticket-header-dates">
          {{ segments[0].mini.raw_depart_date | dates_mobile_header }} – {{ segments[segments.length-1].mini.raw_depart_date   | dates_mobile_header }}
      </div>
    {% else %}
      <div class="ticket-header-cities">
        {{ (segments[0].mini.depart_name + " – " + segments[0].mini.arrival_name) | city_name_shortener }}
      </div>
      <div class="ticket-header-dates">
          {{ segments[0].mini.raw_depart_date | dates_mobile_header }}
          {% if segments[1] %}
            – {{ segments[1].mini.raw_depart_date | dates_mobile_header }}
          {% endif %}
      </div>
    {% endif %}
  </div>

  <div class="ticket-scroll-container">
  <div class="ticket-action">
    <div class="ticket-action-buy">
      <div class="tariffs">
        {% if proposals.otherBaggage.length && proposals.otherBaggage[0].bags %}
          <div class="tariffs__item {{ baggage_expanded ?  '' : 'tariffs__item--active'}} " role="otherBaggage">
            <Baggage bags={{proposals.otherBaggage[0].worstBags}} tariff={{'otherBaggage'}} debug={{debug}} />
            {% if proposals.otherBaggage[0].worstBags.baggage.amount == 0 && proposals.hasBaggage.length %}
              <span class="tariffs__info-text">{{ proposals.otherBaggage[0].worstBags.baggage.code == 'UNKNOWN' ? __('ticket.baggage.unknown_baggage') : __('ticket.baggage.without_baggage') }}</span>
            {% endif %}
          </div>
        {% endif %}
        {% if baggage_price && proposals.hasBaggage.length || proposals.otherBaggage.length == 0 %}
          <div class="tariffs__item {{ baggage_expanded  ?  'tariffs__item--active' : '' }}" role="hasBaggage">
            <Baggage bags={{proposals.hasBaggage[0].worstBags}} tariff={{'hasBaggage'}} debug={{debug}} />
            {% if proposals.otherBaggage.length %}
              <strong class="tariffs__price-text">
                <span>{{ baggage_price > 0 ? ' + ' : ' - '}}</span>
                <div class="currency_font currency_font--{{currency}}">
                  {{ baggage_price | price_to_currency}}
                </div>
              </strong>
            {% endif %}
          </div>
        {% endif %}
      </div>
    </div>
    {% if kviku_params %}
      <form role="credit-form" action="https://kviku.ru/partner/sign" method="POST" target="_blank">
        {% for p of kviku_params %}
          <input type="hidden" name="{{ p.key }}" value="{{ p.value }}">
        {% endfor %}
        <input type="hidden" name="price" value="{{ baggage_expanded ? proposals.hasBaggage[0].unified_price :  proposals.otherBaggage[0].unified_price }}">
      </form>
    {% endif %}
    {% if !baggage_expanded && proposals.otherBaggage.length %}
      <Proposals main_button_text={{main_button_text}}
                 buy_button_text={{buy_button_text}}
                 kviku_params={{kviku_params}}
                 proposals={{proposals.otherBaggage}}
                 debug={{debug}}
                 main_proposal={{proposals.otherBaggage[0]}} />
    {% else %}
      <Proposals main_button_text={{main_button_text}}
                 kviku_params={{ kviku_params }}
                 buy_button_text={{buy_button_text}}
                 proposals={{proposals.hasBaggage}}
                 debug={{debug}}
                 main_proposal={{proposals.hasBaggage[0]}} />
    {% endif %}
    <div class="ticket-action-sharing">
      <div class="ticket-action-sharing__text">{{ copy_link_text }}</div>
      <div class="ticket-action-sharing-input-wrapper">
        <input type="text" class="ticket-action-sharing__input" role="copy-link-input" readonly="readonly" value="{{ baggage_expanded ? proposals.hasBaggage[0].url :  proposals.otherBaggage[0].url }}" />
      </div>
    </div>
  </div>

  <div class="ticket-details" role="ticket-details">
    {% if carrier_code %}
    <div class="ticket-action-airline-container">
      <a href="{{ baggage_expanded ? proposals.hasBaggage[0].deeplink :  proposals.otherBaggage[0].deeplink}}" class="ticket-action-airline" role="main-proposal-link" target="_blank">
        <img src="{{ wide_logo_path }}" class="ticket-action-airline__logo ticket-action-airline__logo--not_mobile"/>
        <img src="{{ square_logo_path }}" class="ticket-action-airline__logo ticket-action-airline__logo--mobile"/>
      </a>
    </div>
    {% endif %}
    {% for index, fly of segments %}
      <div class="flight flight--{{ fly.direction }}">
        <div class="flight-header">
          <span class="flight-header__direction">{{ fly.direction_text }} · </span>
          <span class="flight-header__date">
            {{ fly.mini.depart_day_month_year}}
          </span>
          <span class="flight-header__mobile_time">
            {% unsafe fly.mini.duration %}
          </span>
        </div>
        <div class="flight-brief">
          {% if fly.show_kraynov_filter %}
            <div class="custom_checkbox custom_checkbox--touch_clean kraynov-filter-checkbox">
              <input type="checkbox" checked="{{ fly.segment_hash_selected }}"
              role="kraynov-hash" data-is-kraynov-checkbox="true" value="{{ fly.segment_hash }}" id="kraynov_{{fly.segment_hash}}"/>
              <label class="label-block name g-text-overflow" data-is-kraynov-checkbox="true" for="kraynov_{{fly.segment_hash}}"></label>
              <div class="kraynov-filter-tooltip">
                {{ (fly.direction == 'depart') ? __('ticket.segment_filter.departure') : __('ticket.segment_filter.return') }}
              </div>
            </div>
          {% endif %}

          <div class="flight-brief-departure">
            <time class="flight-brief-time">
              {{ fly.mini.depart_time }}<span class="flight-brief-time__meridiem"> {{ fly.mini.depart_meridiem }}</span>
            </time>
            <div class="flight-brief-date">
              <span class="flight-brief-date__day">{{ fly.mini.depart_day }}</span> {{ fly.mini.depart_month_short }}
            </div>
            <div class="flight-brief-city" title="{{ fly.mini.depart_name }}">
              <span class="flight-brief-city__name">{{ fly.mini.depart_name }}</span>
              <span class="flight-brief-city__iata">{{ fly.mini.depart_iata }}</span>
            </div>
          </div>

          <section class="flight-brief-layovers">
            <header class="flight-brief-layovers__flight_time">
              {% unsafe fly.mini.duration %}
            </header>
            <main class="flight-brief-layovers__list">
              <div class="flight-brief-layovers__list-wrapper">
                <div class="flight-brief-layover">
                  <div class="flight-brief-layover__iata">
                      <span>{{ fly.mini.depart_iata }}</span>
                  </div>
                  <div class="flight-brief-layover-tooltip">
                    <div class="flight-brief-layover-tooltip__airport_name">
                      {{ __('ticket.airport') }}&nbsp;{{ fly.mini.depart_name }}
                    </div>
                  </div>
                </div>
                {% for airport of fly.mini.airports %}
                  {% if airport.change_airports %}
                  <div class="flight-brief-layover flight-brief-layover--change_airports">
                    <div class="flight-brief-layover__iata flight-brief-layover__iata--change_airports">
                        <span>
                          <span>{{ airport.change_airports[0].iata }}</span>
                          <span>{{ airport.change_airports[1].iata }}</span>
                        </span>
                    </div>
                    <div class="flight-brief-layover-tooltip flight-brief-layover-tooltip--change_airports">
                      <div class="flight-brief-layover-tooltip__airport_name">
                        {{ airport.change_airports[0].name }} ⟶ {{ airport.change_airports[1].name }}
                      </div>
                      {% if airport.connection_time %}
                      <div class="flight-brief-layover-tooltip__duration">
                        {{ __('ticket.change_airports') }} — {% unsafe airport.connection_time %}
                      </div>
                      {% endif %}
                    </div>
                  </div>
                  {% else %}
                  <div class="flight-brief-layover">
                      <div class="flight-brief-layover__iata">
                          <span>{{ airport.iata }}</span>
                      </div>
                    <div class="flight-brief-layover-tooltip">
                      <div class="flight-brief-layover-tooltip__airport_name">
                        {{ __('ticket.airport') }}&nbsp;{{ airport.name }}
                      </div>
                      {% if airport.connection_time %}
                      <div class="flight-brief-layover-tooltip__duration flight-brief-layover-tooltip__duration{{ airport.layover_modifier }}">
                        {% if airport.night_layover %}
                          {{ __('ticket.night_layover') }}
                        {% else %}
                        {% if airport.day_layover %}
                          {{ __('ticket.day_layover') }}
                        {% else %}
                          {{ __('ticket.layover') }}
                        {% endif %}
                        {% endif %}
                        — {% unsafe airport.connection_time %}
                      </div>
                      {% endif %}
                    </div>
                  </div>
                  {% endif %}
                {% endfor %}

                <div class="flight-brief-layover">
                  <div class="flight-brief-layover__iata">
                    <span>{{ fly.mini.arrival_iata }}</span>
                  </div>
                  <div class="flight-brief-layover-tooltip">
                    <div class="flight-brief-layover-tooltip__airport_name">
                      {{ __('ticket.airport') }}&nbsp;{{ fly.mini.arrival_name }}
                    </div>
                  </div>
                </div>
              </div>
            </main>
            {% if fly.mini.stops_count == 0 %}
            <footer class="flight-brief-layovers__direct_flight">
              {{ __('search_results.layovers_direct') }}
            </footer>
            {% endif %}
          </section>
          <div class="flight-brief-arrival">
            <div class="flight-brief-date">
              <span class="flight-brief-date__day">{{ fly.mini.arrival_day }}</span>
              {% if fly.mini.next_day_fly %}
                <span class="flight-brief-date__next_day">{{ fly.mini.diff_day }}</span>
              {% endif %}
              {{ fly.mini.arrival_month_short }}
            </div>
            <time class="flight-brief-time">
              {{ fly.mini.arrival_time }}<span class="flight-brief-time__meridiem"> {{ fly.mini.arrival_meridiem }}</span>
              {% if fly.mini.next_day_fly %}
                <span class="flight-brief-date__next_day flight-brief-date__next_day--mobile">{{ fly.mini.diff_day }}</span>
              {% endif %}
            </time>
            <div class="flight-brief-city" title="{{ fly.mini.arrival_name }}">
              <span class="flight-brief-city__name">{{ fly.mini.arrival_name }}</span>
              <span class="flight-brief-city__iata">{{ fly.mini.arrival_iata }}</span>
            </div>
          </div>
        </div>

        <Baggage bags={{ baggage_expanded ? proposals.hasBaggage[0].bags && proposals.hasBaggage[0].bags[index] && proposals.hasBaggage[0].bags[index]  : proposals.otherBaggage[0].bags && proposals.otherBaggage[0].bags[index] && proposals.otherBaggage[0].bags[index]}} />
        <div class="flight-details">
          {% for fly.flights %}
            {% if stop %}
              {% if stop.same_airport_layover %}
                <div class="flight-details-layover">
                  <div class="flight-details-layover__place">
                    {{ __('search.tickets.tickets.connection') + ' ' + __('search.tickets.tickets.at') + ' ' + stop.same_airport_layover.title + ' ' + stop.same_airport_layover.iata }}
                  </div>
                  <time class="flight-details-layover__time">
                    {% unsafe stop.duration %}
                  </time>
                </div>
              {% else %}
                <div class="flight-details-layover flight-details-layover--change_airports">
                  <div class="flight-details-layover__place flight-details-layover__place--change_airports">
                    {{ __('ticket.change_airports') + ' ' + stop.change_airports.arrival_airport + ' ⟶ ' + stop.change_airports.depart_airport }}
                  </div>
                  <time class="flight-details-layover__time">
                    {% unsafe stop.duration %}
                  </time>
                </div>
              {% endif %}
            {% endif %}
            <section class="flight-details-leg">
              <div class="flight-details-leg__airline">
                {% if carrier_code %}
                  <img src="{{ square_logo_path }}"/>
                {% endif %}
              </div>
              <div class="flight-details-leg-time">
                {{ depart_time }} − {{ arrival_time }}
                {% if next_day_messsage %}
                  <span class="flight-details-leg-time__warning">
                    &nbsp; {{ __('ticket.next_day_messsage') }} &nbsp; {% unsafe arrival_date_warning | weekDay_and_date %}
                  </span>
                {% endif %}

                <time class="flight-details-leg__duration">
                  {% unsafe duration %}
                </time>
              </div>
              <div class="flight-details-leg__direction">
                {{ depart_iata }}&nbsp;{{ depart_airport }}
                <span class="flight-details-leg__direction__right_arrow">&rarr;</span>
                <span class="flight-details-leg__direction__left_arrow">&larr;</span>
                {{ arrival_iata }}&nbsp;{{ arrival_airport }}
              </div>
              <div class="flight-details-leg__flight_details">
                {{ carrier_number }}
                · {{ carrier_name }}
                {% if aircraft %}
                · {{ aircraft }}
                {% endif %}

                <div class="flight-details-leg-ameneties">
                  {% if legroom %}
                    <span class="flight-details-leg-ameneties__legroom"></span>
                    {% endif %}
                  {% if wifi %}
                  <span class="flight-details-leg-ameneties__wifi"></span>
                  {% endif %}
                  {% if night_flight %}
                  <span class="flight-details-leg-ameneties__night_flight"></span>
                  {% endif %}
                  <div class="flight-details-leg-ameneties-tooltip">
                    {% if night_flight %}
                    <div class="flight-details-leg-ameneties-tooltip__night_flight">
                      {{ __('ticket.night_flight') }}
                    </div>
                    {% endif %}
                    {% if wifi %}
                    <div class="flight-details-leg-ameneties-tooltip__wifi">
                      {{ __('ticket.ameneties.wifi') }}
                    </div>
                    {% endif %}
                    {% if legroom %}
                    <div class="flight-details-leg-ameneties-tooltip__legroom">
                      {{ legroom + __('ticket.ameneties.legroom') }}
                    </div>
                    {% endif %}
                  </div>
                </div>
              </div>
            </section>

            <section class="flight-details-leg--mobile">
              <div class="leg-brief">
                <div class="leg-brief__flight">
                  {{ __('ticket.flight') }}: {{ carrier_number }}
                </div>
                <div class="leg-brief__carrier">
                  {{ carrier_name }}
                </div>
                <div class="leg-brief__time">
                  {{ __('ticket.flight_duration') }}: {% unsafe duration %}
                </div>
              </div>

              <div class="leg-logo">
                {% if carrier_code %}
                  <img class="leg-logo--not_mobile" src="{{ wide_logo_path }}"/>
                  <img class="leg-logo--mobile" src="{{ square_logo_path }}"/>
                {% endif %}
              </div>

              <div class="leg-details">
                <div class="leg-details-depart">
                  <div class="leg-details__time">
                    <div class="leg-details__time-clock">{{ depart_time }}</div>
                    <div class="leg-details__time-date">
                      {{ depart_day_weekday_month }}
                    </div>
                  </div>
                  <div class="leg-details__airport">
                    <div>{{ depart_city }}</div>
                    {{ depart_iata }}&nbsp;{{ depart_airport }}
                  </div>
                </div>

                <div class="leg-details-return">
                  <div class="leg-details__time">
                    {% if next_day_messsage %}
                      <span class="leg-details__time__warning">
                        &nbsp; {{ __('ticket.next_day_messsage') }} &nbsp; {% unsafe arrival_date_warning | weekDay_and_date %}
                      </span>
                    {% endif %}
                    <div class="leg-details__time-clock">
                      {{ arrival_time }}
                      {% if next_day_messsage %}
                        <span class="flight-brief-date__next_day flight-brief-date__next_day--mobile">{{ diff_day }}</span>
                      {% endif %}
                    </div>
                    <div class="leg-details__time-date {{ next_day_messsage? 'leg-details__time-date--next_day' : ''}}">
                      {{ arrival_day_weekday_month }}
                    </div>
                  </div>
                  <div class="leg-details__airport">
                    <div>{{ arrival_city }}</div>
                    {{ arrival_iata }}&nbsp;{{ arrival_airport }}
                  </div>
                </div>

              </div>

              <div class="flight-details-leg-ameneties">
                {% if legroom %}
                  <span class="flight-details-leg-ameneties__legroom"></span>
                  {% endif %}
                {% if wifi %}
                <span class="flight-details-leg-ameneties__wifi"></span>
                {% endif %}
                {% if night_flight %}
                <span class="flight-details-leg-ameneties__night_flight"></span>
                {% endif %}
                <div class="flight-details-leg-ameneties-tooltip">
                  {% if night_flight %}
                  <div class="flight-details-leg-ameneties-tooltip__night_flight">
                    {{ __('ticket.night_flight') }}
                  </div>
                  {% endif %}
                  {% if wifi %}
                  <div class="flight-details-leg-ameneties-tooltip__wifi">
                    {{ __('ticket.ameneties.wifi') }}
                  </div>
                  {% endif %}
                  {% if legroom %}
                  <div class="flight-details-leg-ameneties-tooltip__legroom">
                    {{ legroom + __('ticket.ameneties.legroom') }}
                  </div>
                  {% endif %}
                </div>
              </div>
            </section>
          {% endfor %}
        </div>
      </div>
    {% endfor %}
    <div class="flight-baggage-terms">
      <sup>*</sup>
      <p class="flight-baggage-terms__info">{{ __('ticket.baggage.terms') }}</p>
    </div>
    <div class="ticket-details-toggler"></div>
  </div>
  </div>
</div>
