_extend = require('lodash/extend')
_isUndefined = require('lodash/isUndefined')
_some = require('lodash/some')
_has = require('lodash/has')
_last = require('lodash/last')
_first = require('lodash/first')

is_open_jaw = (segments) ->
  return true if segments.length > 2
  if segments.length is 2
    first_segment = _first(segments)
    last_segment = _last(segments)

    if _has(first_segment, 'flight')
      first_segment_departure = first_segment.flight[0].departure
      first_segment_arrival = _last(first_segment.flight).arrival
      last_segment_departure = last_segment.flight[0].departure
      last_segment_arrival = _last(last_segment.flight).arrival

      return (first_segment_departure != last_segment_arrival) or
        (first_segment_arrival != last_segment_departure)
    return (first_segment.origin != last_segment.destination) or
        (first_segment.destination != last_segment.origin)
  false


module.exports =
  min: (collection) ->
    minimal = collection[0]
    for val in collection.slice(1)
      minimal = val if val < minimal
    minimal

  max: (collection) ->
    maximal = collection[0]
    for val in collection.slice(1)
      maximal = val if val > maximal
    maximal

  generate_id: -> (0|Math.random()*9e6).toString(36)

  is_ie: navigator.appName == 'Microsoft Internet Explorer'
  is_ff: navigator.userAgent.toLowerCase().indexOf('firefox') != -1

  get_ie_version: ->
    version = -1
    ua = navigator.userAgent
    re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})")
    if re.exec(ua) != null
      version = parseFloat( RegExp.$1 )
    return version

  page_is_visible: ->
    !!!(document.hidden || document.webkitHidden)

  transition_events_supported: ->
    @page_is_visible() && _some(['webkitTransition', 'MozTransition', 'OTransition', 'transition'], (t) -> !_isUndefined(document.body.style[t]))

  is_open_jaw: is_open_jaw

  key_codes:
    esc: 27
    tab: 9
  clean_node: (node) ->
    node.removeChild(child) while(child = node.firstChild)

  filters_state_by_params: (params) ->
    filters_state = {}
    if !is_open_jaw(params.segments) && params.segments[0].origin_city_iata && params.segments[0].origin_city_iata != params.segments[0].origin
      _extend(filters_state, {departure_airports: [params.segments[0].origin]})

    if !is_open_jaw(params.segments) && params.segments[0].destination_city_iata && params.segments[0].destination_city_iata != params.segments[0].destination
      _extend(filters_state, {arrival_airports: [params.segments[0].destination]})

    filters_state

  is_front_page: ->
    document.location.pathname.split('/')[1] == ''
