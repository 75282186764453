{% import FilterTitle from '../base/title.monk' %}

<div class="filter" >
  {% if title %}
    <FilterTitle title="{{title}}"/>
  {% endif %}
  <div class="filter-control-container">
    <div class="control control--expanded" role="filter-body">
      {% for id, option of options %}
        <div class="custom_checkbox">
          <input type="radio" name="{{ id }}" value="1" role="filter-option" id="{{ name + '_' + id }}" checked="{{selectedId == id}}"/>
          <label class="label-block name airlines-label g-text-overflow" for="{{ name + '_' + id }}">
            {{ option }}
          </label>
        </div>
      {% endfor %}
    </div>
  </div>
</div>
