import DOMComponent from 'shared/components/base'
import Template from './header.monk'
import stickySetter from 'shared/lib/sticky_setter.js'
import utils from 'shared/lib/utils.coffee'
import { offsetTop } from 'shared/lib/offset_top.js'
import ResizeWatcher from 'shared/lib/resize_watcher.coffee'
import PoweredBy from 'shared/components/powered_by/powered_by'

export default class Header extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'header',
      render: { template: Template },
      cssx: {
        scope: 'body',
        styles: {
          '.TPWL-template-header': {
            background: window.TPWLCONFIG.color_scheme.bg
          },
          '.TPWL-header-content': {
            background: window.TPWLCONFIG.color_scheme.bg
          },
          '.TPWL-header-content__descrition': {
            color: window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.TPWL-header-content .TPWL-header-content__label': {
            color: window.TPWLCONFIG.color_scheme.text_contrast
          }
        }
      }
    }
  }

  constructor (node, options = {}) {
    super(node, options)
    this.node = node
    this.stickyHeight = ResizeWatcher.width < 440 ? 50 : 110
    this.stickyElement = stickySetter.addElement(node, 'TPWL-template-header--sticky', null, this.stickyHeight)
    utils.is_front_page() && new PoweredBy().init('.TPWL-template-wrapper', {align: "center"})
  }

  _initEvents (dispatcher) {
    var scrolled = false
    var searchStarted = false
    var mewtwoInitialized = false

    var scrollCallback = () => {
      if (!scrolled && searchStarted && mewtwoInitialized) {
        scrolled = true
        setTimeout(() => {
          if(this.node.getBoundingClientRect){
            let rect = this.node.getBoundingClientRect()
            ResizeWatcher.isMobile
              ? window.scrollTo(0, offsetTop(this.node) + this.node.offsetHeight - (this.stickyHeight - 1))
              : window.scrollTo(0, rect.bottom - rect.height)
          }
        }, 50)
      }
    }

    dispatcher.on('page_changed', (event, {old: oldPage, new: newPage}) => {
      if (this.stickyElement === undefined) return false
      if (newPage !== 'serp') {
        stickySetter.unstick(this.stickyElement)
        stickySetter.lock(this.stickyElement)
      } else {
        stickySetter.unlock(this.stickyElement)
      }
    })

    dispatcher.on('mewtwo_init', () => {
      mewtwoInitialized = true
      scrollCallback()
    })

    dispatcher.on('start_search', () => {
      searchStarted = true
      scrollCallback()
    })

    dispatcher.on('card_rendering', () => {
      // only fo mobile we need scroll to card
      if (ResizeWatcher.isMobile) {
        scrolled = false
        scrollCallback()
      }
    })

    dispatcher.on('places_restored', (event, params) => {
      if (utils.is_open_jaw(params.segments)) {
        document.querySelector('[role="main_header"]').classList.add('TPWL-template-header--multi')
      }
    })
  }
}
