import _defaultsDeep from 'lodash/defaultsDeep'
import InformerWithModal from 'shared/components/informer_with_modal/informer_with_modal'
import Template from './user_settings.monk'
import config from 'shared/lib/tp_config.js'
import converter from 'shared/lib/converter.js'
import cookies from 'shared/lib/cookies.coffee'
import dispatcher from 'shared/lib/dispatcher'
import metrics from 'shared/lib/metrics.coffee'
import Device from 'shared/lib/device.js'
import previewParamsParser from 'shared/lib/preview_params.coffee'

window.TP_WL_LOCALE = (LOCALE).toUpperCase(); // Hack for plugins

export default class UserSettings extends InformerWithModal {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'user_settings',
      manual_init: true,
      shared_state: true,
      render: {
        template: Template,
        options: {filters},
        replace: true
      },
      cssx: {
        scope: '.TPWL-widget.user-settings',
        styles: {
          '.user-settings-informer': {
            'background-color': window.TPWLCONFIG.color_scheme.bg
          },
          '.user-settings-selector-currencies-list__item-block': {
            'background-color': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.user-settings-informer__currency': {
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          },

          '.user-settings-informer:after': {
            background: `url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%208%205%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EArrow-down%3C%2Ftitle%3E%3Cpath%20d%3D%22M.34.34C.803-.11%201.537-.11%202.015.325l1.963%201.908L5.992.324c.46-.435%201.212-.435%201.673.034.46.452.444%201.188-.034%201.64L4.78%204.676c-.46.435-1.195.435-1.64-.017L.36%201.963C.118%201.747%200%201.446%200%201.144%200%20.844.12.56.34.34z%22%20fill%3D%22${encodeURIComponent(window.TPWLCONFIG.color_scheme.text_contrast)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E')`
          }
        }
      },
      informerPrefix: 'user-settings',
      state: {
        reload_currencies: false
      }
    }, super.defaultOptions())
  }

  static getCurrency () {
    var currency = 'rub'
    if (previewParamsParser.is_preview) {
      currency = (this.state.currency || window.TPWLCONFIG.currency).toLowerCase()
    } else {
      currency = (this.state.currency || cookies.get('currency') || window.TPWLCONFIG.currency).toLowerCase()
    }
    return currency
  }

  static getLocale () {
    return LOCALE.toLowerCase()
  }

  static sortLocales (locales, list) {
    const sortArr = Object.keys(locales).filter(elem => list.indexOf(elem) === -1)
    return [...list, ...sortArr].reduce((acc, locale) => [...acc, {[locale]: locales[locale]}], [])
  }

  static setCurrency (code) {
    this.state.currency = code.toUpperCase()
    cookies.set('currency', this.state.currency, 'unlimited')
    this.broadcast('refresh')
  }

  static setLocale (code) {
    this.state.locale = code.toLowerCase()
    cookies.set('locale', this.state.locale, 'unlimited')
    this.broadcast('refresh')
  }

  static setFlag (code) {
    this.state.flag = config.LOCALES_INDEX[code.toLowerCase()].flag
    this.broadcast('refresh')
  }

  static initializeComponent (dispatcher) {
    dispatcher.on('currency_updated', (event, code) => { this.setCurrency(code) })
    this.setLocale(LOCALE)
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    this.refresh()
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)

    view.on('click', '[role="currency-switcher"]', (event, eventTarget) => {
      this.hideModal()
      if (eventTarget.dataset.currency === this.state.currency) {
        return false
      }
      metrics.reach_goal('CURRENCY_CHANGE')
      converter.currency = eventTarget.dataset.currency.toLowerCase()
      this._dispatcher.send('currency_updated', eventTarget.dataset.currency.toLowerCase(), 'user_settings')
    })
    view.on('click', '[role="locale-switcher"]', (event) => {
      metrics.reach_goal('LANG_CHANGE')
      this.hideModal()
    })
    view.on('change', '[role="mobile-locale-switcher"]', (event) => {
      this.state.locale = event.target.querySelector('option:checked').value
      UserSettings.setFlag(this.state.locale)
      this.refresh()
      event.target.value = this.state.locale
    })
    view.on('change', '[role="mobile-currency-switcher"]', (event) => {
      this.state.currency = event.target.querySelector('option:checked').value
      this.refresh()
      event.target.value = this.state.currency
    })
    view.on('click', '[role="locale-fast-switcher"]', (event, eventTarget) => {
      const locale = eventTarget.dataset.locale
      UserSettings.setLocale(locale)
      window.location.href = filters.asParam(`locale=${locale}`)
    })
    view.on('click', '[role="user-settings-informer-mobile"]', (event, eventTarget) => {
      dispatcher.send('modal_opened', {id: 'user_settings', closeFunc: () => { this.closeModal() }, openFunc: () => { this.openModal() }})
      this.openModal()
    })
    view.on('click', '[role="user-settings-modal-close"]', (event) => {
      dispatcher.send('modal_closed', {id: 'user_settings'})
      this.closeModal()
    })
    view.on('submit', '[role="user-settings-mobile"]', (event, eventTarget) => {
      event.preventDefault()
      UserSettings.setLocale(this.state.locale)
      UserSettings.setCurrency(this.state.currency)
      window.location.href = filters.asParam(`locale=${this.state.locale}&currency=${this.state.currency}`)
    })
    view.on('wheel', '[role="scrolling_list"]', (event, eventTarget) => {
      let scrollTop = eventTarget.scrollTop
      let scrollHeight = eventTarget.scrollHeight
      let height = eventTarget.clientHeight
      let wheelDelta = event.deltaY
      let isDeltaPositive = wheelDelta > 0

      if (isDeltaPositive && wheelDelta > scrollHeight - height - eventTarget.scrollTop) {
        eventTarget.scrollTop = scrollHeight
        return this.cancelScrollEvent(event)  // if scrolled to the bottom, cancel scroll event
      } else if (!isDeltaPositive && -wheelDelta > scrollTop) {
        eventTarget.scrollTop = 0
        return this.cancelScrollEvent(event)  // if scrolled to the top, cancel scroll event
      }
    })
  }

  cancelScrollEvent (event) {
    event.stopImmediatePropagation()
    event.preventDefault()
    event.returnValue = false
    return false
  }

  openModal () {
    document.querySelector('.user-settings').classList.toggle('user-settings--shown')
    document.ontouchmove = (event) => event.preventDefault()
    Device.lockScroll()
  }

  closeModal () {
    this.state.currency = null
    this.state.currency = UserSettings.getCurrency()
    this.state.locale = UserSettings.getLocale()
    this.state.flag = UserSettings.setFlag(this.state.locale)
    this.refresh()
    Device.unlockScroll()
    document.ontouchmove = null
    document.querySelector('.user-settings').classList.toggle('user-settings--shown')
  }
}
const sortListLocales = ['en', 'en_us']

UserSettings.state = {
  currency: false,
  locale: LOCALE,
  currencies: config.CURRENCIES.order.reduce((acc, elem) => [...acc, {[elem]: config.CURRENCIES.translations[elem]}], []),
  locales: UserSettings.sortLocales(config.LOCALES_INDEX, sortListLocales),
  flag: config.LOCALES_INDEX[LOCALE] ? config.LOCALES_INDEX[LOCALE].flag : '',
  reload_currencies: false
}

UserSettings.initializeComponent(dispatcher)

var filters = {
  uppercase (text) {
    return typeof text === 'string' ? text.toUpperCase() : text
  },
  lowercase (text) {
    return typeof text === 'string' ? text.toLowerCase() : text
  },
  language (locale) {
    return config.LOCALES_INDEX[locale.toLowerCase()].language
  },
  asParam (paramString) {
    let key = paramString.split('=')[0]
    let [href, fragment] = window.location.href.split('#')
    href = href.replace(new RegExp(`${key}=[^&]+(&|$)`), '').replace(/[&?]$/, '')
    href = href + (href.indexOf('?') === -1 ? '?' : '&') + paramString
    return (fragment && fragment !== '') ? [href, fragment].join('#') : href
  }
}
