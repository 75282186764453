dispatcher = require('shared/lib/dispatcher')
if window != window.top
  window.iframe_messenger =
    prev_height: 0
    prev_width: 0
    prev_location: ''
    prev_scroll: 0
    events: []
    set_modifier: 1
    init: ->
      @events_loop()
      dispatcher.on 'modal_opened', (e, data) =>
        @events.push ['modal_opened'] if data && data.id != 'filters'
      dispatcher.on 'modal_closed', => @events.push ['modal_closed']
      # @links_loop()
    scroll: (width, height) ->
      @events.push ['scrollTop', @prev_scroll = height] if @prev_scroll != height
    links_loop: -> #FIXME
      # $('a.TPWL-hotels-grid-title[target="_blank"]').attr("target", "_self")
      # $('a.TPWL-hotels-grid-photos-item-typeMain[target="_blank"]').attr("target", "_self")
      # $('.TPWL-hotels-one-prices-best a[target="_self"]').attr("target", "_blank")
      # $('.TPWL-hotels-one-rooms-item-go a[target="_self"]').attr("target", "_blank")
      # setTimeout (=> @links_loop()), 1000
    events_loop: ->
      # @receive_events()
      @send_events()
      setTimeout (=> @events_loop()), 250
    send_events: ->
      @body ||= document.body
      if @body
        if @set_modifier
          @body.classList.add("TPWL--iframe")
          @set_modifier = 0
        height = @body.offsetHeight
        width = @body.scrollWidth
        @events.push ['height_changed', @prev_height = height] if @prev_height != height
        @events.push ['width_changed', @prev_width = width] if @prev_width  != width

      location_path = window.location.pathname + window.location.search
      @events.push ['location_changed', @prev_location = location_path] if @prev_location != location_path

      window.parent.postMessage(JSON.stringify(events: @events), "*") if @events.length > 0
      @events = []
#     receive_events: ->
#       events = window.parent.tpcwl_machine.pop_events()
#       console.log events if events.length > 0

  iframe_messenger.init()
