dispatcher = require('shared/lib/dispatcher')
metrics = require('shared/lib/metrics.coffee')

Monkberry = require('monkberry')
Template = require('shared/components/system_messages/system_messages.monk')
require('monkberry-events')

preroll_type_classes =
  error_page: 'preroll-container--error'

error_types =
  wrong_dates:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__wrong_dates"
    message: __('errors.wrong_dates.message')
    description:
      text: __('errors.wrong_dates.description')
  bad_search_params:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__bad_search_params"
    message: __('errors.bad_search_params.message')
    description:
      text: __('errors.bad_search_params.description')
    error_type: 'bad_search_params'
  search_failed:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__search_failed"
    message: __('errors.search_failed.message')
    description:
      text: __('errors.search_failed.description')
  empty_tickets:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__empty_tickets"
    message: __('errors.empty_tickets.message')
    description:
      text: __('errors.empty_tickets.description')
    error_type: 'empty_tickets'
  filtered_all_tickets:
    message_type: "#{preroll_type_classes.error_page}__filtered_all_tickets"
    message: ""
    description:
      text: __('errors.filtered_all_tickets.description')
      additional_description: __('errors.filtered_all_tickets.additional_description')
    error_type: 'filtered_all_tickets'
  google_recaptcha:
    message_type: "#{preroll_type_classes.error_page} #{preroll_type_classes.error_page}__google_recaptcha"
    message: __('errors.google_recaptcha.message')
    description:
      text: ""
    error_type: 'google_recaptcha'

message = (container_el) ->
  messages_obj = Monkberry.render(Template, container_el)
  messages_obj.update {
    message_type: ''
    message: ''
  }


  tickets_received = false

  set_error = (params)->
    messages_obj.update({
      message_type: params.message_type
      message: params.message
      description:
        text: params.description.text
        additional_description: params.description.additional_description
      error_type: params.error_type
    })

  showMessage = -> container_el.classList.remove('hidden')

  dispatcher.on('wrong_search_dates', ->
    set_error(error_types.wrong_dates)
    dispatcher.send('systemMessageError')
    showMessage()
    document.body.classList.add("TPWL-widget--system_error")
  )

  dispatcher.on 'search_not_started', ->
    dispatcher.send('systemMessageError')
    container_el.classList.add('hidden')

  dispatcher.on('start_search', (event, params) ->
    container_el.classList.add('hidden')
    tickets_received = false
  )
  dispatcher.on "udefined_on_url", ->
    dispatcher.send('systemMessageError')
    container_el.classList.add('hidden')

  dispatcher.on "no_dates_url", ->
    dispatcher.send('systemMessageError')
    container_el.classList.add('hidden')

  dispatcher.on('search_failed', (event, {request_id, data}) ->
    switch data?.status
      when 400
        set_error(error_types.bad_search_params)
      when 403
        set_error(error_types.google_recaptcha)
        dispatcher.send('systemMessageError403')
      else
        set_error(error_types.search_failed)

    dispatcher.send('systemMessageError')
    showMessage()
    document.body.classList.add("TPWL-widget--system_error")
  )

  dispatcher.on('search_finished', ->
    referrer = document.referrer
    if !tickets_received
      set_error(error_types.empty_tickets)
      metrics.reach_goal('NO_TICKETS_RECEIVED', {referrer: referrer, params: window._location})
      dispatcher.send('systemMessageError')
      showMessage()
      document.body.classList.add("TPWL-widget--system_error")
  )

  dispatcher.on('tickets_updated', (event, {request_id, tickets})->
    container_el.classList.add('hidden')
    if tickets_received and tickets.length == 0
      set_error(error_types.filtered_all_tickets)
      showMessage()
  )

  dispatcher.on('first_tickets_arrived', ->
    tickets_received = true
  )

  messages_obj.on('click', '[role="reset_filters"]', (event, element) ->
    dispatcher.send('reset_filters')
  )


module.exports = message
