import Params from 'flights/lib/params.js'
import MewtwoBase from 'shared/components/mewtwo/mewtwo.js'
import searchParams from 'flights/components/params_parser/search_params.coffee'
import utils from 'shared/lib/utils.coffee'

const TRIP_CLASS_MAP = {
  'Y': 'economy',
  '0': 'economy',
  '': 'economy',
  'C': 'business',
  '1': 'business',
  'b': 'business' // short params notation
}

export default class MewtwoFlights extends MewtwoBase {
  initConfig (config = this.state) {
    let params = Params.parsed || Params.cookie
    let formTypeParams = {
      'trip_class': TRIP_CLASS_MAP[params.trip_class],
      'active_tab': 'avia'
    }
    super.initConfig(config = this.state, params, formTypeParams)

    // TODO: выпилить костыль когда Mewtwo получит внешний API для динамического изменения параметров.
    if (window.TP_FORM_SETTINGS[config.handle].origin.iata === 'сол' ||
      window.TP_FORM_SETTINGS[config.handle].origin.iata === 'СОЛ') {
      window.TP_FORM_SETTINGS[config.handle].origin.name = 'Старый Оскол'
      window.TP_FORM_SETTINGS[config.handle].origin.country_name = 'Россия'
    }

    if (window.TP_FORM_SETTINGS[config.handle].destination.iata === 'сол' ||
      window.TP_FORM_SETTINGS[config.handle].destination.iata === 'СОЛ'
    ) {
      window.TP_FORM_SETTINGS[config.handle].destination.name = 'Старый Оскол'
      window.TP_FORM_SETTINGS[config.handle].destination.country_name = 'Россия'
    }

    var segments = searchParams.get().segments
    var multi = utils.is_open_jaw(segments)
    if (multi) {
      var segment = {}
      for (let i = 1; i < segments.length; i++) {
        segment = {
          'origin': {'iata': segments[i].origin, 'name': ''},
          'destination': {'iata': segments[i].destination, 'name': ''},
          'date': segments[i].date
        }
        window.TP_FORM_SETTINGS[config.handle]['segments'].push(segment)
      }
      window.TP_FORM_SETTINGS[config.handle]['open_multi'] = multi
    }
  }
}
