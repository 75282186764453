{% import Fade from 'shared/components/fade/fade.monk' %}

<div class="layout-manager">
  <Fade/>
  <div is="debug"></div>
  <div is="adsense"></div>
  <div class="fake-sticky-informer"></div>
  <div class="search_progressbar-container" data-title="{{ __('search_progressbar.flight_title') }}" is="search_progressbar"></div>
  <!-- <div id="dl" is="dispatcher_logger"></div> -->
  <div id="boundaries"></div>
  <div id="tickets"></div>
  <div class="js-informer-restart-search-trigger expired-search-plate hidden" is="expired_search"></div>
  <div class="search_results-filters__swipe-trigger" role="swipe-trigger"></div>
  <div class="search_results-wrapper js-results-inner-wrapper">
    <div class="filters_wrapper" is="filters_wrapper" role="filters_wrapper"></div>
    <div class="search_results-wrapper-tickets search_results-wrapper-tickets--right" role="right-column">
      <div role="calendar_line_one_way" is="calendar_line_one_way"></div>
      <div role="calendar_line_round_trip" is="calendar_line_round_trip"></div>
      <div role="calendar" is="calendar" class="hidden"></div>
      <div role="calendar_one_way" is="calendar_one_way"></div>
      {% if googletagIsAvailable %}
        <!-- /68258039/729x250_Top_JR -->
        <div id="div-gpt-ad-1603115806013-0" class="google-ad" style="width: 714px; height: 235px; margin-bottom: 20px; display: none">
          <script>
            googletag.cmd.push(function() { googletag.display("div-gpt-ad-1603115806013-0"); });
          </script>
        </div>
      {% endif %}
      <div class="search-results js-search-results" role="search-results">
        <div role="ad-top-container" id="ad_top_container"></div>
        <div class="tickets-container js-tickets-container">
          <!-- <div class="ad-top-container" id="ad_top_container"></div> -->
          <!-- <div class="ad-top-container" id="ad_top_container"></div> -->
          <!-- <div class="js-highlight-not-found"></div> -->
          <!-- <div class="js-top-ticket top-ticket" is="top_ticket"></div> -->
          <div class="show_filters_wrapper hidden" role="show_filters" is="show_filters_button"></div>
          <div class="calendar_button_m_wrapper" role="m_calendar_show" is="calendar_button_m"></div>
          <div class="hidden" is="airports_precheck"></div>
          <div class="hidden" is="highlighted_ticket"></div>
          <div class="system-messages" is="system_messages"></div>
          <div class="google_recaptcha" is="google_recaptcha"></div>
          <div class="hidden" is="fake_ticket"></div>
          <div class="hidden" is="fake_ticket"></div>
          <div class="hidden" is="fake_ticket"></div>
          <div class="" role="tickets_container"></div>
          <div class="hidden" is="best_tickets"></div>
        </div>
        <div class="hidden" is="footer_pagination"></div>
        <div id="ad_bottom_container"></div>
      </div>
    </div>
    {% if googletagIsAvailable %}
      <!-- /68258039/160x600-JR -->
      <div id="div-gpt-ad-1584004567577-0" class="google-ad" style="width: 160px; height: 600px; margin-top: 20px; margin-left: 20px; display: none">
        <script>
          googletag.cmd.push(function() { googletag.display("div-gpt-ad-1584004567577-0"); });
        </script>
      </div>
    {% endif %}
    {% if yaAdfoxIsAvailable %}
      <!--yandex_aviasales-ads-->
      <!--Площадка: TravelPayouts / Тестовый баннер / Flights / Небоскрёб-->
      <!--Тип баннера: 160х600 Небоскрёб на вайт-лейбле-->
      <div class="yandex-ad" style="width: 160px; height: 600px; margin-top: 20px; margin-left: 20px;"></div>
    {% endif %}
  </div>
  <!-- <div is="god_mode"></div> -->
</div>
