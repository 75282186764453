import Template from './filters_wrapper.monk'
import DOMComponent from 'shared/components/base'

export default class FiltersWrapper extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'filters_wrapper',
      render: {
        template: Template
      },
      state: {
        flightsCounter: '',
        ticketsCount: 0,
        ticketsTotal: 0
      }
    }
  }
  constructor (containerNode, options = {}) {
    super(containerNode, options)
  }

  _initDOMEvents (view) {
    view.on('click', '[role="close_filters"]', (event, target) => {
      this._dispatcher.send('filters_popup_closed')
    })
    view.on('click', '.js-reset-filters', (event, target) => {
      this._dispatcher.send('reset_filters')
    })
  }

  _updateCounter () {
    this.state.flightsCounter = __('filters.counter.flights')
      .replace('%{count}', this.state.ticketsCount)
      .replace('%{total}', this.state.ticketsTotal)
    this.refresh()
  }

  _initEvents (dispatcher) {
    const filtersWrapper = this.view.querySelector('[role="filters"]')
    dispatcher.on('start_search', (event, {request_id}) => {
      filtersWrapper.classList.remove('hidden')
      filtersWrapper.classList.add('disabled')
      filtersWrapper.classList.remove('enabled')
      this.state.ticketsCount = 0
      this.state.ticketsTotal = 0
      this._updateCounter()
    })
    dispatcher.on('systemMessageError', (event) => {
      filtersWrapper.classList.remove('no-shadow')
      filtersWrapper.classList.add('hidden')
    })

    dispatcher.on('fuzzy_tickets_updated', (event, {tickets}) => {
      this.state.ticketsTotal = tickets.length
      this._updateCounter()
    })

    dispatcher.on('tickets_updated', (event, {tickets}) => {
      this.state.ticketsCount = tickets.length
      this._updateCounter()
    })

    dispatcher.on('first_tickets_arrived', (event, {request_id}) => {
      filtersWrapper.classList.add('no-shadow')
      filtersWrapper.classList.remove('disabled')
      filtersWrapper.classList.add('enabled')
    })
    dispatcher.on('hide_passengers', (event) => {
      filtersWrapper.classList.remove('no-shadow')
    })
  }
}
