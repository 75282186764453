domain_detector = require('shared/lib/domain_detector.coffee')
cookies = require('./cookies.coffee')
SERVICE_REGEXP = /\.\$(\d+)/g
SERVICE_AB_MARKER = 1489
window.HANDLE_ALL_MARKERS ||= false

module.exports =
  default_marker: ''

  get: ->
    cookie_marker = cookies.get('marker')
    marker = if window.HANDLE_ALL_MARKERS then (cookie_marker || 'direct') else @fix(cookie_marker)
    @set(marker) unless cookie_marker == marker

    marker

  set: (value) ->
    expire = new Date(+(new Date) + 60 * 60 * 24 * 30 * 1e3)

    if(domain_detector.main_domain() != domain_detector.full_domain())
      cookies.remove('marker')
      cookies.remove('marker', '.' + domain_detector.full_domain())

    cookies.set('marker', value, expire.toUTCString(), domain_detector.main_domain())
    value

  fix: (marker) ->
    marker = (marker && marker.replace(/^\d{5,6}\.?/, '')) || @default_marker
    @inject_service_marker(SERVICE_AB_MARKER, "#{window.TPWLCONFIG.affiliate.marker}#{if !!marker then '.' + marker else ''}")

  handle_marker: (value) ->
    if window.HANDLE_ALL_MARKERS
      marker = Markerable.init(value, @get())
      @set(marker) if marker
    else
      (value && @set(@fix(value))) || @get()

  _new_marker: (value) -> value != @get()

  is_affiliate: (marker) -> (/^\d{5,}(\.|$)/).test marker

  inject_service_marker: (feature_number, marker) ->
    "#{String(marker).replace(SERVICE_REGEXP, '')}.$#{feature_number}"

##
## Simplified helios Markerable
## https://github.com/KosyanMedia/helios/blob/stoyan_markers_filter/web/static/js/common/utils/markerable.coffee
##
Markerable =
  VALID_CONTEXT_ADS_MARKERS: ['15468', '29086']

  CONTEXTUAL_ADS_DOMAINS: [
    'aviasales-tickets.ru',
    'google.com',
    'google.ru',
    'googleads.g.doubleclick.net',
    'googleadservices.co',
    'www.google.com',
    'www.google.ru',
    'www.googleadservices.co',
    'yandex.ru',
  ]

  init: (params_marker, current_marker) ->
    return unless window.top is window.self
    marker = params_marker or @getMarkerFromReferer()
    marker = @_markerForBadBoy(marker) if @_isBadBoy(marker)

    return unless marker?
    return if @isAffiliate(current_marker) and !@isAffiliate(marker)
    return if current_marker is @_markerForBadBoy(marker)
    return marker

  urlToDomain: (url) ->
    a = document.createElement('a')
    a.href = url
    a.hostname

  getMarkerFromReferer: ->
    ref = document.referrer
    return unless ref
    domain = @urlToDomain(ref)
    return if /aviasales|hotellook|jetradar/.test(domain)
    marker = domain.replace(/\./, '_')
    checks =
      bing: /bing\./
      google: /^(www\.)?google\./
      mail: /go\.mail\.ru/
      rambler: /^(www\.)?(nova\.)?rambler\./
      yandex: /^(www\.)?yandex\./
    for engine, regex of checks when regex.test(domain)
      marker = engine
      break
    marker

  isAffiliate: (marker)-> /^\d{5,}(\.|$)/.test(marker)

  _isBadBoy: (marker) ->
    marker and @isAffiliate(marker) and @_fromAdsSite() and !@_isValidAdsMarker(marker)

  _fromAdsSite: ->
    domainRegex = ///^https?:\/\/(#{@CONTEXTUAL_ADS_DOMAINS.join('|').replace(/\./g, '\\.')})///
    /[?&](gclid|yclid)=/.test(document.location.search) or domainRegex.test(document.referrer)

  _isValidAdsMarker: (marker) ->
    ///^(#{@VALID_CONTEXT_ADS_MARKERS.join('|')})///.test(marker)

  _markerForBadBoy: (marker) ->
    "#{@VALID_CONTEXT_ADS_MARKERS[0]}.badboys#{marker}"
