DOMComponent = require('shared/components/base.js').default
Template = require('flights/components/third_ticket/third_ticket.monk')
Monkberry = require('monkberry')

class ThirdTicket extends DOMComponent
  @instance: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @broadcast: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @initializeComponent: () -> #HACK static inheritance in CoffeeScript

  @defaultOptions: () ->
    name: 'third_ticket',
    render: {
      template: Template
      options: {}
    },

  constructor: (containerNode, options = {}) ->
    super(containerNode, options)

  update: (options) ->

module.exports = ThirdTicket
