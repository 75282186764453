const localesMap = {
  'hy': 'hy-AM',
  'zh': 'zh-CN',
  'us': 'en-US',
  'pt': 'pt-PT',
  'br': 'pt-BR',
  'sr': 'sr-CS',
  'es': 'es-ES',
  'sv': 'sv-SE'
}

const localesFallback = __('auto_generated.locales_flights_fallback')
const pluraliseLocaleFallback = __('auto_generated.plural_locales_fallback')

export default class Locale {
  // fixLocale convert bad locales like us to right en-us
  static fixLocale (l) {
    return localesMap[l] ? localesMap[l] : l
  }
  static getLocaleFallback (l) {
    return localesFallback[l] ? localesFallback[l] : l
  }
  static getPluralLocaleFallback (l) {
    return pluraliseLocaleFallback[l] ? pluraliseLocaleFallback[l] : l.substr(0, 2)
  }
}
