let URLFragment = {
  state: {},
  callbacks: [],

  init () {
    if (window.location.hash) this.state = this.parse(decodeURIComponent(window.location.hash))
    window.addEventListener('hashchange', () => { this.changed() }, false)
  },

  parse (hashString) {
    let result = {}
    hashString.replace(/#\/?/, '').split('&').forEach(function (item) {
      let key, values
      [key, values] = item.split('=')
      key = /([^\[]+)(\[([^\]]+)\])?/.exec(key)
      if (key && values) {
        if (result[key[1]] === undefined) result[key[1]] = {}
        values = values.split(';')

        if (key[3]) result[key[1]][key[3]] = values
        else result[key[1]] = values
      }
    })

    return result
  },

  update(state, options = {}) {
    Object.keys(state).forEach((key) => { this.state[key] = state[key] })
    let fragment = encodeURIComponent(this.toString())
    let href = window.location.href.split('#')[0]
    if (fragment !== '') href += '#' + fragment
    if (options.pushState) {
      window.history.pushState(window.history.state, null, href)
    } else {
      window.history.replaceState(window.history.state, null, href)
    }
  },

  toString () {
    let result = []
    Object.keys(this.state).forEach((key) => {
      let item = this.state[key]
      if (Object.prototype.toString.call(item) === '[object Array]') {
        if (item.length > 0) result.push(`${key}=${item.join(';')}`)
      } else {
        Object.keys(item).forEach((field) => {
          if (item[field].length > 0) {
            result.push(`${key}[${field}]=${item[field].join(';')}`)
          }
        })
      }
    })

    return result.join('&')
  },

  changed () {
    let hash = window.location.hash
    let newState = this.parse(decodeURIComponent(hash))

    this.callbacks.forEach((callback) => { callback(hash, this.state, newState) })
    this.state = newState
  },

  onChange (callback) { this.callbacks.push(callback) }
}

URLFragment.init()

export default URLFragment

// #/f[price]=321;5849&f[stars]=3;0&f[distance]=24.9;;;&f[rating]=27;100&f[amenities]=restaurant;tv&f[types]=;
// #f[stars]=3;4&f[distance]=9.8;;;&f[price]=4169;8514;RUB&f[districts]=1657407;1657409&f[rating]=4.5&f[amenities]=hotel:24_hours_front_desk_service;hotel:bar&f[types]=1
