{% import PriceTile from '../shared/price_tile.monk' %}

<div class="calendar_line calendar_line--one_way" role="calendar_line--wrapper">
  <ul class="calendar_line_prices">
    {% for index, date of depart_dates.raw %}
      <li class="{{ (current_depart_date === date.date) ? 'price--current' : '' }}" role="cell" data-cell="{{ date.date }}">
        <PriceTile
          price="{{ prices | price_for_date(date, false, direct_only) }}"
          currency="{{ currency }}"
          depart_date="{{ date.date }}"
          return_date="{{ false }}"
          depart_date_formatted="{{ depart_dates.formatted[index] }}"
          return_date_formatted="{{ false }}"
          min_price="{{ minPrice }}"
          is_current_date="{{ current_depart_date === date.date }}"
          current_min_price="{{ direct_only ? currentDirectMinPrice : currentMinPrice }}"
        />
      </li>
    {% endfor %}
  </ul>
  <div class="calendar_line-info">
    <span class="calendar_line-info__description">{{ __('calendar.button') }}</span>
    <span class="calendar_line-info_toggle" role="calendar_show">{{ __('calendar.toggle') }}</span>
  </div>
</div>
