import _defaultsDeep from 'lodash/defaultsDeep'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import dictionary from 'shared/lib/dictionary.js'

export default class Airlines extends CheckboxFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'airlines',
      state: {
        title: __('filters.airlines'),
        labels: (id) => {
          return dictionary.airlineByCode(id).name
        }
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    this.needDafaultSatate = true
  }

  update (data) {
    const keys = this._getKeys(data)
    const state = this._prepareState(keys)
    super.update(state, keys)
  }

  getState () {
    const state = super.getState()
    return {airlines: Object.keys(state)}
  }

  _prepareState (data) {
    return data.reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {})
  }

  _getKeys (airlines) {
    return Object.keys(airlines).sort((a, b) => airlines[a].name.toLowerCase().localeCompare(airlines[b].name.toLowerCase()))
  }
}
