dispatcher = require('shared/lib/dispatcher')
metrics = require('shared/lib/metrics.coffee')

Monkberry = require('monkberry')
Template  = require('./google_recaptcha.monk')
require('monkberry-events')
cookies = require('shared/lib/cookies.coffee')
xhr      = require('xhr')
domain_detector = require('shared/lib/domain_detector.coffee')
utils                 = require('shared/lib/utils.coffee')
search_params         = require('flights/components/params_parser/search_params.coffee')


google_recaptcha = (container_el) ->
  google_recaptcha_obj = Monkberry.render(Template, container_el)
  google_recaptcha_obj.update {
    google_recaptcha: ''
  }
  init_google_recaptcha = () ->
    containerNode = document.querySelector('[role="google_recaptcha"]')
    script = document.createElement('script')
    script.setAttribute('charset', 'UTF-8')
    script.setAttribute('async', true)
    script.setAttribute('defer', 'defer');
    window.onloadCallback = onloadCallback;
    window.verifyCallback = verifyCallback
    script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=#{LOCALE}"
    containerNode.appendChild(script)
  
  onloadCallback = () ->
    grecaptcha.render('google_recaptcha', {
      'sitekey' : '6Lc91OEUAAAAANpMw4zzN5cQOsC7w1_6MhxcuhRw',
      'callback' : verifyCallback,
    });
    window.grecaptcha = grecaptcha
  
  verifyCallback = (response) ->
    xhr({
      uri: "/captcha?captcha_token=#{response}"
      method: "get"
    }, (err, res, body) =>
      if typeof body == "string"
        body = JSON.parse(body)
        expire = new Date(+(new Date()) + 60 * 60 * 24 * 30 * 12 * 1e3 )
        cookies.set('google_recaptcha_token', body.token, expire.toUTCString(), domain_detector.main_domain())
        dispatcher.send('start_search', {request_id: utils.generate_id(), params: search_params.get()}, 'parser_parse')
    )
  dispatcher.on('systemMessageError403', ->
    showCaptcha()
    if window.grecaptcha then  window.grecaptcha.reset()  else init_google_recaptcha()
  )
  dispatcher.on('start_search', (event, params) ->
    container_el.classList.add('hidden')
  )
  showCaptcha = -> container_el.classList.remove('hidden')

module.exports = google_recaptcha