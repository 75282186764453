<div class="baggage-tooltip  baggage-tooltip--debug">
  <div class="baggage-tooltip__item">
    <span class="baggage-info">Gate id: {{ proposal.gate_id }}</span>
  </div>
  <div class="baggage-tooltip__item baggage-tooltip__item--border">
    <span class="baggage-info">Productivity: {{ proposal.productivity }}</span>
  </div>
  <div class="baggage-tooltip__item baggage-tooltip__item--border">
    <span class="baggage-info">Proposal Multiplier: {{ proposal.proposal_multiplier }}</span>
  </div>
  <div class="baggage-tooltip__item baggage-tooltip__item--border">
    <span class="baggage-info">Multiplier: {{ proposal.multiplier }}</span>
  </div>
  <div class="baggage-tooltip__item baggage-tooltip__item--border">
    <span class="baggage-info"> {{ __('ticket.baggage.baggage')}}: {{proposal.bags[0].baggage.code }};{{proposal.bags[0].baggage.debug }}</span>
  </div>
  <div class="baggage-tooltip__item baggage-tooltip__item--border">
    <span class="baggage-info">{{ __('ticket.baggage.handbags')}}: {{proposal.bags[0].handbags.code }};{{proposal.bags[0].handbags.debug }}</span>
  </div>
</div>
