_each = require('lodash/each')
_find = require('lodash/find')
config = require("./hosts.js")
adsense_settings = require("./adsense_settings.js")
dispatcher = require('shared/lib/dispatcher')
cookies = require('shared/lib/cookies.coffee')
Monkberry = require('monkberry')
Template = require('./adsense.monk')

origin_city = ''
locale = LOCALE;

jetradarCompareLocales =
  default:
    jr_code: "US"
    jr_locale:"en"
  br:
    jr_code: "BR"
    jr_locale:"pt-BR"
  de:
    jr_code: "DE"
    jr_locale:"de"
  el:
    jr_code: "GR"
    jr_locale:"en"
  es:
    jr_code: "ES"
    jr_locale:"es"
  fr:
    jr_code: "FR"
    jr_locale:"fr"
  it:
    jr_code: "IT"
    jr_locale:"it"
  ms:
    jr_code: "MY"
    jr_locale:"en"
  pl:
    jr_code: "PL"
    jr_locale:"pl"
  pt:
    jr_code: "PT"
    jr_locale:"pt"
  th:
    jr_code: "TH"
    jr_locale:"th"
  vi:
    jr_code: "VN"
    jr_locale:"vi"

{jr_locale , jr_code}=
  if jetradarCompareLocales[locale]? then jetradarCompareLocales[locale] else jetradarCompareLocales["default"]

take_correct_config = _find(config, (adsense) ->
  adsense["code"] == jr_code
)
mouse_over_ads = false
show_ads = !cookies.get('noads')

window.SHOW_GOOGLE_ADSENSE ||= false

module.exports = ( root) ->
  return unless window.SHOW_GOOGLE_ADSENSE
  state = {visible: false}
  view = Monkberry.render Template, root
  document.querySelector('[role="ad-top-container"]').classList.add("ad-top-container") if show_ads

  show_google_csa = (params) ->
    adsense_settings.csa_page_options.channel = take_correct_config.google_csa_channel
    adsense_settings.csa_page_options.hl = jr_locale
    new_destination_city = params.segments[0].destination_name

    if adsense_settings.csa_adblocks[0].destination_city != new_destination_city
      _each adsense_settings.csa_adblocks, (adblock) -> adblock.destination_city = new_destination_city
      adsense_settings.csa_page_options.query = __("google_csa_query").replace("%{destination_city}", new_destination_city)
      params = ["ads", adsense_settings.csa_page_options]
      params.push(adsenseBlockItem.params) for adsenseBlockItem in adsense_settings.csa_adblocks
      _googCsa.apply this, params

  dispatcher.on 'start_search', (e, {params}, source) ->
    return if not show_ads

    return if source is 'parser' # and wait for places_restored event.
    show_google_csa params

  dispatcher.on 'places_restored', (e, params) ->
    if show_ads
      show_google_csa params

  dispatcher.on 'noads', (e) ->
    show_ads = false
    adsense_settings.csa_adblocks.forEach((param) ->
      container = document.getElementById(param.params.container)
      container.classList.add('hidden')
      # container.parentNode.removeChild(container)
    )
