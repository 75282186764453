{% import FilterTitle from '../base/title.monk' %}
{% import Subtitle from '../base/subtitle.monk' %}

<div class="filter filter--{{name}}  {{ keys.length ? '' : 'hidden'}}" >
  {% if title %}
    <FilterTitle title="{{title}}" collapsed="{{collapsed}}"/>
  {% endif %}
    <div class="filter-control-container {{ collapsed ? 'filter-control-container--collapsed' : '' }}">
    <div class="control control {{ collapsed ? 'hidden' : '' }}  control--expanded" role="filter-body">
      {% if has_check_all %}
        <div class="custom_checkbox">
          <input checked="{{ checked_all }}" type="checkbox" role="checkbox_count_all" id="{{filter_id}}" />
          <label class="label-block name stops-label" for="{{filter_id}}">
            {{ __('filters.labels.all') }}
          </label>
        </div>
      {% endif %}
      {% if subtitle %}
        <Subtitle subtitle="{{subtitle}}"/>
      {% endif %}
      {% for id of keys %}
        {% if groupLabels(id) %}
          <li class="title semibold title--subtitle">
            {% unsafe groupLabels(id) %}
          </li>
        {% endif %}
        {% if labels(id) %}
          <div class="custom_checkbox{{ options[id] === 0 ? ' disabled' : '' }}" role="checkbox_item">
            <input type="checkbox" name="{{ id }}" value="1" role="filter-option" id="{{ name + '_' + id }}"/>
            <label class="label-block name airlines-label g-text-overflow" for="{{ name + '_' + id }}">
              {% unsafe labels(id) %}
              <div class="right-text{{ !options[id] ? ' hidden' : '' }}">
                {% unsafe options[id] | decorateMeasurement %}
                {% if has_only_button %}
                  <span class="only-button" role="only_button" data-count="{{ id }}">
                {{ __('filters.labels.only') }}</span> {% endif %}
              </div>
            </label>
            {% if has_only_button && !options[id] %}
              <span class="only-button" role="only_button" data-count="{{ id }}">
              {{ __('filters.labels.only') }}</span>
            {% endif %}
          </div>
        {% endif %}
      {% endfor %}
    </div>

    <div class="control-list-opener-wrapper control-list-opener-wrapper--hidden" role="collaps_checkboxes">
      <div class="control-list-opener control-list-opener--open" role="show_more_proposals">
        {{ __('card.checkboxes.more') }}
      </div>
      <div class="control-list-opener control-list-opener--close" role="show_less_proposals">
        {{ __('card.checkboxes.less') }}
      </div>
    </div>
  </div>
</div>
