import _assign from 'lodash/assign'
import _isEmpty from 'lodash/isEmpty'
import filters from 'shared/lib/filters.coffee'
import DOMComponent from 'shared/components/base'
import Template from './airports_precheck.monk'
import colorUtils from 'shared/lib/color_utils.js'

export default class AirportPrecheck extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'airports_precheck',
      render: { template: Template },
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.ticket-hint-button': {
            background: window.TPWLCONFIG.color_scheme.bg,
            color: window.TPWLCONFIG.color_scheme.text_contrast
          },
          '.ticket-hint-button:hover': {
            background: colorUtils.shadeBlend(0.2, window.TPWLCONFIG.color_scheme.bg, '#FFFFFF')
          }
        }
      },
      state: {
        request_id: null,
        airport_iata_departure: null,
        airport_iata_arrival: null,
        currency: null,
        currencies: {},
        price_diff: null,
        cheap_filtered_ticket: null,
        cheap_best_ticket: null,
        airport_name: null,
        price: null,
        currency: null,
        activate_hint: false
      }
    }
  }

  constructor (node, options = {}) {
    super(node, options)
  }

  //wl
  _initDOMEvents (view) {
    view.on('click', '[role="hint_button"]', (event) => {
      ['departure_airports', 'arrival_airports'].forEach(filter => this._dispatcher.send('reset_filter', filter))
    })
  }

  _initEvents (dispatcher) {
    dispatcher.on('start_search', (event, {request_id}) => { this.state.request_id = request_id })

    dispatcher.on('filters_state_updated', (event) => {
      this.state.activate_hint = false
      this.hide()
      if (this.state.airports_change == true) { this.state.user_use_fileters = true }
    })
    dispatcher.on('airport_precheck', (event, activate) => {
      this.state.airports_change = activate
      this.state.user_use_fileters = false
    })
    dispatcher.on('best-tickets-shown', (event) => {
      this.state.activate_hint = false
      this.hide()
    })
    dispatcher.on('reset_filters', (event) => {
      this.state.activate_hint = false
      this.hide()
    })

    dispatcher.on('sort_order_updated', (event) => {
      this.state.activate_hint = false
      this.hide()
    })
    dispatcher.on('cheapest_ticket_updated', (event, {ticket}) => { this.state.cheap_best_ticket = ticket })
    dispatcher.on('places_restored', (event, params) => this.state.params = params )
    dispatcher.on('tickets_updated', (event, {tickets}) => {
      if (!_isEmpty(tickets) && this.state.airports_change && !this.state.user_use_fileters) {
        this.state.cheap_filtered_ticket = tickets[0][1][0]
        const flight = this.state.cheap_best_ticket[0].segment[0].flight 
        this.state.price_diff = this.state.cheap_filtered_ticket.unified_price - this.state.cheap_best_ticket[1][0].unified_price
        this.state = _assign(this.state, {
          airport_iata_departure: this.state.params.segments[0].origin == flight[0].departure ? null : flight[0].departure ,
          airport_iata_arrival: this.state.params.segments[0].destination == flight[flight.length - 1].arrival ? null : flight[flight.length - 1].arrival ,
          price: filters.split_price(parseInt(this.state.price_diff / this.state.currencies[this.state.currency], 10)),
          currency: this.state.currency
        })
        this.refresh()
        if (this.state.price_diff > 0) {
          this.state.activate_hint = true
        } else {
          this.state.activate_hint = false
        }
      }
    })

    dispatcher.on('search_finished', () => {
      if (this.state.activate_hint == true) { this.show() }
    })
    dispatcher.on('currencies_updated', (event, {currencies}) => { this.state.currencies = currencies })
    dispatcher.on('currency_updated', (event, currency) => {
      this.state.currency = currency
      if (!_isEmpty(this.state.currencies)) {
        this.state = _assign(this.state, {
          price: filters.split_price(parseInt(this.state.price_diff / this.state.currencies[currency], 10)),
          currency: currency
        })
        this.refresh()
      }
    })
  }
}
