TRANSITION_MAP =
  {
    'webkitTransition' :'webkitTransitionEnd'
    'MozTransition': 'transitionend'
    'OTransition': 'otransitionend'
  }

ANIMATION_MAP =
  {
    'oAnimation': 'oanimationend'
    'mozAnimation': 'animationend'
    'webkitAnimation': 'webkitAnimationEnd'
    'MSAnimation': 'MSAnimationEnd'
  }

DEFAULT_TRANSITION = 'transitionend'
DEFAULT_ANIMATION = 'animationend'

module.exports =
  get_animation_event_name: (element, is_transition = false) ->
    if is_transition
      animations = TRANSITION_MAP
      default_event = DEFAULT_TRANSITION
    else
      default_event = DEFAULT_ANIMATION
      animations = ANIMATION_MAP

    for animation_name, event_name of animations
      if element.style[animation_name]?
        computed_event_name = event_name
        break

    computed_event_name or default_event
  get_transition_event_name: (element) ->
    @get_animation_event_name(element, true)
