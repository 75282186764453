import CalendarBase from './base.js'
import cookies from 'shared/lib/cookies.coffee'

export default class CalendarWithInterface extends CalendarBase {
  _initEvents (dispatcher) {
    super._initEvents(dispatcher)

    dispatcher.on('calendar_toggle', () => {
      if (this.shown) {
        this.hide()
      } else {
        this.open()
      }
    })
  }

  _initDOMEvents (view) {
    view.on('click', '[role="close_calendar"]', (event, node) => {
      this.hide()
      document.querySelector('[role="calendar_line--wrapper"]').classList.remove('calendar_line--hide')
    })
    view.on('click', '[role="calendar_move"]', (event, node) => {
      CalendarBase.reach_goal(`CALENDAR_${this.options.name.toUpperCase()}_MOVE`)
      this.options.dOffset += parseInt(node.dataset.dDiff, 10)
      this.options.rOffset += parseInt(node.dataset.rDiff, 10)
      this.fetchDataAndRender()
    })
    view.on('change', '[role="direct_only"]', (event, event_target) => {
      CalendarBase.reach_goal(`CALENDAR_${this.options.name.toUpperCase()}_DIRECTONLY`)
      this.state.direct_only = event_target.checked
      this._dispatcher.send('direct_only_change', this.state.direct_only)
      this.setMinPrice()
      this.refresh()
    })
    super._initDOMEvents(view)
  }

  show () {
    super.show()
    this.view.nodes[0].children[0].classList.remove('calendar--closing')
    this.view.nodes[0].classList.remove('calendar--one_way-wrapper--animation')
    this.view.nodes[0].children[0].classList.add('calendar--showing')
    document.body.querySelector('[role="search-results"]').classList.add('calendar--showing')
    document.body.querySelector('[role="search-results"]').classList.remove('calendar--closing')
    if (this.options.name == 'calendar_one_way') { document.body.querySelector('[role="search-results"]').classList.add('search-results--one_way') }
  }

  hide () {
    CalendarBase.reach_goal(`CALENDAR_${this.options.name.toUpperCase()}_CLOSE`)
    this.shown = false
    this.view.nodes[0].addEventListener('webkitAnimationEnd', (event) => {
      if (!this.shown) { super.hide() }
    }, false)
    this.view.nodes[0].children[0].classList.add('calendar--closing')
    this.view.nodes[0].classList.add('calendar--one_way-wrapper--animation')
    this.view.nodes[0].children[0].classList.remove('calendar--showing')
    document.body.querySelector('[role="search-results"]').classList.add('calendar--closing')
    document.body.querySelector('[role="search-results"]').classList.remove('calendar--showing')
    if (this.options.name == 'calendar_one_way') { document.body.querySelector('[role="search-results"]').classList.add('search-results--one_way') }
  }
}
