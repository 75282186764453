import mobileDetector from './mobile_detector';

let device = {
  scrollLocked: false,
  res: window.devicePixelRatio || 1,
  rtl: ['ar', 'he'].indexOf(LOCALE) !== -1,

  isTouch () {
    return mobileDetector.isTouch();
  },

  isDesktopSize () {
    return mobileDetector.isDesktopSize();
  },

  isNarrowTabletMobileSize () {
    return window.innerWidth <= 660
  },

  isMobileSize () {
    return window.innerWidth <= 440
  },

  isRtl () {
    return this.rtl
  },

  lockScroll () {
    if (this.scrollLocked) return false
    this.overflowMemo = document.documentElement.style.overflow || 'visible'
    document.documentElement.style.overflow = 'hidden'
    this.scrollLocked = true
  },

  unlockScroll () {
    if (!this.scrollLocked) return false
    document.documentElement.style.overflow = this.overflowMemo
    this.scrollLocked = false
  },

  // Easy determination of mobile device
  isMobile () {
    return mobileDetector.isMobile();
  }
}

device.pixelsWithRatio = (device.res === 1)
  ? function (pixels) { return pixels }
  : function (pixels) { return Math.round(pixels * this.res) }

export default device
