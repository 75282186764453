_isUndefined = require('lodash/isUndefined')
_isNull = require('lodash/isNull')
prefix = ''
ignore_regex = /js|expl/
class Modifier
  constructor: (@el) ->
    @el = document.createElement('div') if (_isNull(@el) or _isUndefined(@el))
  mutate_class_name: (class_name) ->
    if ignore_regex.test(class_name)
      class_name
    else
      "#{prefix}#{class_name}"
  add: (class_name)->
    @el.classList.add(@mutate_class_name(class_name))
  remove: (class_name)->
    @el.classList.remove(@mutate_class_name(class_name))
  toggle: (class_name, force) ->
    if typeof force == 'boolean'
      @el.classList.toggle(@mutate_class_name(class_name), force)
    else
      @el.classList.toggle(@mutate_class_name(class_name))
  contains: (class_name) ->
    @el.classList.contains(@mutate_class_name(class_name))




modifier_wrapper = (el) -> new Modifier(el)
module.exports = modifier_wrapper
