const mobileDetector = {
  isTouch() {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      return false
    }
  },

  isDesktopSize() {
    return window.innerWidth >= 1024
  },

  isMobile() {
    return this.isTouch() || !this.isDesktopSize()
  }
}

export default mobileDetector
