DOMComponent    = require('shared/components/base.js').default
Template        = require('./show_sorting_button.monk')
metrics         = require('shared/lib/metrics.coffee')
colorUtils      = require('shared/lib/color_utils.js').default
PopupScroller   = require('shared/lib/popup_scroller.js').default

class ShowSortingButton extends DOMComponent
  @instance: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @broadcast: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @initializeComponent: () ->  #HACK static inheritance in CoffeeScript
  @defaultOptions: () ->
    name: 'show_sorting_button'
    render:
      template: Template
    cssx:
      scope: '.TPWL-widget'
      styles:
        ".show_sorting_button":
          "background": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.text_contrast
          "border-color": colorUtils.shadeBlend(0.12, window.TPWLCONFIG.color_scheme.bg, '#000000')
        '.show_sorting_button:hover': {
          "background": colorUtils.shadeBlend(0.2,window.TPWLCONFIG.color_scheme.bg, '#FFFFFF')
        },
        ".show_sorting_button:before":
          "background": "url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2019%2015%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ESort%3C%2Ftitle%3E%3Cg%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.text_contrast)}%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20y%3D%226%22%20width%3D%2210%22%20height%3D%222%22%20rx%3D%22.5%22%2F%3E%3Crect%20y%3D%2212%22%20width%3D%224%22%20height%3D%222%22%20rx%3D%22.5%22%2F%3E%3Crect%20width%3D%2218%22%20height%3D%222%22%20rx%3D%22.5%22%2F%3E%3Cpath%20d%3D%22M15.207%2014.75l-.02.017c-.018.018-.038.035-.06.053-.09.065-.188.115-.295.144-.082.024-.166.036-.252.036-.058%200-.116-.006-.172-.016-.034-.006-.066-.015-.098-.025-.067-.023-.13-.05-.187-.084-.03-.016-.058-.036-.086-.056-.024-.017-.043-.035-.062-.053l-.04-.036-2.684-2.466c-.334-.308-.334-.81%200-1.118.336-.308.882-.308%201.217%200L13.72%2012.3V6.79c0-.434.388-.79.86-.79.475%200%20.86.356.86.79v5.512l1.256-1.156c.335-.308.88-.308%201.216%200%20.334.308.334.81%200%201.118l-2.705%202.485z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') 50% 50% no-repeat"

  constructor: (containerNode, options = {}) ->
    super(containerNode, options)

  _initDOMEvents: (view) ->
    view.update()
    view.on 'click', '[role="show_sorting"]', ->
      document.querySelector("[role='sorting_dropdown']").classList.remove("hidden")
      document.querySelector("[role='tpwl-content']").classList.add("popup_shown")
      document.body.classList.add("overflow--hidden")
      PopupScroller.toggle()
      metrics.reach_goal("show_sorting_button")

  _initEvents: (dispatcher) ->
    dispatcher.on 'systemMessageError', () => this.hide()

module.exports = { default: ShowSortingButton }
