import _minBy from 'lodash/minBy'
import Params from 'flights/lib/params.js'

let prevRender = null;

module.exports = {
  lower_modificator (price, min_price) {
    return (price && (price < min_price + 1)) ? ' price--lower' : ''
  },
  price_for_date (prices, depart_date, return_date, direct_only = false) {
    if(prices[depart_date.date]){
      if(prices[depart_date.date][return_date.date]){
        if(direct_only){
          return prices[depart_date.date][return_date.date].filter(price => price.number_of_changes < 1)
        }else{
          return _minBy(prices[depart_date.date][return_date.date], 'value');
        }
      }else{
        return _minBy(prices[depart_date.date], 'value');
      }
    }

    return {}
  },
  short_params (depart_date, return_date) {
    return Params.getShortParams(Params.lastSearch.params, depart_date, return_date)
  }
}
