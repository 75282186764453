<div class="sticky-informer-info sticky-informer-info--multi">
  <div class="sticky-informer-info__button" onclick="window.scrollTo(0, 0)">
    {{ __('helpers.submit.search.update') }}
  </div>
  <div class="sticky-informer-info-big_wrapper">
    <div class="sticky-informer-info-big_wrapper-helper">
      <ul class="sticky-informer-info-content_wrapper">
        {% for segment of params.segments %}
          <li class="sticky-informer-info__left-block">
            <div class="sticky-informer-info__route">
              <div class="sticky-informer-info-route__origin">
                {{ segment && segment.origin }}
              </div>
              <div class="sticky-informer-info-route__arrow">{% unsafe  "&xrarr;" %}</div>
              <div class="sticky-informer-info-route__destination">
                {{ segment && segment.destination }}
              </div>
            </div>
            <div class="sticky-informer-info-dates-narrow">
              <span class="sticky-informer-info-dates__date">{% unsafe (segment && segment.date) | sticky_date_narrow %}</span>
            </div>
          </li>
          <span class="sticky-informer-info__separator">|</span>
        {% endfor %}
      </ul>
      <div class="sticky-informer-info__passengers">
        <b>{{ (params.passengers.adults + params.passengers.infants + params.passengers.children) | passengers }}</b>
        <br />
        {{ params.trip_class == 'Y' ? __('form.trip_class.Y') : __('form.trip_class.C') }}
      </div>
    </div>
  </div>
</div>
