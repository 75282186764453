_sortBy = require('lodash/sortBy')
_toPairs = require('lodash/toPairs')
_values = require('lodash/values')
_flatten = require('lodash/flatten')
_uniq = require('lodash/uniq')
_isEmpty = require('lodash/isEmpty')
_some = require('lodash/some')
_union = require('lodash/union')
_map = require('lodash/map')
_each = require('lodash/each')
_keys = require('lodash/keys')
_includes = require('lodash/includes')
_extend = require('lodash/extend')
_sortedIndexBy = require('lodash/sortedIndexBy')
_min = require('lodash/min')
_minBy = require('lodash/minBy')
utils = require('shared/lib/utils.coffee')
yasen_client = require('./yasen_cache_client.coffee')
sorter = require('./sorter.coffee')
filter = require('./filter.coffee')
bench = require('shared/lib/bench.coffee')
Locale = require('shared/lib/locale').default
dictionary = require('shared/lib/dictionary.js').default
Porter = require('shared/lib/porter.js').default

# # Hack for Globus -> S7 renaming
# AIRLINES_MERGE_MAP =
#   GH: {
#     "id": 444,
#     "name": "S7 (Сибирь)",
#     "iata": "S7"
#   }
# S7_GATE_ID = 280

carriers_key = (carriers) ->
  carriers.sort().join(',')

class RequestProcessor
  first_tickets_arrived_timeout: 9000

  _should_raise_productivity: (carriers, gate_id) ->
    'raise_productivity_airlines' of @gates[gate_id] and
      carriers_key(carriers) in @gates[gate_id].raise_productivity_airlines

  _merge_proposals: (old_proposals, ticket) ->
    proposals = old_proposals or []
    terms = ticket.xterms
    # NOTE: use trusted ticket instead new one, if it is available
    [ticket, proposals] = @tickets_by_sign[ticket.sign] ? [ticket, []]
    for gate_id, gate_proposals of terms
      if @gates[gate_id] != undefined and @gates[gate_id].top_placement_type isnt 'only'
        should_raise_productivity = @_should_raise_productivity(ticket.carriers, gate_id)
        for key, proposal of gate_proposals
          proposal.gate_id = gate_id
          proposal.is_charter = Boolean(ticket.is_charter)
          proposal.fare_codes = key.split(';').map((codes) -> codes.split(','))
          proposal.fare_codes_key = key
          if should_raise_productivity
            # we have to increase productivity only for gate_proposals
            proposal.productivity = 1
          insert_to = _sortedIndexBy(proposals, proposal, (p) =>
            # sort by price and then by productivity
            # Store productivity in proposal instead of change gate productivity by reference
            # to prevent losing of it on next chunk when other gate has more productivity
            productivity = p.productivity or @gates[p.gate_id].productivity or 0
            multiplier = p.multiplier or @gates[p.gate_id].multiplier or 1
            p.debugProductivity = productivity
            p.debugMultiplier = multiplier
            p.debugProposalMultiplier = p.proposal_multiplier
            parseFloat(p.unified_price) - ((productivity * multiplier) / 1000)
          )
          proposals.splice(insert_to, 0, proposal)
    _each proposals, (val, i) -> val.original_index = parseInt(i)
    @merge_baggage(ticket, proposals)
    proposals

  merge_baggage: (ticket, proposals) =>
    bag = Porter.getTariffs(ticket, proposals, dictionary.airline_rules )
    _each(_map(bag.hasBaggage, 'original_index'), (i) -> proposals[i].hasBaggage = true)
    mergeBagProposal = [].concat(bag.otherBaggage).concat(bag.hasBaggage)
    proposals.forEach (proposal) ->
      _map(mergeBagProposal, 'original_index').forEach (index, i) ->
        if proposal.original_index == index
          proposal.bags = mergeBagProposal[i].bags
          proposal.worstBags = mergeBagProposal[i].worstBags
          proposal.worstBagsForSegments = mergeBagProposal[i].worstBagsForSegments
    ticket.baggage = bag
    @baggage_boundaries['0'] =  @_minUnifiedPrice(bag.otherBaggage, @baggage_boundaries['0'])
    @baggage_boundaries['1'] =  @_minUnifiedPrice(bag.hasBaggage, @baggage_boundaries['1'])

  _minUnifiedPrice: (proposals, current) ->
    min = _minBy(proposals, 'unified_price')
    min = (min && min.unified_price) || 0
    if current && !min then current else (current && _min([current, min])) || min

  merge_left_seats: (ticket) =>
    seats = []
    for segment in ticket.segment
      for flight in segment.flight
        seats.push(parseInt(flight.seats, 10)) if flight.seats
    if seats.length
      seats = Math.min(seats...)
      previous_ticket = @tickets_by_sign[ticket.sign]?[0]
      if seats > previous_ticket?.seats
        ticket.seats = previous_ticket.seats
      else
        ticket.seats = seats

  constructor: (@id, @di_callbacks, @sort_order) ->
    @throttlers = []
    @params = {}
    @filters_state = {}
    @yasen_client = null
    @tickets_by_sign = {}
    @tickets_list = []
    @tickets = []
    @gates = {}
    @search_id = {}
    @airlines = {}
    @airline_rules = {}
    @airports = {}
    @average_prices = {}
    @currencies = {}
    @boundaries = {}
    @segments = []
    @gates_meta = []
    @server_name = null
    @baggage_boundaries = {}

  start: (@params) ->
    @params.locale = Locale.getLocaleFallback(@params.locale)
    @yasen_client = yasen_client(@params, {
      on_update: @on_update
      on_finish: @on_finish
      on_error: @on_error
    })

  stop: ->
    return if !@yasen_client
    @yasen_client.stop()

  _compose_products: bench('compose_products', (with_filtering) ->
    filtered_tickets = if with_filtering then filter.filter(@tickets_list, @filters_state) else @tickets_list
    @tickets = sorter.sort(filtered_tickets, @sort_order)
  )

  set_filters: (filters_state, with_composing) ->
    _extend(@filters_state, filters_state)
    return unless with_composing
    @_compose_products(true)
    @di_callbacks.tickets_updated(@id, @tickets, 'filters_updated')

  reset_filters: ->
    @filters_state = {}
    @_compose_products(false)
    @di_callbacks.tickets_updated(@id, @tickets, 'filters_updated')

  set_sort_order: (@sort_order) ->
    @_compose_products(true)
    @di_callbacks.tickets_updated(@id, @tickets, 'sort_order')

  is_innovata: (ticket) ->
    _includes(_keys(ticket.terms), '666')

  _sign_ticket_segments: (ticket) ->
    _each ticket.segment, (segment) ->
      flight_hashes = _map segment.flight, (f) ->
        [f.operating_carrier, f.number, f.departure_date].join('_')
      segment.hash = flight_hashes.join('+')

  on_update: (yasen_response) =>
    callback_accum = [ [], [], [] ]
    # NOTE: you should use data name as part of event_name
    # for example: event - currencies_updated, data name - currencies
    # FOO_updated
    # city_distance_updated
    key_processes =
      segments: (@segments) =>
        # Rollbar?.configure({payload: {yasen_response:{segments: @segments}}})
        callback_accum[1].push('segments_updated')
      city_distance: (@city_distance) =>
        callback_accum[0].push('city_distance_updated')
      search_id: (search_id) =>
        @search_id = {search_id: search_id}
        # Rollbar?.configure({payload: {yasen_response: {search_id: search_id}}})
        callback_accum[0].push('search_id_updated')
      average_price: (@average_prices) =>
        callback_accum[0].push('average_prices_updated')
      currency_rates: (currency_rates) =>
        @currencies = currency_rates
        callback_accum[0].push('currencies_updated')
      # banner_info: (@banner_info) =>
      gates_info: (gates_info) =>
        @gates = _extend(@gates, gates_info)
        callback_accum[0].push('gates_updated')
      airports: (airports) =>
        @airports = _extend(@airports, airports)
        callback_accum[0].push('airports_updated')
      airlines: (airlines) =>
        @airlines = _extend(@airlines, airlines)
        for key, val of @airline_rules
          dictionary.airline_rules[key] = {"bags": val}
        callback_accum[0].push('airlines_updated')
      airline_rules: (airline_rules) =>
        @airline_rules = _extend(@airline_rules, airline_rules)
        callback_accum[0].push('airline_rules_updated')
      server_name: (server_name) =>
        @server_name = server_name
        callback_accum[0].push('server_name_updated')
      meta: (meta) =>
        return if !meta.gates
        @gates_meta = @gates_meta.concat(meta.gates)
        callback_accum[1].push('gates_meta_updated')

      filters_boundary: (filters_boundaries) =>
        for name in ['departure', 'arrival']
          @boundaries["#{name}_airports"] = if @boundaries["#{name}_airports"]
            _union(@boundaries["#{name}_airports"], filters_boundaries['airports'][name])
          else
            filters_boundaries['airports']?[name]

        for bound_name, bound_values of filters_boundaries
          if _includes(['flights_duration', 'stops_duration', 'stops_count', 'price'], bound_name) || bound_name.match('departure_time') || bound_name.match('arrival_datetime')
            bound_values = filters_boundaries[bound_name]
            continue if !bound_values
            @boundaries[bound_name] or= {}
            for key, value of bound_values
              if key of @boundaries[bound_name]
                compare_func = if key == 'min' or /^\d+$/.test(key) then utils.min else utils.max
                @boundaries[bound_name][key] = compare_func([@boundaries[bound_name][key], value])
              else
                @boundaries[bound_name][key] = value

        @boundaries['baggage'] = @baggage_boundaries

        callback_accum[1].push('boundaries_updated')

      proposals: bench('merge', ((tickets) =>
        return if tickets.length == 0

        for ticket in tickets
          # @merge_airlines(ticket)
          @merge_left_seats(ticket)
          @_sign_ticket_segments(ticket)
          continue if @is_innovata(ticket)
          if ticket.sign of @tickets_by_sign
            if _some(ticket.terms, ((value, key) => !!@gates[key] && @gates[key].is_trusted), @)
              @tickets_by_sign[ticket.sign][0] = ticket
            @tickets_by_sign[ticket.sign][1] =
              @_merge_proposals(@tickets_by_sign[ticket.sign][1], ticket)
          else
            proposals = @_merge_proposals([], ticket)
            ticket_item = [ticket, proposals]
            @tickets_by_sign[ticket.sign] = ticket_item
            @tickets_list.push(ticket_item)

        @fuzzy_tickets = @tickets_list
        if @tickets_list.length
          callback_accum[2].push('fuzzy_tickets_updated')
          callback_accum[2].push('tickets_updated')

      ), {tickets: (args) -> args[0].length})

    for data in yasen_response
      pairs = _toPairs(data)
      pairs = _sortBy(pairs, (el) -> el[0])

      for [key, value] in pairs
        if key of key_processes and (!!value || _isEmpty(value))
          key_processes[key](value)

    callback_accum = _map(callback_accum, (callback_chunk) -> _uniq(callback_chunk))
    callbacks = _flatten(callback_accum)

    @_compose_products(true) if 'tickets_updated' in callbacks

    for callback_name in callbacks
      [data_name..., _verb] = callback_name.split('_')
      data_name = data_name.join('_')
      @di_callbacks[callback_name](@id, @[data_name])

  on_finish: (data) => @di_callbacks.finish(@id, data)
  on_error: (data) => @di_callbacks.error(@id, data)

module.exports = RequestProcessor
