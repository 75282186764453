import DOMComponent from 'shared/components/base.js'
import Template from './show_filters_button.monk'
import metrics from 'shared/lib/metrics.coffee'
import colorUtils from 'shared/lib/color_utils.js'
import PopupScroller from 'shared/lib/popup_scroller.js'

export default class ShowFiltersButton extends DOMComponent {
  static defaultOptions() {
    return {
      name: 'show_filters_button',
      render: {
        template: Template
      },
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.show_filters_button': {
            'background': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast,
            'border-color': colorUtils.shadeBlend(0.12, window.TPWLCONFIG.color_scheme.bg, '#000000')
          },
          '.show_filters_button:hover': {
            'background': colorUtils.shadeBlend(0.2, window.TPWLCONFIG.color_scheme.bg, '#FFFFFF')
          },
          '.show_filters_button:before': {
            'background': `50% 50% no-repeat url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2018%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EFilter%3C%2Ftitle%3E%3Cpath%20d%3D%22M9%201v2H.51C.228%203%200%202.786%200%202.495v-.99C0%201.225.222%201%20.51%201H9zm0%2012v2H.51c-.282%200-.51-.214-.51-.505v-.99c0-.28.222-.505.51-.505H9zM3%207v2H.495C.222%209%200%208.786%200%208.495v-.99C0%207.225.216%207%20.495%207H3zm14.505-6c.273%200%20.495.214.495.505v.99c0%20.28-.216.505-.495.505H15V1h2.505zm0%2012c.273%200%20.495.214.495.505v.99c0%20.28-.216.505-.495.505H15v-2h2.505zm-.015-6c.282%200%20.51.214.51.505v.99c0%20.28-.222.505-.51.505H9V7h8.49zM14%202c0-1.105-.895-2-2-2s-2%20.895-2%202%20.895%202%202%202%202-.895%202-2zm0%2012c0-1.105-.895-2-2-2s-2%20.895-2%202%20.895%202%202%202%202-.895%202-2zM8%208c0-1.105-.895-2-2-2s-2%20.895-2%202%20.895%202%202%202%202-.895%202-2z%22%20fill%3D%22${encodeURIComponent(window.TPWLCONFIG.color_scheme.text_contrast)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E')`
          }
        }
      }
    }
  }

  constructor(node, options = {}) {
    super(node, options)
  }

  _initDOMEvents(view) {
    view.update()
    view.on('click', '[role="show_filters"]', () => {
      this._dispatcher.send('filters_popup_opened')
    })
  }

  _initEvents(dispatcher) {
    dispatcher.on('systemMessageError', () => { this.hide() })
    dispatcher.on('filters_popup_opened', () => {
      dispatcher.send('modal_opened', { id: 'filters', closeFunc: () => { this.closeFilters() }, openFunc: () => { this.showFilters() } })
      this.showFilters()
    })
    dispatcher.on('filters_popup_closed', () => {
      dispatcher.send('modal_closed', { id: 'filters' })
      this.closeFilters()
    })
    dispatcher.on('search_expired', () => {
      PopupScroller.closePopup()
    })
    dispatcher.on('first_tickets_arrived', () => { this.show() })
  }

  showFilters() {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    const bodyHeight = document.body.offsetHeight
    this.scrollHeight = bodyHeight - (bodyHeight - scrollOffset)
    document.querySelector('.js-left-column, [role="filters_wrapper"] [role="swipe-target"]').classList.add('filters--shown')
    document.body.classList.add('overflow--hidden')
    document.body.style.top = `-${this.scrollHeight}px`
    metrics.reach_goal('show_filters_button')
    document.documentElement.style.paddingRight = '1px'
  }
  closeFilters() {
    document.body.style.top = null
    document.querySelector('.filters--shown') && document.querySelector('.filters--shown').classList.remove('filters--shown')
    document.body.classList.remove('overflow--hidden')
    window.scrollTo(0, this.scrollHeight)
    document.documentElement.style = {}
  }
}
