{% import PriceTile from '../shared/price_tile.monk' %}
{% import Header from '../shared/header.monk' %}
{% import Footer from '../shared/footer.monk' %}
{% import Spinner from 'shared/components/spinner/spinner.monk' %}

<div class="calendar--wrapper">
  <section class="calendar">
    <div class="calendar-loader">
      <Spinner />
    </div>

    <Header existDirectFlights="{{ existDirectFlights }}" direct_only="{{ direct_only }}"/>

    <main class="calendar_content">
      <section class="depart_dates">
        <header class="depart_dates__title">
          <div class="calendar_roll_button calendar_roll_button--up" role="calendar_move" data-d-diff="-1" data-r-diff="0"></div>
          <div class="calendar_roll_button calendar_roll_button--down" role="calendar_move" data-d-diff="1" data-r-diff="0"></div>
          {{ __('calendar.depart_title') }}
        </header>
        <ul class="depart_dates__list">
          {% for index, date of depart_dates.formatted %}
          <li role="cell_dates_depart--{{depart_dates.raw[index].date}}">{% unsafe date %}</li>
          {% endfor %}
        </ul>
      </section>

      <div class="right_column">
        <section class="return_dates">
          <header class="return_dates__title">
            <div class="calendar_roll_button calendar_roll_button--left" role="calendar_move" data-d-diff="0" data-r-diff="-1"></div>
            <div class="calendar_roll_button calendar_roll_button--right" role="calendar_move" data-d-diff="0" data-r-diff="1"></div>
            {{ __('calendar.return_title') }}
          </header>
          <ul class="return_dates__list">
            {% for index, date of return_dates.formatted %}
            <li role="cell_dates_return--{{return_dates.raw[index].date}}">{% unsafe date %}</li>
            {% endfor %}
          </ul>
        </section>

        <ul class="prices">
          {% for depart_date of depart_dates.raw %}
            {% for return_date of return_dates.raw %}
              {% if(depart_date.number > return_date.number || return_date.number <= today_number || depart_date.number < today_number ) %}
                <li class="forbidden_dates" role="cell" data-cell="{{ depart_date.date }}/{{ return_date.date }}">—</li>
              {% else %}
                <li class="{{ (current_depart_date === depart_date.date && current_return_date === return_date.date ) ? 'price--current' : '' }}" role="cell" data-cell="{{ depart_date.date }}/{{ return_date.date }}">
                  <PriceTile
                    price="{{ prices | price_for_date(depart_date, return_date, direct_only) }}"
                    currency="{{ currency }}"
                    depart_date="{{ depart_date.date }}"
                    return_date="{{ return_date.date }}"
                    min_price="{{ minPrice }}"
                    current_min_price="{{ direct_only ? currentDirectMinPrice : currentMinPrice }}"
                    is_current_date="{{ current_depart_date === depart_date.date && current_return_date === return_date.date }}"
                    depart_date_formatted="{{ false }}"
                    return_date_formatted="{{ false }}"
                  />
                </li>
              {% endif %}
            {% endfor %}
          {% endfor %}
        </ul>
      </div>
    </main>

    <Footer />
  </section>
</div>
