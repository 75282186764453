{% import Standart from "./_standart.monk" %}
{% import Multi from "./_multi.monk" %}


<div class="sticky-informer-wrapper">
  {% if multi %}
    <Multi params={{ params }}/>
  {% else %}
    <Standart params={{ params }}/>
  {% endif %}
</div>

<div class="sticky-informer-wrapper sticky-informer-wrapper--mobile">
  <div class="sticky-informer-mobile-cities">
    {% if multi %}
      {{ ((params.segments[0].origin_name || params.segments[0].origin.toUpperCase()) + " ... " + (params.segments[params.segments.length-1].destination_name || params.segments[params.segments.length-1].destination.toUpperCase())) | city_name_shortener }}
    {% else %}
      {{ ((params.segments[0].origin_name || params.segments[0].origin.toUpperCase()) + " – " + (params.segments[0].destination_name || params.segments[0].destination.toUpperCase())) | city_name_shortener }}
    {% endif %}
  </div>
  <div class="sticky-informer-mobile-dates">
    {% if multi %}
      {{ params.segments[0].date | sticky_date_mobile }} – {{ params.segments[params.segments.length-1].date | sticky_date_mobile }}
    {% else %}
      {{ params.segments[0].date | sticky_date_mobile }}
      {% if params.segments[1] %}
        – {{ params.segments[1].date | sticky_date_mobile }}
      {% endif %}
    {% endif %}
  </div>
  <div class="sticky-informer-mobile__button" onclick="window.scrollTo(0, 0)"></div>
</div>
