!(function() {
  "use strict";
  function t(t, e) {
    var n = "AS",
      o = "WA",
      a = atob("YXZpYXNhbGVz"),
      c = atob("dnNlcG9rYS5ydQ=="),
      d = atob("YXZpYXNhbGVzLnJ1"),
      r = atob("c3RhdGljLmF2aWFzYWxlcy5ydQ=="),
      u = atob("c3RhdGljLmF2aWFzYWxlcy5jb20="),
      i = atob("YXZzcGxvdy5jb20="),
      s = atob("c3RhdGljLndheWF3YXkuaW8="),
      m = "/snowplow/" + e + "/sp.js";
    function l() {
      try {
        return document.documentElement.dataset.brand;
      } catch (t) {
        return n;
      }
    }
    var b = l(),
      f;
    return (f =
      b === n
        ? t.match(a) || t === c
          ? t.match(d)
            ? "//" + r + m
            : "//" + u + m
          : "//st." + i + m
        : b === o
          ? "//" + s + m
          : "//" + u + m);
  }
  module.exports = (function() {
    if ("undefined" != typeof window && "undefined" != typeof document) {
      var e = window,
        n = document,
        o = "script",
        a = "19.20.1",
        c = "mamka",
        d,
        r;
      if (!e[c]) {
        (e.GSN = e.GSN || []),
          e.GSN.push(c),
          (e[c] = function() {
            (e[c].q = e[c].q || []).push(arguments);
          }),
          (e[c].q = e[c].q || []),
          (d = n.createElement(o)),
          (r = n.getElementsByTagName(o)[0]),
          (d.async = 1);
        var u,
          i = t(e.location.host, a);
        (d.src = i), void 0 !== r && r.parentNode.insertBefore(d, r);
      }
      return e[c];
    }
  })();
})();
