module.exports = {
  'HOSTS': [],
  'CURRENCIES': __('auto_generated.currency'),
  'SHARING_IMAGES_CDN': 'http://cdn.photo.hotellook.com/static/cities/1200x630/',
  'GATE_JETRADAR': 999,
  'JR_OTA_WARM_ENDPOINT': 'https://book.jetradar.co.th/robots.txt',
  'LOCALES_INDEX': __('languages'),
  'HOSTS_INDEX': {},
  'host': {
    'code': 'US',
    'host': '',
    'country': 'United States',
    'currency': 'USD',
    'yasen_locale_param': 'en'
  },
  'url': '',
  'locale': {
    'code': 'en',
    'language': 'English',
    'flag': 'GB',
  }
}
