<header class="calendar_header">
  <h3>{{ __('calendar.button') }}</h3>
  {% if (existDirectFlights) %}
    <div class="custom_checkbox custom_checkbox--touch_clean">
      <input type="checkbox" role="direct_only" value="0" checked="{{ direct_only }}" id="direct_only">
      <label class="label-block name airlines-label g-text-overflow" for="direct_only">
        {{ __('calendar.direct_only') }}
      </label>
    </div>
  {% endif %}
</header>
