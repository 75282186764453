import _defaultsDeep from 'lodash/defaultsDeep'
import filters from 'shared/lib/filters.coffee'
import RangeFilter from 'shared/components/filters/range/range.js'

export default class FlightsDuration extends RangeFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'stops_duration',
      render: {
        options: {
          filters: {
            decorateMeasurement (value) {
              return filters.flight_time_format_duration_with_short_units(value)
            }
          },
          onChange: this.onChange
        }
      },
      state: {
        getFromText () { return `&nbsp;` },
        getToText () { return `&nbsp;–&nbsp;` },
        title: __('filters.duration_of_stop')
      }
    }, super.defaultOptions())
  }

  getState () {
    let state = super.getState()

    return {
      min_stop_duration: state.left,
      max_stop_duration: state.right
    }
  }
}
