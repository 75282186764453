import DOMComponent from 'shared/components/base'
import Template from './pagination.monk'

export default class Pagination extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'pagination',
      render: {
        template: Template
      },
      cssx: {
        scope: '.TPWL-widget .pagination_button',
        styles: {
          '': {
            'background': window.TPWLCONFIG.color_scheme.bg,
            'color': window.TPWLCONFIG.color_scheme.text_contrast
          }
        }
      },
      state: {
        itemsCount: 0,
        searchFinished: false,
        buttonHidden: false
      }
    }
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    this.ChunkItemCount = containerNode.dataset.chunks || 10
    this.refresh()
  }

  itemsUpdated (count) {
    this.state.itemsCount = count
    this.checkVisibility()
  }

  _initDOMEvents (view) {
    view.createDocument().addEventListener('click', () => {
      this._dispatcher.send('pagination-show-more', this, 'pagination')
    })
  }

  _initEvents (dispatcher) {
    dispatcher.on('search_finished', () => {
      this.state.searchFinished = true
      this.checkVisibility()
    })

    dispatcher.on('start_search', () => {
      this.state.itemsCount = 0
      this.state.searchFinished = true
      this.checkVisibility()
    })
  }

  checkVisibility () {
    if (this.state.itemsCount <= this.ChunkItemCount || !this.state.searchFinished || this.state.buttonHidden) { this.hide() } else { this.show() }
  }
}
