_extend = require('lodash/extend')
cookies = require('shared/lib/cookies.coffee')
utils = require('shared/lib/utils.coffee')
moment = require('moment')

module.exports =
  set_params: (params) ->
    return if utils.is_open_jaw(params.segments)
    search_params = {
      params_attributes:
        origin:
          name: params.segments[0].origin_name or params.segments[0].origin
          iata: params.segments[0].origin
        destination:
          name: params.segments[0].destination_name or params.segments[0].destination
          iata: params.segments[0].destination
        depart_date: params.segments[0].date
        adults: params.passengers.adults
        children: params.passengers.children
        infants: params.passengers.infants
        trip_class: if params.trip_class == 'Y' then 0 else 1
        range: false
        oneway: params.segments.lenght == 1
        one_way: params.segments.lenght == 1
    }

    if params.segments[1]?
      search_params.params_attributes.return_date = params.segments[1].date

    cookies.set('flight_search_params', JSON.stringify(search_params), 'unlimited')

  get_params: ->

    search_params = _extend({params_attributes: {}}, JSON.parse(cookies.get('flight_search_params')))
    today = moment()
    depart_date = moment(search_params.params_attributes.depart_date, 'YYYY-MM-DD')

    if today > depart_date
      depart_date = today.add(1, 'days')

    segments = [{
      origin: search_params.params_attributes.origin?.iata || ''
      origin_name: search_params.params_attributes.origin?.name || ''
      destination: search_params.params_attributes.destination?.iata || ''
      destination_name: search_params.params_attributes.destination?.name || ''
      date: depart_date.format('YYYY-MM-DD')
    }]

    if search_params.params_attributes.return_date and !search_params.params_attributes.oneway and !search_params.params_attributes.one_way
      return_date = moment(search_params.params_attributes.return_date, 'YYYY-MM-DD')

      if depart_date > return_date
        return_date = depart_date.clone().add(7, 'days')

      segments.push({
        origin: segments[0].destination
        origin_name: segments[0].destination_name
        destination: segments[0].origin
        destination_name: segments[0].origin_name
        date: return_date.format('YYYY-MM-DD')
      })

    params = {
      trip_class: if +search_params.params_attributes.trip_class == 1 then 'C' else 'Y'
      passengers:
        adults: +search_params.params_attributes.adults || 1
        children: +search_params.params_attributes.children || 0
        infants: +search_params.params_attributes.infants || 0
      segments: segments
    }

    return params
