<div class="user-settings-mobile-modal">
  <header class="user-settings-mobile-header">
    <h3 class="user-settings-mobile-header__title">{{ __('user_mobile_settings.currency_and_language') }}</h3>
    <span class="user-settings-mobile-header__close" role="user-settings-modal-close">+</span>
  </header>
  <div class="user-settings-mobile-content">
    <form class="user-settings-mobile-form" role="user-settings-mobile" id="user_settings_mobile_form" action="/submit">
      <div class="user-settings-mobile-form-element">
        <label class="user-settings-mobile-form-element__label" for="mobile_locale">{{ __('user_mobile_settings.language') }}</label>
        <select class="user-settings-mobile-form-element__select user-settings-mobile-form-element__select--{{ flag | lowercase }}" role="mobile-locale-switcher"
          id="mobile_locale" name="mobile_locale">
          {% for elem of locales %}
            {% for item of elem %}
            <option selected="{{ (locale | lowercase) === (item.code | lowercase) }}" value="{{ item.code | lowercase }}">
              {{item.language}}
            </option>
            {% endfor %}
          {% endfor %}
        </select>
      </div>
      <div class="user-settings-mobile-form-element">
        <label class="user-settings-mobile-form-element__label" for="mobile_currency">{{ __('user_mobile_settings.currency') }}</label>
        <span class="user-settings-mobile-form-element__add user-settings-mobile-form-element__add--currency">{{ currency }}</span>
        <select class="user-settings-mobile-form-element__select" role="mobile-currency-switcher" id="mobile_currency" name="mobile_currency">
          {% for current_currency of currencies %}
            {% for code, name of current_currency %}
              <option selected="{{ (code | lowercase) === currency }}" value="{{ code | lowercase }}">
                {{ name[0].toUpperCase() + name.substring(1) }}
              </option>
            {% endfor %}
          {% endfor %}
        </select>
        <span class="user-settings-mobile-form-element__add user-settings-mobile-form-element__add--font currency_font--{{ currency }}"></span>
      </div>
    </form>
    <button type="button" role="locale-fast-switcher" data-locale="en" class="user-settings-mobile-content__fast-button">SWITCH TO ENGLISH</button>
    <button type="submit" form="user_settings_mobile_form" class="user-settings-mobile-content__submit">{{ __('user_mobile_settings.apply') }}</button>
  </div>
</div>
