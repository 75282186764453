import _forEach from 'lodash/forEach'
import _assign from 'lodash/assign'
import DOMComponent from 'shared/components/base'
import UserSettings from 'shared/components/user_settings/user_settings.js'
import cssx from 'cssx'
import dispatcher from 'shared/lib/dispatcher'
import marker from 'shared/lib/marker.coffee'
import metrics from 'shared/lib/metrics.coffee'
import moment from 'moment'
import previewParamsParser from 'shared/lib/preview_params.coffee'
import stickySetter from 'shared/lib/sticky_setter.js'
import utils from 'shared/lib/utils.coffee'
import _defaultsDeep from 'lodash/defaultsDeep'
import constants from 'shared/constants.js'
var previewParams = previewParamsParser.parse()



var sheet = cssx('tpwl-css-mewtwo')
sheet.scope('.mewtwo-widget')

sheet.add({
  '.mewtwo-flights .mewtwo-custom_checkbox_wrapper .mewtwo-custom_checkbox': {
    'background-image': "url('data:image/svg+xml,%3Csvg%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%208.5%206%22%3E%3Cstyle%3E.st0%7Bfill%3A%23#{window.TPWLCONFIG.color_scheme.text_contrast}%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M8.5%201.1L7.4%200%203.6%203.8%201.1%201.3%200%202.4%203.6%206z%22%2F%3E%3C%2Fsvg%3E') !important"
  }
})

const MEWTWO_ENGINE_MAP = {
  'combined': 'avia_hotel',
  'avia': 'avia',
  'hotel': 'hotel'
}

const SELECTORS_METRICS_DICT = {
  '.mewtwo-show_hotels__label': 'CLICKTRIPZ_CLICK',
  '.mewtwo-tabs-tabs_list__item--hotels': 'FORMS_HOTEL_TAB_CLICK',
  '.mewtwo-flights-origin': 'ORIGIN_FIELD_CLICK',
  '.mewtwo-flights-destination': 'DESTINATION_FIELD_CLICK',
  '.mewtwo-flights-dates-depart': 'DEPART_DATE_FIELD_CLICK',
  '.mewtwo-flights-dates-return': 'RETURN_DATE_FIELD_CLICK',
  '.mewtwo-flights-submit_button': 'SEARCH_START_BUTTON_CLICK',
  '.mewtwo-flights-trip_class': 'PASSENGERS_FIELD_CLICK'
}

const FRONTPAGE_ONLY_METRICS_DICT = {
  '.mewtwo-flights-submit_button': 'FRONTPAGE_SEARCH_START'
}

export default class MewtwoBase extends DOMComponent {
  static defaultOptions () {
    return {
      name: 'mewtwo',
      state: window.TPWLCONFIG
    }
  }

  constructor (containerNode, options = {}) {
    let handleOptions = {}
    let handle = `${containerNode.dataset.handle || 'whitelabel'}_${LOCALE}`
    handleOptions.state = {handle: handle}
    options = _defaultsDeep(options, handleOptions)
    super(containerNode, options)
    this.initConfig(this.state)
    // let timeoutID = setTimeout(() => {
    //   timeoutID = false
    //   this._initScript(containerNode)
    // }, 1000)
    this._initScript(containerNode)
    dispatcher.on('marker_restored', () => {
      window.TP_FORM_SETTINGS[handle]['marker'] = marker.get()
      window.TP_FORM_SETTINGS[handle]['additional_marker'] = marker.get().split('.').slice(1).join('.')

      // if (timeoutID) {
      //   clearTimeout(timeoutID)
      //   timeoutID = false
      //   this._initScript(containerNode)
      // }
    })
  }

  _initScript (element) {
    if (window.MewtwoIsLoaded || !(window.document.createElement('link').relList && window.document.createElement('link').relList.supports && window.document.createElement('link').relList.supports('preload'))) {
      let script = document.createElement('script')
      script.src = `/widgets/${this.state.handle}.js`
      script.setAttribute('role', 'mewtwo_widget')
      script.setAttribute('relative-path', 'true')
      // script.src = `http://localhost:9292/mewtwo/init.js?handle=${this.state.handle}`
      let engineType = this.state.engine_type
      if (engineType !== 'combined') { element.classList.add('tpwl-main-form--single_tab') }

      script.onload = function () {
        setTimeout(() => {
          element.style.display = ''
        }, 500)

        let checksCount = 20
        let checkIfInitialized = (callback) => {
          let fSNode = document.querySelector('[role="flights_submit"]')
          let hSNode = document.querySelector('[role="hotels_submit"]')
          let wNode = document.querySelector('[is="mewtwo"]')
          if ((fSNode || hSNode) && wNode && wNode.offsetHeight) {
            callback()
          } else {
            checksCount -= 1
            if (checksCount > 0) { setTimeout(() => { checkIfInitialized(callback) }, 500) };
          }
        }
        checkIfInitialized(() => {
          setTimeout(() => {
            dispatcher.send('mewtwo_init')
            let merticsDict = SELECTORS_METRICS_DICT
            if (utils.is_front_page()) { merticsDict = _assign({}, merticsDict, FRONTPAGE_ONLY_METRICS_DICT) }
            _forEach(merticsDict, (goal, selector) => {
              let node = element.querySelector(selector)
              if (node) { node.addEventListener('click', () => { metrics.reach_goal(goal) }) }
            })
            stickySetter.unlock()
          }, 1000)
        })
      }
      element.style.display = 'none'
      element.appendChild(script)
    } else {
      setTimeout(() => { this._initScript(element) }, 100)
    }
  }

  initConfig (config = this.state, params = {}, formTypeParams = {}) {
    window.TP_MEWTWO_SKIPSTYLES = true
    var additionalParams = []
    if (previewParamsParser.is_preview()) {
      additionalParams = [
        {
          'name': 'preview',
          'value': previewParams.preview
        },
        {
          'name': 'host',
          'value': previewParams.host
        }
      ]
      config.host = window.location.host
    }


    if(constants.IS_LOCALHOST) config.host = window.location.host

    if (config.default_origin) {
      config.default_origin_name = config.default_origin.name
      config.default_origin_iata = config.default_origin.iata
      config.default_origin_country_name = config.default_origin.country_name
    }
    if (config.default_destination) {
      config.default_destination_name = config.default_destination.name
      config.default_destination_iata = config.default_destination.iata
      config.default_destination_country_name = config.default_destination.country_name
    }
    if (config.hotel && !config.hotel_name) {
      config.hotel_name = config.hotel.name
    }

    window.TP_FORM_SETTINGS = window.TP_FORM_SETTINGS || {}

    let showHotels = true
    if (window.TPWithoutClicktripz || window.top !== window) {
      showHotels = false
    } else {
      showHotels = config.hasOwnProperty('show_hotels') ? config.show_hotels : true
    }

    let showFlightsDatepicker = params.origin && params.origin.iata && params.destination && params.destination.iata && !params.depart_date
    window.TP_FORM_SETTINGS[config.handle] = {
      'force_config': true,
      'silent_metrics': true,
      'skip_highlight': true,
      'multi_city': true,
      'powered_by': false,
      'handle': config.handle,
      'border_radius': '0',
      'additional_marker': 'whitelabel',
      'width': config.width ? config.width : null,
      'show_logo': false,
      'show_hotels': showHotels,
      'booking': showHotels,
      'form_type': MEWTWO_ENGINE_MAP[config.engine_type],
      'locale': LOCALE,
      'currency': UserSettings.getCurrency() || config.currency,
      'sizes': 'default',
      'check_in_date': params.depart_date,
      'check_out_date': params.return_date || (params.depart_date && moment(params.depart_date).add(1, 'days').format('YYYY-MM-DD')),
      'search_target': '_self',
      'active_tab': showFlightsDatepicker ? 'avia' : (utils.is_front_page() && window.TPWLCONFIG.active_tab) || formTypeParams.active_tab,
      'focus_datepicker': showFlightsDatepicker,
      'search_host': `${config.host}/flights`,
      'hotels_host': `${config.host}/hotels`,
      'hotel': {
        'name': config.hotel_name || '',
        'location': '',
        'search_type': 'city'
      },
      'hotel_alt': '',
      'avia_alt': '',
      'retargeting': false,
      'depart_date': params.depart_date || '',
      'return_date': params.return_date || '',
      'marker': marker.get(),
      'origin': {
        'name': (params.origin && params.origin.name) ||
          ((!params.origin || (!params.origin.name && !params.origin.iata)) &&
            config.default_origin_name) || '',
        'iata': (params.origin && params.origin.iata) ||
          ((!params.origin || (!params.origin.name && !params.origin.iata)) &&
            config.default_origin_iata) || '',
        'country_name': (params.origin && params.origin.country_name) || (!params.origin && config.default_origin_country_name) || ''
      },
      'destination': {
        'name': (params.destination && params.destination.name) ||
          ((!params.destination || (!params.destination.name && !params.destination.iata)) &&
            config.default_destination_name) || '',
        'iata': (params.destination && params.destination.iata) ||
          ((!params.destination || (!params.destination.name && !params.destination.iata)) &&
            config.default_destination_iata) || '',
        'country_name': (params.destination && params.destination.country_name) || (!params.destination && config.default_destination_country_name) || ''
      },
      'open_multi': false,
      'segments': [],
      'passengers': {
        'adults': params.adults || 1,
        'children': params.children || 0,
        'infants': params.infants || 0
      },
      'guests': params.guests || {
        'adults': params.adults || 2,
        'children': 0,
        'children_age': []
      },
      'color_scheme': {
        'icon': config.color_scheme.bg,
        'background': config.color_scheme.bg,
        'color': config.color_scheme.text_contrast,
        'border_color': config.color_scheme.border,
        'button': config.color_scheme.btn_bg,
        'button_text_color': config.color_scheme.btn_text,
        'input_border': '#ffffff'
      },
      'best_offer': {'routes': []},
      'responsive': true,
      'trip_class': formTypeParams.trip_class,
      'force_currency': false,
      'additional_params': additionalParams,
      'popup_open_callback': function (options) {
        window.TP_DISPATCHER && window.TP_DISPATCHER.send('modal_opened', options)
      },
      'popup_close_callback': function (options) {
        window.TP_DISPATCHER && window.TP_DISPATCHER.send('modal_closed', options)
      }
    }
  }

  _initEvents (dispatcher) {
    if(constants.IS_LOCALHOST){
      // On localhost, we do not want form link to point at tp.media,
      // Because we do not want redirect to be processed and counted as real user-made redirect
      // therefore we are just bypassing default submit behaviour of mewtwo by pointing form directly at localhost

      const interceptSubmit = (buttonSelector, action) => {
        document.querySelector(buttonSelector).addEventListener('click', e => {
          const form = e.target.closest('form')
          e.preventDefault();
          e.stopPropagation()
          form.action = action;
          form.submit()
        }, {capture:true})
      }


      dispatcher.on('mewtwo_init', ()=>{
        interceptSubmit('[role=flights_submit]', '/flights')
        interceptSubmit('[role=hotels_submit]', '/hotels')
      })
    }


    dispatcher.on('currency_updated', (event, currency) => {
      if(window.TP_FORM_SETTINGS && window.TP_FORM_SETTINGS[this.state.handle]){
        window.TP_FORM_SETTINGS[this.state.handle].currency = currency
      }

      if(window.mewtwoForms){
        window.mewtwoForms.forms.forEach((form) => {
          let input = form.wrapper.querySelector('input[name="currency"][type="hidden"]')
          if (input) input.value = currency

        })
      }
    })
  }
}
