_isEmpty = require('lodash/isEmpty')
_isNull = require('lodash/isNull')
_clone = require('lodash/clone')
_map = require('lodash/map')
_filter = require('lodash/filter')
_every = require('lodash/every')
_intersection = require('lodash/intersection')
_includes = require('lodash/includes')
bench = require('shared/lib/bench.coffee')

proposal_filters =
  baggage: (proposal, filter_value) ->
    ((filter_value[0] == 1 || filter_value[1] == 1) && proposal.hasBaggage)  ||
    ((filter_value[0] == 0 || filter_value[1] == 0) && !proposal.hasBaggage)

  gates: (proposal, filter_value) ->
    # magic gates have id with minus
    _includes(filter_value, proposal.gate_id) or _includes(filter_value, (+proposal.gate_id * -1).toString())

  max_unified_price: (proposal, filter_value) -> proposal.unified_price <= filter_value

  min_unified_price: (proposal, filter_value) -> proposal.unified_price >= filter_value

ticket_filters =
  tour_ticket: (ticket, filter_value) -> !filter_value or ticket.hotel?

  stops_count: (ticket, filter_value) ->
    ticket.max_stops in filter_value

  max_stop_duration: (ticket, filter_value) ->
    return true if ticket.max_stop_duration == 0
    ticket.max_stop_duration <= filter_value

  min_stop_duration: (ticket, filter_value) ->
    return true if !ticket.min_stop_duration
    ticket.min_stop_duration >= filter_value

  segments_time: (ticket, filter_value) ->
    for index in filter_value
      [filter_departure_time, filter_arrival_time] = filter_value[index]
      [ticket_departure_time, ticket_arrival_time] = ticket.segments_time[index]
      if ticket_departure_time < filter_departure_time
        return false
      if ticket_arrival_time > filter_arrival_time
        return false
    true

  segments_airports: (ticket, filter_value) ->
    for index in filter_value
      [filter_departure_list, filter_arrival_list] = filter_value[index]
      [ticket_departure, ticket_arrival] = ticket.segments_airports[index]
      return false if !_includes(filter_departure_list, ticket_departure)
      return false if !_includes(filter_arrival_list, ticket_arrival)
    true

  departure_airports: (ticket, filter_value) ->
    departure_airports_list = [ticket.segments_airports[0][0]]
    if ticket.segments_airports.length > 1 and ticket.segments_airports[0][0] != ticket.segments_airports[1][1]
      departure_airports_list.push(ticket.segments_airports[1][1])
    _intersection(departure_airports_list, filter_value).length == departure_airports_list.length

  arrival_airports: (ticket, filter_value) ->
    departure_airports_list = [ticket.segments_airports[0][1]]
    if ticket.segments_airports.length > 1 and ticket.segments_airports[0][1] != ticket.segments_airports[1][0]
      departure_airports_list.push(ticket.segments_airports[1][0])
    _intersection(departure_airports_list, filter_value).length == departure_airports_list.length

  stops_airports: (ticket, filter_value) ->
    _every(ticket.stops_airports, (iata) -> filter_value.indexOf(iata) != -1)

  max_flight_duration: (ticket, filter_value) ->
    _filter(ticket.segment_durations, (duration)-> duration > filter_value).length == 0

  min_flight_duration: (ticket, filter_value) ->
    _filter(ticket.segment_durations, (duration)-> duration < filter_value).length == 0

  kraynov_filter: (ticket, filter_value) ->
    _intersection(_map(ticket.segment, (t) -> t.hash), filter_value).length == filter_value.length

  _flight_time_filter: (ticket, filter_value, segment, direction) ->
    direction_hash = {
      departure: 0
      arrival: ticket.segment[segment].flight.length - 1
    }
    ticket.segment[segment].flight[direction_hash[direction]]["#{direction}_time"] >= filter_value[0] and
    ticket.segment[segment].flight[direction_hash[direction]]["#{direction}_time"] <= filter_value[1]

  arrival_datetime: (ticket, filter_value, segment) ->
    flight_index = ticket.segment[segment].flight.length - 1
    ticket.segment[segment].flight[flight_index]["local_arrival_timestamp"] >= filter_value[0] and
    ticket.segment[segment].flight[flight_index]["local_arrival_timestamp"] <= filter_value[1]

  departure_time: (ticket, filter_value, segment) ->
    ticket.segment[segment].flight[0]["departure_time"] >= filter_value[0] and
    ticket.segment[segment].flight[0]["departure_time"] <= filter_value[1]

  airlines: (ticket, filter_value) ->
    validating_carrier =  ticket.validating_carrier
    _includes(filter_value, validating_carrier)

# speed is around 0.1ms
smart_clone = (tickets) -> _map(tickets, (ticket) -> [ticket[0], _clone(ticket[1])])

filter = bench('filter', ((tickets, filters_state) ->
  filters_by_segment = {}
  # here we are tryin to catch all filter names with segment in the end
  FILTERS_WITH_SEGMENT_REGEXP = /(departure_time|arrival_datetime)_(\d)/
  for name, filter_value of filters_state
    if matched = name.match(FILTERS_WITH_SEGMENT_REGEXP)
      filters_by_segment[name] =
        filter_name: matched[1]
        segment: parseInt(matched[2])

  _filter(smart_clone(tickets), (ticket) ->
    ticket[1] = _filter(ticket[1], (proposal) ->
      for name, filter_value of filters_state
        continue if _isNull(filter_value) or !proposal_filters[name]
        if(!proposal_filters[name](proposal, filter_value))
          return false
      true
    )

    return false if _isEmpty(ticket[1])

    for name, filter_value of filters_state
      if filter_by_segment = filters_by_segment[name]
        # filter with segment like departure_time or arrival_datetime
        continue if _isNull(filter_value)
        if(!ticket_filters[filter_by_segment.filter_name](ticket[0], filter_value, filter_by_segment.segment))
          return false
      else
        # filter by exact name
        continue if _isNull(filter_value) or !ticket_filters[name]
        if(!ticket_filters[name](ticket[0], filter_value))
          return false

    true
  )), {tickets: (args) -> args[0].length} )

module.exports = {
  filter: filter
}
