import _assign from 'lodash/assign'
import _isEmpty from 'lodash/isEmpty'
import _forEach from 'lodash/forEach'
import _values from 'lodash/values'
import _find from 'lodash/find'
import dispatcher from 'shared/lib/dispatcher'

const dictionary = {
  search_id: {},
  airports: {},
  airlines: {},
  gates: {},
  currencies: {},
  airports_by_name: {},
  airline_rules: {},
  districts: {},
  proposals_options: {},
  property_types: {},
  room_types: {},
  hotels_amenities: {},
  locations: {},
  rooms: {},
  pois: {},
  trustyou: {},
  airportDeclByIata (iata, decl) {
    let result = ''

    if (this.airports[iata]) {
      let airport = this.airports[iata]
      result = airport.city

      if (LOCALE === 'ru') {
        if (airport['cases'] && airport['cases'][decl]) {
          result = airport['cases'][decl]
        } else {
          result = this.airportDeclByCity(iata, decl) || result
        }
      }
    }

    return result
  },
  airportDeclByCity (iata, decl) {
    _forEach(this.airports, (value, key) => {
      if (value['city_code'] === iata && value['cases'] && value['cases'][decl]) {
        this.airports[iata]['cases'] = value['cases']
        return value['cases'][decl]
      }
    })
    return false
  },
  airportByIata (iata) {
    return this.airports[iata] || {name: iata, city: iata}
  },
  airlineByCode (code) {
    return this.airlines[code] || {economyLegroom: 0, firstLegroom: 0, name: code, wifi: false, baggage: {}}
  },
  gateById (id) {
    return this.gates[id] || {label: false}
  },
  getName (dictKey, id) {
    return this.getRow(dictKey, id, 'name')
  },
  getRow (dictKey, id, field = false) {
    if (this[dictKey][id] === undefined) {
      console.warn(`Do not have "${dictKey}" with id "${id}"`)
      return undefined
    } else return field ? this[dictKey][id][field] : this[dictKey][id]
  }
}

_forEach(dictionary, (dict, name) => {
  if (typeof (dict) === 'object' && _isEmpty(dict)) {
    dispatcher.on(`${name}_updated`, (__, params) => {
      _assign(dict, params[name])
    })
  }
})

dispatcher.on('places_restored', (name, params) => {
  var places = {}
  _forEach(params.segments, (segment) => {
    places[segment.origin] = {name: segment.origin_name, city: segment.origin_name_name || segment.origin_name}
    places[segment.destination] = {name: segment.destination_name, city: segment.destination_name_name || segment.destination_name}
  })
  _assign(dictionary.airports, places)
})

export default dictionary
