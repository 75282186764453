import dispatcher from 'shared/lib/dispatcher'
import URLFragment from 'shared/lib/url_fragment.js'

let ModalHistory = {
  modals: {},
  init () {
    dispatcher.on('modal_opened', (event, params) => {
      let modal = this.modals[params.id] = params
      let ids = URLFragment.state.mds || []

      if (ids.indexOf(modal.id) === -1 && !modal.opened) {
        ids.push(modal.id)
        modal['urlBeforeUpdate'] = window.location.href
        URLFragment.update({mds: ids}, {pushState: true})
      }
      modal.opened = true
    })
    dispatcher.on('modal_closed', (event, {id}) => {
      let modal = this.modals[id]
      if (!modal) return false
      let ids = URLFragment.state.mds || []
      let index = ids.indexOf(id)
      if (index !== -1 && modal.opened) {
        ids.splice(index, 1)
        URLFragment.update({mds: ids})
        if (modal['urlBeforeUpdate'] === window.location.href) window.history.go(-1)
        modal.opened = false
      }
    })
    URLFragment.onChange((hash, oldState, newState) => this.changed(oldState.mds, newState.mds))
  },
  changed (oldModals, newModals) {
    if (oldModals === undefined) oldModals = []
    if (newModals === undefined) newModals = []

    oldModals.forEach((id) => {
      if (newModals.indexOf(id) === -1 && this.modals[id]) {
        this.modals[id].opened = false
        this.modals[id].closeFunc()
      }
    })

    let goodModals = []
    let hasBadModal
    newModals.forEach((id) => {
      let isGoodModal = !!this.modals[id]
      if (oldModals.indexOf(id) === -1 && isGoodModal) {
        this.modals[id].opened = true
        this.modals[id].openFunc()
      }

      if (isGoodModal) goodModals.push(id)
      else hasBadModal = true
    })

    if (hasBadModal) URLFragment.update({mds: goodModals})
  }
}

ModalHistory.init()

export default ModalHistory
