ticket = require('shared/lib/ticket.coffee')
bench = require('shared/lib/bench.coffee')

PRICE = 'price'
DURATION = 'duration'
RATING = 'rating'

_compare = (a, b) ->
  return 1 if a > b
  return -1 if a < b
  return 0

sortings = {
  price: ([ticket1, proposals1], [ticket2, proposals2]) ->
    try
      return _compare(+proposals1[0]['unified_price'], +proposals2[0]['unified_price']) or
        _compare(ticket1.total_duration, ticket2.total_duration) or
        _compare(ticket1.sign, ticket2.sign)
    catch error
      return 0  if !proposals1[0] && !proposals2[0]
      return 1  if !proposals1[0]
      return -1 if !proposals2[0]
      throw error

  duration: ([ticket1, proposals1], [ticket2, proposals2]) ->
#invert comparison coz true > false
    -_compare(ticket1.is_direct, ticket2.is_direct) or
      (if ticket1.is_direct and ticket2.is_direct then sortings.price([ticket1, proposals1], [ticket2, proposals2]) else 0) or
      _compare(ticket1.total_duration, ticket2.total_duration)or
      _compare(ticket1.sign, ticket2.sign)
  earliest_depart: ([ticket1, proposals1], [ticket2, proposals2]) ->
    _compare(ticket.departure_timestamp(ticket1), ticket.departure_timestamp(ticket2)) or
      (if ticket1.is_direct and ticket2.is_direct then sortings.price([ticket1, proposals1], [ticket2, proposals2]) else 0) or
      _compare(ticket1.total_duration, ticket2.total_duration) or
      _compare(ticket1.sign, ticket2.sign)
  latest_depart: ([ticket1, proposals1], [ticket2, proposals2]) ->
    -_compare(ticket.departure_timestamp(ticket1), ticket.departure_timestamp(ticket2)) or
      (if ticket1.is_direct and ticket2.is_direct then sortings.price([ticket1, proposals1], [ticket2, proposals2]) else 0) or
      _compare(ticket1.total_duration, ticket2.total_duration) or
      _compare(ticket1.sign, ticket2.sign)
  stops: ([ticket1, proposals1], [ticket2, proposals2]) ->
    _compare(ticket1.max_stops, ticket2.max_stops) or
      (if ticket1.is_direct and ticket2.is_direct then sortings.price([ticket1, proposals1], [ticket2, proposals2]) else 0) or
      _compare(ticket1.total_duration, ticket2.total_duration) or
      _compare(ticket1.sign, ticket2.sign)
  rating: ([ticket1, proposals1], [ticket2, proposals2]) ->
    _compare(+ticket1.ticket_rating, +ticket2.ticket_rating) or
      _compare(ticket1.sign, ticket2.sign)
}

sort = bench('sort', ((tickets, criteria) ->
  tickets.sort(sortings[criteria])), {tickets: (args) -> args[0].length})

module.exports = {
  criteria: {
    PRICE: PRICE
    DURATION: DURATION
    RATING: RATING
  },
  sort_funcs: sortings
  sort: sort
}
