_find = require('lodash/find')
_partial = require('lodash/partial')
autocomplete_client = require('shared/lib/autocomplete_client.coffee')

module.exports =
  class PlacesRestorer

    constructor: (@parsed_params, @finish_callback)->
      @callback_count = 0
      @executed = 0
      requests = {}
      for idx in Object.keys(@parsed_params.segments)
        for direction in ['origin', 'destination']
          place_iata = @parsed_params.segments[idx][direction]
          place_name = @parsed_params.segments[idx][direction + '_name']

          if place_iata
            requests[place_iata] or= []
            requests[place_iata].push(_partial(@restore_by_iata, idx, direction))
          else if place_name
            requests[place_name] or= []
            requests[place_name].push(_partial(@restore_by_place, idx, direction))

      for term, callbacks of requests
        @callback_count += callbacks.length
        autocomplete_client().load(term, callbacks)

      @finish_callback(@parsed_params) unless @callback_count

    all_restored: =>
      @executed++
      if @executed == @callback_count
        @finish_callback(@parsed_params)

    restore_by_place: (idx, direction, places = []) =>
      if places
        @parsed_params.segments[idx][direction] = places[0].iata
        @parsed_params.segments[idx]["#{direction}_name"] = places[0].name?.split(',')[0]
        @parsed_params.segments[idx]["#{direction}_city_iata"] = places[0].city_iata
        @parsed_params.segments[idx][direction + '_country'] = places[0].country_iata
      @all_restored()

    restore_by_iata: (idx, direction, places = []) =>
      result = null

      if places?.length == 1
        result = places[0]
      else
        result = _find(places, (place) => @parsed_params.segments[idx][direction] == place.code and place._type is 'city')

      if not result
        result = _find(places, (place) => @parsed_params.segments[idx][direction] == place.code)

      # Some times, destination (and maybe origin, ¯\_(ツ)_/¯) replaces by another (line SVO -> MOW),
      # in this case we cannot find old iata in response and need to look up "original_destination".
      if not result
        result = _find(places, (place) => @parsed_params.segments[idx]['original_' + direction] == place.code)

      if result
        @parsed_params.segments[idx][direction + '_name'] = result.name
        @parsed_params.segments[idx][direction + '_city_iata'] = result.city_code || result.code
        @parsed_params.segments[idx][direction] = result.code
        @parsed_params.segments[idx][direction + '_country'] = result.country_code
      @all_restored()
