import _defaultsDeep from 'lodash/defaultsDeep'
import Select from 'shared/components/select/select.js'
import Template from 'shared/components/filters/sorting/sorting.monk'
import dispatcher from 'shared/lib/dispatcher'

const SORTINGS = {
  'price': __('sorting.price'),
  'duration': __('sorting.duration'),
  'earliest_depart': __('sorting.earliest_depart'),
  'latest_depart': __('sorting.latest_depart')
}

export default class Sorting extends Select {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'sorting',
      render: {
        template: Template,
        options: {
          filters: {
            formatSelected (text = 'd') {
              return `<span class="sorting_current_prefix">${__('sorting.by')}</span> ${text.toLowerCase()}`
            }
          }
        }
      },
      state: {
        title: __('sorting.title'),
        selectedId: 'price',
        options: SORTINGS
      }
    }, super.defaultOptions())
  }

  getState () {
    return {request_id: this.state.request_id, sort_order: this.state.selectedId}
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)

    view.on('click', 'li', (event, target) => {
      if (this.state.selectedId !== target.dataset.optionId && target.dataset.optionId !== undefined) {
        this.state.selectedId = target.dataset.optionId
        this.refresh()
        dispatcher.send('sort_order_updated', this.getState(), 'sorting')
      }
      this.hideModalPromise = setTimeout(() => { this.hideModal() }, 100)
    })
  }
}
