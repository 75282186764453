import _xor from 'lodash/xor'
import _forEach from 'lodash/forEach'
import _values from 'lodash/values'
import _isEmpty from 'lodash/isEmpty'
import _extend from 'lodash/extend'
import _map from 'lodash/map'
import _mapValues from 'lodash/mapValues'
import _keys from 'lodash/keys'
import _sortBy from 'lodash/sortBy'
import _defaultsDeep from 'lodash/defaultsDeep'
import BaseFilter from '../base/base.js'
import Template from './checkbox.monk'
import _cloneDeep from 'lodash/cloneDeep'
import _reduce from 'lodash/reduce'

export default class CheckboxFilter extends BaseFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'checkbox_filter',
      render: {
        template: Template,
        options: {
          filters: {
            decorateMeasurement (value) {
              return value || ''
            }
          }
        }
      },
      state: {
        title: 'checkbox',
        labels: (id) => id,
        keys: [],
        options: {},
        collapsed: true
      },
      collapsedCheckboxes: false,
      manualExpanded: false,
      defaultHeight: 51,
      data: {}
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    if (this.options.options.has_check_all) {
      this.state.checked_all = this.state.has_check_all = true
    }
    if (this.options.options.has_only_button) {
      this.state.has_only_button = true
    }
    this.state.rate = this.options.options.rate || 1
    this.state.filter_id = encodeURIComponent(this.options.name).replace(/%/g, '')
    this.forced = false
    this.checkboxWrapper = this.view.querySelector('[role="filter-body"]')
    this.collapseCheckboxes()
  }

  update (data, keys) {
    this.data = data
    if (!keys) { keys = _sortBy(_keys(this.data), (id) => this._keysSortFunction(id)) }
    let oldKeys = this.state.keys.slice()

    let _data = _cloneDeep(this.data)
    super.update({'options': _data, keys})
    this.setStates(_data, oldKeys, keys)
    this.collapseCheckboxes()
  }

  _keysSortFunction (key) {
    let label = this.state.labels(key)
    if (/[\u0400-\u04FF]/.test(label)) label = `AAA${label}` // set cyrillic first
    return label
  }

  updateState (data, forced = false) {
    _keys(this.options.filterState).forEach((key) => { this.options.filterState[key] = false })
    this.state.keys = _keys(data)
    let _data = _cloneDeep(data)
    super.updateState(_data, forced)
  }

  getState () {
    let state = {}
    _forEach(super.getState(), (value, index) => { if (value) { state[index] = value } })
    return _mapValues(state, (flag) => { return {'checked': flag} })
  }

  getShortState () {
    let result = []
    _forEach(super.getState(), (value, index) => { if (value) { result.push(index) } })
    return result
  }

  collapseCheckboxes () {
    if (this.needCollapsed()) {
      this.view.createDocument().querySelector('[role="collaps_checkboxes"]')
        .classList.remove('control-list-opener-wrapper--hidden')
      this.previewHeight = this.calculateCollapsedHeight(this.checkboxWrapper)
      this.transformVisibleCheckboxes(this.previewHeight + 'px', true)
    } else if (!this.options.manualExpanded) {
      this.view.createDocument().querySelector('[role="collaps_checkboxes"]')
        .classList.add('control-list-opener-wrapper--hidden')
      this.transformVisibleCheckboxes('auto')
    }
  }

  setStates (data, oldKeys, keys) {
    let oldFiltersState = _extend({}, this.options.filterState)

    if (this.forced && _isEmpty(data)) {
      data = oldFiltersState
      oldKeys = this.state.keys = _keys(data)
    }
    this.options.defaultFilterState = {}
    this.options.filterState = {}

    let defaultValue = _values(oldFiltersState).indexOf(false) === -1 && !this.forced
    _forEach(data, (val, id) => {
      this.options.defaultFilterState[id] = true
      this.options.filterState[id] = oldKeys.indexOf(id) !== -1 ? !!oldFiltersState[id] : defaultValue
    })

    if (_xor(oldKeys, keys)[0]) { this.actualizeChecked() }
  }

  actualizeChecked () {
    let forceUncheck = this.isInvertAndNeedForceUncheck(this.options.filterState, false)
    this.setCheckboxesState(forceUncheck)
  }

  onChange (sendCallback = true, forceUncheck = false) {
    super.onChange(sendCallback)
    this.setCheckboxesState(forceUncheck)
  }

  setCheckboxesState (forceUncheck = false) {
    if (this.state.has_check_all) {
      this.state.checked_all = this.isDefaultState
      let allNode = this.view.createDocument().querySelector('[role="checkbox_count_all"]')
      if (allNode) allNode.checked = this.state.checked_all
    }
    _forEach(this.view.createDocument().querySelectorAll('[role="filter-option"]'), (node, index) => {
      let stateValue = forceUncheck ? false : this.options.filterState[node.getAttribute('name')]
      if (node.checked !== stateValue) node.checked = stateValue
    })
  }

  resetState () {
    let forceUncheck = this.isInvertAndNeedForceUncheck(this.options.defaultFilterState, false)
    super.resetState(true, forceUncheck)
  }

  isInvertAndNeedForceUncheck (state, allChecked = false) {
    return this.options.options.invert_defaults && _values(state).indexOf(allChecked) === -1
  }

  calculateCollapsedHeight (wrapper) {
    let item = wrapper.querySelector('[role="checkbox_item"]')
    let itemHeight = parseInt(item.offsetHeight) +
      parseInt(window.getComputedStyle(item).getPropertyValue('margin-top')) +
      parseInt(window.getComputedStyle(item).getPropertyValue('margin-bottom'))
    itemHeight = itemHeight > 0 ? itemHeight : this.options.defaultHeight
    return itemHeight * this.options.options.preview_count
  }

  needCollapsed () {
    const userNotCollapsedfByClick = !this.options.manualExpanded
    const notCollapsedBefore = !this.options.collapsed
    const tooMuchItems = this.options.options.preview_count < Object.keys(this.state.options).length
    const manualSetLimitForVisibleItems = this.options.options.preview_count > 0
    return manualSetLimitForVisibleItems && tooMuchItems && notCollapsedBefore && userNotCollapsedfByClick
  }

  transformVisibleCheckboxes (value, forceCollaps = false) {
    if (forceCollaps) { this.options.collapsedCheckboxes = false }
    this.checkboxWrapper.classList.toggle('control--expanded', this.options.collapsedCheckboxes)
    this.options.collapsedCheckboxes = !this.options.collapsedCheckboxes
    this.checkboxWrapper.classList.toggle('control--collapsed', this.options.collapsedCheckboxes)
    this.checkboxWrapper.style.setProperty('height', value)
  }

  parseShortState (state) {
    let result = {}
    state.forEach((id) => { result[parseInt(id, 10)] = true })
    return result
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)

    view.on('click', '[role="show_more_proposals"]', (e) => {
      this.options.manualExpanded = true
      this.transformVisibleCheckboxes('auto')
    })

    view.on('click', '[role="show_less_proposals"]', (e) => {
      this.transformVisibleCheckboxes(this.previewHeight + 'px')
    })

    view.on('change', '[role="checkbox_count_all"]', (event, target) => {
      this.state.checked_all = target.checked
      if (this.state.checked_all && !this.isDefaultState) this.resetState()
      if (this.state.checked_all && this.isDefaultState) {
        this.state.checked_all = target.checked = true
      }
      if (!this.state.checked_all) {
        _forEach(view.createDocument().querySelectorAll('[role="filter-option"]'), (node, index) => {
          this.options.filterState[node.getAttribute('name')] = false
        })
        this.onChange()
      }
    })
    view.on('click', '[role="filter-toggler"]', (event, target) => {
      this.collapseCheckboxes()
    })

    view.on('change', '[role="filter-option"]', (event, eventTarget) => {
      this.options.filterState = {}

      _forEach(view.createDocument().querySelectorAll('[role="filter-option"]'), (node, index) => {
        this.options.filterState[node.getAttribute('name')] = node.checked
      })

      let forceUncheck = this.isInvertAndNeedForceUncheck(this.options.filterState, true)

      if (forceUncheck) {
        _forEach(this.options.filterState, (v, i) => { this.options.filterState[i] = true })
      }
      this.onChange(true, forceUncheck)
    })

    view.on('click', '[role="only_button"]', (event, event_target) => {
      // filters_helper.ga_event('airlines_only')
      event.stopPropagation()
      event.preventDefault()

      let parent = event_target
      let checkbox = false
      for (let i = 0; i < 5; i++) {
        parent = parent.parentElement
        checkbox = parent.querySelector('[role="filter-option"]')
        if(checkbox) break
      }
      this.options.filterState = _mapValues(this.options.filterState, false)
      this.options.filterState[checkbox.name] = true

      this.onChange()
    }, true)
  }
}
