import dispatcher from 'shared/lib/dispatcher'

export default {
  _changeCallbacks: [],
  _currentPage: null,
  _pagesHistory: [],

  get page () { return this._currentPage },
  set page (page) {
    if (page === this._currentPage) return false
    // console.log(`ROUTER page changed from ${this._currentPage} to ${page}`)
    dispatcher.send('page_changed', {new: page, old: this._currentPage}, 'router')
    this._pagesHistory.unshift(this._currentPage)
    this._currentPage = page
  },

  get prevPage () { return this._pagesHistory[0] },

  goBack () { this.page = this._pagesHistory.shift() }
}
