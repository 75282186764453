_map = require('lodash/map')
dispatcher = require('shared/lib/dispatcher')
module.exports = (name, func, additional={}) ->
  ->
    data = {func: name}
    for key, arg_callback of additional
      data[key] = arg_callback(arguments)
    start = performance.now()
    result = func.apply(this, arguments)
    delta = performance.now() - start
    data.time = delta
    dispatcher.send('log_info', _map(data, (value, key) -> "#{key}: #{value}").join(', '), 'benchmark')
#    dispatcher.send('benchmark_info', data, 'benchmark') to do: uncomment after finish pipline
    result

