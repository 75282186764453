import _defaultsDeep from 'lodash/defaultsDeep'
import _map from 'lodash/map'
import _extend from 'lodash/extend'
import _forEach from 'lodash/forEach'
import filters from 'shared/lib/filters.coffee'
import Device from 'shared/lib/device.js'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import UserSettings from 'shared/components/user_settings/user_settings.js'

export default class Stops extends CheckboxFilter {
  static defaultOptions () {
    const color = Device.isTouch() ? '#FFFFFF' : window.TPWLCONFIG.color_scheme.body_bg
    return _defaultsDeep({
      name: 'stops_count',
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.TPWL_widget--xl .filter--stops_count_filter .only-button': {
            'background-color': color
          }
        }
      },
      state: {
        collapsed: false,
        title: __('filters.number_of_stops'),
        labels: (id) => {
          id = +id
          return filters.pluralize(id, _extend(__('filters.stops_name'), { zero: __('filters.stops_zero') })) // stops_zero required by CrowdIn
        }
      },
      render: {
        options: {
          filters: {
            decorateMeasurement (value) {
              this.currency = UserSettings.getCurrency()
              return `<span class="currency_font currency_font--${this.currency}">${filters.split_price(value)}</span>`
            }
          }
        }
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    this.needDafaultSatate = true
  }

  update (data, keys) {
    if ('currency' in data || 'rate' in data) {
      if ('currency' in data) this.state.currency = data.currency
      if ('rate' in data) this.state.rate = data.rate

      this.data = this._convertPrices(this.defaultPrices)
    } else {
      this.defaultPrices = data
      this.data = this._convertPrices(data)
    }

    super.update(this.data, keys)
  }

  updateState (id, forced = false) {
    const state = {}
    if (id in this.state.keys) {
      _map(Object.keys(this.state.keys), i => parseInt(i, 10) === id ? state[i] = true : state[i] = false)
    } else {
      _map(Object.keys(this.state.keys), i => state[i] = true)
    }
    super.updateState(state, forced)
    this.onChange()
  }

  _convertPrices (data) {
    let convertedPrices = {}
    _forEach(data, (val, key) => {
      convertedPrices[key] = (val === false || val === true) ? val : parseFloat(val * this.state.rate, 10)
    })

    return convertedPrices
  }

  _keysSortFunction (key) {
    return parseInt(key)
  }

  getState () {
    let state = super.getState()
    return { stops_count: _map(Object.keys(state), (i) => parseInt(i, 10)) }
  }
}
