import DOMComponent from 'shared/components/base'
import Template from './filters.monk'
import colorUtils from 'shared/lib/color_utils.js'
import Device from 'shared/lib/device.js'
import metrics from 'shared/lib/metrics.coffee'
import URLFragment from 'shared/lib/url_fragment.js'

export default class Filters extends DOMComponent {
  static defaultOptions () {
    const color = Device.isTouch() ? '#FFFFFF' : window.TPWLCONFIG.color_scheme.body_bg
    const transparentColor = colorUtils.clarify(color, 0)
    return {
      name: 'filters',
      cssx: {
        scope: '.TPWL-widget',
        styles: {
          '.TPWL_widget--xl .right-text': {
            'background': `linear-gradient(to right, ${transparentColor} 0%, ${color} 30%)`
          },
          '.TPWL_widget--xl .only-button': {
            'background': `linear-gradient(to right, ${transparentColor} 0%, ${color} 30%)`
          },
          '.tickets_counter': {
            'color': window.TPWLCONFIG.color_scheme.text_interesting
          },
          '.filters-counter .relax_filters': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.filters-counter .relax_filters:after': {
            'background': `url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2010%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Ereset%3C%2Ftitle%3E%3Cpath%20d%3D%22M8.086%204.222c.314.528.485%201.142.485%201.784%200%201.97-1.6%203.567-3.57%203.567-1.97%200-3.57-1.598-3.57-3.567%200-1.898%201.484-3.453%203.356-3.567v.812c0%20.314.228.443.5.286l.77-.443.987-.57.77-.443c.273-.157.273-.414%200-.57l-.77-.443-.986-.572-.77-.428c-.273-.157-.5-.042-.5.257v.685C2.128%201.126%200%203.324%200%206.006%200%208.76%202.243%2011%205%2011s5-2.24%205-4.994c0-.913-.243-1.755-.67-2.497l-1.244.712z%22%20fill%3D%22${encodeURIComponent(window.TPWLCONFIG.color_scheme.link)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E") 0 0 no-repeat`
          },
          '.segment_selection__item': {
            'color': window.TPWLCONFIG.color_scheme.text_interesting
          },
          '.slider-block .barchart .bar:after': {
            'background-color': colorUtils.hexToRgba(
              colorUtils.shadeBlend(0.1, window.TPWLCONFIG.color_scheme.bg, '#000000'), 0.5
            )
          },
          '.cards-filtering_loader-panel .spinner div': {
            'background': window.TPWLCONFIG.color_scheme.bg
          },
          '.filters_layout .filters-counter .relax_filters': {
            'color': window.TPWLCONFIG.color_scheme.link
          },
          '.filters_layout .filters-counter .relax_filters:after': {
            'background': `url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2010%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Ereset%3C%2Ftitle%3E%3Cpath%20d%3D%22M8.086%204.222c.314.528.485%201.142.485%201.784%200%201.97-1.6%203.567-3.57%203.567-1.97%200-3.57-1.598-3.57-3.567%200-1.898%201.484-3.453%203.356-3.567v.812c0%20.314.228.443.5.286l.77-.443.987-.57.77-.443c.273-.157.273-.414%200-.57l-.77-.443-.986-.572-.77-.428c-.273-.157-.5-.042-.5.257v.685C2.128%201.126%200%203.324%200%206.006%200%208.76%202.243%2011%205%2011s5-2.24%205-4.994c0-.913-.243-1.755-.67-2.497l-1.244.712z%22%20fill%3D%22${encodeURIComponent(window.TPWLCONFIG.color_scheme.link)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E") 0 0 no-repeat`
          },
          '.filters_layout .filters-counter .counter span': {
            'color': window.TPWLCONFIG.color_scheme.link
          }
        }
      },
      render: { template: Template },
      state: {}
    }
  }

  static getState () {
    let instance = this.instance()
    return instance ? instance.getState() : {}
  }

  constructor (node, options = {}) {
    super(node, options)
    this.keyMap = {}
    this.containerNode = this.view.createDocument()
  }

  _init () {
    this.filtersKeys = Object.keys(this.filters)
    this.eachFilter((name, [ClassObject, options, container = this.containerNode]) => {
      this.filters[name] = new ClassObject(container, {
        name: `${name}_filter`,
        options,
        onChange: this.onChangeCallback()
      })
    })
    if (this.urlFragmentEnabled) this._setStateFromURLFragment()
  }

  _setStateFromURLFragment () {
    if (URLFragment.state.f !== undefined) this.updateState(this._castFragmentState(URLFragment.state.f), true)
    URLFragment.update({f: this.getShortState()})
  }

  getState () {
    let state = {}
    this.eachFilter((name, filter) => {
      if (!filter.isDefaultState || filter.needDafaultSatate) state[name] = filter.getState()
    })
    return state
  }

  getShortState () {
    let state = {}

    this.eachFilter((name, filter) => {
      if (!filter.isDefaultState || filter.needDafaultSatate) {
        let item = filter.getShortState()
        if (item && item.join('') !== '') state[name] = item
      }
    })

    return state
  }

  onChangeCallback () {
    return (name) => {
      let state = this.getState()
      metrics.reach_goal('FILTERS_CHANGED', {'filter': name, 'values': state})
      this._dispatcher.send('filters_state_updated', state, name)
      if (this.urlFragmentEnabled) URLFragment.update({f: this.getShortState()})
    }
  }

  update (data) {
    this.eachFilter((name, filter) => {
      if (name in data) filter.update(data[name])
    })
  }

  updateState (states, forced = false) {
    this.eachFilter((name, filter) => {
      if (name in states) filter.updateState(states[name], forced)
    })
  }
  updateAll () {
    this._dispatcher.send('filters_state_updated', this.getState(), 'button')
    if (this.urlFragmentEnabled) URLFragment.update({f: this.getShortState()})
  }

  resetState () {
    this.eachFilter((name, filter) => { filter.resetState && filter.resetState() })
  }

  eachFilter (func) {
    for (let i = 0; i < this.filtersKeys.length; i++) {
      func.call(this, this.filtersKeys[i], this.filters[this.filtersKeys[i]])
    }
  }

  _repaintSliders () {
    this.rangeFilters.forEach((key) => { this.filters[key].repaintSlider() })
  }

  _initEvents (dispatcher) {
    dispatcher.on('filters_popup_opened', () => { this._repaintSliders() })

    dispatcher.on('reset_filters', () => {
      this.resetState()
      this.updateAll()
    })

    dispatcher.on('reset_filter', (event, data) => {
      this.eachFilter((name, filter) => { name === data && filter.resetState() })
    })
  }

  _castFragmentState (states) {
    let result = {}

    this.eachFilter((name, filter) => {
      let state = false
      if (states[name] !== undefined) state = states[name]
      else if (states[this.keyMap[name]] !== undefined) state = states[this.keyMap[name]]
      if (state) result[name] = filter.parseShortState(state)
    })

    return result
  }
}

window.f = Filters
