<div class="search_results-wrapper-filters search_results-wrapper-filters--left js-left-column" role="swipe-target">
  <div role="filters" class="js-filters filters hidden">
    <section class="filters_layout">
      <header class="filters-header">
        {{ __('filters.header') }}
        <span role="close_filters"></span>
        <div class="filters-counter">
          <div class="counter">{% unsafe flightsCounter %}</div>
          <div class="relax_filters js-reset-filters {{ clean_state ? 'invisible' : '' }}">
            {% unsafe __('filters.clear_all') %}
          </div>
        </div>
      </header>
      <main class="filters-left js-filters-container">
        <div is="filters" class="search_results-filters"></div>
      </main>
    </section>
  </div>
</div>
