_reduce = require('lodash/reduce')
_map = require('lodash/map')
_map = require('lodash/map')
moment = require('moment')
queryString = require('shared/lib/query_string.js')

parsed_params = {}

module.exports =
  is_preview: ->
    parsed_params.host && (parsed_params.preview == '1' || parsed_params.preview == 'true')

  parse: ->
    params = queryString.parse(window.location.search)
    parsed_params.host = params.host if params.host
    parsed_params.preview = params.preview if params.preview
    parsed_params
