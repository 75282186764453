import _defaultsDeep from 'lodash/defaultsDeep'
import _toPairs from 'lodash/toPairs'
import _flow from 'lodash/flow'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'
import CheckboxFilter from 'shared/components/filters/checkbox/checkbox.js'
import dictionary from 'shared/lib/dictionary.js'

const innovataGateId = '666'

export default class Gates extends CheckboxFilter {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'gates',
      state: {
        title: __('filters.gates'),
        labels: (id) => {
          return dictionary.gateById(id).label
        }
      }
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)
    this.needDafaultSatate = true
  }

  update (data) {
    const keys = this._getKeys(data)
    const state = this._prepareState(keys)
    super.update(state, keys)
  }

  getState () {
    const state = super.getState()
    return {gates: Object.keys(state)}
  }

  _prepareState (data) {
    return data.reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {})
  }

  _getKeys ({meta, gates}) {
    const metaFromId = meta.reduce((acc, gateMeta) => {
      acc[gateMeta.id] = gateMeta
      return acc
    }, {})
    const filter = (pairsGates) =>
      _filter(pairsGates, (gate) =>
        gate[0] > 0 &&
        gate[0] !== innovataGateId &&
        metaFromId[gate[0]] &&
        metaFromId[gate[0]].good_count > 0
      )
    const sortByLabel = (gates) => _sortBy(gates, gate => gate[1].label.toLowerCase())
    const mapById = (gates) => gates.map(gate => gate[0])
    return _flow([_toPairs, filter, sortByLabel, mapById])(gates)
  }
}
