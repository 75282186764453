import _defaultsDeep from 'lodash/defaultsDeep'
import DOMComponent from 'shared/components/base'

export default class InformerWithModal extends DOMComponent {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'abstract_informer_with_modal',
      informerPrefix: 'informer_with_modal'
    }, super.defaultOptions())
  }

  constructor (containerNode, options = {}) {
    super(containerNode, options)
    this.showModalPromise = false
    this.hideModalPromise = false
    this.modalShown = false
    this.modalTouched = true
    this.informerOver = false
    this.modalOver = false
  }

  _initDOMEvents (view) {
    super._initDOMEvents(view)
    this.node = view.createDocument()
    this.documentCallback = (event) => this._closeModal(event.target)

    view.on('mouseover', `[role="${this.options.informerPrefix}-informer"]`, () => {
      if (this.informerOver) return false
      this.informerOver = true
      // console.log('informer OVER')
      if (this.showModalPromise) { clearTimeout(this.showModalPromise) }
      this.showModalPromise = setTimeout(() => { this.showModal() }, 300)
    })

    view.on('mouseout', `[role="${this.options.informerPrefix}-informer"]`, () => {
      // console.log('informer OUT')
      this.informerOver = false
      if (this.showModalPromise) { clearTimeout(this.showModalPromise) }
      if (this.hideModalPromise) { clearTimeout(this.hideModalPromise) }
      this.hideModalPromise = setTimeout(() => { this.hideModal() }, 200)
    })

    view.on('click', `[role="${this.options.informerPrefix}-informer"]`, () => {
      if (!this.modalShown) this.showModal()
    })

    view.on('mouseover', `[role="${this.options.informerPrefix}-modal"]`, () => {
      if (this.modalOver) return false
      this.modalOver = true
      // console.log('modal OVER')
      if (this.hideModalPromise) { clearTimeout(this.hideModalPromise) }
      this.modalTouched = true
    })

    view.on('mouseout', `[role="${this.options.informerPrefix}-modal"]`, () => {
      // console.log('modal OUT')
      this.modalOver = false
      if (this.hideModalPromise) { clearTimeout(this.hideModalPromise) }
      this.hideModalPromise = setTimeout(() => { this.hideModal() }, 700)
    })
  }

  showModal () {
    // console.log('SHOW')
    if (this.hideModalPromise) { clearTimeout(this.hideModalPromise) }

    this.modalShown = true
    this.node.classList.add(`${this.options.informerPrefix}--shown`)
    document.addEventListener('click', this.documentCallback)
    setTimeout(() => { this.modalTouched = false }, 500)
  }

  hideModal () {
    // console.log('HIDE')
    if (this.showModalPromise) { clearTimeout(this.showModalPromise) }
    this.modalShown = false
    this.node.classList.remove(`${this.options.informerPrefix}--shown`)
    document.removeEventListener('click', this.documentCallback)
    this.modalTouched = true
  }

  _closeModal (node) {
    // console.log('CLOSE')
    if (!(this.modalShown && node)) return false

    if (node.tagName === 'BODY' || node.tagName === 'HTML') {
      this.hideModal()
    }

    if (node.tagName !== 'BODY' && node.tagName !== 'HTML') {
      node = node.parentNode
      while (node) {
        if (node.tagName === 'BODY') {
          break
        }
        if (!node.parentNode || (this.modalTouched && node.classList.contains(this.options.informerPrefix))) {
          return
        }

        node = node.parentNode
      }
      return this.hideModal()
    }
  }
}
