{% import dictionary from 'shared/lib/dictionary.js' %}

<div class="ticket-hint">
  <div class="ticket-hint-wrapper">
    <div class="ticket-hint-info__icon ticket-hint-info__icon--logo"></div>
    <div class="ticket-hint-wrapper-info" >
      <span class="ticket-hint-info__information">
        {{ __('airports_precheck.save') }}&nbsp;
        <span class="currency_font currency_font--{{currency}}">{{price}}</span>
        &nbsp;{{ __('airports_precheck.flying') }}
        {% if airport_iata_departure %}
          &nbsp;{{ __('airports_precheck.flying_from') }}&nbsp;{{ dictionary.airportByIata(airport_iata_departure).name }}
        {% endif %}
        {% if airport_iata_arrival %}
          &nbsp;{{ __('airports_precheck.flying_to') }}&nbsp;{{ dictionary.airportByIata(airport_iata_arrival).name }}
        {% endif %}
        {% if !airport_iata_arrival && !airport_iata_departure %}
          &nbsp;{{ __('airports_precheck.flying_near') }}
        {% endif %}
      </span>
      <span class="ticket-hint-info__discription">
        {{ __('airports_precheck.discription') }}
        {% if airport_iata_departure %}
          &nbsp;{{ __('airports_precheck.discription_from') }}
        {% endif %}
        {% if airport_iata_arrival %}
          &nbsp;{{ __('airports_precheck.discription_to') }}
        {% endif %}
        {% if !airport_iata_arrival && !airport_iata_departure %}
          &nbsp;{{ __('airports_precheck.discription_near') }}
        {% endif %}
      </span>
    </div>
  </div>
  <div class="ticket-hint-button" role="hint_button">
    {{ __('airports_precheck.show_and_save') }}
  </div>
</div>
