module.exports = {
  csa_page_options: {
    pubId: 'pub-0240520437227076',
    domainLinkAboveDescription: true,
    instantPreviews: false,
    location: true,
    plusOnes: false,
    sellerRatings: false,
    siteLinks: false,
    linkTarget: '_blank'
  },
  csa_adblocks: [
    {
      destination_city: '',
      params: {
        container: 'ad_top_container',
        minTop: 1,
        width: '100%',
        fontSizeTitle: 13,
        fontSizeDescription: 13,
        colorBackground: '#FFFFFF',
        colorTitleLink: '#000000',
        colorDomainLink: '#000000',
        colorText: '#222222',
        colorLocation: '#000000',
        fontFamilyAttribution: 'Arial',
        noTitleUnderline: true,
        detailedAttribution: false,
        adLoadedCallback (id, ads_loaded) { if (!ads_loaded) { console.log('no ads loaded') } }
      }
    }, {
      destination_city: '',
      params: {
        container: 'ad_bottom_container',
        number: 5,
        width: '100%',
        fontSizeTitle: 13,
        fontSizeDescription: 13,
        colorBackground: '#F1F1F1',
        colorTitleLink: '#000000',
        colorDomainLink: '#000000',
        colorText: '#222222',
        colorLocation: '#000000',
        fontFamilyAttribution: 'Arial',
        noTitleUnderline: true,
        detailedAttribution: false,
        adLoadedCallback (id, ads_loaded) { if (!ads_loaded) { console.log('no ads loaded') } }
      }
    }
  ]
}
