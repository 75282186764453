{% import Spinner from 'shared/components/spinner/spinner.monk' %}
{% import DebugTooltip from './baggage_debugger_tooltip.monk' %}
<div class="ticket-action__container">
  <div class="ticket-action-button ticket-action-button--{{ jr_selector_class }}">
    <div class="ticket-action-button-tooltip">
      <span class="currency_font currency_font--{{main_proposal.original_gate_currency}}">
        {{ main_proposal.original_gate_price }}
      </span>
    </div>
    <a href="{{ main_proposal.deeplink }}" class="ticket-action-button-deeplink ticket-action-button-deeplink--{{ jr_selector_class }}" role="main-proposal-link" target="_blank">
      <span class="ticket-action-button-deeplink-text">
        {% unsafe main_button_text  %}
        <span class="ticket-action-button-deeplink-text__not-mobile">&nbsp;{{ main_button_additional_text }}<br /></span>
        <strong class="ticket-action-button-deeplink-text__price--not-mobile">
          <span class="currency_font currency_font--{{main_proposal.currency}}">{{ main_proposal.price }}</span>
        </strong>
      </span>
      {% if debug %}
        <DebugTooltip proposal={{main_proposal}}/>
      {% endif %}
      <div class="ticket-action-button-deeplink-spinner">
        <Spinner />
      </div>
    </a>

    <div class="ticket-action-button-price_and_proposal">
      <strong class="ticket-action-button-deeplink-text__price--mobile">
        <span class="currency_font currency_font--{{main_proposal.currency}}">{{ main_proposal.price }}</span>
      </strong>
      <a href="{{ main_proposal.deeplink }}" target="_blank" class="main-proposal-link" role="main-proposal-link">
        <div class="ticket-action__main_proposal ticket-action__main_proposal--{{ jr_selector_class }}">
          {{ on + ' ' }}{{ main_proposal.name }}
        </div>
      </a>
    </div>
  </div>
  {% if kviku_params %}
    <div class="ticket-credit">
      <span role="ticket-credit-button" class="ticket-credit-button">
        Купить в кредит
      </span>
    </div>
  {% endif %}
  <ul class="ticket-action-proposals-list ticket-action-proposals-list--collapsed {{ proposals_count_classname }} ticket-action-proposals-list--{{jr_selector_class}}" role="proposals_list">
    {% for index, proposal of proposals %}
      <li class="ticket-action-proposals-item">
        {% if debug %}
          <DebugTooltip proposal={{proposal}}/>
        {% endif %}
        <a href="{{ proposal.deeplink }}" class="ticket-action-proposals-item-link {{ proposal.is_airline ? 'ticket-action-proposals-item-link--airline': '' }}" role="additional-proposal-link" target="_blank">
          <span class="ticket-action-proposals-item-link__gate">
            {{ proposal.name }}
          </span>
          <span class="ticket-action-proposals-item-link__price">
            <span class="currency_font currency_font--{{proposal.currency}}">{{ proposal.price }}</span>
          </span>
          <span class="ticket-action-proposals-item-link__button">
            {% unsafe buy_button_text %}
          </span>
        </a>
        <div class="ticket-action-proposals-item-tooltip">
          <span class="currency_font currency_font--{{proposal.original_gate_currency}}">
            {{ proposal.original_gate_price }}
          </span>
        </div>
      </li>
      <!-- kviku_params conditions  - from js  / is_kviku_credit_available() function /-->
      <!-- index == 1  - from design  / we need show this at 3rd position COLLAPSED proposals /-->
      <!-- index == (proposals.length-1)  - from design / we need show at last position EXPANDED proposals /-->
      <!-- {index==1 ? 'collapsed' : ''}} / show this only COLLAPSED proposals view /-->
      <!-- {{(proposals.length-1) == 1 ? 'show': ''}} / force show this when expanded and only 2 proposals /-->
      {% if kviku_params && (index == 1  ||  index == (proposals.length-1)) %}
        <li class="ticket-credit-mobile ticket-credit-mobile--{{index==1 ? 'collapsed' : ''}} ticket-credit-mobile--{{(proposals.length-1) == 1 ? 'show': ''}} ticket-action-proposals-item">
          <a role="ticket-credit-button"  class="ticket-action-proposals-item-link">
            <span class="ticket-action-proposals-item-link__gate">
              Билет в кредит
            </span>
            <span class="ticket-action-proposals-item-link__button">
              <span class="ticket-action-button-deeplink-text__not-mobile">
                Купить
              </span>
            </span>
          </a>
        </li>
      {% endif %}
    {% endfor %}
  </ul>
  {% if proposals.length > 2 %}
    <div class="proposals-list-opener-wrapper--mobile_popup">
      <div class="proposals-list-opener proposals-list-opener--open" role="show_more_proposals">
        {{ __('ticket.proposals.more') }}
      </div>
      <div class="proposals-list-opener proposals-list-opener--close" role="show_less_proposals">
        {{ __('ticket.proposals.less') }}
      </div>
    </div>
  {% endif %}
  {% if proposals.length > 3 %}
    <div class="proposals-list-opener-wrapper--desktop ticket-action-proposals-list--{{jr_selector_class}}">
      <div class="proposals-list-opener proposals-list-opener--open" role="ticket-details">
        {{ __('ticket.proposals.more') }}
      </div>
    </div>
  {% endif %}
</div>
