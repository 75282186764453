_extend = require('lodash/extend')
_each = require('lodash/each')
_cloneDeep = require('lodash/cloneDeep')
_mergeWith = require('lodash/mergeWith')
_isArray = require('lodash/isArray')
moment = require('moment')
filters = require('shared/lib/filters.coffee')
utils = require('shared/lib/utils.coffee')
cookies = require('shared/lib/cookies.coffee')
bench = require('shared/lib/bench.coffee')
dictionary = require('shared/lib/dictionary.js').default
PopupScroller = require('shared/lib/popup_scroller.js').default
DOMComponent = require('shared/components/base.js').default
Template = require('flights/components/ticket/ticket.monk')
Monkberry = require('monkberry')
UserSettings = require('shared/components/user_settings/user_settings.js').default
ResizeWatcher = require('shared/lib/resize_watcher.coffee')
metrics = require('shared/lib/metrics.coffee')
presenter = require('./ticket_presenter.coffee')
storage = require('shared/lib/local_storage.coffee')
cookies = require('shared/lib/cookies.coffee')

globParams = {
  currency: UserSettings.getCurrency(),
  search_expired: false,
  trip_class: 'Y'
  params: {}
  kraynov_filter: []
}


templateFilters =
  dates_mobile_header: (date) -> if date then moment.dayLongMonthName(date) else ''
  city_name_shortener: (cities_name) -> if cities_name.length > 28 then cities_name.slice(0,28)+"..." else cities_name
  weekDay_and_date: (date) -> date.format(__('moment.weekDay_date')) if date
  is_NaN: (date) -> isNaN(date)
  price_to_currency: (price) -> filters.split_price(Math.round(price / (dictionary.currencies[globParams.currency] || 1)))

class Ticket extends DOMComponent
  @instance: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @broadcast: (args...) -> super(args...) #HACK static inheritance in CoffeeScript

  @initializeComponent: (dispatcher) ->

    dispatcher.on 'kraynov-filter-new-state', (event, new_state) =>
      globParams.kraynov_filter = new_state
      window.requestAnimationFrame( => this.broadcast('update') )

    dispatcher.on 'currency_updated', (event, currency) =>
      globParams.currency = currency
      window.requestAnimationFrame( => this.broadcast('update') )

    dispatcher.on 'search_expired', =>
      globParams.search_expired = true
      PopupScroller.closePopup()
      window.requestAnimationFrame( => this.broadcast('update') )

    dispatcher.on 'start_search', (event, {request_id, params}) ->
      globParams.search_expired = false
      globParams.trip_class = params.trip_class
      globParams.params = params

    dispatcher.on 'places_restored', (event, params) ->
      globParams.params.segments = _cloneDeep(params.segments)

  @buildCache: bench 'buildCache', (amount = 10) ->
    Monkberry.prerender(Template, 2 * amount) if Template.pool.length < amount * 2

  @defaultOptions: () ->
    name: 'ticket',
    render: {
      template: Template
      options: {filters: templateFilters}
    },
    cssx:
      scope: '.TPWL-widget',
      styles:
        ".ticket-action-button":
          "color": window.TPWLCONFIG.color_scheme.btn_text
        ".ticket-action-button-deeplink:hover":
          "background-color": window.TPWLCONFIG.color_scheme.button_hover,
        ".ticket-action-button__details":
          "background-color": window.TPWLCONFIG.color_scheme.btn_bg,
          "border-color": window.TPWLCONFIG.color_scheme.btn_text
        ".ticket-action-button__details:before":
          "background": "url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%207%205%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ECombined%20Shape%3C%2Ftitle%3E%3Cpath%20d%3D%22M3.4%202.28L5.68%200%206.8%201.12%203.42%204.5l-.02-.02-.02.02L0%201.12%201.12%200%203.4%202.28z%22%20stroke%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.btn_text)}%22%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.btn_text)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') 50% 50% no-repeat"
          "background-size": "5px 7px"
        ".ticket-action-button-deeplink-spinner .spinner > div":
          "background-color": window.TPWLCONFIG.color_scheme.btn_text
        ".flight-brief-layover-tooltip":
          "background-color": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.text_contrast
        ".flight-brief-layover-tooltip:before":
          "border-top-color": window.TPWLCONFIG.color_scheme.bg
        ".ticket-baggage-tooltip":
          "background-color": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.text_contrast
        ".ticket-baggage-tooltip:before":
          "border-top-color": window.TPWLCONFIG.color_scheme.bg
        ".flight-brief-layover-tooltip--change_airports":
          "background-color": "#FE4242"
        ".flight-brief-layover-tooltip--change_airports:before":
          "border-top-color": "#FE4242"
        ".flight-details-leg-additional-ameneties-tooltip":
          "background-color": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.text_contrast
        ".flight-details-leg-additional-ameneties-tooltip:before":
          "border-top-color": window.TPWLCONFIG.color_scheme.bg
        ".kraynov-filter-tooltip":
          "background-color": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.text_contrast
        ".kraynov-filter-tooltip:before":
          "border-top-color": window.TPWLCONFIG.color_scheme.bg
        ".kraynov-filter-tooltip:after":
          "border-top-color": window.TPWLCONFIG.color_scheme.bg
        ".flight-brief-layover:hover .flight-brief-layover__iata:after":
          "background-color": window.TPWLCONFIG.color_scheme.bg
        ".ticket--fake .flight-brief-layover:hover .flight-brief-layover__iata:after":
          "background-color": "rgba(255,255,255,0)"
        ".ticket--fake .ticket-action-button-deeplink:hover":
          "background-color": window.TPWLCONFIG.color_scheme.btn_bg,
        ".flight-brief-layover.flight-brief-layover--change_airports:hover .flight-brief-layover__iata:after":
          "background-color": "rgba(255,255,255,0)"
        ".flight-brief-layover.flight-brief-layover--change_airports:hover .flight-brief-layover__iata:before":
          "background-color": "#FF5353"
        ".flight-details-leg-ameneties-tooltip":
          "background-color": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.text_contrast
        ".flight-details-leg-ameneties-tooltip:before":
          "border-top-color": window.TPWLCONFIG.color_scheme.bg
        ".flight-brief-layover-tooltip__airport_name:before":
          "background": "url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2014%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EAirport%3C%2Ftitle%3E%3Cpath%20d%3D%22M11.397%201.396l-3.105.923S5.644.852%204.32.12C3.902-.108%202.803.35%203.052.63l2.29%202.563-2.682.797L.916%202.982c-.585-.34-1.084.078-.902.29.75.866%202.05%202.354%202.13%202.463.114.15.23.162.39.11.127-.044%207.11-2.13%2010.763-3.215.46-.137.847-.19.73-.672-.05-.21-.392-.396-.835-.515-.57-.152-1.305-.193-1.795-.047zM.143%209.493c0-.28.232-.507.507-.507h12.327c.28%200%20.507.215.507.507%200%20.28-.232.507-.507.507H.65c-.28%200-.507-.215-.507-.507z%22%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.text_contrast)}%22%20stroke-width%3D%22.5%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') no-repeat"
        ".flight-brief-layover-tooltip__duration:before":
          "background": "url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2011%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EClock%3C%2Ftitle%3E%3Cpath%20d%3D%22M5.5%200C2.462%200%200%202.462%200%205.5S2.462%2011%205.5%2011%2011%208.538%2011%205.5%208.538%200%205.5%200zm0%209.9c-2.43%200-4.4-1.97-4.4-4.4%200-2.43%201.97-4.4%204.4-4.4%202.43%200%204.4%201.97%204.4%204.4%200%202.43-1.97%204.4-4.4%204.4zm.69-4.508V3.126c0-.376-.29-.682-.65-.682-.36%200-.65.306-.65.682v2.5c0%20.045.003.09.01.132.007.215.147.434.392.567l2.14.894c.367.198.828.13%201.03-.153.2-.282.068-.673-.298-.872L6.19%205.392z%22%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.text_contrast)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') no-repeat"
        ".flight-details-leg-ameneties-tooltip__night_flight:before, .TPWL-widget .flight-brief-layover-tooltip__duration.flight-brief-layover-tooltip__duration--night:before":
          "background": "url('data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%209%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ENight%3C%2Ftitle%3E%3Cpath%20d%3D%22M8.032%208.707c-.16-.035-.32-.077-.474-.125-1.605-.503-2.846-1.7-3.405-3.283-.56-1.584-.345-3.294.59-4.694.09-.135.187-.268.29-.395.03-.038.037-.092.015-.137C5.026.028%204.98%200%204.93%200c-.542.007-1.078.103-1.595.285-2.6.918-3.967%203.78-3.05%206.38.918%202.6%203.78%203.967%206.38%203.05.516-.183.994-.445%201.42-.78.04-.03.058-.082.046-.13-.01-.05-.05-.087-.098-.098z%22%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.text_contrast)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') 50% 0 no-repeat"
        ".flight-brief-layover-tooltip__duration.flight-brief-layover-tooltip__duration--day:before":
          "background": "url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ECombined%20Shape%3C%2Ftitle%3E%3Cpath%20d%3D%22M7%202.04c.31%200%20.568-.258.568-.568V.568C7.568.258%207.31%200%207%200c-.31%200-.568.258-.568.568v.904c0%20.31.258.57.568.57zm3.9%201.628c.155%200%20.285-.052.414-.18l.645-.647c.232-.23.232-.593%200-.825-.233-.233-.595-.233-.827%200l-.646.646c-.232.233-.232.595%200%20.827.13.13.284.18.413.18zM11.96%207c0%20.31.258.568.568.568h.904c.31%200%20.568-.258.568-.568%200-.31-.258-.568-.568-.568h-.904c-.31%200-.57.258-.57.568zm-.827%204.96c.103.103.258.18.413.18.155%200%20.284-.05.413-.18.232-.233.232-.595%200-.827l-.646-.646c-.233-.232-.594-.232-.827%200-.232.233-.232.594%200%20.827l.646.645zM7.568%2013.43v-.904c0-.31-.258-.57-.568-.57-.31%200-.568.26-.568.57v.904c0%20.31.258.568.568.568.31%200%20.568-.258.568-.568zm-4.7-1.473l.645-.646c.232-.233.232-.594%200-.827-.233-.232-.594-.232-.827%200l-.645.646c-.232.232-.232.594%200%20.826.104.103.26.18.414.18.155-.026.284-.077.413-.18zm-2.3-4.392h.904c.31%200%20.57-.258.57-.568%200-.31-.26-.568-.57-.568H.568C.258%206.432%200%206.69%200%207c0%20.31.258.568.568.568zm2.118-4.055c.104.103.26.18.414.18.155%200%20.284-.05.413-.18.232-.233.232-.594%200-.827l-.646-.645c-.232-.232-.594-.232-.826%200-.232.233-.232.595%200%20.827l.646.646zM7%2010.255c1.782%200%203.255-1.447%203.255-3.255%200-1.808-1.447-3.255-3.255-3.255-1.808%200-3.255%201.473-3.255%203.255%200%201.782%201.473%203.255%203.255%203.255z%22%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.text_contrast)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') 50% 50% no-repeat"
        ".kraynov-filter-checkbox input[type=\"checkbox\"]:checked + span:before":
          "background": "#{window.TPWLCONFIG.color_scheme.bg} url(\"data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%228%22%20viewBox%3D%220%200%2010%208%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M3.936%204.81L1.49%202.246%200%203.82%203.936%208%2010%201.573%208.51%200%203.937%204.81z%22%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.text_contrast)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E\") no-repeat center center"
        ".ticket-action-proposals-item-link__button":
          "color": window.TPWLCONFIG.color_scheme.btn_text
          "background-color": window.TPWLCONFIG.color_scheme.btn_bg
        ".ticket-action-proposals-item-link__button:hover":
          "background-color": window.TPWLCONFIG.color_scheme.button_hover
        ".ticket-header":
          "background-color": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.btn_text
        ".ticket-credit-button":
          "color": window.TPWLCONFIG.color_scheme.link
        ".ticket-action-proposals-item-tooltip":
          "background-color": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.text_contrast
        ".ticket-action-proposals-item-tooltip:before":
          "border-top-color": window.TPWLCONFIG.color_scheme.bg
        ".ticket-action-button-tooltip":
          "background-color": window.TPWLCONFIG.color_scheme.bg
          "color": window.TPWLCONFIG.color_scheme.text_contrast
        ".ticket-action-button-tooltip:before":
          "border-top-color": window.TPWLCONFIG.color_scheme.bg,
        ".flight-details-leg--mobile .leg-details":
          "border-color": window.TPWLCONFIG.color_scheme.bg
        ".proposals-list-opener":
          "color": window.TPWLCONFIG.color_scheme.link
        ".TPWL-widget .TPWL_widget--xl .ticket-action-proposals-item":
          "color": window.TPWLCONFIG.color_scheme.link
        ".TPWL-widget .TPWL_widget--l .ticket-action-proposals-item":
          "color": window.TPWLCONFIG.color_scheme.link
        ".TPWL-widget .TPWL_widget--m .ticket-action-proposals-item":
          "color": window.TPWLCONFIG.color_scheme.link
        ".TPWL_widget--s .ticket-credit-mobile .ticket-action-proposals-item-link .ticket-action-proposals-item-link__button":
          "color": window.TPWLCONFIG.color_scheme.btn_bg
        ".TPWL_widget--xs .ticket-credit-mobile .ticket-action-proposals-item-link .ticket-action-proposals-item-link__button":
          "color": window.TPWLCONFIG.color_scheme.btn_bg
        ".TPWL-widget .TPWL_widget--s .tariffs__item--active":
          "border-bottom-color": window.TPWLCONFIG.color_scheme.bg
        ".TPWL-widget .TPWL_widget--xs .tariffs__item--active":
          "border-bottom-color": window.TPWLCONFIG.color_scheme.bg
        ".proposals-list-opener:before":
          "background": "url(\"data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%207%205%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ECombined%20Shape%3C%2Ftitle%3E%3Cpath%20d%3D%22M3.4%202.28L5.68%200%206.8%201.12%203.42%204.5l-.02-.02-.02.02L0%201.12%201.12%200%203.4%202.28z%22%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.link)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E\") 50% 50% no-repeat"
        ".proposals-list-opener:after":
          "background": "url(\"data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%207%205%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ECombined%20Shape%3C%2Ftitle%3E%3Cpath%20d%3D%22M3.4%202.28L5.68%200%206.8%201.12%203.42%204.5l-.02-.02-.02.02L0%201.12%201.12%200%203.4%202.28z%22%20fill%3D%22#{encodeURIComponent(window.TPWLCONFIG.color_scheme.link)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E\") 50% 50% no-repeat"


  constructor: (containerNode, options = {}) ->
    super(containerNode, options)
    return false if options.onlyInit
    this.rawState = this.state
    this.state = presenter(this.rawState, globParams)
    this.state.baggage_expanded = if this.state.proposals && this.state.proposals.otherBaggage.length then this.state.baggage_price < 0 && this.state.proposals.hasBaggage.length || false else true
    this.state.ticket_expanded = false
    this.state.ticket_open = false
    this.state.debug = storage.getItem('proposals_debug') || cookies.get('proposals_debug')
    this.refresh()
    this.ticketNode = this.view.createDocument()

  mergeArrays: (objValue, srcValue) ->
    return srcValue if _isArray(srcValue)

  update: (options) ->
    this.rawState = options || this.rawState
    this.state = _mergeWith({}, this.state, presenter(this.rawState, globParams), this.mergeArrays)
    this.state.baggage_expanded = if this.state.proposals.otherBaggage.length  then this.state.baggage_price < 0 && this.state.proposals.hasBaggage.length || false else true
    if this.open_ticket_state && this.open_ticket_state.index == this.state.index
      this.oldState = this.state
    else
      this.refresh()


  _initDOMEvents: (view) ->
    view.on('click', '[role="ticket-details"],[role="proposals-toggler"]', (event) =>
      unless event.target.getAttribute('data-is-kraynov-checkbox') == 'true'
        @expandTicket()
    )

    view.on('click', '[role="ticket-mobile-back"]', (event) =>
      @closeModal()
    )

    view.on('click', '[role="otherBaggage"]', (event, target) =>
      this.update()
      this.state.baggage_expanded = false
      metrics.reach_goal("BAGGAGE_TAB_CLICK")
      this.refresh()
    )

    view.on('click', '[role="hasBaggage"]', (event, target) =>
      this.state.baggage_expanded = true
      metrics.reach_goal("BAGGAGE_TAB_CLICK")
      this.refresh()
    )

    view.on('click', '[role="main-proposal-link"]', (event) =>
      return event.preventDefault() if this.state.preloaded
      if globParams.search_expired
        event.preventDefault()
        @_dispatcher.send('start_search', {request_id: utils.generate_id(), params: globParams.params}, 'search_results')
      else
        @_dispatcher.send('ecommerce_buy_ticket', this.rawState, 'ticket_component')
        @_dispatcher.send('buy_button_clicked')
    , true)

    view.on('click', '[role="additional-proposal-link"]', (e) =>
      if globParams.search_expired
        event.preventDefault()
        @_dispatcher.send('start_search', {request_id: utils.generate_id(), params: globParams.params}, 'search_results')
      else
        @_dispatcher.send('ecommerce_buy_ticket', this.rawState, 'ticket_component')
        @_dispatcher.send('buy_gate_clicked')
    , true)

    view.on('click', '[role="kraynov-hash"]', (e) =>
      @_dispatcher.send('kraynov-filter-clicked', e.target.getAttribute('value'))
    , true)

    view.on('click', '[role="show_more_proposals"]', (e) =>
      proposals_list = view.querySelector('[role="proposals_list"]')
      proposals_list.classList.remove("ticket-action-proposals-list--collapsed")
      proposals_list.classList.add("ticket-action-proposals-list--expanded")
    )

    view.on('click', '[role="show_less_proposals"]', (e) =>
      proposals_list = view.querySelector('[role="proposals_list"]')
      proposals_list.classList.add("ticket-action-proposals-list--collapsed")
      proposals_list.classList.remove("ticket-action-proposals-list--expanded")
    )

    view.on('click', '[role="ticket-credit-button"]', (e) =>
      form = view.querySelector('[role="credit-form"]')
      metrics.reach_goal("kviku_lead")
      form.submit()
    )

    view.on('mouseenter', '[role="ticket-container"]', (e) =>
      @_dispatcher.send('ecommerce_impression_ticket', this.rawState, 'ticket_component')
    )

  expandTicket: ->
    unless this.state.preloaded
      unless this.ticketNode.classList.contains('ticket--expanded')
        @_dispatcher.send('ecommerce_expand_ticket', this.rawState, 'ticket_component')
        this.ticketNode.classList.add('ticket--open')
        this.state.ticket_open = true
        setTimeout(=>
          this.ticketNode.classList.add('ticket--expanded')
          this.state.ticket_expanded = true
        , 50)

        if ResizeWatcher.width < 440
          this.open_ticket_state = this.state
          @_dispatcher.send('ticket_open', this)
          @_dispatcher.send('modal_opened', {id: 'ticket_details', closeFunc: ( => @closeModal()), openFunc: ( => @expandTicket())})
          PopupScroller.toggle()
        metrics.reach_goal("TICKET_DETAILS_EXPAND")
      else
        if ResizeWatcher.width >= 440
          this.ticketNode.classList.add('ticket--closing')
          this.ticketNode.classList.remove('ticket--expanded')
          this.state.ticket_expanded = false
          setTimeout(=>
            this.ticketNode.classList.remove('ticket--closing')
            this.ticketNode.classList.remove('ticket--open')
            this.state.ticket_open = false
          , 300)
        metrics.reach_goal("TICKET_DETAILS_CLOSE")

  closeModal: ->
    unless this.state.preloaded
      if this.oldState
        delete this.open_ticket_state
        this.state = this.oldState
      this.ticketNode.classList.toggle('ticket--expanded')
      this.state.ticket_expanded = false
      if ResizeWatcher.width < 440
        @_dispatcher.send('modal_closed', {id: 'ticket_details'})
        PopupScroller.toggle()
        this.refresh()


delete(new Ticket(document.createElement('div'), {onlyInit: true})) #HACK for styles initialize

module.exports = Ticket
