import _keys from 'lodash/keys'
import _defaultsDeep from 'lodash/defaultsDeep'
import InformerWithModal from 'shared/components/informer_with_modal/informer_with_modal'
import Template from './select.monk'

export default class Select extends InformerWithModal {
  static defaultOptions () {
    return _defaultsDeep({
      name: 'select',
      render: {
        template: Template,
        options: {
          filters: {
            formatSelected (text) { return text }
          }
        }
      },
      state: {
        ids: [],
        options: {}
      },
      informerPrefix: 'custom_select'
    }, super.defaultOptions())
  }

  constructor (node, options = {}) {
    super(node, options)

    if (this.state.ids.length === 0) { this.state.ids = _keys(this.state.options) }
    if (!('selectedId' in this.state)) { this.state.selectedId = this.state.ids[0] }

    this.refresh()
  }

  get selected () {
    return this.state.selectedId
  }
}
