class Converter {
  constructor () {
    this.rates = {}
    this.base = 'rub'
    this.currency = 'usd'
  }

  /**
   * Covert rate and format price.
   * @param {number} price
   * @returns {string}
   */
  format (price, rounding) {
    return this.formatUnifiedPrice(this.convert(price), rounding)
  }

  /**
   * Only format price, without convert.
   * @param {number} price
   */
  formatUnifiedPrice (price, rounding) {
    if (price) {
      return /(\d{1,3})(\d{3})?(\d{3})?(\d{3})?(\d{3})?$/.exec('' + (rounding || Math.round).call(this, price)).slice(1).join(String.fromCharCode(160)).trim()
    } else {
      return 0
    }
  }

  convert (val, from, to) {
    to = to || this.currency
    from = from || this.base
    return val / this.getRate(from, to) // Multiple the value by the exchange rate
  }

  revert (val) {
    return this.convert(val, this.currency, this.base)
  }

  getRate (from, to) {
    if (!this.rates) {
      return from
    }
    // Make sure the base rate is in the rates object:
    this.rates[this.base] = 1
    if (!this.rates[to] || !this.rates[from]) {
      console.error(`One of rates does not found: ${to}/${from}`)
    }

    // If `from` currency === this.base, return the basic exchange rate for the `to` currency
    if (from === this.base) {
      return this.rates[to]
    }

    // If `to` currency === this.base, return the basic inverse rate of the `from` currency
    if (to === this.base) {
      return 1 / this.rates[from]
    }

    // Otherwise, return the `to` rate multipled by the inverse of the `from` rate to get the
    // relative exchange rate between the two currencies
    return this.rates[to] * (1 / this.rates[from])
  }
}

module.exports = new Converter()
