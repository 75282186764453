export function shadeBlend (p, c0, c1) {
  var n = p < 0 ? p * -1 : p, u = Math.round, w = parseInt
  if (c0.length > 7) {
    var f = c0.split(','), t = (c1 || (p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)')).split(','), R = w(f[0].slice(4)), G = w(f[1]), B = w(f[2])
    return 'rgb(' + (u((w(t[0].slice(4)) - R) * n) + R) + ',' + (u((w(t[1]) - G) * n) + G) + ',' + (u((w(t[2]) - B) * n) + B) + ')'
  } else {
    var f = w(c0.slice(1), 16), t = w((c1 || (p < 0 ? '#000000' : '#FFFFFF')).slice(1), 16), R1 = f >> 16, G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF
    return '#' + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1)
  }
};

export function rgbToHsl (r, g, b) {
  r /= 255, g /= 255, b /= 255
  var max = Math.max(r, g, b), min = Math.min(r, g, b)
  var h, s, l = (max + min) / 2

  if (max == min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break
      case g: h = (b - r) / d + 2; break
      case b: h = (r - g) / d + 4; break
    }
    h /= 6
  }

  return [h, s, l]
}

export function rgbToHex (r, g, b) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

export function toHex (color) {
  color = color.trim()
  if (color[0] == '#') return color
  if (color.substr(0, 3) == 'rgb') {
    let rgb = hexToRgb(/rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(,\s*(\d*\.?\d*)\s*)?\)/.exec(color))
    if (rgb[1]) return rgbToHex(rgb[1], rgb[2], rgb[3])
  }
  return false
}

export function hexToRgb (hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export function getBGColor (el) {
  var compStyle
  if (window.getComputedStyle) {
    compStyle = window.getComputedStyle(el, '')
  } else {
    compStyle = el.currentStyle
  }
  if (compStyle.backgroundColor === 'transparent') {
    return 'rgba(0,0,0,0)'
  } else {
    return compStyle.backgroundColor
  }
};

export function hexToHsl (hex) {
  let rgb = hexToRgb(hex)
  return rgbToHsl(rgb.r, rgb.g, rgb.b)
};

export function hexToRgba (hex, opacity) {
  let rgb = hexToRgb(hex)
  if (rgb) return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + opacity + ')'
};

export function isBright (hex) {
  if (hex === 'transparent' || hex === '') return true
  hex = hex.replace('#', '')
  if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]

  let hsl = hexToHsl(hex)

  return hsl[2] > 0.8
};

export function getNotTransparentBGColor (el) {
  var bg, currentEl
  currentEl = el
  while (true) {
    bg = getBGColor(currentEl)
    if (bg.split(',').length < 4 || bg.split(',')[3].trim() !== '0)' || currentEl === document.body) break
    currentEl = currentEl.parentElement
  }
  if (bg.split(',').length > 1) {
    let nums = ((bg.split('(')[1]).split(')')[0]).split(',')
    bg = rgbToHex(+nums[0], +nums[1].trim(), +nums[2].trim())
  }

  return bg
};

export function clarify (color, opacity) {
  return hexToRgba(toHex(color), opacity)
};

export default {
  shadeBlend,
  toHex,
  rgbToHsl,
  rgbToHex,
  hexToRgb,
  hexToRgba,
  getBGColor,
  isBright,
  getNotTransparentBGColor,
  clarify
}
