{% import PriceTile from '../shared/price_tile.monk' %}

<div class="calendar_line calendar_line--round_trip" role="calendar_line--wrapper">
  <ul class="calendar_line_prices">
    {% for index, price of prices %}
      {% if(price.impossible) %}
        <li class="forbidden_dates" role="cell" data-cell="{{ price.depart_date }}/{{ price.return_date }}">—</li>
      {% else %}
        <li class="{{ (current_depart_date === price.depart_date && current_return_date === price.return_date) ? 'price--current' : '' }}" role="cell" data-cell="{{ price.depart_date }}/{{ price.return_date }}">
          <PriceTile
            price="{{ price }}"
            currency="{{ currency }}"
            depart_date="{{ price.depart_date }}"
            return_date="{{ price.return_date }}"
            depart_date_formatted="{{ depart_dates.dict[price.depart_date] ? depart_dates.dict[price.depart_date].formatted : '' }}"
            return_date_formatted="{{ return_dates.dict[price.return_date] ? return_dates.dict[price.return_date].formatted : '' }}"
            min_price="{{ minPrice }}"
            is_current_date="{{ current_depart_date === price.depart_date && current_return_date === price.return_date }}"
            current_min_price="{{ direct_only ? currentDirectMinPrice : currentMinPrice }}"
          />
        </li>
      {% endif %}
    {% endfor %}
  </ul>
  <div class="calendar_line-info">
    <span class="calendar_line-info__description">{{ __('calendar.button') }}</span>
    <span class="calendar_line-info_toggle" role="calendar_show">{{ __('calendar.toggle') }}</span>
  </div>
</div>
