_each         = require('lodash/each')
_isArray      = require('lodash/isArray')
_extend       = require('lodash/extend')
xhr           = require('xhr')
metrics       = require('./metrics.coffee')

SETTINGS =
  locale: LOCALE
  host: "//autocomplete.travelpayouts.com/places2"
  max: 6

localesMap =
  tg: "ru"
  ca: "es"
  be: "ru"
  bs: "hr"
  ce: "ru"
  kk: "ru"
  uz: "ru"
  me: "sr"
  en_us: "en"
  sr_cs: "en"
  az: "ru"
  bg: "en"
  bn: "en"
  br: "pt-BR"
  cs: "cs"
  da: "da"
  de: "de"
  el: "el"
  en: "en"
  es: "es"
  fi: "fi"
  fr: "fr"
  hu: "hu"
  id: "id"
  is: "en"
  it: "it"
  ja: "en"
  ka: "en"
  lt: "lt"
  lv: "en"
  ms: "ms"
  mn: "en"
  nl: "en"
  no: "no"
  pl: "pl"
  pt: "pt"
  ro: "ro"
  ru: "ru"
  sk: "sk"
  sl: "en"
  sr: "en"
  sv: "sv"
  th: "th"
  tr: "tr"
  tw: "zh-TW"
  uk: "uk"
  us: "en"
  vi: "vi"
  zh: "zh-Hans"

callback_stub = (data) -> console.log(data)

_cache = {}

module.exports = (settings = {}) ->
  settings = _extend(SETTINGS, settings)
  settings.locale = settings.locale.toLowerCase()
  _running = null
  _aborted = false
  settings.locale = localesMap[settings.locale] if localesMap[settings.locale]

  load: (term, callback = callback_stub) ->
    callbacks = if !_isArray(callback)
        [callback]
      else
        callback

    if _running
      _aborted = true
      _running.abort()

    if _cache[term]
      _each(callbacks, (c) -> c(_cache[term]))
      return

    _running = xhr({
        uri: "#{settings.host}?locale=#{settings.locale}&term=#{encodeURIComponent(term)}&types=city%2Cairport"
        method: "get"
        useXDR: true
      }, (err, res, body) =>
        if typeof body == "string"
          body = JSON.parse(body)

        if err
          if !_aborted
            metrics.reach_goal('AUTOCOMPLETE_ERROR')
        else
          _cache[term] = body
          _each(callbacks, (c) -> c(body))
        _aborted = false
    )
